import { TabPanel, TabPanels, Tabs } from "@vygruppen/spor-react";
import { GlobalEventResponse } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { GlobalEventsList } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/GlobalEventsList";

type GlobalEventsProps = {
  data: GlobalEventResponse[];
  onEditClick: (event: GlobalEventResponse) => void;
};

export const GlobalEvents = ({ data, onEditClick }: GlobalEventsProps) => (
  <Tabs variant="accent" colorScheme="base" isFitted gap={3} size="sm">
    <TabPanels>
      <TabPanel>
        <GlobalEventsList data={data} onEditClick={onEditClick} />
      </TabPanel>
    </TabPanels>
  </Tabs>
);
