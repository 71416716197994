import { Box, Flex, Text } from "@vygruppen/spor-react";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { internalMessagesResponseToFormHistory } from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { FC } from "react";
import { toButtonSeverity } from "shared/components/buttons/SemanticButton";
import { SeverityColors } from "shared/components/buttons/SemanticButtonPrimary";
import {
  Severity,
  severityBorderColor,
  severitySubtitleColor,
} from "shared/components/feedback/InfoMessage/severity";
import { NoteFill24Icon } from "shared/icons/NoteIcon";
import { formatDayDateString } from "shared/utils/datetime";
import { Nullish } from "shared/utils/objectUtils";
import { useTheme } from "styled-components";

type MessageProps = {
  updatedAt?: string;
  message: string;
  user?: string;
  isInternalMessage?: boolean;
  severity: Severity;
};

export type VersionProps = MessageProps & {
  isLastElement: boolean;
  internalMessages?: Nullish<InternalMessageResponse[]>;
};

const Message: FC<MessageProps> = ({
  updatedAt,
  message,
  user,
  severity,
  isInternalMessage,
}) => {
  const theme = useTheme();
  const fill = SeverityColors(toButtonSeverity(severity), theme).severity;
  return (
    <Flex flexDir="column" pb={3}>
      {isInternalMessage && (
        <Box position="absolute" ml="-29px">
          <NoteFill24Icon $fill={fill} />
        </Box>
      )}
      <Text variant="xs">{message}</Text>
      {(updatedAt || user) && (
        <Flex mt={2} mb={1} w="100%" justifyContent="space-between">
          <Text variant="xs">{formatDayDateString(updatedAt)}</Text>
          <Text variant="xs">{user}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export const Version: FC<VersionProps> = ({
  updatedAt,
  message,
  isLastElement,
  severity,
  user,
  internalMessages,
}) => {
  const theme = useTheme();
  const internalMessagesHistory =
    internalMessagesResponseToFormHistory(internalMessages);
  const latestInternalMessage =
    internalMessagesHistory[internalMessagesHistory.length - 1];
  return (
    <li key={updatedAt}>
      <Flex
        flexDir="column"
        w="100%"
        borderBottom={`${
          isLastElement ? "0px" : "1px"
        } dashed ${severityBorderColor(severity, theme)}`}
        color={severitySubtitleColor(severity, theme)}
      >
        <Message
          message={message}
          updatedAt={updatedAt}
          user={user}
          severity={severity}
        />
        {latestInternalMessage && (
          <Message
            {...latestInternalMessage}
            severity={severity}
            isInternalMessage
          />
        )}
      </Flex>
    </li>
  );
};
