import { useQuery } from "@tanstack/react-query";
import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import {
  Box,
  Button,
  ChoiceChip,
  Flex,
  SearchInput,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { useEffect, useState } from "react";
import {
  ChangeInternalFontSize,
  ChecklistHistoryWrapper,
  NoDivOverflowButton,
  NoLabelOverflow,
} from "./ChecklistCustomStyle";
import ChecklistModal from "./ChecklistModal";
import ChecklistTable from "./ChecklistTable";
import { FillOut, FillOuts, FillOutsSchema } from "./schema/responseSchema";
import { CheckListType, getChecklistText } from "./utils";

enum modalType {
  create = "create",
  edit = "edit",
  closed = "closed",
}

const Checklist = () => {
  const [filter, setFilter] = useState<string[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [modal, setModal] = useState<modalType>(modalType.closed);
  const [showAll, setShowAll] = useState(false);

  const {
    data: checklistFillOuts,
    refetch,
    isLoading,
  } = useQuery({
    queryKey: ["checklist", searchQuery],
    queryFn: ({ signal }) =>
      searchQuery.length > 1
        ? queryFnGET<FillOuts>({
            signal,
            url: `${getBackendUrl()}/checklist/fill-outs/?rollingStockId=${searchQuery}`,
          }).then((data) => FillOutsSchema.parse(data))
        : Promise.resolve({ fillOuts: [] }),
    staleTime: 10,
  });

  const fillOuts = checklistFillOuts ? checklistFillOuts.fillOuts : [];

  const filteredFillOuts = fillOuts.filter(
    (fillOut: FillOut) =>
      filter.length === 0 || filter.includes(fillOut.checklistType),
  );

  const displayedFillOuts = filteredFillOuts.slice(0, showAll ? undefined : 8);

  const handleFilterToggle = (type: string) => {
    setFilter((prev) =>
      prev.includes(type)
        ? prev.filter((item) => item !== type)
        : [...prev, type],
    );
  };

  const handleModalClose = () => setModal(modalType.closed);

  useEffect(() => {
    refetch();
  }, [searchQuery, filter, refetch]);

  return (
    <ChecklistHistoryWrapper>
      <ErrorBoundary>
        <VStack>
          <Box maxWidth="1600px" width="100%">
            <Flex
              w="100%"
              justifyContent="space-between"
              alignItems="center"
              mt="4"
            >
              <Flex alignItems="center" pl="1" w="100%" maxW="1200px">
                <Flex alignItems="center" flex={1} pr="1">
                  <NoLabelOverflow>
                    <SearchInput
                      autoFocus
                      type="search"
                      label="Søk etter kjøretøy-id"
                      value={searchQuery}
                      onChange={(e) =>
                        setSearchQuery(e.target.value.toUpperCase())
                      }
                      maxWidth="600px"
                    />
                  </NoLabelOverflow>
                  <Flex gap={2} alignItems="center">
                    <Text pl="2">Vis</Text>
                    {Object.values(CheckListType).map((type) => (
                      <ChangeInternalFontSize key={type}>
                        <ChoiceChip
                          size="sm"
                          variant="base"
                          isChecked={filter.includes(type)}
                          onChange={() => handleFilterToggle(type)}
                        >
                          {getChecklistText(type)}
                        </ChoiceChip>
                      </ChangeInternalFontSize>
                    ))}
                  </Flex>
                </Flex>
              </Flex>
              <NoDivOverflowButton>
                <Button
                  variant="secondary"
                  onClick={() => setModal(modalType.create)}
                  leftIcon={<AddOutline24Icon />}
                  size="md"
                >
                  Opprett ny kvittering
                </Button>
              </NoDivOverflowButton>
            </Flex>
            <ChecklistTable
              fillouts={displayedFillOuts}
              totalFillOuts={fillOuts.length}
              isLoading={isLoading}
              refetch={refetch}
            />
            {fillOuts.length > 0 && (
              <Flex
                justifyContent="end"
                alignItems="center"
                pt="10px"
                pb="24px"
              >
                <Text pr={2}>
                  Viser {displayedFillOuts.length} av {filteredFillOuts.length}{" "}
                  kvitteringer
                </Text>
                {fillOuts.length > 8 && (
                  <Button
                    variant="tertiary"
                    onClick={() => setShowAll(!showAll)}
                  >
                    {showAll ? "Vis færre" : "Vis alle"}
                  </Button>
                )}
              </Flex>
            )}
          </Box>
          {modal === "create" && (
            <ChecklistModal
              onClose={handleModalClose}
              currentFillOut={null}
              refetch={refetch}
            />
          )}
        </VStack>
      </ErrorBoundary>
    </ChecklistHistoryWrapper>
  );
};

export default Checklist;
