import { ArrowRightOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { FC, useState } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import TransferWithSearch from "./TransferWithSearch";

type Props = {
  requestId: string;
};

const ShowTransferWithSearch: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button
    variant="tertiary"
    size="sm"
    leftIcon={<ArrowRightOutline24Icon />}
    onClick={onClick}
  >
    Sett videre
  </Button>
);

const CallTransfer = (props: Props) => {
  const [showTransfer, setShowTransfer] = useState(false);

  const { requestId } = props;
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {!showTransfer ? (
        <ShowTransferWithSearch
          onClick={() => {
            setShowTransfer(true);
          }}
        />
      ) : (
        <>
          <Text>Sett videre:</Text>
          <TransferWithSearch
            requestId={requestId}
            closeSearch={() => setShowTransfer(false)}
          />
        </>
      )}
    </div>
  );
};

export default CallTransfer;
