import {
  CalendarDateTime,
  getLocalTimeZone,
  toZoned,
} from "@internationalized/date";
import { CountryCode, TrainIdentifier } from "@vygruppen/vy-train-map";
import {
  TextMap,
  Visibility,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { PeriodicFormDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";

export type PreviewResponse = {
  internalMessage: string;
};

export type OperationalIdentifier_JSON = {
  operational_identifier: string;
  nominal_date: string;
  country_code: CountryCode;
};

export function trainIdentifierToOperationalIdentifierJson({
  identifier,
  countryCode,
  nominalDate,
}: TrainIdentifier): OperationalIdentifier_JSON {
  return {
    operational_identifier: identifier,
    country_code: countryCode,
    nominal_date: nominalDate,
  };
}

export function operationalIdentifierJsonToTrainIdentifier({
  operational_identifier,
  country_code,
  nominal_date,
}: OperationalIdentifier_JSON): TrainIdentifier {
  return {
    identifier: operational_identifier,
    countryCode: country_code,
    nominalDate: nominal_date,
  };
}

export function trainIdentifierIsEqual(
  first: TrainIdentifier,
  second: TrainIdentifier,
): boolean {
  return (
    first.identifier === second.identifier &&
    first.nominalDate === second.nominalDate &&
    first.countryCode === second.countryCode
  );
}

export type TrainIdentifierWithIncidentIds_JSON = OperationalIdentifier_JSON & {
  incident_ids: string[];
};

export type TrainIdentifierWithIncidentIdString =
  `${string}-${OperationalIdentifier_JSON["country_code"]}-${OperationalIdentifier_JSON["operational_identifier"]}-${OperationalIdentifier_JSON["nominal_date"]}`;

export type PreviewResponseBatch = {
  preview: PreviewResponse;
  train: OperationalIdentifier_JSON;
}[];

export type InfrastructureEventCreatedResponse = {
  uuid: string;
  incidentId: string;
  texts: TextMap;
  visibility: Visibility;
};

export type Stop = {
  name: string;
  id: string;
};

export type Leg = {
  fromStop: Stop;
  toStop: Stop;
};
export const formatTime = (time: CalendarDateTime) => {
  const localTimeZone = getLocalTimeZone();
  return toZoned(time, localTimeZone).toString();
};
export const initialPeriodicDuration: PeriodicFormDuration = {
  type: "PERIODIC",
  rules: [],
};
