import styled from "styled-components";

type CollapsibleWrapperProps = {
  $gridArea: string;
  $isOpen?: boolean;
  $side: "left" | "right";
  $multipleTabs?: boolean;
};

const SIDE_PANEL_WIDTH_MIN = 420;
const SIDE_PANEL_WIDTH_MAX = 550;

const makeClamp = (multipleTabs?: boolean) => {
  const min = SIDE_PANEL_WIDTH_MIN * (multipleTabs ? 2 : 1);
  const max = SIDE_PANEL_WIDTH_MAX * (multipleTabs ? 2 : 1);
  return `clamp(${min}px, 20vw, ${max}px)`;
};

export const CollapsibleWrapper = styled.div<CollapsibleWrapperProps>`
  display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
  grid-area: ${({ $gridArea }) => $gridArea};
  min-width: ${({ $multipleTabs }) => makeClamp($multipleTabs)};
  max-width: ${({ $multipleTabs }) => makeClamp($multipleTabs)};
  padding-left: ${({ $side }) => ($side === "left" ? "12px" : "0px")};
  padding-right: ${({ $side }) => ($side === "right" ? "12px" : "0px")};
  min-height: 0; // https://css-tricks.com/preventing-a-grid-blowout/
  filter: ${({ theme }) => theme.dropShadow};
`;
