import { ChoiceChip } from "@vygruppen/spor-react";
import styled, { css } from "styled-components";

export const ChoiceChipForSuccessBackground = styled(ChoiceChip)`
  text-wrap: nowrap;

  > div:first-of-type {
    ${({ isChecked }) =>
      !isChecked
        ? css`
        outline-color: ${({ theme }) => theme.colorSuccess};
        border-radius: 6px;
        padding: 12px;
    > span {
      color: ${({ theme }) => theme.colorSuccessTextMain};
      font-weight: 600;
      font-size: 14px;
   `
        : css`
        border-radius: 6px;
        padding: 12px;
        background: ${({ theme }) => theme.colorSuccessFill};
        outline-color: ${({ theme }) => theme.colorSuccessOutline};
        :hover {color: ${({ theme }) => theme.colorSuccessTextTertiary};}
    > span {
      color: ${({ theme }) => theme.colorSuccessTextMain};
      font-weight: 600;
      font-size: 14px;
    
   `}
  }
`;
