import { Divider, Flex, Textarea } from "@vygruppen/spor-react";
import { MessageListElement } from "features/CenterContent/RoleContent/InternalMessage/shared/MessageListElement";
import { DropsExpandable } from "features/CenterContent/VehicleDetails/shared/DropsExpandable";
import { useFormContext } from "react-hook-form";
import { EndsWith } from "shared/utils/objectUtils";
import { InternalMessagesForm } from "../schema/formSchema";

export const MessageList = ({
  formField,
  collapsed,
  maxHeight,
}: {
  formField: EndsWith<"internalMessages">;
  collapsed: boolean;
  maxHeight?: string;
}) => {
  const { register, watch } = useFormContext();

  const watchedFormField: InternalMessagesForm = watch(formField);
  const messages = watchedFormField || [];

  const prevMessages = messages.slice(0, -1);
  const messageTextField = `${formField}[${messages.length - 1}].message`;
  const description = watch(messageTextField);
  if (description === undefined) {
    console.log(
      `FormField (${messageTextField}) does not have a defaultValue. This component is most likely set up wrong.\n
       Use internalMessagesFormDefault() or internalMessagesResponseToForm() as starting value.`,
    );
    return null;
  }

  return (
    <DropsExpandable collapsed={collapsed} mt={collapsed ? "0px" : "12px"}>
      <Divider />
      <Flex
        flexDir="column"
        maxHeight={maxHeight}
        overflowY="auto"
        position="relative"
      >
        {prevMessages.map((message, index) => (
          <MessageListElement
            key={index}
            {...message}
            isLastEntry={index === messages.length - 1}
          />
        ))}
        <Textarea
          {...register(messageTextField)}
          label="Skriv ny melding..."
          resize="none"
          m="6px"
          my="18px"
        />
      </Flex>
    </DropsExpandable>
  );
};
