import { matchQueryToPhoneNumber } from "shared/regex";
import {
  AlternativeTransport,
  ReserveAlternativeTransport,
} from "shared/types/alternativeTransport";

type TransportType = AlternativeTransport | ReserveAlternativeTransport;

type Filter = (
  transport: TransportType,
  searchQuery: string,
  selectedFilter: string,
) => boolean;

type FilterOption = {
  name: string;
  matcher: (transport: TransportType, query: string) => boolean;
};

const MINIMUM_SEARCH_LENGTH_FOR_PHONE_NUMBERS = 4;

const matchPhoneNumber = (
  searchQuery: string,
  phoneNumber: string,
): boolean => {
  if (searchQuery.startsWith("+")) {
    return phoneNumber.startsWith(searchQuery);
  }

  if (searchQuery.length < MINIMUM_SEARCH_LENGTH_FOR_PHONE_NUMBERS) {
    return false;
  }
  return matchQueryToPhoneNumber(searchQuery).test(phoneNumber);
};

const matchesWorkShiftId = (workShiftId: string | null, query: string) =>
  workShiftId?.includes(query) ?? false;

const matchesDriverPhoneNumber = (
  driverPhoneNumber: string | null,
  query: string,
) => (driverPhoneNumber ? matchPhoneNumber(query, driverPhoneNumber) : false);

const matchesTrainIds = (trainIds: string[], query: string) =>
  trainIds.filter((trainId) => trainId.toLowerCase().startsWith(query)).length >
  0;

const matchesOriginStopName = (
  originStopName: string | undefined,
  query: string,
) => originStopName?.toLowerCase().startsWith(query) ?? false;

const matchesDestinationStopName = (
  destinationStopName: string | null,
  query: string,
) => destinationStopName?.toLowerCase().startsWith(query) ?? false;

const getReserveFilterOptions = (
  transport: ReserveAlternativeTransport,
): FilterOption[] => [
  {
    name: "Telefonnummer",
    matcher: (_, query) =>
      matchesDriverPhoneNumber(transport.driverPhoneNumber, query),
  },
  {
    name: "Skiftnummer",
    matcher: (_, query) => matchesWorkShiftId(transport.workShiftId, query),
  },
];

const getAlternativeFilterOptions = (
  transport: AlternativeTransport,
): FilterOption[] => [
  {
    name: "Telefonnummer",
    matcher: (_, query) =>
      matchesDriverPhoneNumber(transport.driverPhoneNumber, query),
  },
  {
    name: "Skiftnummer",
    matcher: (_, query) => matchesWorkShiftId(transport.workShiftId, query),
  },
  {
    name: "Tognummer",
    matcher: (_, query) => matchesTrainIds(transport.trainIds, query),
  },
];

const getDefaultMatchers = (
  transport: TransportType,
): ((transport: TransportType, query: string) => boolean)[] => {
  if ("trainIds" in transport && "originStopName" in transport) {
    return [
      (_, query) => matchesWorkShiftId(transport.workShiftId, query),
      (_, query) =>
        matchesDriverPhoneNumber(transport.driverPhoneNumber, query),
      (_, query) => matchesTrainIds(transport.trainIds, query),
      (_, query) => matchesOriginStopName(transport.originStopName, query),
      (_, query) =>
        matchesDestinationStopName(transport.destinationStopName, query),
    ];
  }

  return [
    (_, query) => matchesWorkShiftId(transport.workShiftId, query),
    (_, query) => matchesDriverPhoneNumber(transport.driverPhoneNumber, query),
  ];
};

const filterTransport: Filter = (transport, searchQuery, selectedFilter) => {
  const lowerCaseQuery = searchQuery.toLowerCase();

  if (lowerCaseQuery.length < 2) {
    return true;
  }

  if (selectedFilter) {
    const filterOptions =
      "trainIds" in transport && "originStopName" in transport
        ? getAlternativeFilterOptions(transport)
        : getReserveFilterOptions(transport as ReserveAlternativeTransport);

    const selectedOption = filterOptions.find(
      (option) => option.name === selectedFilter,
    );
    return selectedOption?.matcher(transport, lowerCaseQuery) ?? false;
  }

  const matchers = getDefaultMatchers(transport);
  return matchers.some((matcher) => matcher(transport, lowerCaseQuery));
};

export const filterReserveAlternativeTransport = filterTransport as (
  transport: ReserveAlternativeTransport,
  searchQuery: string,
  selectedFilter: string,
) => boolean;

export const filterAlternativeTransport = filterTransport as (
  transport: AlternativeTransport,
  searchQuery: string,
  selectedFilter: string,
) => boolean;
