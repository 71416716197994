import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 6px;
  align-items: center;
  width: 100%;
  height: fit-content;
  padding: 24px 12px;
  margin: 6px auto;

  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorBorder};
  border-radius: 6px;

  & :nth-child(1) {
    grid-row: 1;
  }

  & :nth-child(2) {
    grid-row: 1 / span 2;
  }
`;

export const Message = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 21px;
  font-family: "Vy Sans", sans-serif;
  color: ${({ theme }) => theme.colorTextMain};
`;
