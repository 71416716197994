import { getLocalTimeZone, toZoned } from "@internationalized/date";
import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { HStack, IconButton, Stack, VStack } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { Badges } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import {
  getDisplayDuration,
  sortByDisplayDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { InfrastructureResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { FC } from "react";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { useTheme } from "styled-components";
import { useDropsRole } from "stores/useDropsRole";

type UpcomingEventProps = {
  data: InfrastructureResponse[];
  onEditClick: (event: InfrastructureResponse) => void;
};

export const UpcomingEventsList: FC<UpcomingEventProps> = ({
  data,
  onEditClick,
}) => {
  const theme = useTheme();
  const { isVaktleder } = useDropsRole();
  if (data.length === 0)
    return (
      <NoDataMessage reason="Det er ikke registrert noen fremtidige hendelser" />
    );
  return (
    <Stack gap={3}>
      {data.sort(sortByDisplayDuration).map((event) => (
        <HStack
          key={event.referenceId}
          border={`1px solid ${theme.colorHoverItem}`}
          background={theme.colorBackgroundTertiary}
          borderRadius="sm"
          p={3}
          justifyItems="space-between"
          alignItems="flex-start"
          gap={4}
        >
          <VStack alignItems="flex-start" flex="1">
            <Text bold>
              {[event.isirkId, event.stretchName].filter(Boolean).join(", ")}
            </Text>
            <Badges
              eventCategory={
                isPlannedInfraStructureEvent(event.type)
                  ? "plannedInfrastructure"
                  : "futureInfrastructure"
              }
              displayDuration={getDisplayDuration(event.durations)}
            />
            <Text>{event.message}</Text>
            <Text secondary>
              {format(
                toZoned(event.updatedAt, getLocalTimeZone()).toDate(),
                "'Sist oppdatert' dd.MM.yyyy 'kl.' HH:mm",
              )}
            </Text>
          </VStack>
          {isVaktleder() && (
            <IconButton
              onClick={() => onEditClick(event)}
              size="sm"
              aria-label="Rediger"
              variant="tertiary"
              icon={<EditOutline18Icon />}
            />
          )}
        </HStack>
      ))}
    </Stack>
  );
};
