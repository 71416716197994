import { Box } from "@vygruppen/spor-react";
import React, { useState } from "react";
import { FCWC } from "shared/utils/FCWC";
import styled, { useTheme } from "styled-components";
import { ActionButton, ActionButtons } from "../ActionButtons";
import { Severity, severitySubtitleColor } from "../severity";
import { InfoMessageBaseStyling } from "../style";
import { ExpandableButton } from "./ExpandableButton";

const ExpandableMessage = styled(Box)<{
  severity: Severity;
  borderColorOverride?: string;
}>`
  ${({ severity, borderColorOverride }) =>
    InfoMessageBaseStyling(severity, borderColorOverride)};
`;

const ExpandablePanel = styled.div`
  display: grid;
  transition: grid-template-rows 500ms;

  min-height: 21px; /* Necessary for smooth transition from subtitle to panel */
  margin-top: -21px; /* Necessary for smooth transition from subtitle to panel */
  grid-template-rows: 0fr;
  opacity: 0;

  &[aria-hidden="false"] {
    margin-top: 0px;
    grid-template-rows: 1fr;
    opacity: 1;
  }

  div {
    overflow: hidden;
    grid-row: 1 / span 2;
  }
`;

interface InfoMessageProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  severity: Severity;
  title: string;
  subTitle?: string;
  tooltip?: string; // Hides severity icon if used
  actionButtons?: ActionButton[];
  initialOpenState?: boolean;
  colorOverride?: string;
  hideSubtitleWhileOpen?: boolean;
  skeleton?: boolean;
  showExpandWhenTruncated?: boolean;
}

export const ExpandableInfoMessage: FCWC<InfoMessageProps> = ({
  children,
  severity,
  colorOverride,
  title,
  subTitle,
  tooltip,
  actionButtons = [],
  initialOpenState = false,
  hideSubtitleWhileOpen = true,
  skeleton = false,
  showExpandWhenTruncated = false,
  ...rest
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(initialOpenState);
  return (
    <ExpandableMessage
      severity={severity}
      borderColorOverride={colorOverride}
      p={tooltip ? "18px" : "9px"}
      {...rest}
    >
      <ExpandableButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        severity={severity}
        title={title}
        subTitle={hideSubtitleWhileOpen && isOpen ? undefined : subTitle}
        skeleton={skeleton}
        tooltip={tooltip}
        iconColorOverride={colorOverride}
        showExpandWhenTruncated={showExpandWhenTruncated}
      />
      <ExpandablePanel
        id="collapsible_info_message_content"
        aria-hidden={!isOpen}
        style={{
          color: severitySubtitleColor(severity, theme),
          marginLeft: tooltip ? "6px" : "30px",
        }}
      >
        <div>
          {children}
          {actionButtons?.length > 0 && (
            <ActionButtons
              severity={severity}
              actionButtons={actionButtons}
              rightAlign
              style={{
                marginBottom: "12px",
                paddingRight: "12px",
              }}
            />
          )}
        </div>
      </ExpandablePanel>
    </ExpandableMessage>
  );
};
