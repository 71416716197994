import { FC } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import { useTheme } from "styled-components";

type SearchResultTextProps = {
  searchQuery: string;
  resultLength: number;
};

export const SearchResultText: FC<SearchResultTextProps> = ({
  searchQuery,
  resultLength,
}) => {
  const theme = useTheme();
  return searchQuery.length >= 2 ? (
    <Text
      style={{
        backgroundColor: theme.colorBackgroundSecondary,
        borderWidth: "1px",
        borderRadius: "6px",
        marginTop: "6px",
        padding: "18px",
        borderColor: theme.colorBorder,
      }}
    >
      Ditt søk på <b>{searchQuery}</b> ga <b>{resultLength}</b> treff
    </Text>
  ) : null;
};
