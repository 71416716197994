import { useMutation } from "@tanstack/react-query";
import { CloseOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { isShuttleVehicle } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/utils";
import { FC, FormEvent, useState } from "react";
import { ActionModal } from "shared/components/ActionModal";
import { ISelectListOption } from "shared/components/SelectList/SelectList";
import { RadioButton } from "shared/components/forms/RadioButton";
import { Select } from "shared/components/forms/Select";
import { Text } from "shared/components/typography/TitleStyles";
import {
  AlternativeTransportCancelReq,
  AlternativeTransportVehicle,
  StopReference,
} from "shared/types/alternativeTransport";
import styled from "styled-components";

type RadioOptions = "PARTIALLY" | "FULLY";

const RadioGroup = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
`;

const ChooseItinerarySection = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  align-items: center;
  gap: 6px;
  height: 60px;
  width: 100%;
  justify-content: center;
  margin: 18px 0 24px 0;
`;

const getValueFromStopRef = (stopRef: StopReference) =>
  stopRef.nsrCode ??
  stopRef.nsrStopPlace ??
  stopRef.hastusCode ??
  stopRef.jbvId ??
  stopRef.uicCode ??
  "";

type CancellationProps = {
  vehicle: AlternativeTransportVehicle;
};

export const VehicleCancellation: FC<CancellationProps> = ({ vehicle }) => {
  const { itinerary } = vehicle;
  const [showModal, setShowModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState<RadioOptions>("PARTIALLY");
  const [fromStopId, setFromStopId] = useState<string | null>(
    itinerary ? getValueFromStopRef(itinerary.stops[0].stopReference) : null,
  );

  const mutation = useMutation({
    mutationFn: (body: AlternativeTransportCancelReq) =>
      axiosClient.patch<any>(
        `${getBackendUrl()}/alternativeTransports/${vehicle.id}/cancel`,
        body,
      ),
  });

  /* Do not show cancellation option when empty itinerary
   * or when vehicle is an operational shuttle vehicle
   * or when vehicle is already fully cancelled
   */
  if (
    !itinerary ||
    itinerary.stops.length === 0 ||
    isShuttleVehicle(vehicle) ||
    vehicle.vehicleStatus === "CANCELLED" ||
    fromStopId === null
  ) {
    return null;
  }

  // eslint-disable-next-line no-undef
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let stopsToCancel;

    if (selectedRadio === "FULLY") {
      stopsToCancel = itinerary.stops;
    } else {
      const matchingIndex = itinerary.stops.findIndex((stop) =>
        Object.values(stop.stopReference).includes(fromStopId),
      );
      stopsToCancel =
        matchingIndex >= 0 ? itinerary.stops.slice(matchingIndex) : [];
    }

    mutation.mutate({
      cancelledStops: stopsToCancel.map((stop) => ({
        stopReference: stop.stopReference,
        stopName: stop.name,
        isArrivalCancelled: true,
        isDepartureCancelled: true,
      })),
    });
  };

  const onClose = () => {
    setShowModal(false);
    setSelectedRadio("PARTIALLY");
    setFromStopId(getValueFromStopRef(itinerary.stops[0].stopReference));
    mutation.reset();
  };

  /* For the select input */
  const stopSelection: ISelectListOption[] = itinerary.stops.map((stop) => ({
    text: stop.name ?? "",
    value: getValueFromStopRef(stop.stopReference),
  }));

  return (
    <>
      <Button
        variant="tertiary"
        size="sm"
        leftIcon={<CloseOutline24Icon />}
        onClick={() => setShowModal(true)}
      >
        Kanseller
      </Button>
      {showModal && (
        <ActionModal
          title={`Kanseller avgang ${vehicle.workShiftPlan?.shiftId}`}
          actionTitle="Gjennomfør"
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={mutation.isPending}
          isSuccess={mutation.isSuccess}
          isError={mutation.isError}
          successMessage="Kansellering vellykket."
          failureMessage="Kunne ikke kansellere avgang. Prøv igjen, eller kontakt IT hvis feilen vedvarer."
        >
          <RadioGroup role="radiogroup">
            <RadioButton
              name="cancellation-type"
              value="delvis"
              checked={selectedRadio === "PARTIALLY"}
              onChange={() => setSelectedRadio("PARTIALLY")}
            >
              Kanseller delvis
            </RadioButton>
            <RadioButton
              name="cancellation-type"
              value="helt"
              checked={selectedRadio === "FULLY"}
              onChange={() => setSelectedRadio("FULLY")}
            >
              Kanseller helt
            </RadioButton>
          </RadioGroup>

          <ChooseItinerarySection>
            {selectedRadio === "PARTIALLY" ? (
              <Select
                maxWidth="max-content"
                label="Fra stopp"
                aria-label="Velg fra stopp"
                value={fromStopId}
                onChange={(e) => setFromStopId(e.target.value)}
              >
                {stopSelection.map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.text}
                  </option>
                ))}
              </Select>
            ) : (
              <Text>{vehicle.originStopName}</Text>
            )}
            -<Text>{vehicle.destinationStopName}</Text>
          </ChooseItinerarySection>
        </ActionModal>
      )}
    </>
  );
};
