import { Td, Thead, Tr } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Heading, Table, Tbody, Th, VStack } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import {
  ReasonCodes,
  ReasonCodesSchema,
} from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/schemas";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { formatDateString } from "shared/utils/datetime";
import { DetailsParams } from "../../TrainDetails";

const tableColumns = ["Tid", "Sted", "BN-kode", "Lok-kode", "Kond-kode"];
const TrainReasonCodes = () => {
  const { trainNumber, trainDate } = useParams<DetailsParams>();

  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ["getReasonCodesForTrain", trainNumber, trainDate],
    queryFn: ({ signal }) =>
      axiosClient
        .get<ReasonCodes>(
          `${getBackendUrl()}/reason-codes/by-train-departure?nominalDate=${trainDate}&trainId=${trainNumber}`,
          {
            signal,
          },
        )
        .then((res) => ReasonCodesSchema.parse(res.data)),
  });

  if (isError) {
    return <FailureMessage customMessage="" />;
  }

  if (!data || data.length <= 0 || isLoading) {
    return null;
  }

  const getCodeAndComment = (code: string | null, comment: string | null) =>
    [code, comment].filter(Boolean).join(": ");

  return (
    <VStack alignItems="flex-start" width="100%" maxW="800px">
      <Heading variant="sm" as="h3" fontWeight="Bold">
        Årsakskoder
      </Heading>
      <Table variant="outline" colorScheme="grey" size="sm" width="100%">
        <Thead>
          <Tr>
            {tableColumns.map((column) => (
              <Th key={column}>{column}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isSuccess &&
            data.map((rc) => {
              const isConductor = rc.employeeRole === "CONDUCTOR";
              const isDriver = rc.employeeRole === "DRIVER";
              return (
                <Tr key={rc.id}>
                  <Td>{formatDateString(rc.registeredAt, "HH:mm")}</Td>
                  <Td>
                    <p>{rc.stationName}</p>
                  </Td>
                  <Td>
                    {getCodeAndComment(rc.tiosReasonCode, rc.tiosComment)}
                  </Td>
                  <Td>
                    {isDriver &&
                      getCodeAndComment(
                        rc.employeeReasonCode,
                        rc.employeeComment,
                      )}
                  </Td>
                  <Td>
                    {isConductor &&
                      getCodeAndComment(
                        rc.employeeReasonCode,
                        rc.employeeComment,
                      )}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </VStack>
  );
};

export default TrainReasonCodes;
