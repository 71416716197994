import { Stack } from "@vygruppen/spor-react";
import { InfrastructureEventType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { BatchPreview } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/BatchPreview";
import { FormControlErrorMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/FormControlErrorMessage";
import {
  FieldErrorOrUndefined,
  TrainFormGroup,
  TrickFormHookGroupedTrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { BatchSubTypeInput } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/BatchSubTypeInput";
import { useTrainInfoBatchPreviews } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/useTrainInfoBatchPreviews";
import { BatchTrainFormFieldWithCustomInput } from "features/CenterContent/shared/operationalInformation/components/BatchTrainFormFieldWithCustomInput";
import {
  EventGroup,
  OperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import {
  filterActions,
  sortAndFilterReasons,
} from "features/CenterContent/shared/operationalInformation/utils";
import { FC, useMemo } from "react";
import { useFormState, useWatch } from "react-hook-form";

type TrainInfoBatchFormProps = {
  opInfoTypes: OperationalInformationTypes;
  infrastructureEvent: InfrastructureEventType;
  activeTrainSeriesKey: keyof TrickFormHookGroupedTrainFormSchema;
  groupsInSeries: number;
};

const TrainInfoBatchForm: FC<TrainInfoBatchFormProps> = ({
  opInfoTypes,
  infrastructureEvent,
  activeTrainSeriesKey,
  groupsInSeries,
}) => {
  const formData = useWatch<TrickFormHookGroupedTrainFormSchema>({
    name: activeTrainSeriesKey,
  }) as TrainFormGroup[];

  const { errors } = useFormState<TrickFormHookGroupedTrainFormSchema>({
    name: activeTrainSeriesKey,
  });

  const { previewData, previewStatus } = useTrainInfoBatchPreviews({
    formData,
  });

  const previewTexts = useMemo(
    () => previewData?.map(({ preview }) => preview.internalMessage),
    [previewData],
  );

  const reasons = useMemo(() => {
    if (opInfoTypes) {
      return sortAndFilterReasons(opInfoTypes.reasons, "TRAIN");
    }
    return [];
  }, [opInfoTypes?.reasons]);

  return (
    <div>
      <Stack gap={2}>
        <BatchTrainFormFieldWithCustomInput
          pathPrefix={`${activeTrainSeriesKey}`}
          numberOfGroups={groupsInSeries}
          items={opInfoTypes.events.filter(
            (event) => event.group === EventGroup.TRAIN,
          )}
          formFieldType="event"
          optionDisabledPredicate={
            () => false // Consider adding this. Currently only supported in non-batch actions.
          }
        />
        <FormControlErrorMessage
          field={
            errors?.[activeTrainSeriesKey]?.[0]?.trainForm
              ?.type as FieldErrorOrUndefined
          }
        />
        <BatchTrainFormFieldWithCustomInput
          pathPrefix={`${activeTrainSeriesKey}`}
          numberOfGroups={groupsInSeries}
          items={reasons}
          formFieldType="reason"
        />
        <FormControlErrorMessage
          field={
            errors?.[activeTrainSeriesKey]?.[0]?.trainForm?.reason
              ?.type as FieldErrorOrUndefined
          }
        />
        <BatchTrainFormFieldWithCustomInput
          pathPrefix={`${activeTrainSeriesKey}`}
          numberOfGroups={groupsInSeries}
          items={filterActions(opInfoTypes.actions)}
          formFieldType="action"
        />
      </Stack>

      {formData.map((group, index) => {
        if (!group.trainIdsInGroup?.length) return undefined;
        return (
          <BatchSubTypeInput
            key={`BatchSubTypeInput-${index}`}
            infrastructureEvent={infrastructureEvent}
            eventType={group.trainForm.type}
            direction={group.direction}
            representativeTrainId={group.trainIdsInGroup[0]}
            activeSeriesKey={activeTrainSeriesKey}
            groupIndex={index}
          />
        );
      })}

      <BatchPreview
        previewStatus={previewStatus}
        previewTexts={previewTexts}
        isFormValid={Object.keys(errors).length === 0}
      />
    </div>
  );
};

export { TrainInfoBatchForm };
