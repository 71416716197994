import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { infrastructureParametersSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { formToRequest } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/transformers";
import { useEffect } from "react";
import { z } from "zod";
import { InfrastructureRequest } from "../../types/requestSchema";
import { PreviewResponse } from "../../utils";

export const usePreview = (
  formState: z.infer<typeof infrastructureParametersSchema>,
) => {
  const {
    mutate: preview,
    data: previewData,
    status: previewStatus,
    reset: resetPreview,
  } = useMutation({
    mutationFn: (body: InfrastructureRequest) =>
      mutationFnPOST<PreviewResponse, InfrastructureRequest>(
        `${getBackendUrl()}/infrastructure-event/preview`,
        body,
      ),
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      const parsedForm = infrastructureParametersSchema.safeParse(formState);
      if (parsedForm.success) {
        const body = formToRequest(parsedForm.data);
        if (body) {
          preview(body);
        }
      } else {
        resetPreview();
      }
    }, 300);
    return () => {
      clearTimeout(timer);
    };
  }, [formState]);

  return {
    previewData,
    previewStatus,
  };
};
