import { useMutation } from "@tanstack/react-query";
import { HistoryOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { isStopCancelled } from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/Itinerary/utils";
import { FC, FormEvent, useState } from "react";
import { ActionModal } from "shared/components/ActionModal";
import { Text } from "shared/components/typography/TitleStyles";
import {
  AlternativeTransportCancelReq,
  AlternativeTransportVehicle,
} from "shared/types/alternativeTransport";

type CancellationProps = {
  vehicle: AlternativeTransportVehicle;
};
export const VehicleUndoCancellation: FC<CancellationProps> = ({ vehicle }) => {
  const cancelledStops = vehicle.itinerary?.stops.filter((stop) =>
    isStopCancelled(stop, vehicle.vehicleStatus),
  );

  const [showModal, setShowModal] = useState(false);

  const mutation = useMutation({
    mutationFn: (body: AlternativeTransportCancelReq) =>
      axiosClient.patch<any>(
        `${getBackendUrl()}/alternativeTransports/${vehicle.id}/cancel`,
        body,
      ),
  });

  if (!cancelledStops || cancelledStops.length <= 0) {
    return null;
  }

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    mutation.mutate({
      cancelledStops: cancelledStops.map((stop) => ({
        stopReference: stop.stopReference,
        stopName: stop.name,
        isArrivalCancelled: false,
        isDepartureCancelled: false,
      })),
    });
  };

  const onClose = () => {
    setShowModal(false);
    mutation.reset();
  };

  return (
    <>
      <Button
        leftIcon={<HistoryOutline24Icon />}
        variant="tertiary"
        size="sm"
        onClick={() => setShowModal(true)}
      >
        Angre kansellering
      </Button>
      {showModal && (
        <ActionModal
          title={`Angre kansellert avgang ${vehicle.workShiftPlan?.shiftId}`}
          actionTitle="Angre kansellering"
          onClose={onClose}
          onSubmit={onSubmit}
          isLoading={mutation.isPending}
          isSuccess={mutation.isSuccess}
          isError={mutation.isError}
          successMessage="Angring vellykket."
          failureMessage="Kunne ikke angre kansellert avgang. Prøv igjen, eller kontakt IT hvis feilen vedvarer."
        >
          <Text>
            Er du sikker på at du vil angre alle kanselleringer på avgangen?
          </Text>
        </ActionModal>
      )}
    </>
  );
};
