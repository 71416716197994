import { Tooltip } from "@chakra-ui/react";
import { Flex, VStack } from "@vygruppen/spor-react";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { getEventSemantics } from "features/VehicleSidebar/AffectedTrains/utils/utils";
import { TrainData } from "features/VehicleSidebar/VehicleList/TrainList/types";
import { internalMessagesHasTrain } from "features/VehicleSidebar/VehicleList/TrainList/utils";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { CallButton } from "shared/components/buttons/CallButton";
import { NoteOutline18Icon } from "shared/icons/NoteIcon";
import { useTheme } from "styled-components";
import { ButtonGroup, ButtonStyled } from "./styles";

const getSemanticsForEvents = (types: string[]) => {
  if (types.some((type) => getEventSemantics(type) === "alarm")) {
    return "alarm";
  }
  if (types.some((type) => getEventSemantics(type) === "warning")) {
    return "warning";
  }
  return "info";
};

type trainEventTitlesSchema = {
  type: string;
  label: string;
  priority: number;
};

const trimType = (label: string) => {
  const trimmed = label.split(" (")[0];
  return trimmed.length > 15 ? `${trimmed.substring(0, 12)}...` : trimmed;
};

export const RightContent = ({
  train,
  eventNames: [firstEvent, ...otherEvents],
  internalMessages,
}: {
  train: TrainData;
  eventNames: trainEventTitlesSchema[];
  internalMessages: InternalMessageResponse[] | undefined;
}) => {
  const theme = useTheme();

  const eventTypes = otherEvents.map((event) => event.type).filter(Boolean);

  const hasInternalMessage = internalMessagesHasTrain(internalMessages, train);

  return (
    <ButtonGroup role="group">
      <VStack spacing={2} alignItems="flex-end">
        <Flex wrap="wrap" justifyContent="flex-end" gap="3px">
          {firstEvent && (
            <DropsBadge semantics={getSemanticsForEvents([firstEvent.type])}>
              {trimType(firstEvent.label)}
            </DropsBadge>
          )}

          {otherEvents.length > 0 && (
            <Tooltip
              hasArrow
              placement="right"
              label={
                <VStack>
                  {otherEvents.map((event, i) => (
                    <DropsBadge
                      key={i}
                      semantics={getSemanticsForEvents([event.type])}
                    >
                      {trimType(event.label)}
                    </DropsBadge>
                  ))}
                </VStack>
              }
              padding="8px"
              bg={theme.colorBackgroundMain}
              border={`1px solid ${theme.borderColor}`}
              shouldWrapChildren
            >
              <ButtonStyled
              // We are using this component in order to steal mouseEvent from trainListElement
              >
                <DropsBadge semantics={getSemanticsForEvents(eventTypes)}>
                  +{otherEvents.length}
                </DropsBadge>
              </ButtonStyled>
            </Tooltip>
          )}
          {hasInternalMessage && (
            <Tooltip
              hasArrow
              placement="right"
              label={
                <DropsBadge semantics="neutral">Intern melding</DropsBadge>
              }
              padding="8px"
              bg={theme.colorBackgroundMain}
              border={`1px solid ${theme.borderColor}`}
              shouldWrapChildren // This works instead of ButtonStyled because of NoteIcon stealing the mouseEvent
            >
              <DropsBadge px="3px !important" semantics="neutral">
                <NoteOutline18Icon transform="scale(0.75, 0.75)" />
              </DropsBadge>
            </Tooltip>
          )}
        </Flex>
        <Flex wrap="wrap" justifyContent="flex-end" gap="3px">
          {train.trainStaffOnDuty?.map((staffOnDuty) => (
            <CallButton
              key={staffOnDuty.gsmrNumber}
              label={staffOnDuty.function}
              phoneNumber={staffOnDuty.functionalTelephoneNumber}
              size="xs"
            />
          ))}
        </Flex>
      </VStack>
    </ButtonGroup>
  );
};
