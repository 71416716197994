import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { InfrastructureResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";

export type GroupedInformationResponse = {
  directlyAffecting: Array<InfrastructureResponse>;
  rest: Array<InfrastructureResponse>;
};

export const useInfrastructureInformationForTrain = (
  nominalDate: string,
  trainId: string,
) => {
  const { data, status, refetch } = useQuery({
    queryKey: ["operationalInformationForTrain", trainId],
    queryFn: async ({ signal }: { signal: AbortSignal }) => {
      const res = await queryFnGET<GroupedInformationResponse>({
        signal,
        url: `${getBackendUrl()}/infrastructure-event/train/${nominalDate}/${trainId}`,
      });
      return res;
    },
  });

  return { status, data, refetch };
};
