import { CalendarDate, DateValue } from "@internationalized/date";
import { getTrainSeriesFromIdentifier } from "features/History/groupTrainSeries";
import { calendarDateToday } from "features/VehicleSidebar/VehicleList/VehicleList";
import {
  CombinedHistoryResponse,
  InfrastructureOperationalInformationOverviewResponse,
  TrainOperationalInformationOverviewResponse,
} from "shared/types/operationalInformation";

export const ACTIONABLE_TRAIN_TYPES = [
  "TRAIN_CUSTOM",
  "TRAIN_DELAY_EXPECTED",
  "TRAIN_STOPPED",
];
const GROUP_TRAVEL_TYPE = "TRAIN_GROUP_TRAVEL";
const ASSISTANCE_TYPES = [
  "TRAIN_WHEELCHAIR_PASSENGER",
  "TRAIN_REDUCED_MOBILITY_PASSENGER",
  "TRAIN_VISUALLY_IMPAIRED_PASSENGER",
];
const ENTUR_TYPES = [GROUP_TRAVEL_TYPE, ...ASSISTANCE_TYPES];

function isTrainEvent(
  event: CombinedHistoryResponse,
): event is TrainOperationalInformationOverviewResponse {
  return event.type.group === "TRAIN";
}

type FilterAction = "train" | "infrastructure" | "station" | "global";
type FilterCondition = "open" | "closed" | "unhandled" | "replaced";
type FilterVisibility = "visible" | "outdated";

export class FilterData {
  actions: FilterAction[] = [];

  searchString: string = "";

  date: DateValue = calendarDateToday();

  groupTravel: boolean = false;

  condition: FilterCondition | null = null;

  visibility: FilterVisibility | null = null;

  trainSeries: string[] = [];

  // TODO(afriestad): maybe extract this? Feels unnecessary to keep around in here
  allTrainIds: string[] = [];

  plannedEvents: boolean = false;

  // Same behaviour as .clone
  constructor(init?: FilterData) {
    Object.assign(this, init);
  }

  static fromString(jsonString: string | null): FilterData | null {
    if (!jsonString) return null;

    const stringObject = JSON.parse(jsonString);
    const [year, month, day] = stringObject.date.split("-");

    return Object.assign(new FilterData(), {
      ...stringObject,
      date: new CalendarDate(
        parseInt(year, 10),
        parseInt(month, 10),
        parseInt(day, 10),
      ),
    });
  }

  stringify(): string {
    const dateString = `${this.date.year}-${this.date.month}-${this.date.day}`;

    return JSON.stringify({ ...this, date: dateString });
  }

  isEmpty(): boolean {
    const emptyFilter = new FilterData();
    emptyFilter.allTrainIds = this.allTrainIds;
    return this.stringify() === emptyFilter.stringify();
  }

  updateAction(value: FilterAction): FilterData {
    const next = new FilterData(this);

    if (next.actions.includes(value)) {
      next.actions = next.actions.filter((action) => action !== value);
    } else {
      next.actions.push(value);
    }

    return next;
  }

  updateSearchString(value: typeof this.searchString): FilterData {
    const next = new FilterData(this);

    next.searchString = value;

    return next;
  }

  updateDate(value: typeof this.date): FilterData {
    const next = new FilterData(this);

    next.date = value;

    return next;
  }

  updateGroupTravel(value: typeof this.groupTravel): FilterData {
    const next = new FilterData(this);

    next.groupTravel = value;
    if (next.groupTravel) {
      next.actions = ["train"];
    }

    return next;
  }

  updatePlannedEvent(value: typeof this.plannedEvents): FilterData {
    const next = new FilterData(this);

    next.plannedEvents = value;

    return next;
  }

  updateCondition(value: NonNullable<typeof this.condition>): FilterData {
    const next = new FilterData(this);

    if (next.condition === value) {
      next.condition = null;
    } else {
      next.condition = value;
    }

    return next;
  }

  updateVisibility(value: NonNullable<typeof this.visibility>): FilterData {
    const next = new FilterData(this);

    if (next.visibility === value) {
      next.visibility = null;
    } else {
      next.visibility = value;
    }

    return next;
  }

  updateTrainIds(value: string): FilterData {
    const next = new FilterData(this);

    if (next.trainSeries.includes(value)) {
      next.trainSeries = next.trainSeries.filter((it) => it !== value);
    } else {
      next.trainSeries.push(value);
    }

    if (next.trainSeries.length > 0) {
      next.actions = ["train"];
    }

    return next;
  }

  selectAllTrainIds(): FilterData {
    const next = new FilterData(this);

    next.trainSeries = next.allTrainIds;
    if (next.trainSeries.length > 0) {
      next.actions = ["train"];
    }

    return next;
  }

  deselectAllTrainIds(): FilterData {
    const next = new FilterData(this);

    next.trainSeries = [];

    return next;
  }

  setAllTrainIds(trainIds: typeof this.allTrainIds): FilterData {
    const next = new FilterData(this);

    next.allTrainIds = Array.from(
      // TODO(afriestad): optimise with reduce-function
      new Set(trainIds.map(getTrainSeriesFromIdentifier)),
    );

    return next;
  }

  isMatch(event: CombinedHistoryResponse) {
    // Action
    if (this.actions.length > 0) {
      const eventAction = event.type.group.toLowerCase() as FilterAction;
      const filterAction = this.actions;
      if (!filterAction.includes(eventAction)) {
        return false;
      }
    }

    // Search string
    if (this.searchString !== "") {
      const message = event.distributions
        .map((it) => it.text.message)
        .join("\n")
        .toLowerCase();
      const title = event.type.label?.toLowerCase();
      const subTitle =
        (
          event as InfrastructureOperationalInformationOverviewResponse
        ).actionCard?.type.toLowerCase() ?? "";
      const trainIdentifier =
        (
          event as TrainOperationalInformationOverviewResponse
        ).trainIdentifier?.operational_identifier.toLowerCase() ?? "";
      const lineNumber =
        (
          event as TrainOperationalInformationOverviewResponse
        ).lineNumber?.toLowerCase() ?? "";
      const allText = [
        message,
        title,
        subTitle,
        trainIdentifier,
        lineNumber,
      ].join("\n");

      if (!allText.includes(this.searchString.toLowerCase())) {
        return false;
      }
    }

    // Date: No need to filter since the date field is used to fetch data from backend

    // Events made by Entur
    if (!this.groupTravel) {
      if (ENTUR_TYPES.includes(event.type.type)) {
        return false;
      }
    }

    // planned infrastructure event
    if (
      !this.plannedEvents &&
      event.type.type.startsWith("INFRASTRUCTURE_PLANNED")
    ) {
      return false;
    }

    // Condition
    if (this.condition) {
      // Open condition
      const isOpen = event.state === "OPEN";
      const filterOpen = this.condition === "open";

      if (filterOpen && !isOpen) {
        return false;
      }

      // Closed condition
      const isClosed = event.state === "CLOSED";
      const filterClosed = this.condition === "closed";

      if (filterClosed && !isClosed) {
        return false;
      }

      // Unhandled condition (open and actionable type)
      const filterUnhandled = this.condition === "unhandled";
      if (
        filterUnhandled &&
        (!isOpen || !ACTIONABLE_TRAIN_TYPES.includes(event.type.type))
      ) {
        return false;
      }

      // Replaced condition
      const isReplaced = event.state === "REPLACED";
      const filterReplaced = this.condition === "replaced";
      if (filterReplaced && !isReplaced) {
        return false;
      }
    }

    // Visibility
    if (this.visibility) {
      const { isVisible } = event;
      const filterVisible = this.visibility === "visible";
      if (isVisible !== filterVisible) {
        return false;
      }
    }

    // Train series
    if (this.trainSeries.length > 0) {
      if (!isTrainEvent(event)) return false;

      const trainSeries = getTrainSeriesFromIdentifier(
        event.trainIdentifier.operational_identifier,
      );

      if (!this.trainSeries.includes(trainSeries)) {
        return false;
      }
    }

    return true;
  }
}
