import { CalendarDateTime } from "@internationalized/date";
import {
  internalMessagesFormDefault,
  internalMessagesFormToRequest,
  internalMessagesResponseToForm,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import { getInitialOpenEndedDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import {
  createRulesFromPeriodicDuration,
  ruleToPeriod,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/periodicDurationUtils";
import {
  DateTimeForm,
  DateTimeRequest,
  dateTimeSchemaRequest,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/baseSchema";
import {
  FormSchemaDuration,
  InfrastructureCommon,
  InfrastructureForm,
  OpenEndedFormDuration,
  PeriodicFormDuration,
  TimedFormDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import {
  InfrastructureRequest,
  OpenEndedRequestDuration,
  PeriodicRequestDuration,
  Prognosis,
  RequestDuration,
  TimedRequestDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/requestSchema";
import {
  Duration,
  InfrastructureResponse,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import {
  DescribedType,
  isCustomEmptyType,
  isCustomInputType,
} from "features/CenterContent/shared/operationalInformation/utils";
import { DefaultValues } from "react-hook-form";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { Nullable } from "shared/utils/objectUtils";

const getReason = (event: InfrastructureResponse) => {
  if (isPlannedInfraStructureEvent(event.type))
    return { type: "WORK_ON_TRACK" };
  return event.reason;
};

export const getCurrentCalendarDateTime = (plusHours?: number) => {
  const now = new Date();
  return new CalendarDateTime(
    now.getFullYear(),
    now.getMonth() + 1,
    now.getDate(),
    now.getHours() + (plusHours ?? 0),
    now.getMinutes(),
  );
};

const getInitialDuration = (duration: Duration): FormSchemaDuration => {
  switch (duration.type) {
    case "PERIODIC": {
      const rules = createRulesFromPeriodicDuration(duration);
      return { type: "PERIODIC", rules };
    }
    case "TIMED": {
      return {
        type: "TIMED",
        from_time: duration.from_time,
        to_time: duration.to_time,
      };
    }

    case "OPEN_ENDED":
    default: {
      return { ...duration, from_time: duration.from_time };
    }
  }
};

const getPrognosisTime = (event: InfrastructureResponse) => {
  if (event.prognosis) {
    if (
      event.prognosis.type === "ESTIMATED_OPENING" &&
      event.prognosis.estimatedResolved
    )
      return event.prognosis.estimatedResolved;
    if (
      event.prognosis.type === "ESTIMATED_INFO_UPDATE" &&
      event.prognosis.estimatedInfoUpdate
    )
      return event.prognosis.estimatedInfoUpdate;
  }
  return getCurrentCalendarDateTime();
};

function defaultForm(): DefaultValues<InfrastructureForm> {
  return {
    infrastructureForm: {
      type: undefined,
      reason: { type: "" },
      actionCard: { type: "" },
      consequence: { type: "" },
      prognosisType: null,
      prognosisTime: getCurrentCalendarDateTime(),
      stretchName: "",
      affectedLegs: [],
      redirectStretches: [],
      durations: getInitialOpenEndedDuration(),
      isirkId: "",
      internalMessages: internalMessagesFormDefault(),
      action: null,
      infrastructureProvider: InfrastructureProviderEnum.BANE_NOR,
    },
  };
}

export const responseToForm = (
  event: InfrastructureResponse | undefined,
): DefaultValues<InfrastructureForm> => {
  if (!event) return defaultForm();
  const form = {
    reason: getReason(event),
    action: event.action,
    actionCard: event.actionCard,
    consequence: event.consequence,
    prognosisType: event.prognosis,
    prognosisTime: getPrognosisTime(event),
    stretchName: event.stretchName,
    affectedLegs: event.affectedLegs,
    redirectStretches: event.redirectStretches,
    durations: getInitialDuration(event.durations),
    isirkId: event.isirkId,
    infrastructureProvider:
      event.infrastructureProvider === null
        ? InfrastructureProviderEnum.NONE
        : event.infrastructureProvider,
    internalMessages: internalMessagesResponseToForm(event.internalMessages),
  };
  switch (event.type) {
    case "INFRASTRUCTURE_PLANNED_MAINTENANCE":
      return {
        infrastructureForm: {
          type: event.type,
          ...form,
          durations: getInitialDuration(event.durations) as
            | TimedFormDuration
            | PeriodicFormDuration,
        },
      };
    case "INFRASTRUCTURE_STRETCH_CLOSED":
    case "INFRASTRUCTURE_STRETCH_PARTIALLY_CLOSED":
    case "INFRASTRUCTURE_SLOW_DRIVING":
    default:
      return {
        infrastructureForm: {
          type: event.type,
          ...form,
          durations: getInitialDuration(
            event.durations,
          ) as OpenEndedFormDuration,
        },
      };
  }
};

// We don't run a full parse for Form -> Request, therefore we need to manually parse this field
function dateTimeFormToRequest(dateTimeForm: DateTimeForm): DateTimeRequest {
  return dateTimeSchemaRequest.parse(dateTimeForm);
}

export const createRequestDurations = (
  durations: FormSchemaDuration,
): RequestDuration => {
  switch (durations.type) {
    case "TIMED":
      return {
        fromTime: dateTimeFormToRequest(durations.from_time),
        toTime: dateTimeFormToRequest(durations.to_time),
      };
    case "PERIODIC": {
      return { periods: durations.rules.flatMap((rule) => ruleToPeriod(rule)) };
    }
    case "OPEN_ENDED":
    default:
      return {
        fromTime: dateTimeFormToRequest(durations.from_time),
        timeHorizonInMinutes: Number(durations.extension_time_in_minutes),
      };
  }
};

export const validateTypeAndDescriptionNullable = (
  field: Nullable<DescribedType>,
) => {
  if (!field) return null;
  const { type, description } = field;
  const validatedType = type && type.length > 0;
  if (!validatedType) return null;
  const validatedDescription = isCustomInputType(type) && description;
  return validatedDescription ? { type, description } : { type };
};

/**
 * This can return null because of the emptyType check. If you need to
 * ensure that the field is not null, separate this function into two.
 */
export const validateTypeAndDescription = (field: DescribedType) => {
  const validated = validateTypeAndDescriptionNullable(field);
  if (!validated) {
    throw new Error(
      "This should not be possible. Ensure that formMethods.trigger() is ran before this.",
    );
  }
  const emptyType = isCustomEmptyType(validated.type);
  if (emptyType) return null;
  return validated;
};

export const validateActionCard = (
  field: InfrastructureCommon["actionCard"],
) => {
  const type = field?.type;
  if (!type || type === "") return null;
  return { type };
};

const validatePrognosis = (
  field: Nullable<DescribedType>,
  prognosisTime: DateTimeForm,
): Prognosis => {
  const validatedPrognosis = validateTypeAndDescriptionNullable(field);
  if (!validatedPrognosis) return null;
  const { type, description } = validatedPrognosis;
  const estimatedResolved =
    type === "ESTIMATED_OPENING" ? dateTimeFormToRequest(prognosisTime) : null;
  const estimatedInfoUpdate =
    type === "ESTIMATED_INFO_UPDATE"
      ? dateTimeFormToRequest(prognosisTime)
      : null;
  return {
    type,
    description,
    estimatedResolved,
    estimatedInfoUpdate,
  };
};

export const formToRequest = (
  formState: InfrastructureForm["infrastructureForm"],
): InfrastructureRequest => {
  const form = {
    type: formState.type,
    reason: validateTypeAndDescriptionNullable(formState.reason),
    action: validateTypeAndDescriptionNullable(formState.action),
    consequence: validateTypeAndDescriptionNullable(formState.consequence),
    stretchName: formState.stretchName,
    affectedLegs: formState.affectedLegs,
    countryCode: "NO" as const,
    infrastructureProvider:
      formState.infrastructureProvider === InfrastructureProviderEnum.NONE
        ? null
        : formState.infrastructureProvider,
    internalMessage: internalMessagesFormToRequest(formState.internalMessages),
  };
  switch (formState.type) {
    case "INFRASTRUCTURE_PLANNED_MAINTENANCE":
      return {
        ...form,
        isirkId: formState.isirkId === "" ? null : formState.isirkId,
        duration: createRequestDurations(formState.durations) as
          | TimedRequestDuration
          | PeriodicRequestDuration,
      };
    case "INFRASTRUCTURE_STRETCH_PARTIALLY_CLOSED":
    case "INFRASTRUCTURE_STRETCH_CLOSED":
    case "INFRASTRUCTURE_SLOW_DRIVING":
    default:
      return {
        ...form,
        actionCard: validateActionCard(formState.actionCard),
        prognosis: validatePrognosis(
          formState.prognosisType,
          formState.prognosisTime,
        ),
        redirectStretches: formState.redirectStretches,
        duration: createRequestDurations(
          formState.durations,
        ) as OpenEndedRequestDuration,
      };
  }
};
