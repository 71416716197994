import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { useParams } from "react-router-dom";

export const TrainInternalMessagesQueryKey = (
  trainDate: string | undefined,
  trainNumber: string | undefined,
) => ["trainInternalMessage", trainDate, trainNumber];

export const useTrainInternalMessage = () => {
  const { trainNumber, trainDate } = useParams();

  const queryKey = TrainInternalMessagesQueryKey(trainDate, trainNumber);

  const { data, status } = useQuery({
    queryKey,
    queryFn: ({ signal }) =>
      queryFnGET<InternalMessageResponse[]>({
        signal,
        url: `${getBackendUrl()}/global-information/internal-info/train/NO/${trainDate}/${trainNumber}`,
      }),
    refetchOnWindowFocus: true,
  });

  return {
    data,
    status,
  };
};
