import { Semantics } from "shared/components/badge/DropsBadge";
import styled, { DefaultTheme, useTheme } from "styled-components";

const semanticsToStyle = (theme: DefaultTheme) => ({
  success: {
    fill: theme.colorSuccess,
    outline: theme.colorSuccessFillPulse,
  },
  warning: {
    fill: theme.colorAlarmSecondary,
    outline: theme.colorWarningFill,
  },
  alarm: {
    fill: theme.colorAlarm,
    outline: theme.colorAlarmFill,
  },
  "secondary-alarm": {
    fill: theme.colorSecondaryAlarm,
    outline: theme.colorSecondaryAlarmFill,
  },
  info: {
    fill: theme.colorInfo,
    outline: theme.colorInfoFill,
  },
  neutral: {
    fill: theme.colorNeutral,
    outline: theme.colorNeutralFill,
  },
});

const Indicator = styled.div<{
  $fill: string;
  $outline: string;
}>`
  width: 20px;
  height: 20px;
  background-color: ${({ $fill }) => $fill};
  border-radius: 100%;
  border: solid 6px ${({ $outline }) => $outline};
`;

export const VisibilityIndicator = ({
  semantics,
}: {
  semantics: Semantics;
}) => {
  const theme = useTheme();
  const { fill, outline } = semanticsToStyle(theme)[semantics];
  return <Indicator $fill={fill} $outline={outline} />;
};
