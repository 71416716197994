import { log, LogLevel } from "logging/datadogBrowserLogs";
import { SessionCommands } from "../../features/Header/PuzzelTelephone/components/types";
import { fetchPhoneNumberType } from "../drops/drops";
import { dropsHttpClient } from "../drops/dropsHttpClient";
import { puzzelHttpClient } from "./puzzelHttpClient";

export const puzzelLogin = () => dropsHttpClient.get(`puzzel/login`);

export const puzzelRefreshToken = (data: any) =>
  dropsHttpClient.post(`puzzel/refreshToken`, data);

export const extendedStateInformationGetTelephoneApi = (parameter: any) =>
  puzzelHttpClient.get(
    `users/${parameter.userId}/stateinformation/extended?agentState=true&personalQueue=true&request=true&iqSessionState=true&extendedRequestInfo=true`,
  );

export const userGetTelephoneApi = (parameter: any) =>
  puzzelHttpClient.get(`users/${parameter.userId}`);

export const userListTelephoneApi = () => puzzelHttpClient.get(`users/`);

export const userLogOnOffTelephoneApi = (data: any) =>
  puzzelHttpClient.post(
    `users/${data.param.userId}/${data.param.logOnOrOff}`,
    data.payload,
  );

type callOutPayload = {
  destination: string;
  countryCode: string;
  maxAttempts: number;
  secondsBetweenAttempts: number;
  ciqType: string;
  queueKey: string;
  maxOneRequestForPreferredAgent: boolean;
  preferredAgentWaitTimeSeconds: number;
  reservedAgentWaitTimeSeconds: number;
  serviceSystemVariables: null | {
    name: string;
    value: string;
  };
};

const withPuzzelPrefix = (
  puzzelContext: any,
  phoneNumberToCall: string,
  isFunctional: boolean,
) =>
  puzzelContext.phoneNumberPrefix +
  (isFunctional ? puzzelContext.functionalNumberPrefix : "") +
  phoneNumberToCall;

const getPuzzelPhoneNumber = async (
  phoneNumberToCall: string,
  puzzelContext: any,
) => {
  const numberType = await fetchPhoneNumberType(phoneNumberToCall);
  const isFunctional = numberType.data.phoneNumberType === "FUNCTIONAL";
  return withPuzzelPrefix(puzzelContext, phoneNumberToCall, isFunctional);
};

export const initiatePhoneCall = async (
  numberToCall: string,
  userId: string,
  puzzelContext: any,
  outboundVisualPhoneNumber: string | undefined,
) => {
  const destination = await getPuzzelPhoneNumber(numberToCall, puzzelContext);

  const payload: callOutPayload = {
    destination,
    countryCode: puzzelContext.countryCode,
    maxAttempts: puzzelContext.maxAttempts,
    secondsBetweenAttempts: puzzelContext.secondsBetweenAttempts,
    ciqType: puzzelContext.ciqType,
    queueKey: puzzelContext.queueKey,
    maxOneRequestForPreferredAgent:
      puzzelContext.maxOneRequestForPreferredAgent,
    preferredAgentWaitTimeSeconds: puzzelContext.preferredAgentWaitTimeSeconds,
    reservedAgentWaitTimeSeconds: puzzelContext.reservedAgentWaitTimeSeconds,
    serviceSystemVariables:
      outboundVisualPhoneNumber === undefined
        ? null
        : {
            name: "ciq_originating",
            value: outboundVisualPhoneNumber,
          },
  };

  return puzzelHttpClient.post(`users/${userId}/callout`, payload);
};

export const sessionCommandObject = async (data: any) => {
  const { payload, param } = data;
  if (
    payload.command === SessionCommands.TransferToPhone &&
    !payload.phoneNumber
  ) {
    try {
      const response = await userGetTelephoneApi({ userId: payload.agentId });
      const puzzelAgent = response.data?.result;
      const currentProfileId = puzzelAgent?.currentProfileId;
      const currentProfile = puzzelAgent.profiles.find(
        (profile: { id: number }) => profile.id === currentProfileId,
      );

      payload.phoneNumber = currentProfile.phoneNumber;
    } catch (e) {
      log(
        LogLevel.error,
        "sessionCommandObject",
        `Failed to resolve phone number to transfer to: ${e}`,
      );
    }
  }

  log(
    LogLevel.info,
    "sessionCommandObject",
    `New phone call session command: {agentId: ${payload.agentId}, command: ${payload.command}, currentUserId: ${payload.currentUserId}, phoneNumber: ${payload.phoneNumber}, requestId: ${payload.requestId}},
    `,
  );
  return puzzelHttpClient.post(
    `users/${param.userId}/sessions/${param.iqSessionId}/command`,
    payload,
  );
};

export const userSearchTelephoneApi = (data: any) => {
  const { payload, param } = data;
  return puzzelHttpClient.post(`users/${param.userId}/usersearch`, payload);
};

export const performPuzzelCatalogSearch = (query: {
  searchString: string;
  userId: string | null;
}) => {
  const payload = {
    organizationalUnit: "TOP|1092673|",
    searchString: query.searchString,
  };
  return puzzelHttpClient.post(
    `users/${query.userId}/catalog/contacts/search`,
    payload,
  );
};
