import styled from "styled-components";

export const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: min-content;
  column-gap: 9px;
  row-gap: 2px;
  align-items: baseline;
  height: 80%;
  padding-right: 24px;
  border-right: 1px solid ${({ theme }) => theme.colorSeparationLine};

  & .fillrate-failure {
    grid-row: 1;
    grid-column: 2;
  }

  & .train-numbers {
    grid-column: 1 / span 2;
    margin-top: 6px;
  }

  & .route-details {
    grid-column: 1 / span 2;
  }

  & .train-times {
    grid-column: 1 / span 2;
  }
`;

export const TrainNumbers = styled.div`
  display: flex;
  gap: 12px;
`;

export const TrainDate = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colorTextSecondary};
  margin-left: 12px;
`;
