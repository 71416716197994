import { CloseFill18Icon } from "@vygruppen/spor-icon-react";
import { ButtonGroup, Flex, HStack, Text } from "@vygruppen/spor-react";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { ChoiceChipForSuccessBackground } from "features/CenterContent/RoleContent/EventInteraction/ChoiceChipForInfoBackground";
import { StretchBuilderModalState } from "features/CenterContent/RoleContent/EventInteraction/StretchBuilderModalState";
import { StretchNameBadges } from "features/CenterContent/RoleContent/EventInteraction/StretchNameBadges";
import { CreateGlobalEventModal } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModal";
import CreateInternalMessageModal from "features/CenterContent/RoleContent/InternalMessage/CreateInternalMessageModal";
import {
  getClickedStopPairs,
  getStretchName,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { FC, useEffect, useState } from "react";
import { SemanticButton } from "shared/components/buttons/SemanticButton";
import { useEventMode, useStretchBuilder } from "stores/useStretchBuilder";
import { useTrainMapOverlayProvider } from "stores/useTrainMapOverlayProvider";
import styled, { useTheme } from "styled-components";

const AnimatedBox = styled.div<{ show: boolean }>`
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.3s;
`;

type EventTypeButton = {
  title: string;
  isDisabled: boolean;
  onClick: () => void;
};

export const EventInteractionBar: FC = () => {
  const theme = useTheme();
  const [showGlobalEventModal, setShowGlobalEventModal] = useState(false);
  const [showInternalMessageModal, setShowInternalMessageModal] =
    useState(false);
  const [show, setShow] = useState(false);
  const eventMode = useEventMode((state) => state.eventMode);

  useEffect(() => {
    setTimeout(() => {
      setShow(eventMode);
    }, 50);
  }, [eventMode]);

  const [
    clickedStops,
    selectedLegs,
    selectedStops,
    allStopsSelected,
    setAllStopsSelected,
    stretchBuilderModalState,
    setStretchBuilderModalState,
    closeAndResetStretchBuilder,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.selectedStops,
    state.allStopsSelected,
    state.setAllStopsSelected,
    state.stretchBuilderModalState,
    state.setStretchBuilderModalState,
    state.closeAndResetStretchBuilder,
  ]);

  const { setTrainMapOverlay } = useTrainMapOverlayProvider();

  const { singleClickedStop } = getClickedStopPairs(clickedStops);

  const isEditStation =
    stretchBuilderModalState.type === "station" &&
    !!stretchBuilderModalState.eventInfo;
  const isEditInfrastructure =
    stretchBuilderModalState.type === "infrastructure" &&
    !!stretchBuilderModalState.eventInfo;
  const disableInfrastructureButton =
    isEditStation || selectedLegs.length === 0 || singleClickedStop !== null;
  const disableStationButton =
    isEditInfrastructure || selectedStops.length === 0;
  const disableGlobalButton = selectedStops.length > 0;

  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" in stretch builder

  useEffect(() => {
    if (showGlobalEventModal || showInternalMessageModal) {
      setTrainMapOverlay(true);
    } else {
      setTrainMapOverlay(false);
    }
  }, [
    showGlobalEventModal,
    showInternalMessageModal,
    stretchBuilderModalState,
  ]);

  const eventTypeButtons: EventTypeButton[] = [
    {
      title: "Infrastruktur",
      isDisabled: disableInfrastructureButton,
      onClick: () => {
        if (stretchBuilderModalState.type === "infrastructure") {
          // if type is already "infrastructure", it means edit mode
          setStretchBuilderModalState({
            ...stretchBuilderModalState,
            stretchBuilderMode: false,
          });
        } else {
          setStretchBuilderModalState({
            type: "infrastructure",
            stretchBuilderMode: false,
          });
        }
      },
    },
    {
      title: "Stasjon",
      isDisabled: disableStationButton,
      onClick: () => {
        if (stretchBuilderModalState.type === "station") {
          setStretchBuilderModalState({
            ...stretchBuilderModalState,
            stretchBuilderMode: false,
          });
        } else {
          setStretchBuilderModalState({
            type: "station",
            stretchBuilderMode: false,
          });
        }
      },
    },
    {
      title: "Global driftsmelding",
      isDisabled: disableGlobalButton,
      onClick: () => {
        setShowGlobalEventModal(true);
        setStretchBuilderModalState({
          type: "none",
        });
      },
    },
    {
      title: "Intern melding",
      isDisabled: false,
      onClick: () => {
        if (selectedStops.length === 0) {
          setStretchBuilderModalState({
            type: "none",
          });
        }
        setShowInternalMessageModal(true);
      },
    },
  ];

  const eventTypeButtonsToShow = eventTypeButtons.filter((button) => {
    if (button.title === "Intern melding") return internalMessageEnabled;
    return true;
  });

  const stretchNames = getStretchName(
    selectedLegs,
    clickedStops,
    allStopsSelected,
  );

  return (
    <AnimatedBox show={show}>
      <Flex
        backgroundColor={theme.colorSuccessFillPulse}
        borderColor={theme.colorSuccessOutline}
        borderWidth="1px 1px 0 1px"
        color={theme.colorSuccessTextMain}
        justifyContent="stretch"
        paddingY={2}
        paddingX={3}
        marginTop={1}
        marginBottom={0}
        borderRadius="xs"
      >
        <HStack width="100%" flexWrap="wrap">
          <Text
            variant="xs"
            fontWeight="bold"
            width="fit-content"
            paddingRight={2}
          >
            {isEditInfrastructure || isEditStation ? "Endre" : "Opprett"}{" "}
            hendelse
          </Text>
          <Text variant="xs" width="fit-content">
            For område
          </Text>
          <ChoiceChipForSuccessBackground
            size="xs"
            isChecked={allStopsSelected}
            onChange={() => setAllStopsSelected(!allStopsSelected)}
          >
            Hele landet
          </ChoiceChipForSuccessBackground>
          {!allStopsSelected && (
            <StretchNameBadges
              stretchNames={stretchNames}
              singleClickedStop={singleClickedStop}
            />
          )}
          <HStack flexWrap="nowrap">
            <Text variant="xs" minWidth="fit-content">
              og type hendelse
            </Text>
            <ButtonGroup>
              {eventTypeButtonsToShow.map((button) => (
                <SemanticButton
                  key={button.title}
                  size="xs"
                  severity="success"
                  onClick={button.onClick}
                  isDisabled={button.isDisabled}
                >
                  {button.title}
                </SemanticButton>
              ))}
            </ButtonGroup>
          </HStack>
        </HStack>
        <HStack alignItems="start">
          <SemanticButton
            size="xs"
            severity="success"
            onClick={() => closeAndResetStretchBuilder()}
            isDisabled={false}
            leftIcon={<CloseFill18Icon />}
            variant="tertiary"
          >
            Avbryt
          </SemanticButton>
        </HStack>
      </Flex>

      <StretchBuilderModalState />
      {showGlobalEventModal && (
        <CreateGlobalEventModal onClose={() => closeAndResetStretchBuilder()} />
      )}

      {showInternalMessageModal && (
        <CreateInternalMessageModal
          setModalOpen={setShowInternalMessageModal}
          stations={selectedStops}
          allStopsSelected={allStopsSelected}
          stretchBuilderModalState={stretchBuilderModalState}
          setStretchBuilderModalState={setStretchBuilderModalState}
          onClose={() => {
            closeAndResetStretchBuilder();
          }}
        />
      )}

      <DashedLine color={theme.colorSuccessFillPulse} />
    </AnimatedBox>
  );
};
