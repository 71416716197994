import { HStack, Skeleton, SkeletonText, VStack } from "@vygruppen/spor-react";
import { Text } from "../../typography/TitleStyles";
import {
  SkeletonButton,
  SkeletonElement,
  SkeletonElementWrapper,
  SkeletonRow,
  SkeletonWrapper,
} from "./SkeletonLoaderStyles";

type SkeletonType =
  | "list"
  | "listBig"
  | "trainmap"
  | "trainStops"
  | "trainList"
  | "affectedTrains"
  | "reasonCode"
  | "tilsynAlertsFullSize"
  | "tilsynAlerts"
  | "trainReasonCodes"
  | "materialContent"
  | "oplogg"
  | "todo"
  | "trainComposition"
  | "selectReserveDropdown";

const ListSkeleton = ({ height }: { height?: string }) => (
  <SkeletonWrapper>
    {Array.from(Array(3)).map((_, index) => (
      <SkeletonElement key={index} style={{ height }} />
    ))}
  </SkeletonWrapper>
);

const TrainMapSkeleton = () => (
  <SkeletonWrapper style={{ borderRadius: "6px" }}>
    <SkeletonElement
      style={{
        margin: "0px",
        height: "35rem",
        borderRadius: "6px 6px 0 0",
      }}
    />
  </SkeletonWrapper>
);

const TrainStopsSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonElement style={{ margin: "3rem auto", width: "80%" }} />
  </SkeletonWrapper>
);

const TrainListSkeleton = () => (
  <>
    {Array.from(Array(3)).map((_, index) => (
      <SkeletonWrapper key={index} style={{ height: "auto" }} $borderBottom>
        <SkeletonButton style={{ marginRight: "12px" }} />
        <SkeletonButton />
        {Array.from(Array(3)).map((element, i) => (
          <SkeletonElement
            key={`${index}-${i}`}
            style={{ width: "60%", margin: "15px" }}
          />
        ))}
      </SkeletonWrapper>
    ))}
  </>
);

const AffectedTrainsSkeleton = () => (
  <>
    <SkeletonWrapper style={{ height: "auto", backgroundColor: "transparent" }}>
      <VStack width="100%" p={2} gap="16px">
        {Array.from(Array(3)).map((_, i) => (
          <Skeleton key={i} height={7} width="100%" borderRadius="10" />
        ))}
      </VStack>
    </SkeletonWrapper>
    <SkeletonWrapper style={{ height: "auto", backgroundColor: "transparent" }}>
      <SkeletonText width="60%" margin="16px" />

      <HStack width="100%" p={2} gap="40px">
        {Array.from(Array(2)).map((_, i) => (
          <Skeleton key={i} height={7} width="160px" borderRadius="10" />
        ))}
      </HStack>
      <HStack width="100%" p={2} gap="40px">
        <Skeleton height={7} width="200px" borderRadius="10" />
      </HStack>
    </SkeletonWrapper>
  </>
);

const TrainReasonCodesSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonRow style={{ justifyContent: "space-between" }}>
      {Array.from(Array(5)).map((_, index) => (
        <SkeletonElement key={index} style={{ width: "12%" }} />
      ))}
    </SkeletonRow>
  </SkeletonWrapper>
);

const TilsynAlertsFullSizeSkeleton = () => (
  <SkeletonWrapper>
    {Array.from(Array(3)).map((_, index) => (
      <SkeletonRow key={index}>
        {Array.from(Array(6)).map((element, i) => (
          <SkeletonElement
            key={`${index}-${i}`}
            style={{ width: "8%", margin: "15px 7% 15px 0px" }}
          />
        ))}
        <SkeletonButton
          style={{ margin: "0px", height: "36px", width: "65px" }}
        />
      </SkeletonRow>
    ))}
  </SkeletonWrapper>
);

const TilsynAlertsSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonRow style={{ justifyContent: "space-between" }}>
      {Array.from(Array(4)).map((_, index) => (
        <SkeletonElement key={index} style={{ width: "12%" }} />
      ))}
    </SkeletonRow>
  </SkeletonWrapper>
);

const MaterialContentSkeleton = () => (
  <SkeletonWrapper>
    {Array.from(Array(5)).map((_, index) => (
      <SkeletonElement
        key={index}
        style={{
          width: "32%",
          height: "35px",
          margin: "10px 10px 20px 10px",
        }}
      />
    ))}
  </SkeletonWrapper>
);

const ReasonCodeSkeleton = () => (
  <SkeletonWrapper>
    {Array.from(Array(3)).map((_, index) => (
      <SkeletonRow key={index} style={{ margin: "0px" }}>
        {Array.from(Array(6)).map((_element, i) => (
          <SkeletonElement
            key={`${index}-${i}`}
            style={{ width: "10%", margin: "15px 2% 0px 0px" }}
          />
        ))}
      </SkeletonRow>
    ))}
  </SkeletonWrapper>
);

const OploggSkeleton = () => (
  <SkeletonWrapper>
    {Array.from(Array(3)).map((_, index) => (
      <SkeletonElementWrapper key={index}>
        <SkeletonElement
          style={{ width: "100%", margin: "0px 0px 45px 0px" }}
        />
        <SkeletonButton
          style={{ width: "95px", height: "12px", margin: "-32px 0px" }}
        />
      </SkeletonElementWrapper>
    ))}
  </SkeletonWrapper>
);

const TodoSkeleton = () => (
  <>
    {Array.from(Array(2)).map((_, index) => (
      <SkeletonWrapper key={index} style={{ height: "auto" }} $borderBottom>
        <SkeletonElementWrapper>
          <SkeletonButton
            style={{ width: "69px", height: "36px", margin: "0px -8px" }}
          />
          <SkeletonElement style={{ width: "95px", margin: "0px" }} />
          <SkeletonElement style={{ width: "304px", margin: "8px 0px" }} />
          <SkeletonElement
            style={{
              width: "95px",
              height: "12px",
              margin: "25px 0px 0px 0px",
            }}
          />
        </SkeletonElementWrapper>
      </SkeletonWrapper>
    ))}
  </>
);

const TrainCompositionSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonElement
      style={{ height: "30px", width: "60%", margin: "50px auto" }}
    />
  </SkeletonWrapper>
);

const SelectReserveDropdownSkeleton = () => (
  <SkeletonWrapper>
    <SkeletonElement style={{ width: "100%", height: "42px" }} />
  </SkeletonWrapper>
);

const SkeletonLoader = ({ skeletonType }: { skeletonType: SkeletonType }) => {
  switch (skeletonType) {
    case "list":
      return <ListSkeleton />;
    case "listBig":
      return <ListSkeleton height="60px" />;
    case "trainmap":
      return <TrainMapSkeleton />;
    case "trainStops":
      return <TrainStopsSkeleton />;
    case "trainList":
      return <TrainListSkeleton />;
    case "affectedTrains":
      return <AffectedTrainsSkeleton />;
    case "reasonCode":
      return <ReasonCodeSkeleton />;
    case "trainReasonCodes":
      return <TrainReasonCodesSkeleton />;
    case "tilsynAlertsFullSize":
      return <TilsynAlertsFullSizeSkeleton />;
    case "tilsynAlerts":
      return <TilsynAlertsSkeleton />;
    case "materialContent":
      return <MaterialContentSkeleton />;
    case "oplogg":
      return <OploggSkeleton />;
    case "todo":
      return <TodoSkeleton />;
    case "trainComposition":
      return <TrainCompositionSkeleton />;
    case "selectReserveDropdown":
      return <SelectReserveDropdownSkeleton />;
    default:
      return <Text>Laster...</Text>;
  }
};

export default SkeletonLoader;
