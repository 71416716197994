import { zodResolver } from "@hookform/resolvers/zod";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import { Box, Skeleton, SkeletonText, Stack } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { createInternalMessage } from "features/CenterContent/RoleContent/InternalMessage/utils/queries";
import { InfrastructureEventType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { TrainInfoForm } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import {
  SingleTrainFormSchema,
  singleTrainFormSchema,
  trainFormSchemaValidatorMessage,
  TrickFormHookGroupedTrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { useInfrastructureInformationForTrain } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/useInfrastructureInformationForTrain";
import {
  defaultFormSchema,
  singleTrainFormToRequestBody,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { OperationalInformationTrainResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/operationalInformationTrainResponse";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { useOperationalInformationTypes } from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { FC, useEffect, useMemo } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { InfoMessageWithRetryButton } from "shared/components/feedback/InfoMessageWithRetryButton";
import { useDropsRole } from "stores/useDropsRole";
import { TrainInfoProvider, useSetAllTrainIds } from "./useTrainInfoContext";

export type CommonSubTypeProps = {
  trainId: TrainIdentifier;
  infrastructureEvent?: InfrastructureEventType;
};

export type BatchSubTypeProps = {
  activeSeriesKey: keyof TrickFormHookGroupedTrainFormSchema;
  groupIndex: number;
};

export type RequestBodyEdit = {
  type: "edit";
  body: TrainInfoRequest;
};

export type RequestBodyCreate = {
  type: "create";
  body: TrainInfoRequest;
};

export type RequestBody = RequestBodyCreate | RequestBodyEdit;

type NetworkStatus = "error" | "pending" | "idle" | "success";

type TrainInfoCreateProps = {
  mode: "create";
  onSubmit: UseMutateAsyncFunction<
    OperationalInformationTrainResponse,
    Error,
    RequestBody
  >;
  formData?: undefined;
  formDataStatus?: undefined;
  uuid?: undefined;
};

type TrainInfoEditProps = {
  mode: "edit";
  onSubmit: UseMutateAsyncFunction<
    OperationalInformationTrainResponse,
    Error,
    RequestBody
  >;
  formData: SingleTrainFormSchema | undefined; // Not optional but can be undefined before network request resolves
  formDataStatus: NetworkStatus;
  uuid: string;
};

type TrainInfoModalProps = {
  onClose: () => void;
  submitStatus: NetworkStatus;
  trainInfoWithOpenState: string[];
  title?: string;
  submitButtonLabel?: string;
  selectedTrain: TrainIdentifier;
};

const RenderInfrastructureDataNoDataInfo = ({
  retryFn,
}: {
  retryFn: () => void;
}) => (
  <InfoMessageWithRetryButton
    message="Fikk ikke til å hente aktive infrastrukturhendelser. Du kan fortsatt opprette toghendelse, men ikke koble den til en infrastrukturhendelse"
    retryFn={retryFn}
  />
);

/**
 * Render the train info form modal, either for editing an existing train event or creating a new train event
 * @param selectedTrain
 * @param onSubmit callback to perform when the form is submitted. The form must be valid before this callback is called.
 * @param onClose callback to perform when closing the modal
 * @param submitStatus the status of the action being performed onSubmit. Typically, the status of
 *                     the create/edit mutation
 * @param formData when editing an existing Event pass the existing status as formData
 * @param formDataStatus the status of the request that fetches formData
 * @param mode indicates if a new Event is being created, or an existing Event is being edited
 * @param uuid the id of event currently being edited
 * @param trainInfoWithOpenState a list of open train Events for this train. Used to avoid duplicate Events
 * @param title override the title
 * @param submitButtonLabel override the label on the submit button
 */

const TrainInfoFormModalContent: FC<
  TrainInfoModalProps & (TrainInfoCreateProps | TrainInfoEditProps)
> = ({
  selectedTrain,
  onSubmit,
  onClose,
  submitStatus,
  formData,
  formDataStatus = "success",
  mode,
  uuid,
  trainInfoWithOpenState,
  title,
  submitButtonLabel,
}) => {
  const { role } = useDropsRole();

  const { identifier, nominalDate } = selectedTrain;

  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();

  const formMethods = useForm<SingleTrainFormSchema>({
    resolver: zodResolver(singleTrainFormSchema),
    defaultValues: formData ?? defaultFormSchema(role),
    values: formData, // the formState will be updated if and when formData is updated
  });

  const { handleSubmit, control, formState } = formMethods;

  // Watch the form state and fetch preview data when it changes
  const currentFormValues = useWatch({
    control,
    name: "trainForm",
  });

  const {
    data: infrastructureData,
    status: infrastructureDataStatus,
    refetch: infrastructureDataRetryFn,
  } = useInfrastructureInformationForTrain(nominalDate, identifier);

  // Necessary for batchUseTrainRoute (Reused from TrainInfoBatchModal for consistency)
  const setAllTrainIds = useSetAllTrainIds();
  useEffect(() => {
    setAllTrainIds([selectedTrain]);
  }, [selectedTrain]);

  const tooltip = useMemo(
    () => trainFormSchemaValidatorMessage(currentFormValues),
    [currentFormValues],
  );

  // Submitting the form
  const onSubmitForm = handleSubmit((data) => {
    const trainInfoBody = singleTrainFormToRequestBody(
      data.trainForm,
      selectedTrain,
    );
    if (!trainInfoBody) {
      return;
    }

    const requestBody: RequestBody = {
      type: mode,
      body: trainInfoBody,
    };

    onSubmit(requestBody).then(
      createInternalMessage(data.trainForm.internalMessages),
    );
  });

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        onSubmitTooltip={tooltip}
        title={
          title ??
          (mode === "create" ? "Opprett toghendelse" : "Endre toghendelse")
        }
        actionTitle={
          submitButtonLabel ??
          (mode === "create" ? "Opprett hendelse" : "Endre hendelse")
        }
        onClose={onClose}
        onSubmit={onSubmitForm}
        isLoading={submitStatus === "pending"}
        isSuccess={submitStatus === "success"}
        isError={submitStatus === "error"}
        successMessage={
          mode === "create" ? "Hendelse opprettet" : "Hendelse endret"
        }
        failureMessage={
          mode === "create"
            ? "Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
            : "Kunne ikke endre hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
        }
        internalMessagesFormField="trainForm.internalMessages"
        internalMessagesMode={mode}
      >
        <ErrorBoundary>
          <Box w="100%" display="grid" gap={5}>
            {(opInfoTypesStatus === "pending" ||
              formDataStatus === "pending" ||
              infrastructureDataStatus === "pending") && (
              <>
                <Stack gap={2}>
                  <Skeleton height={6} />
                  <Skeleton height={6} />
                </Stack>
                <SkeletonText noOfLines={3} width="30%" />
              </>
            )}

            {(opInfoTypesStatus === "error" || formDataStatus === "error") && (
              <FailureMessage />
            )}

            {infrastructureDataStatus === "error" && (
              <RenderInfrastructureDataNoDataInfo
                retryFn={infrastructureDataRetryFn}
              />
            )}

            {opInfoTypesStatus === "success" &&
              formDataStatus === "success" && (
                <TrainInfoForm
                  mode={mode}
                  uuid={uuid}
                  selectedTrain={selectedTrain}
                  trainInfoWithOpenState={trainInfoWithOpenState}
                  opInfoTypes={opInfoTypes}
                  infrastructureData={infrastructureData}
                  formState={formState}
                  currentFormValues={currentFormValues}
                  previousEventType={formData?.trainForm?.type}
                />
              )}
          </Box>
        </ErrorBoundary>
      </ActionModal>
    </FormProvider>
  );
};

const TrainInfoFormModal: FC<
  TrainInfoModalProps & (TrainInfoCreateProps | TrainInfoEditProps)
> = (props) => (
  <TrainInfoProvider>
    <TrainInfoFormModalContent {...props} />
  </TrainInfoProvider>
);

export default TrainInfoFormModal;
