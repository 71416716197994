import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { Flex, HStack, IconButton, Stack, VStack } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { getVisibility } from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { Badges } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import { INTERNAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import {
  getBadgeExtra,
  getBadgeItem,
  getCategory,
  getDisplayDurationFromVisibility,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { DepublishInformation } from "features/OperationalInformation/DepublishInformation";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { useDropsRole } from "stores/useDropsRole";
import { useTheme } from "styled-components";
import { InternalMessageResponse } from "../../stationEvents/stationEventResponseSchema";

type InternalMessageEventsProps = {
  data: InternalMessageResponse[];
  onEditClick: (event: InternalMessageResponse) => void;
};

export const InternalMessageEventsList = ({
  data,
  onEditClick,
}: InternalMessageEventsProps) => {
  const theme = useTheme();
  const { isVaktleder } = useDropsRole();
  if (data.length === 0)
    return (
      <NoDataMessage reason="Det er ikke registrert noen hendelser med denne filtreringen" />
    );
  return (
    <Stack gap={3}>
      {data.map((event) => {
        const text = event.customTexts[0].texts.NOB;
        const dropsBadgeItem = getBadgeItem(event);
        const dropsBadgeExtra = getBadgeExtra(event);
        const category = getCategory(event);
        return (
          <VStack
            key={event.uuid}
            border={`1px solid ${theme.colorHoverItem}`}
            background={theme.colorBackgroundTertiary}
            borderRadius="sm"
            p={3}
            justifyItems="space-between"
            alignItems="flex-start"
            gap={2}
            flex="1"
            position="relative"
          >
            <Flex w="100%" flexWrap="wrap-reverse">
              <Flex flexDir="column" gap="12px">
                {dropsBadgeItem && (
                  <Flex gap={2}>
                    <DropsBadge semantics="info">{dropsBadgeItem}</DropsBadge>
                    {dropsBadgeExtra && (
                      <DropsBadge semantics="info">
                        {dropsBadgeExtra}
                      </DropsBadge>
                    )}
                  </Flex>
                )}
                <Flex
                  // DropsBadgeItem decides the width instead if it exists (necessary for wrap)
                  w={dropsBadgeItem ? "0px" : undefined}
                >
                  <Badges
                    minW="max-content" // necessary when parent is 0px
                    eventCategory={category}
                    displayDuration={getDisplayDurationFromVisibility(
                      getVisibility(event.visibility),
                    )}
                  />
                </Flex>
              </Flex>
              {isVaktleder() && (
                <HStack minWidth="fit-content" ml="auto" mb="auto">
                  <DepublishInformation
                    uuid={event.uuid}
                    isDisabled={false}
                    queryKey={[INTERNAL_EVENTS_QUERY_KEY]}
                  />
                  <IconButton
                    onClick={() => onEditClick(event)}
                    size="sm"
                    aria-label="Rediger"
                    variant="tertiary"
                    icon={<EditOutline18Icon />}
                  />
                </HStack>
              )}
            </Flex>
            <Text>{text.description}</Text>
            <Text secondary>
              {format(
                event.updatedAt,
                "'Sist oppdatert' dd.MM.yyyy 'kl.' HH:mm",
              )}
            </Text>
          </VStack>
        );
      })}
    </Stack>
  );
};
