import {
  ArrowRightFill18Icon,
  CloseFill18Icon,
} from "@vygruppen/spor-icon-react";
import { BoxProps, Flex } from "@vygruppen/spor-react";
import {
  ActiveTab,
  InactiveTab,
  TabText,
} from "features/CenterContent/shared/Tabs/TabsStyle";
import { addOrRemove } from "features/TodoOploggSidebar/Todo/todoFilter/useTodoFilterState";
import { FC, ReactNode, useEffect } from "react";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";

export type Tab = {
  id: string;
  iconActive: ReactNode;
  iconInactive: ReactNode;
  text: string;
};

type TabsSidesProps = {
  tabs: Tab[];
  selectedTabIds: string[];
  setSelectedTabIds: (tabIds: string[]) => void;
  justifyContent: "start" | "end";
  allowSplit?: boolean;
  notifications?: number[];
  setNotifications?: (value: number[]) => void;
} & BoxProps;

export const TabsSides: FC<TabsSidesProps> = ({
  tabs,
  selectedTabIds,
  setSelectedTabIds,
  justifyContent,
  allowSplit,
  notifications,
  setNotifications,
  ...props
}) => {
  const multipleTabs = selectedTabIds.length >= 2;
  const showNotification = (tabId: string, index: number) => {
    const isSelected = selectedTabIds.includes(tabId);
    const hasNotifications = notifications && notifications[index] > 0;
    return !isSelected && hasNotifications;
  };

  useEffect(() => {
    // If you switch tab, set each tab's notification to 0
    if (setNotifications) setNotifications(tabs.map((_) => 0));
  }, [selectedTabIds]);
  return (
    <Flex
      justifyContent={allowSplit ? "space-between" : justifyContent}
      width="100%"
      mb="0px"
      zIndex="9"
      px="6px"
      {...props}
    >
      {tabs?.map((it, i) => {
        const isActive = selectedTabIds.includes(it.id);
        const TabForRoleItem = isActive ? ActiveTab : InactiveTab;
        const SplitIcon = multipleTabs ? CloseFill18Icon : ArrowRightFill18Icon;
        const icon = isActive ? it.iconActive : it.iconInactive;
        const action = allowSplit
          ? () => setSelectedTabIds(addOrRemove(selectedTabIds, it.id))
          : () => setSelectedTabIds([it.id]);
        const disabled = allowSplit && i === 0;
        return (
          <TabForRoleItem
            key={i}
            title="Vis hovedside for valgt funksjon"
            onClick={disabled ? undefined : action}
            $disabled={disabled}
          >
            <TabText>
              {icon}
              {it.text}
            </TabText>
            {showNotification(it.id, i) ? (
              <AlertsCounter
                ml={0.5}
                alerts={notifications![i]}
                severity="neutral"
              />
            ) : null}
            {allowSplit && i === 1 && <SplitIcon />}
          </TabForRoleItem>
        );
      })}
    </Flex>
  );
};
