import { z } from "zod";
import {
  fromDate,
  getLocalTimeZone,
  ZonedDateTime,
} from "@internationalized/date";
import { CheckListType, companyId } from "../utils";

export const dateTimeSchemaResponse = z.coerce
  .date()
  .transform((time) => fromDate(time, getLocalTimeZone()));
export type DateTimeResponse = z.infer<typeof dateTimeSchemaResponse>;

export const dateTimeSchemaRequest = z
  .custom<ZonedDateTime>()
  .transform((date) => date.toString());
export type DateTimeRequest = z.infer<typeof dateTimeSchemaRequest>;

export const dateTimeSchemaForm = z.custom<ZonedDateTime>();
export type DateTimeForm = z.infer<typeof dateTimeSchemaForm>;

export const checklistFormSchema = z.object({
  dateTime: dateTimeSchemaForm,
  comment: z.string(),
  fillOutType: z.custom<CheckListType>(),
  rollingStockId: z.string().min(1, "Velg kjøretøy"),
  vehicleType: z.string().min(1, "Kjøretøytype er påkrevd"),
  trainOwner: z.custom<companyId>().refine((elem) => elem, "Velg selskap"),
});

export type ChecklistFormData = z.infer<typeof checklistFormSchema>;
