import { addDays, format, isAfter, isBefore, isEqual } from "date-fns";
import { nb } from "date-fns/locale";
import {
  AlternativeTransportItineraryStop,
  VehicleStatus,
} from "shared/types/alternativeTransport";
import { parseTime } from "shared/utils/datetime";
import { delayThreshold } from "shared/utils/delayUtils";
import { DefaultTheme } from "styled-components";

export type DateComparisonFn = (date: Date, dateToCompare: Date) => boolean;

export const isBeforeOrEqual: DateComparisonFn = (date, dateToCompare) =>
  isBefore(date, dateToCompare) || isEqual(date, dateToCompare);
export const isAfterOrEqual: DateComparisonFn = (date, dateToCompare) =>
  isAfter(date, dateToCompare) || isEqual(date, dateToCompare);

export const getAuthor = (name: string, author: string | null) =>
  author ? author.split("@")[0] : name;

export const isDateValidComparison = (
  date: Date | null,
  dateToCompare: Date | null,
  comparison: DateComparisonFn,
  errorMessage: string,
) => {
  if (date === null || dateToCompare === null) return true;

  return comparison(date, dateToCompare) ? undefined : errorMessage;
};

export const timeFromKeys = (
  stop: AlternativeTransportItineraryStop | null,
  keys: Array<TimeKeys>,
): ReturnTypeTimeKeys => {
  if (stop === null) return null;

  return keys.map((key) => stop[key]).find((time) => time !== null) ?? null;
};

export const isPassed = (stop: AlternativeTransportItineraryStop) => {
  const actualDepartureTime = timeFromKeys(stop, ["actualDepartureTime"]);
  if (actualDepartureTime === null) {
    const estimatedDepartureTime = timeFromKeys(stop, ["estimatedArrivalTime"]);
    return (
      estimatedDepartureTime !== null && new Date() > estimatedDepartureTime
    );
  }
  return new Date() > actualDepartureTime;
};

export const getArrivalTime = (
  stop: AlternativeTransportItineraryStop,
): Date | null => {
  if (stop.actualArrivalTime) {
    return new Date(stop.actualArrivalTime);
  }
  if (stop.estimatedArrivalTime) {
    return new Date(stop.estimatedArrivalTime);
  }

  if (stop.scheduledArrivalTime) {
    return new Date(stop.scheduledArrivalTime);
  }

  return null;
};

export const getArrivalTimeStr = (
  stop: AlternativeTransportItineraryStop,
  defaultStr = "",
): string => {
  const arrivalTime = getArrivalTime(stop);

  return (
    (arrivalTime && format(arrivalTime, "HH:mm", { locale: nb })) ?? defaultStr
  );
};

export const getIsBetweenStops = (
  allStops: AlternativeTransportItineraryStop[],
  stop: AlternativeTransportItineraryStop,
  index: number,
) => {
  let departureTime = timeFromKeys(stop, ["actualDepartureTime"]);
  if (departureTime === null) {
    departureTime = timeFromKeys(stop, ["estimatedDepartureTime"]);
  }
  if (allStops.length > index + 1) {
    let nextArrivalTime = timeFromKeys(allStops[index + 1], [
      "estimatedArrivalTime",
    ]);
    if (nextArrivalTime === null) {
      nextArrivalTime = timeFromKeys(allStops[index + 1], [
        "estimatedArrivalTime",
      ]);
    }

    if (index < allStops.length && nextArrivalTime !== null) {
      const timeNow = new Date();

      if (!departureTime || !nextArrivalTime) {
        return false;
      }

      return departureTime < timeNow && nextArrivalTime > timeNow;
    }
  }
  return false;
};

export const getColor = (
  stop: AlternativeTransportItineraryStop,
  theme: DefaultTheme,
  index?: number,
) => {
  let stopColor = theme.colorOutline;
  let lineColor = theme.colorOutline;
  let textColor = theme.colorTextSecondary;

  if (isPassed(stop)) {
    lineColor = theme.colorTextMain;
    stopColor = theme.colorTextMain;
    textColor = theme.colorTextPrimary;
  }

  const scheduledArrivalTime = timeFromKeys(stop, ["scheduledArrivalTime"]);
  let arrivalTime = timeFromKeys(stop, ["actualArrivalTime"]);
  if (arrivalTime === null) {
    arrivalTime = timeFromKeys(stop, ["estimatedArrivalTime"]);
  }

  if (scheduledArrivalTime !== null) {
    const delay = arrivalTime
      ? (arrivalTime.getTime() - scheduledArrivalTime.getTime()) / 60000 // divide to get minutes
      : 0;

    if (delay >= delayThreshold.major) {
      lineColor = theme.colorAlarm;
      stopColor = theme.colorAlarm;
    } else if (delay >= delayThreshold.moderate) {
      lineColor = theme.colorSecondaryAlarm;
      stopColor = theme.colorSecondaryAlarm;
    } else if (delay >= delayThreshold.minor) {
      lineColor = theme.colorWarning;
      stopColor = theme.colorWarning;
    }
  }

  if (index !== undefined && index === 0 && stop.activity === "BOARDING") {
    stopColor = theme.colorOutline;
  }

  return { lineColor, stopColor, textColor };
};
export const isStopCancelled = (
  stop: AlternativeTransportItineraryStop,
  vehicleStatus: VehicleStatus,
) =>
  stop.isDepartureCancelled ||
  stop.isArrivalCancelled ||
  vehicleStatus === "CANCELLED";

export const getDepartureTime = (
  stop: AlternativeTransportItineraryStop,
): Date | null => {
  if (stop.actualDepartureTime) {
    return new Date(stop.actualDepartureTime);
  }
  if (stop.estimatedDepartureTime) {
    return new Date(stop.estimatedDepartureTime);
  }

  if (stop.scheduledDepartureTime) {
    return new Date(stop.scheduledDepartureTime);
  }

  return null;
};

export const getDepartureTimeStr = (
  stop: AlternativeTransportItineraryStop,
  defaultStr = "",
): string => {
  const departureTime = getDepartureTime(stop);

  return (
    (departureTime && format(departureTime, "HH:mm", { locale: nb })) ??
    defaultStr
  );
};

/**
 *  Utility function for parsing a time string, but also overflow to next day whenever
 *  parsed date is earlier than travelDate. This means essensially that parsed time is
 *  passed midnight, therefore earlier, and should now be incremented by +1 day.
 *
 * @param timeStr time string in HH:mm format
 * @param travelDate reference date when journey is starting. Serves as refernce point.
 */
export const parseTimeAndApplyMidnightCheck = (
  timeStr: string,
  travelDate: Date,
): Date => {
  const date = parseTime(timeStr, travelDate);

  return isBefore(date, travelDate) ? addDays(date, 1) : date;
};

/**
 * Utility function to validate a time string. It will only compare the given time string if both time strings are valid.
 * @param timeStr time string to validate
 * @param timeToCompareStr time string to compare with
 * @param referenceDate reference date
 * @param comparison comparison function used to compare the time strings if both are present
 * @param errorMessage errorMessage yielded should the time string be invalid
 * @returns undefined if successfully valid or errorMessage as string if invalid.
 */
export const isTimeValidComparedTo = (
  timeStr: string,
  timeToCompareStr: string,
  referenceDate: Date,
  comparison: DateComparisonFn,
  errorMessage: string,
) => {
  if (timeStr === "") return undefined;
  try {
    const date = parseTimeAndApplyMidnightCheck(timeStr, referenceDate);
    if (timeToCompareStr === "") return undefined;
    const dateToCompare = parseTimeAndApplyMidnightCheck(
      timeToCompareStr,
      referenceDate,
    );

    return comparison(date, dateToCompare) ? undefined : errorMessage;
  } catch (err) {
    return errorMessage;
  }
};

type TimeKeys =
  | "actualArrivalTime"
  | "actualDepartureTime"
  | "scheduledArrivalTime"
  | "scheduledDepartureTime"
  | "estimatedArrivalTime"
  | "estimatedDepartureTime";
type ReturnTypeTimeKeys = AlternativeTransportItineraryStop[keyof Pick<
  AlternativeTransportItineraryStop,
  TimeKeys
>];
