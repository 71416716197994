import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { EditTrainInfoModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/EditTrainInfoModal";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import {
  TrainInfoMessage as TrainInfoMsg,
  TrainInformationAggregated,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { getUrgencySeverityMapping } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { DetailsParams } from "features/CenterContent/VehicleDetails/TrainDetails/TrainDetails";
import { routeChangedEventsQueryKey } from "features/CenterContent/VehicleDetails/TrainDetails/useRouteChangedEvents";
import { EventInfoMessage } from "features/CenterContent/VehicleDetails/shared/EventInfoMessage";
import { DropsLogTextInput } from "features/CenterContent/shared/DropsLogTextInput";
import { supportedOpInfoTypes } from "features/CenterContent/shared/operationalInformation/supportedOpInfoTypes";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { formatDayDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";

type TrainInfoMessageProps = {
  info: TrainInformationAggregated;
  trainInfoWithOpenState: string[];
};

export const TrainInfoMessage: FC<TrainInfoMessageProps> = ({
  info,
  trainInfoWithOpenState,
}) => {
  const { trainNumber, trainDate } = useParams<DetailsParams>();
  const queryClient = useQueryClient();

  const [dropsLogText, setDropsLogText] = useState<string | null>(null);
  const { mutate: closeTrainStoppedStatus } = useMutation({
    mutationKey: ["closeTrainStoppedStatus", info.latestInfoUuid],
    mutationFn: () =>
      mutationFnPOST<string, { dropsLogText?: string }>(
        `${getBackendUrl()}/trainInformation/information/${info.latestInfoUuid}/close`,
        dropsLogText ? { dropsLogText } : {},
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [routeChangedEventsQueryKey, trainNumber, trainDate],
      });
    },
  });

  const [askConfirmation, setAskConfirmation] = useState<boolean>(false);

  const indexOfLatestInfoUuid = info.versions.findIndex(
    (version) => version.uuid === info.latestInfoUuid,
  );

  // For some event types (TRAIN_STOPPED, TRAIN_INCREASED_CAPACITY, TRAIN_REDUCED_CAPACITY, TRAIN_MISSING_PRODUCT), multiple events for the same train is not supported.
  // Creating a new event will be considered a new version of the previous event of the same type, even if it is closed or replaced.
  // This can in some edge cases lead to all events being considered related to each other, leading to duplicate info lists with too many versions.
  // Therefore, we need to ensure that the the newest version uuid matches latestInfoUuid.
  // This is to ensure that all non-replaced versions are displayed on top, so that they can be closed or edited.
  const infoWithAllVersionsUpToLatestInfoUuid = {
    ...info,
    versions: info.versions.slice(indexOfLatestInfoUuid),
  };

  const { versions } = infoWithAllVersionsUpToLatestInfoUuid;

  const newestVersion = versions[0];

  const theme = useTheme();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const showActionButtons =
    newestVersion.state === "OPEN" &&
    (supportedOpInfoTypes.includes(newestVersion.type) ||
      newestVersion.type === "TRAIN_GENERAL_INFO" ||
      newestVersion.type === "TRAIN_GENERAL_WARNING");

  const getTitle = (trainInfoMessage: TrainInfoMsg): string => {
    switch (trainInfoMessage.state) {
      case "CLOSED":
        return `${trainInfoMessage.title} [friskmeldt]`;
      case "DEPUBLISHED":
        return `${trainInfoMessage.title} [depublisert]`;
      case "REPLACED":
        return `${trainInfoMessage.title} [erstattet av ny hendelse]`;
      default:
        return trainInfoMessage.title;
    }
  };

  if (trainNumber === undefined || trainDate === undefined) {
    return (
      <FailureMessage customMessage="Kunne ikke hente tognummer eller dato for dette toget." />
    );
  }

  const severity = getUrgencySeverityMapping(newestVersion);

  return (
    <>
      <EventInfoMessage
        title={getTitle(newestVersion)}
        subTitle={formatDayDateString(newestVersion.updatedAt)}
        severity={getUrgencySeverityMapping(versions[0])}
        versions={versions}
        showActionButtons={showActionButtons}
        askConfirmation={askConfirmation}
        setAskConfirmation={setAskConfirmation}
        setModalOpen={setModalOpen}
        close={async () => closeTrainStoppedStatus()}
        dropsLogTextInput={
          <DropsLogTextInput
            dropsLogText={dropsLogText}
            setDropsLogText={setDropsLogText}
            uuid={info.latestInfoUuid}
            defaultEnabled={
              newestVersion.type === TrainEventTypeEnum.TRAIN_STOPPED // We almost always want "Tog står" to go to drops log
            }
          />
        }
      />
      {modalOpen && (
        <EditTrainInfoModal
          uuid={info.latestInfoUuid}
          setModalOpen={setModalOpen}
          trainId={trainNumber}
          nominalDate={trainDate}
          countryCode="NO" // TODO: CountryCode should not be hardcoded
          trainInfoWithOpenState={trainInfoWithOpenState}
        />
      )}
    </>
  );
};
