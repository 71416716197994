import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { InternalInfoMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/InternalInfoMessage";
import { TrainInfoMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoMessage";
import { TrainInformationAggregatedResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import {
  getOpenStates,
  sortTrainInfos,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";

type Status = "error" | "success" | "pending";

type QueryDataStatus<T> = {
  data: T | undefined;
  status: Status;
};

const combineStatus = (...statuses: Status[]): Status => {
  const statusPriority: Status[] = ["error", "pending", "success"];
  const highestPriority = Math.min(
    ...statuses.map((it) => statusPriority.indexOf(it)),
  );
  return statusPriority[highestPriority];
};

export const getInfoMessages = (
  trainDataStatus: QueryDataStatus<TrainInformationAggregatedResponse>,
  internalMessagesDataStatus: QueryDataStatus<InternalMessageResponse[]>,
) => {
  const status = combineStatus(
    trainDataStatus.status,
    internalMessagesDataStatus.status,
  );
  switch (status) {
    case "pending":
      return <SkeletonLoader skeletonType="listBig" />;
    case "success": {
      const internalInfoMessages = (
        <InternalInfoMessage
          key="internalInfoMessage"
          info={internalMessagesDataStatus.data!!}
        />
      );
      const trainInfoWithOpenState = getOpenStates(trainDataStatus.data!!);
      const trainInfoMessages = sortTrainInfos(trainDataStatus.data!!).map(
        (trainInfo) => (
          <TrainInfoMessage
            info={trainInfo}
            key={trainInfo.latestInfoUuid}
            trainInfoWithOpenState={trainInfoWithOpenState}
          />
        ),
      );
      return [internalInfoMessages, ...trainInfoMessages];
    }
    case "error":
    default:
      return (
        <FailureMessage customMessage="Kunne ikke hente aktive hendelser for dette toget." />
      );
  }
};
