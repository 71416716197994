import { differenceInMinutes } from "date-fns";
import { DefaultTheme } from "styled-components";
import { DropsLogEntry, DropsLogResponse } from "./types";

const OPLOGG_NEW_TRESHOLD = 3;
const OPLOGG_OLD_TRESHOLD = 8;

export type DropsLogAge = "NEW" | "ALMOST_NEW" | "OLD";
const getAgeInMinutes = (reportedTime: Date): number => {
  const now = new Date();
  return differenceInMinutes(now, reportedTime);
};

export const getAge = (reportedTime: Date): DropsLogAge => {
  const ageInMinutes = getAgeInMinutes(reportedTime);

  if (ageInMinutes <= OPLOGG_NEW_TRESHOLD) {
    return "NEW";
  }

  if (ageInMinutes <= OPLOGG_OLD_TRESHOLD) {
    return "ALMOST_NEW";
  }

  return "OLD";
};
export const getAgeColor = (
  age: DropsLogAge,
  theme: DefaultTheme,
  highlighted: boolean = false,
): string => {
  switch (age) {
    case "ALMOST_NEW":
      return highlighted
        ? theme.colorSecondaryAlarm
        : theme.colorSecondaryAlarm;
    case "NEW":
      return highlighted ? theme.colorAlarm : theme.colorAlarm;
    case "OLD":
    default:
      return highlighted ? theme.colorTextMain : theme.colorSeparationLine;
  }
};

export const getAgeColorForVerticalLine = (
  age: DropsLogAge,
  theme: DefaultTheme,
  highlighted: boolean = false,
): string => {
  switch (age) {
    case "ALMOST_NEW":
      return highlighted
        ? theme.colorSecondaryAlarm
        : theme.colorSecondaryAlarmOutline;
    case "NEW":
      return highlighted ? theme.colorAlarm : theme.colorAlarmOutline;
    case "OLD":
    default:
      return highlighted ? theme.colorTextTertiary : theme.colorSeparationLine;
  }
};

const alreadyInPages = (pages: DropsLogResponse[], newMessage: DropsLogEntry) =>
  pages.some((page) =>
    page.entries.some((entry) => entry.uuid === newMessage.uuid),
  );

export const upsertPages = (
  pages: DropsLogResponse[],
  newMessage: DropsLogEntry,
) =>
  // Add new message. Filter out old version if new message is an update
  // This array isn't sorted anyway, so we sort it where it's used.
  // Adding the updated message in the same place as the old one isn't necessary.
  pages.map((page, index) => ({
    ...page,
    lastSeen: page.lastSeen + (!alreadyInPages(pages, newMessage) ? 1 : 0),
    entries: [
      ...(index === 0 ? [newMessage] : []),
      ...page.entries.filter((entry) => entry.uuid !== newMessage.uuid),
    ],
  }));
