import { Textarea } from "@vygruppen/spor-react";
import { Header } from "features/TodoOploggSidebar/Todo/common/TextInputStyle";
import { HandleInputChange } from "features/TodoOploggSidebar/Todo/todoList/todoElement/useForm";
import React from "react";
import { SectionTitle, Text } from "shared/components/typography/TitleStyles";

type Props = {
  messageText: string;
  handleInputChange: HandleInputChange;
  title: string;
  style?: React.CSSProperties;
};

const MAX_DESCRIPTION_LENGTH = 1000;

const TextInput = ({ messageText, handleInputChange, title, style }: Props) => (
  <div style={{ width: "100%", ...style }}>
    <Header>
      <SectionTitle>{title}</SectionTitle>
      <Text>
        {messageText.length ?? 0}/{MAX_DESCRIPTION_LENGTH}
      </Text>
    </Header>
    <Textarea
      name="description"
      maxLength={MAX_DESCRIPTION_LENGTH}
      label="Skriv melding her"
      onChange={(e) => {
        handleInputChange(e);
      }}
      value={messageText || ""}
      minHeight="8rem"
    />
  </div>
);

export default TextInput;
