import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { GLOBAL_EVENT_QUERY_KEY } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModal";
import {
  GlobalEventResponse,
  globalEventsResponseSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { closeInternalMessage } from "features/CenterContent/RoleContent/InternalMessage/utils/queries";

export async function getGlobalEventDetailed(
  uuid?: string,
  signal?: AbortSignal,
): Promise<GlobalEventResponse> {
  return axiosClient
    .get<
      GlobalEventResponse[]
    >(`${getBackendUrl()}/global-information/by-uuid/${uuid}?latest=true`, { signal })
    .then((res) => globalEventsResponseSchema.parse(res.data)[0]);
}

export const useGlobalEventDetailed = (uuid?: string) =>
  useQuery({
    queryKey: [GLOBAL_EVENT_QUERY_KEY, uuid],
    staleTime: 0,
    queryFn: ({ signal }) => getGlobalEventDetailed(uuid, signal),
  });

export const closeGlobalInternalMessage = (uuid: string) =>
  getGlobalEventDetailed(uuid)
    .then((res) => res.internalMessages)
    .then(closeInternalMessage);
