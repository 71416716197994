import { Flex } from "@vygruppen/spor-react";

export const SeperationLine = ({
  color = "#fff",
  marginY = 0,
}: {
  color: string;
  marginY: number;
}) => (
  <Flex justifyContent="center" width="100%" h="2px" my={marginY}>
    <svg width="100%" height="2" xmlns="http://www.w3.org/2000/svg">
      <line
        x1="1"
        y1="1"
        x2="100%"
        y2="1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 6"
      />
    </svg>
  </Flex>
);
