import { HTMLChakraProps } from "@chakra-ui/react";
import { Box } from "@vygruppen/spor-react";
import styled from "styled-components";
import { Text } from "../../typography/TitleStyles";

interface NoDataProps extends HTMLChakraProps<"div"> {
  reason: string;
}

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  margin-top: 0px;
  padding: 24px 12px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  justify-content: center;
  flex: 1;
`;

export const NoDataMessage = ({ reason, ...props }: NoDataProps) => (
  <Wrapper {...props}>
    <Text style={{ fontWeight: 600, textAlign: "center" }}>Ingen treff</Text>
    <Box maxWidth="300px" textAlign="center">
      <Text>{reason}</Text>
    </Box>
  </Wrapper>
);
