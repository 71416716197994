import { Grid, GridItem } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { BusGpsData } from "features/CenterContent/shared/types";
import { busGpsDataToBaseGpsData } from "features/CenterContent/shared/utils";
import MapAlert from "./MapAlert";
import { Wrapper } from "./VehicleMap";
import VehicleMapLayers from "./VehicleMapLayers";

import { useAlternativeTransportData } from "../../../VehicleSidebar/VehicleList/AlternativeTransportList/useAlternativeTransportData";
import { useVyatData } from "../../../VehicleSidebar/VehicleList/AlternativeTransportList/useVyAtData";

export const VehicleMapBus = () => {
  const day = format(new Date(), "yyyy-MM-dd");

  const { data } = useAlternativeTransportData(day);

  const { vyAtData, isError } = useVyatData();

  const mergedData: BusGpsData[] =
    vyAtData?.operationalVehicles.map((busGps) => {
      const { vehicleId } = busGps;
      const metaData = data?.vehicles.find(
        (vehicle) => vehicle.id === vehicleId,
      );

      return {
        timestamp: new Date(busGps.latestLocation.timestamp).toISOString(),
        gps: {
          longitude: parseFloat(busGps.latestLocation.longitude),
          latitude: parseFloat(busGps.latestLocation.latitude),
        },
        workshiftId: metaData?.workShiftId ?? "",
        trainNumber: metaData?.trainIds.join(" - ") ?? "Ikke tilgjengelig", // Dobbeltsjekke at en buss kan erstatte mange tog?
        phoneNumber: metaData?.driverPhoneNumber ?? "Ikke tilgjengelig",
        closestNeighbor: null,
        path: null,
      };
    }) ?? [];

  return (
    <Wrapper
      position="relative"
      height="100%"
      width="100%"
      align="center"
      justify="center"
    >
      <Grid
        position="absolute"
        alignItems="center"
        column={2}
        top={8}
        left={0}
        p={2}
        gap={2}
        zIndex={1000}
      >
        <GridItem colSpan={2}>
          <MapAlert isError={isError} hasNoResults={false} query="" />
        </GridItem>
      </Grid>

      <VehicleMapLayers
        searchPhoneNumber
        gpsData={busGpsDataToBaseGpsData(mergedData)}
        query=""
      />
    </Wrapper>
  );
};
