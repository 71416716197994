import { CloseOutline18Icon } from "@vygruppen/spor-icon-react";
import { Text } from "@vygruppen/spor-react";
import { ButtonTextContainer } from "features/CenterContent/shared/Tabs/TabsCenter";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CrossButton } from "../../../../shared/components/buttons/CrossButton";
import * as ROUTES from "../../../../shared/utils/routes";
import { ActiveTab, InactiveTab } from "./TabsStyle";
import { TrainTab } from "./useTabsContext";

export const TrainTabElement = ({
  trainTab,
  index,
  activeVehicleTabs,
  setActiveVehicleTabs,
}: {
  trainTab: TrainTab;
  index: number;
  activeVehicleTabs: any;
  setActiveVehicleTabs: any;
}) => {
  const { trainNumber, trainDate } = useParams();
  const navigate = useNavigate();
  const selected =
    trainTab.trainNumber === trainNumber && trainTab.trainDate === trainDate;
  const TabElement = selected ? ActiveTab : InactiveTab;
  return (
    <TabElement
      title={`${trainTab.trainNumber} - ${trainTab.trainDate}`}
      key={index}
      onClick={(event) => {
        event.preventDefault();
        navigate(
          generatePath(ROUTES.TRAIN, {
            countryCode: trainTab.countryCode,
            trainNumber: trainTab.trainNumber,
            trainDate: trainTab.trainDate,
          }),
        );
      }}
      style={{
        padding: "4px 10px",
        borderRadius: "6px 6px 0px 0px",
      }}
    >
      <ButtonTextContainer>
        <Text variant="xs" margin="auto">
          {trainTab.trainNumber || ""}
        </Text>
        <CrossButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (selected) {
              if (activeVehicleTabs.length === 1) {
                sessionStorage.removeItem("openTabs");
                navigate(ROUTES.DASHBOARD);
              } else {
                const {
                  trainNumber: prevTrainNumber,
                  trainDate: prevTrainDate,
                  countryCode,
                } = activeVehicleTabs[index - 1]
                  ? activeVehicleTabs[index - 1]
                  : activeVehicleTabs[index + 1];
                if (prevTrainNumber) {
                  navigate(
                    generatePath(ROUTES.TRAIN, {
                      countryCode,
                      trainNumber: prevTrainNumber,
                      trainDate: prevTrainDate,
                    }),
                  );
                }
              }
            }
            setActiveVehicleTabs((prevArr: any) => {
              prevArr.splice(index, 1);
              return Array.from(prevArr);
            });
          }}
        >
          <CloseOutline18Icon marginLeft="4px" />
        </CrossButton>
      </ButtonTextContainer>
    </TabElement>
  );
};
