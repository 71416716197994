import { FC } from "react";
import { Circle, CircleProps } from "react-leaflet";

interface LeafletCircleProps extends CircleProps {}

const LeafletDottedLine: FC<LeafletCircleProps> = ({
  children,
  center,
  ...props
}) => (
  <Circle center={center} {...props}>
    {children}
  </Circle>
);

export default LeafletDottedLine;
