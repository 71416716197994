import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { DayPlanResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainStaff/DayPlan/DayPlanTypes";
import { staffSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainStaff/types";
import { FC } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import z from "zod";
import { DayPlanList, DayPlanListItem } from "../../../../shared/DayPlanList";

type DayPlanProps = {
  staff: z.infer<typeof staffSchema>;
  trainId: string | undefined;
};

export const DayPlan: FC<DayPlanProps> = ({ staff, trainId }) => {
  const { data, status } = useQuery({
    queryKey: [`dayPlan${staff.employeeNumber}`],
    queryFn: ({ signal }) =>
      queryFnGET<DayPlanResponse>({
        signal,
        url: `${getBackendUrl()}/crew/dayPlanForEmployeeByDate/${
          staff.employeeNumber
        }/${staff.dayPlanDate}`,
      }),
  });

  const getDayPlanContent = () => {
    switch (status) {
      case "pending":
        return <SkeletonLoader skeletonType="list" />;
      case "success": {
        const dayPlanList = data.dayPlan?.duties ?? [];
        return dayPlanList.length > 0 && dayPlanList[0].tasks.length > 0 ? (
          dayPlanList[0].tasks.map((task) => (
            <DayPlanListItem
              key={`${task.externalType}-${task.trainNumber}-${task.startTime}`}
              title={task.trainNumber ?? "-"}
              subTitle={task.externalType}
              fromLocation={task.startStationName}
              toLocation={task.endStationName}
              startTime={task.startTime}
              endTime={task.endTime}
              isSelected={task.trainNumber === trainId}
            />
          ))
        ) : (
          <NoDataMessage
            reason={`Fant ingen skiftplan for ${staff.name ?? "vedkommende"}`}
            mt={1}
          />
        );
      }
      case "error":
      default:
        return (
          <FailureMessage
            customMessage="Noe gikk galt ved lasting av dagsverk."
            style={{ padding: "12px" }}
          />
        );
    }
  };

  return <DayPlanList>{getDayPlanContent()}</DayPlanList>;
};
