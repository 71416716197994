import { ErrorOutline24Icon } from "@vygruppen/spor-icon-react";
import React from "react";
import { FCWC } from "shared/utils/FCWC";
import { TextLink, Box } from "@vygruppen/spor-react";
import styled from "styled-components";
import { HTMLChakraProps } from "@chakra-ui/react";
import { Message } from "./styles";

interface FailureMessageProps extends HTMLChakraProps<"div"> {
  customMessage?: string;
}

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 6px;
  align-items: center;
  max-width: 483px;
  margin: auto;

  & :nth-child(1) {
    grid-row: 1;
  }

  & :nth-child(2) {
    grid-row: 1 / span 2;
  }
`;

export const FailureMessage: FCWC<FailureMessageProps> = ({
  customMessage,
  ...props
}) => (
  <Wrapper data-testid="failureMessage" {...props}>
    <ErrorOutline24Icon />
    <Message>
      {customMessage ? (
        customMessage
      ) : (
        <>
          Noe gikk galt ved lasting av innhold. Last siden på nytt for å prøve
          igjen. Ta kontakt med IT
          <TextLink
            href="mailto:team-brukerflater-drops@vy.no?cc=itservicesenter@service-now.com"
            target="_blank"
            rel="noopener noreferer"
            style={{ margin: "0 0.5ch", display: "inline" }}
          >
            her
          </TextLink>
          hvis feilen vedvarer.
        </>
      )}
    </Message>
  </Wrapper>
);
