import { ModalBody, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  Skeleton,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { useInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/useInfrastructureEvent";
import { InfrastructureEventType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { EventInfoMessage } from "features/CenterContent/VehicleDetails/shared/EventInfoMessage";
import { FC, useEffect, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";
import { useStretchBuilder } from "stores/useStretchBuilder";

type EventActionProps = {
  uuids: string[];
};

const eventsByPriority = (
  a: InfrastructureEventType,
  b: InfrastructureEventType,
) => {
  const eventA = a.infrastructureInformation;
  const eventB = b.infrastructureInformation;
  if (isPlannedInfraStructureEvent(eventA.type)) return 1;
  if (isPlannedInfraStructureEvent(eventB.type)) return -1;
  return (
    eventA.priority - eventB.priority ||
    eventB.updatedAt.compare(eventA.updatedAt)
  );
};

export const InfrastructureEventAction: FC<EventActionProps> = ({ uuids }) => {
  const { data, status } = useInfrastructureEvent(uuids);
  const [askConfirmationForUuid, setAskConfirmationForUuid] = useState<
    string | null
  >(null);
  const closeAndResetStretchBuilder = useStretchBuilder(
    (state) => state.closeAndResetStretchBuilder,
  );

  const { mutateAsync: closeInfrastructureEvent, status: closeStatus } =
    useMutation({
      mutationFn: (uuid: string) =>
        mutationFnPOST<string, undefined>(
          `${getBackendUrl()}/infrastructure-event/${uuid}/close`,
        ),
    });
  // Close the selectEvent modal after one second if closeEvent is successful
  useEffect(() => {
    if (closeStatus === "success") {
      setTimeout(closeAndResetStretchBuilder, 1000);
    }
  }, [closeStatus]);

  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );

  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" in stretch builder

  return (
    <Modal isOpen onClose={closeAndResetStretchBuilder} size="lg">
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader alignSelf="start">
          Aktive infrastrukturhendelser
        </ModalHeader>
        <ModalCloseButton />

        <ModalBody display="grid" gap={2} w="100%" pb={2}>
          {status === "pending" && <Skeleton height={6} />}
          {status === "error" && <FailureMessage />}
          {status === "success" && (
            <ul>
              <Flex flexDir="column" gap="12px">
                {Object.values(data)
                  .sort(eventsByPriority)
                  .map((response, i) => {
                    const event = response.infrastructureInformation;
                    return (
                      <EventInfoMessage
                        key={i}
                        title={event.stretchName}
                        subTitle={event.title}
                        severity={
                          isPlannedInfraStructureEvent(event.type)
                            ? "info"
                            : "error"
                        }
                        versions={[
                          {
                            // Without updatedAt because it uses too much space
                            message: event.message,
                            internalMessages: internalMessageEnabled
                              ? event.internalMessages
                              : undefined,
                          },
                        ]}
                        showActionButtons
                        askConfirmation={askConfirmationForUuid === event.uuid}
                        setAskConfirmation={(value) =>
                          setAskConfirmationForUuid(value ? event.uuid : null)
                        }
                        setModalOpen={() =>
                          setStretchBuilderModalState({
                            type: "infrastructure",
                            eventInfo: event,
                            stretchBuilderMode: false,
                          })
                        }
                        close={() => closeInfrastructureEvent(event.uuid)}
                        initialOpenState
                      />
                    );
                  })}
              </Flex>
            </ul>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
