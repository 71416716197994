import { HStack, Text } from "@vygruppen/spor-react";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { format } from "date-fns/format";
import { isToday } from "date-fns/isToday";
import { nb } from "date-fns/locale/nb";
import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { getAbbreviationForRole } from "features/TodoOploggSidebar/common/utils";
import { ReadReceipts } from "features/TodoOploggSidebar/Oplogg/OploggElement/ReadReceipt";
import {
  IndicatorWrapper,
  LogItem,
  VerticalLine,
  VerticalLineForHighlightedBox,
} from "features/TodoOploggSidebar/Oplogg/OploggElement/styles";
import { DropsLogEntry } from "features/TodoOploggSidebar/Oplogg/OploggElement/types";
import {
  getAge,
  getAgeColor,
  getAgeColorForVerticalLine,
} from "features/TodoOploggSidebar/Oplogg/OploggElement/utils";
import { FC, useEffect, useRef, useState } from "react";
import { CircleIndicator } from "shared/components/dataDisplay/CircleIndicator";
import { useElementVisibilityState } from "shared/hooks/useIsElementCurrentlyVisible";
import { useDropsRole } from "stores/useDropsRole";
import { useTheme } from "styled-components";

type OploggElementProps = {
  logEntry: DropsLogEntry;
  isHighlighted: boolean;
  onClick: () => void;
  userEmail: string | null;
  readonly: boolean;
};

export const OploggElement: FC<OploggElementProps> = ({
  logEntry,
  isHighlighted,
  onClick,
  userEmail,
  readonly,
}) => {
  const {
    uuid,
    author,
    reportedTime,
    text,
    originSystem,
    originSystemHumanReadable,
    receipts,
  } = logEntry;
  // reportedTime is null if the log was not successfully sent to cim
  // in that case, show the current time with "*"
  const reportedDateTime = new Date(reportedTime ?? Date.now());
  const displayedDateTime =
    format(reportedDateTime, "HH:mm", { locale: nb }) +
    (reportedTime ? "" : "*");

  const isItemFromThisUser = userEmail === author;
  const { role: currentRole } = useDropsRole();
  const currentRoleAbbreviation = getAbbreviationForRole(currentRole);
  const readByThisUser = receipts?.includes(currentRoleAbbreviation);

  const [actualAge, setAge] = useState(getAge(reportedDateTime));
  const age = readByThisUser || isItemFromThisUser ? "OLD" : actualAge;

  const [hasElementBeenVisible, setHasElementBeenVisible] = useState(false);

  const isFromToday = isToday(reportedDateTime);
  const isNew = age === "NEW";
  const isAlmostNew = age === "ALMOST_NEW";

  const ref = useRef<HTMLLIElement>(null);
  const elementVisibilityState = useElementVisibilityState(ref);

  const theme = useTheme();
  const textColor =
    isNew && !readonly ? theme.colorAlarmTextMain : theme.colorTextMain;
  const subtextColor =
    isNew && !readonly
      ? theme.colorAlarmTextSecondary
      : theme.colorTextSecondary;

  useEffect(() => {
    let interval: any;
    if (age === "NEW" || age === "ALMOST_NEW") {
      interval = setInterval(() => {
        setAge(getAge(reportedDateTime));
      }, 10000); // check age every 10 seconds
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (hasElementBeenVisible === true) {
      return;
    }

    setHasElementBeenVisible(elementVisibilityState.isVisible);
  }, [elementVisibilityState]);

  const getAuthor = () => {
    if (originSystem === "DROPS_DASHBOARD")
      return author?.split("@")[0] ?? originSystemHumanReadable;
    return author
      ? `${author} (${originSystemHumanReadable})`
      : originSystemHumanReadable;
  };

  const showHighlighted = isHighlighted && !readonly;
  const showNewIndicator =
    isNew && !hasElementBeenVisible && !isItemFromThisUser;
  const showBookmarkedIndicator =
    showHighlighted && !elementVisibilityState.isVisible;

  const { enabled: showReadReceipts } = useFeatureFlag("readReceipts");

  return (
    <>
      <LogItem
        data-new={isNew || undefined}
        data-almost-new={isAlmostNew || undefined}
        data-show-new-indicator={showNewIndicator || undefined}
        data-bookmarked={showHighlighted || undefined}
        data-show-bookmarked-indicator={showBookmarkedIndicator || undefined}
        ref={ref}
        onClick={readonly ? undefined : onClick}
        $readonly={readonly}
      >
        <IndicatorWrapper>
          <VerticalLine
            $color={getAgeColorForVerticalLine(age, theme, showHighlighted)}
          />
          <CircleIndicator $color={getAgeColor(age, theme, showHighlighted)} />
          <VerticalLine
            $color={getAgeColorForVerticalLine(age, theme, showHighlighted)}
          />
        </IndicatorWrapper>
        <Text
          variant="xs"
          fontWeight="bold"
          style={{
            color: textColor,
          }}
        >
          {displayedDateTime}
        </Text>
        <Text
          variant="xs"
          style={{
            color: textColor,
            gridColumn: "3",
            wordBreak: "break-word",
          }}
        >
          {text}
        </Text>
        <HStack
          gridColumn={3}
          justifyContent="flex-end"
          gap={1}
          alignSelf="stretch"
        >
          <Text
            variant="xxs"
            style={{
              color: subtextColor,
              textAlign: "end",
              fontSize: "14px", // XXS doesn't actually exist in spor-react
              lineHeight: "18px", // XXS doesn't actually exist in spor-react
            }}
          >
            {getAuthor()}
          </Text>
          {!isFromToday && (
            <Text
              variant="xxs"
              style={{
                color: subtextColor,
                textAlign: "end",
                fontSize: "14px", // XXS doesn't actually exist in spor-react
                lineHeight: "18px", // XXS doesn't actually exist in spor-react
              }}
            >
              {format(reportedDateTime, "dd.MM", { locale: nb })}
            </Text>
          )}
        </HStack>
        {showReadReceipts && (
          <ReadReceipts
            readReceipts={receipts}
            entryUuid={uuid}
            isNew={isNew && !readonly}
          />
        )}
      </LogItem>
      {showHighlighted && (
        <HStack
          style={{
            alignItems: "flex-start",
            height: 0,
          }}
        >
          <VerticalLineForHighlightedBox color={theme.colorSeparationLine} />
        </HStack>
      )}
      {showReadReceipts}
      {!isNew && !isAlmostNew && (
        <DashedLine color={theme.colorBackgroundSecondary} />
      )}
    </>
  );
};
