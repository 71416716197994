import { isFuture } from "date-fns";
import { TodoNoMessage } from "features/TodoOploggSidebar/Todo/TodoStyles";
import { UserRole } from "shared/types/roles";
import styled from "styled-components";
import { Text } from "../../../../shared/components/typography/TitleStyles";
import { TodoFilterState } from "../todoFilter/useTodoFilterState";
import TodoElement, { Todo } from "./todoElement/TodoElement";

type Props = {
  todoList: Todo[];
  todoFilterState: TodoFilterState;
};

const TodoListContainer = styled.ul`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-self: center;
`;

const filterHasValue = <T extends unknown>(
  filterSet: Array<T>,
  filterValue: T,
) => filterSet.length === 0 || filterSet.includes(filterValue);

const isFutureAlarm = (todo: Todo): boolean =>
  !!todo.alarmTime && isFuture(new Date(todo.alarmTime));

const hasOverlap = (a: string[], b: string[]) =>
  a.some((value) => b.indexOf(value) > -1);

const TodoList = ({ todoList, todoFilterState }: Props) => {
  const showTodo = (todo: Todo): boolean => {
    if (todo.role === UserRole.lokleder2) return false;
    if (!filterHasValue(todoFilterState.roles, todo.role as UserRole))
      return false;
    if (
      todoFilterState.vehicleSetTypes.length !== 0 &&
      !hasOverlap(
        todoFilterState.vehicleSetTypes,
        (todo.vehicleSetTypes || "").split(/[/\s,]+/),
      )
    )
      return false;
    if (
      todoFilterState.trainNumber !== "" &&
      !(todo.trainNumber || "")
        .toLowerCase()
        .startsWith(todoFilterState.trainNumber.toLowerCase())
    )
      return false;
    return (
      (todoFilterState.todoStatus.includes("futureAlarms") &&
        isFutureAlarm(todo)) ||
      (todoFilterState.todoStatus.includes("completed") &&
        todo.status === "inActive") ||
      (todoFilterState.todoStatus.includes("incomplete") &&
        todo.status === "active" &&
        !isFutureAlarm(todo)) ||
      todoFilterState.todoStatus.length === 0
    );
  };

  const filteredTodos = todoList
    .filter(showTodo)
    .sort(
      (a, b) =>
        new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime(),
    );

  return (
    <TodoListContainer>
      {filteredTodos.length === 0 ? (
        <TodoNoMessage>
          <Text style={{ margin: "3rem auto", textAlign: "center" }}>
            Tom liste. Legg til ny eller endre filterkriteriene
          </Text>
        </TodoNoMessage>
      ) : (
        filteredTodos.map((todo: Todo, i: number) => (
          <TodoElement key={`element${i}`} todo={todo} />
        ))
      )}
    </TodoListContainer>
  );
};

export default TodoList;
