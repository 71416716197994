import { Text } from "@vygruppen/spor-react";
import { usePostReceipt } from "features/TodoOploggSidebar/Oplogg/useOploggContext";
import { getAbbreviationForRole } from "features/TodoOploggSidebar/common/utils";
import { FC, MouseEvent } from "react";
import UserCircleCheckFill from "shared/icons/user-circle-check-fill.svg?react";
import UserCircleCheck from "shared/icons/user-circle-check.svg?react";
import UserCircleDashed from "shared/icons/user-circle-dashed.svg?react";
import { ROLE_TEXTS } from "shared/types/roles";
import styled, { css } from "styled-components";
import { useDropsRole } from "stores/useDropsRole";

type ReadReceiptProps = {
  readReceipts: string[] | undefined;
  entryUuid: string;
  isNew: boolean;
};

function makeReadReceiptText(
  readReceipts: ReadReceiptProps["readReceipts"],
  currentRoleAbbreviation: string,
) {
  if (!readReceipts?.length) {
    return "Ikke lest av noen";
  }

  if (readReceipts.length === ROLE_TEXTS.length) {
    // More semantic maybe to use Object.keys(UserRole).length
    // but at the moment of writing that has two extra roles
    return "Lest av alle";
  }

  // At least one read receipt, but not everyone
  const readReceiptsExceptCurrent = readReceipts
    .filter((receipt) => receipt !== currentRoleAbbreviation)
    .map((abbr) => abbr.toLocaleUpperCase());

  if (readReceiptsExceptCurrent.length === 0) {
    // Current role is the only receipt
    return "Lest";
  }

  const sortedReceipts = readReceiptsExceptCurrent.toSorted();
  const lastReceipt = sortedReceipts.pop()!; // Modifies the sortedReceipts array, removing the last element

  if (!sortedReceipts.length) {
    // LastReceipt is the only other receipt
    return `Lest av ${lastReceipt}`;
  }

  // More than one other read receipt to list
  return `Lest av ${sortedReceipts.join(", ")} og ${lastReceipt}`;
}

const ReadReceiptIconContainer = styled.div<{
  $readByCurrentRole: boolean;
  $isNew: boolean;
}>`
  grid-area: 3 / 2;
  align-self: center;
  height: 24px;

  & * {
    opacity: 1;
  }

  ${({ $readByCurrentRole, $isNew, theme }) =>
    $readByCurrentRole
      ? css`
          & * {
            fill: ${$isNew ? theme.colorAlarm : theme.colorSuccessPulse};
          }
        `
      : css`
          cursor: pointer;

          & * {
            fill: ${$isNew
              ? theme.colorAlarmTextSecondary
              : theme.colorTextDisabled};
          }

          &:hover * {
            fill: ${$isNew ? theme.colorAlarmTextMain : theme.colorTextMain};
          }

          &:active * {
            fill: ${$isNew ? theme.colorAlarm : theme.colorSuccessPulse};
          }
        `}
`;

const ReadReceiptTextContainer = styled.div<{
  $isNew: boolean;
  $readByCurrentRole: boolean;
}>`
  grid-area: 3 / 3;
  align-self: center;
  align-items: center;

  color: ${({ $isNew, $readByCurrentRole, theme }) => {
    if ($isNew) return theme.colorAlarmTextSecondary;
    if ($readByCurrentRole) return theme.colorSuccessPulse;
    return theme.colorTextSecondary;
  }};
`;

const ReadReceipts: FC<ReadReceiptProps> = ({
  readReceipts,
  entryUuid,
  isNew,
}) => {
  const { role: currentRole } = useDropsRole();
  const currentRoleAbbreviation = getAbbreviationForRole(currentRole);

  const readByCurrentRole = !!readReceipts?.includes(currentRoleAbbreviation);

  const postReceipt = usePostReceipt();

  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!readByCurrentRole) {
      postReceipt({
        entryUuid,
        role: currentRoleAbbreviation.toLocaleLowerCase(),
      });
    }
  };

  const iconComponent = () => {
    if (readByCurrentRole) return <UserCircleCheckFill />;
    if (readReceipts?.length) return <UserCircleCheck />;
    return <UserCircleDashed />;
  };

  return (
    <>
      <ReadReceiptIconContainer
        $readByCurrentRole={readByCurrentRole}
        $isNew={isNew}
        onClick={onClick}
      >
        {iconComponent()}
      </ReadReceiptIconContainer>
      <ReadReceiptTextContainer
        $isNew={isNew}
        $readByCurrentRole={readByCurrentRole}
      >
        <Text
          variant="xs"
          style={{
            fontSize: "14px", // XXS doesn't actually exist in spor-react
            lineHeight: "18px", // XXS doesn't actually exist in spor-react
          }}
        >
          {makeReadReceiptText(readReceipts, currentRoleAbbreviation)}
        </Text>
      </ReadReceiptTextContainer>
    </>
  );
};

export { ReadReceipts };
