import { toCalendarDateTime } from "@internationalized/date";
import { contextsForGlobalWarning } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import {
  GlobalEventResponse,
  GlobalFormSchema,
  visibilitySchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import {
  internalMessagesFormDefault,
  internalMessagesResponseToForm,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { getCurrentCalendarDateTime } from "../TrainMap/StretchBuilder/infrastructureEvents/types/transformers";
import { InfrastructureEventCreatedResponse } from "../TrainMap/StretchBuilder/infrastructureEvents/utils";

const getDefaultValuesForEdit = (
  globalEvent: GlobalEventResponse,
): GlobalFormSchema => {
  const { visibility } = globalEvent;
  const prevFromTime =
    visibility.type !== "CONSTANT"
      ? toCalendarDateTime(visibility.fromTime)
      : getCurrentCalendarDateTime();
  const now = getCurrentCalendarDateTime();
  return {
    globalForm: {
      customTexts: globalEvent.customTexts[0] ?? {
        contexts: contextsForGlobalWarning,
        texts: { NOB: { title: "", description: "" } },
      },
      visibility: {
        type: "OPEN_ENDED",
        fromTime: prevFromTime > now ? prevFromTime : now,
      },
      incidentIds: globalEvent.referencedIncidentIds,
      internalMessages: internalMessagesResponseToForm(
        globalEvent.internalMessages,
      ),
    },
  };
};

const getDefaultValuesFromInfrastructureEvent = (
  relatedInfrastructureEvent: InfrastructureEventCreatedResponse,
): GlobalFormSchema => {
  const visibility = visibilitySchema.safeParse(
    relatedInfrastructureEvent.visibility,
  );
  return {
    globalForm: {
      customTexts: {
        contexts: contextsForGlobalWarning,
        texts: relatedInfrastructureEvent.texts,
      },
      visibility: {
        type: "OPEN_ENDED",
        fromTime:
          visibility.success && visibility.data.type === "OPEN_ENDED"
            ? toCalendarDateTime(visibility.data.fromTime)
            : getCurrentCalendarDateTime(),
      },
      incidentIds: [relatedInfrastructureEvent.incidentId],
      internalMessages: internalMessagesFormDefault(),
    },
  };
};

export const getDefaultValues = (
  relatedInfrastructureEvent?: InfrastructureEventCreatedResponse,
  globalEventToEdit?: GlobalEventResponse,
): GlobalFormSchema => {
  if (globalEventToEdit) {
    return getDefaultValuesForEdit(globalEventToEdit);
  }

  if (relatedInfrastructureEvent) {
    return getDefaultValuesFromInfrastructureEvent(relatedInfrastructureEvent);
  }

  return {
    globalForm: {
      customTexts: {
        contexts: contextsForGlobalWarning,
        texts: {
          NOB: {
            title: "",
            description: "",
          },
        },
      },
      visibility: {
        type: "OPEN_ENDED",
        fromTime: getCurrentCalendarDateTime(),
      },
      incidentIds: [],
      internalMessages: internalMessagesFormDefault(),
    },
  };
};
