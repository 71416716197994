import { PositionOnMapOutline24Icon } from "@vygruppen/spor-icon-react";
import { TextLink } from "@vygruppen/spor-react";
import { VehicleMapMarkers } from "features/CenterContent/RoleContent/VehicleMap/VehicleMapMarkers";
import FitToBounds from "features/CenterContent/shared/Map/FitToBounds";
import { TooltipArrowColor } from "features/CenterContent/shared/Map/LeafletTooltip";
import { BaseGpsData } from "features/CenterContent/shared/types";
import { latLngBounds, LatLngLiteral } from "leaflet";
import { FC } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { Text } from "shared/components/typography/TitleStyles";
import { formatDayDateString } from "shared/utils/datetime";
import styled from "styled-components";

type VehicleMapContentProps = {
  gpsData: BaseGpsData[];
};

// TooltipArrowColor is added here since you are unable to add it at the tooltip-level, it must be added globally
const MapWrapper = styled.div`
  .leaflet-tooltip-top:before {
    border-top-color: ${TooltipArrowColor};
  }

  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-rows: 1fr auto;
  height: 100%;
  width: 100%;
  border-radius: 6px;
`;

const GrayscaleTileLayer = styled(TileLayer)`
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
`;

const MapInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 12px;
`;

export const GPSContent: FC<VehicleMapContentProps> = ({ gpsData }) => {
  const coords: LatLngLiteral[] = gpsData.map((data) => ({
    lat: data.gps.latitude,
    lng: data.gps.longitude,
  }));

  const bounds = latLngBounds(coords);

  return (
    <MapWrapper>
      <MapContainer
        attributionControl={false}
        scrollWheelZoom={false}
        bounds={bounds}
        boundsOptions={{ padding: [30, 35], maxZoom: 16 }}
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <GrayscaleTileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <VehicleMapMarkers gpsData={gpsData} />
        <FitToBounds bounds={bounds} />
      </MapContainer>

      <MapInfo>
        {gpsData.map((marker, index) => (
          <div
            key={`${marker.id}_${index}`}
            style={{ display: "flex", gap: "6px", alignItems: "center" }}
          >
            <TextLink
              variant="primary"
              href={`https://maps.google.com/maps?t=k&q=loc:${marker.gps.latitude},${marker.gps.longitude}`}
              target="_blank"
              rel="noopener noreferer"
            >
              <PositionOnMapOutline24Icon style={{ marginRight: "6px" }} />
              {marker.id}
            </TextLink>
            <Text secondary>({formatDayDateString(marker.timestamp)})</Text>
          </div>
        ))}
      </MapInfo>
    </MapWrapper>
  );
};
