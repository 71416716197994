import { useQuery } from "@tanstack/react-query";
import { Flex } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { EventsOverviewModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewModal";
import { partitionInternalMessagesRelatedToEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { useState } from "react";
import { SemanticButton } from "shared/components/buttons/SemanticButton";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import { NoteOutline24Icon } from "shared/icons/NoteIcon";
import { GlobalEventType } from "shared/types/operationalInformation";
import { useTheme } from "styled-components";
import { InternalMessageResponse } from "../../stationEvents/stationEventResponseSchema";

export const INTERNAL_EVENTS_QUERY_KEY = "internalEvents";

export const InternalMessageOverviewButtonAndModal = () => {
  const [showModal, setShowModal] = useState(false);
  const internalEventsEnabled = useFeatureFlag("internalMessage").enabled;

  const internalEventsType: GlobalEventType = "GLOBAL_INTERNAL_INFO";

  const internalMessageEvents = useQuery({
    queryKey: [INTERNAL_EVENTS_QUERY_KEY],
    enabled: internalEventsEnabled,
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InternalMessageResponse[]>(
          `${getBackendUrl()}/global-information/visible/NO?type=${internalEventsType}`,
          {
            signal,
          },
        )
        .then((res) => res.data.reverse()),
  });

  const [internalMessagesNoEvent] = partitionInternalMessagesRelatedToEvent(
    internalMessageEvents.data ?? [],
  );

  const theme = useTheme();

  if (!internalEventsEnabled) return null;

  return (
    <>
      <SemanticButton
        severity="neutral"
        variant="tertiary"
        size="md"
        leftIcon={<NoteOutline24Icon />}
        aria-label="Se fremtidige hendelser"
        onClick={() => setShowModal(true)}
        style={{ width: "fit-content" }}
        pointerEvents="auto"
      >
        <Flex
          gap="6px"
          alignItems="center"
          color={theme.colorNeutralTextPrimary}
        >
          Interne meldinger
          <AlertsCounter
            alerts={internalMessagesNoEvent.length}
            severity="neutral"
          />
        </Flex>
      </SemanticButton>

      {showModal && (
        <EventsOverviewModal
          onClose={() => setShowModal(false)}
          header="Interne meldinger"
          selectedTab="internalEvents"
          internalMessageEvents={internalMessageEvents}
        />
      )}
    </>
  );
};
