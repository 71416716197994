import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { UPCOMING_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import { InfrastructureRequest } from "../../types/requestSchema";
import { InfrastructureEventCreatedResponse } from "../../utils";

export const usePostInfrastructureEvent = (uuid?: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (body: InfrastructureRequest) =>
      mutationFnPOST<InfrastructureEventCreatedResponse, InfrastructureRequest>(
        `${getBackendUrl()}/infrastructure-event${uuid ? `/${uuid}/change` : ""}`,
        body,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [UPCOMING_EVENTS_QUERY_KEY] });
    },
  });
};
