import { Flex, useTheme } from "@vygruppen/spor-react";
import { FC } from "react";

interface DashedLineProps {
  color?: string;
  mt?: string;
}
const DashedLine: FC<DashedLineProps> = ({ color, mt }) => {
  const theme = useTheme();
  const lineColor = color || theme.colorSeparationLine;
  // Each mask id must be unique, since they for some reason can affect one another
  const uuid = Math.random();
  return (
    <Flex justifyContent="center" width="100%" h="2px" mt={mt}>
      <svg width="80%" height="3" xmlns="http://www.w3.org/2000/svg">
        <mask id={`inverseMask_${uuid}`}>
          <rect width="100%" height="2" fill="white" />
          <line
            x1="0"
            y1="1.5"
            x2="100%"
            y2="1.5"
            stroke="black"
            strokeWidth="4"
            strokeLinecap="round"
            strokeDasharray="3 9"
          />
        </mask>
        <rect
          width="100%"
          height="4"
          fill={lineColor}
          mask={`url(#inverseMask_${uuid})`}
        />
      </svg>
    </Flex>
  );
};
export default DashedLine;
