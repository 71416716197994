import { ChoiceChip, Flex } from "@vygruppen/spor-react";
import { FC } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import { useTheme } from "styled-components";

type VehicleSearchFilterProps = {
  searchQuery: string;
  resultLength: number;
  filterOptions: string[];
  selectedFilter: string | null;
  handleFilterChange: (filter: string) => void;
};

export const VehicleSearchFilter: FC<VehicleSearchFilterProps> = ({
  searchQuery,
  resultLength,
  filterOptions,
  selectedFilter,
  handleFilterChange,
}) => {
  const theme = useTheme();

  return searchQuery.length >= 2 ? (
    <div
      style={{
        backgroundColor: theme.colorBackgroundSecondary,
        borderWidth: "1px",
        borderRadius: "6px",
        marginTop: "6px",
        padding: "18px",
        borderColor: theme.colorBorder,
      }}
    >
      <Text>
        Ditt søk på <b>{searchQuery}</b> ga <b>{resultLength}</b> treff
      </Text>
      <Flex
        color={theme.colorBackgroundSecondary}
        borderColor={theme.colorBorder}
        pt={1}
        flexDir="column"
      >
        <Text>Filtrer søk på:</Text>
        <Flex flexDir="row" gap={2}>
          {filterOptions.map((filterOption) => (
            <ChoiceChip
              key={filterOption}
              isChecked={selectedFilter === filterOption}
              onChange={() => handleFilterChange(filterOption)}
            >
              {filterOption}
            </ChoiceChip>
          ))}
        </Flex>
      </Flex>
    </div>
  ) : null;
};
