import { Flex } from "@vygruppen/spor-react";
import { FC } from "react";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { Stop } from "../TrainMap/StretchBuilder/infrastructureEvents/utils";

type StretchNameBadgesProps = {
  stretchNames: string[];
  singleClickedStop: Stop | null;
};

export const StretchNameBadges: FC<StretchNameBadgesProps> = ({
  stretchNames,
  singleClickedStop,
}) => (
  <Flex gap="4px" flexWrap="wrap">
    {stretchNames.map((stretchName) => (
      <DropsBadge
        key={stretchName}
        borderRadius="6px"
        paddingX="12px !important"
        paddingY="3px"
        semantics="success"
      >
        {stretchName}
      </DropsBadge>
    ))}
    {singleClickedStop && (
      <DropsBadge
        borderRadius="6px"
        paddingX="12px !important"
        paddingY="3px"
        semantics="success"
      >
        {singleClickedStop.name}
      </DropsBadge>
    )}
  </Flex>
);
