import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { log, LogLevel } from "logging/datadogBrowserLogs";

// Id is not used, but included for debugging purposes. Some ids are missing.
const listofExcludedStationsWithId = [
  { name: "Aspedammen", id: "ASP" },
  { name: "Asper", id: "ASE" },
  { name: "Ballerud Bp B", id: null },
  { name: "Ballerud Bp A", id: null },
  { name: "Barkåker", id: "BÅR" },
  { name: "Bekkedalshøgda", id: "BKH" },
  { name: "Berg", id: "BG" },
  { name: "Bergheim", id: "BGH" },
  { name: "Bergseng", id: "BGG" },
  { name: "Bergsvika", id: "BVK" },
  { name: "Borgestad", id: "BOS" },
  { name: "Brennhaug", id: "BRH" },
  { name: "Brøttum", id: "BUM" },
  { name: "Daler", id: "DLR" },
  { name: "Dilling", id: "DIL" },
  { name: "Disenå", id: "DIS" },
  { name: "Dorr Bp.A", id: null },
  { name: "Dorr Bp.B", id: null },
  { name: "Eriksrud", id: "ERU" },
  { name: "Espa", id: "EPA" },
  { name: "Fagernut", id: "FGN" },
  { name: "FLAJ Bp", id: null },
  { name: "FLAK Bp", id: null },
  { name: "Fron", id: "FRN" },
  { name: "Fåberg", id: "FÅB" },
  { name: "Fåvang", id: "FÅV" },
  { name: "Galleberg", id: "GAB" },
  { name: "Galterud", id: "GLT" },
  { name: "Geithus", id: "GHS" },
  { name: "Gulsvik", id: "GUV" },
  { name: "Haug", id: "HG" },
  { name: "Hellerud", id: "HLR" },
  { name: "Hjuksebø", id: "HBØ" },
  { name: "Hobekk", id: "HOB" },
  { name: "Hol", id: "HOL" },
  { name: "Holmen s.sp", id: null },
  { name: "Hove", id: "HVE" },
  { name: "Hundorp", id: "HUN" },
  { name: "Hølen", id: "HLN" },
  { name: "Ingedal", id: "ING" },
  { name: "Jessnes", id: "JES" },
  { name: "Kobbervik", id: "KOB" },
  { name: "Krekling", id: "KRE" },
  { name: "Kvitfjell", id: "KVI" },
  { name: "Langeland", id: "LAL" },
  { name: "Langset", id: "LAS" },
  { name: "Lauve", id: "LAU" },
  { name: "Lillestrøm N", id: "BRT" },
  { name: "Lillestrøm Øst", id: null },
  { name: "Lisleby", id: "LBY" },
  { name: "Losna", id: "LOS" },
  { name: "Magnor", id: "MAG" },
  { name: "Martineåsen", id: "MÅS" },
  { name: "Matrand", id: "MAT" },
  { name: "Myrane", id: "MNE" },
  { name: "Nykirke", id: "NYK" },
  { name: "Onsøy", id: "ONS" },
  { name: "Ottestad", id: "OTT" },
  { name: "Rolvsøy", id: "RVY" },
  { name: "Roven", id: "RVN" },
  { name: "Rudshøgda", id: "RUD" },
  { name: "Sand", id: "SAD" },
  { name: "Sandbukta", id: "SAB" },
  { name: "Sander", id: "SAN" },
  { name: "Sandesund", id: "SDS" },
  { name: "Sel", id: "SEL" },
  { name: "Seterstøa", id: "SET" },
  { name: "Sjoa", id: "SJO" },
  { name: "Skare Bp", id: "SRX" },
  { name: "Skaugum Bp.B", id: null },
  { name: "Skjeberg", id: "SKJ" },
  { name: "Skoger Bp", id: null },
  { name: "Skoger Bp.B", id: null },
  { name: "Skollenborg", id: "SKL" },
  { name: "Skotselv", id: "SEV" },
  { name: "Skrårud Bp.A", id: null },
  { name: "Skrårud Bp.B", id: null },
  { name: "Smørstein", id: "SMØ" },
  { name: "Sokna", id: "SOK" },
  { name: "Steinsrud", id: "STE" },
  { name: "Strandlykkja", id: "SLY" },
  { name: "Sørli", id: "SRI" },
  { name: "Torpo", id: "TPO" },
  { name: "Tretten", id: "TRE" },
  { name: "Trolldalen", id: "TDA" },
  { name: "Tunga", id: "TNG" },
  { name: "Tyristrand", id: "TYR" },
  { name: "Valebø", id: "VBØ" },
  { name: "Veme", id: "VEM" },
  { name: "Øyer", id: "ØYE" },
  { name: "Åbogen", id: "ÅBO" },
  { name: "Åmot", id: "ÅMO" },
  { name: "Lodalen", id: "LOD" },
  { name: "Filipstad", id: null },
  { name: "Haven", id: null },
  { name: "Sundland", id: null },
];

export const listOfExcludedStations = listofExcludedStationsWithId.map(
  (station) => station.name.toLowerCase(),
);

export const toNonExcludedStops = (stops: Stop[]) =>
  stops?.filter(
    (s) => !listOfExcludedStations.includes(s.name.toLowerCase().trim()),
  );

export enum SearchDirection {
  SearchBackward, // use this for fromStop
  SearchForward, // use this for toStop
}

// toSelectableStop. fromStop = SearchBackward / toStop = SearchForward
export const toNonExcludedStopId = (
  stops: Stop[],
  stopId: string | undefined | null,
  searchDirection: SearchDirection,
) => {
  if (!stopId) return undefined;
  const stopsWithoutExcludedStations = toNonExcludedStops(stops);
  const stopsSorted =
    searchDirection === SearchDirection.SearchBackward
      ? stops.toReversed()
      : stops;
  const stopIndex = stopsSorted.findIndex((stop) => stopId === stop.stopId);
  let newStopIndex = stopsSorted.findIndex(
    (stop, index) =>
      index >= stopIndex && stopsWithoutExcludedStations?.includes(stop),
  );
  if (newStopIndex === -1) {
    newStopIndex = stopsSorted.findLastIndex((stop) =>
      stopsWithoutExcludedStations?.includes(stop),
    );
    if (newStopIndex === -1) {
      log(
        LogLevel.warn,
        "toNonExcludedStopId",
        `Train route does not include any non-excluded stops: ${stops.map((stop) => stop.stopId).join(", ")}`,
      );
      return stopId;
    }
  }
  return stopsSorted[newStopIndex].stopId;
};
