import CallIcon from "shared/icons/phone_outline_24px.svg?react";
import styled, { css, keyframes } from "styled-components";
import Pause from "./icons/Pause.svg?react";

const shadowpulse = keyframes`
      0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
      }
      100% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
      }
`;

const shadowpulsebig = keyframes`
      0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
      }
      100% {
        box-shadow: 0 0 0 100px rgba(0, 0, 0, 0);
      }
`;

export const CallStatusStyled = styled.div<{ $showBigAnimation?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: ${({ $showBigAnimation }) =>
    $showBigAnimation ? shadowpulsebig : "none"};

  color: ${({ theme }) => theme.colorTextMain};
`;

const iconStyle = css`
  height: 24px;
  width: auto;
  cursor: pointer;
`;

export const CallPauseIconStyled = styled(Pause)`
  ${iconStyle}
`;

export const CallIconStyled = styled(CallIcon)`
  ${iconStyle};
  height: 34px;
  cursor: initial;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colorSuccessFill};
  animation: ${shadowpulse} 1s infinite;
  & > * {
    fill: ${({ theme }) => theme.colorSuccessFillPulse};
  }
`;

export const CallIconHoldStyled = styled(CallIcon)`
  ${iconStyle};
  height: 34px;
  cursor: initial;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colorAlarmFill};
  transform: rotate(90deg);
  animation: none;
  & > * {
    fill: ${({ theme }) => theme.colorTextMain};
  }
`;

export const UserListStyled = styled.div`
  position: absolute;
  width: 300px;
  padding: 0.5rem 0;
  background-color: ${({ theme }) => theme.colorBackgroundMain};
  color: ${({ theme }) => theme.colorTextMain};
  border-radius: 0 0 6px 6px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
`;

export const CallOutStyled = styled.div`
  width: 100%;
  position: relative;
  color: ${({ theme }) => theme.colorTextMain};
`;
