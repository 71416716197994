import { FC } from "react";
import styled from "styled-components";
import { isToday } from "date-fns";
import { Text } from "../../../../shared/components/typography/TitleStyles";
import { formatDateString } from "../../../../shared/utils/datetime";

const LogListItem = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 6px;
`;

interface Props {
  timestamp: string;
  message: string | null;
  source?: string | null;
  fallbackSource: string;
}

export const LogEntry: FC<Props> = ({
  timestamp,
  message,
  source,
  fallbackSource,
}) => (
  <LogListItem>
    <Text bold>{formatDateString(timestamp, "HH:mm")}</Text>
    <Text>{message}</Text>
    {!isToday(new Date(timestamp)) && (
      <Text
        secondary
        style={{
          gridRow: 2,
          gridColumn: "1 / span 2",
        }}
      >
        {formatDateString(timestamp, "dd.MM.yy")}
      </Text>
    )}
    <Text
      secondary
      style={{
        gridRow: 2,
        gridColumn: 2,
        marginLeft: "auto",
      }}
    >
      {source ?? fallbackSource}
    </Text>
  </LogListItem>
);
