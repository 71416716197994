import { useMutation } from "@tanstack/react-query";
import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { useStationEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/useStationEvents";
import { getAffectedStops } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";
import { EventInfoMessage } from "features/CenterContent/VehicleDetails/shared/EventInfoMessage";
import { FC, useEffect, useState } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { useStretchBuilder } from "stores/useStretchBuilder";

type EventActionProps = {
  uuids: string[];
};

export const StationEventAction: FC<EventActionProps> = ({ uuids }) => {
  const queries = useStationEvents(uuids);
  const isQueriesPending = queries.some((query) => query.isPending);
  const isAllQueriesError = queries.every((query) => query.isError);
  const closeAndResetStretchBuilder = useStretchBuilder(
    (state) => state.closeAndResetStretchBuilder,
  );
  const [askConfirmationForUuid, setAskConfirmationForUuid] = useState<
    string | null
  >(null);

  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );

  const { mutateAsync: closeStationEvent, status: closeStatus } = useMutation({
    mutationFn: (uuid: string) =>
      mutationFnPOST<string, undefined>(
        `${getBackendUrl()}/station-information/${uuid}/close`,
      ),
  });

  useEffect(() => {
    if (closeStatus === "success") {
      setTimeout(closeAndResetStretchBuilder, 1000);
    }
  }, [closeStatus]);

  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" in stretch builder

  return (
    <Modal isOpen onClose={closeAndResetStretchBuilder} size="xl">
      <ModalOverlay />
      <ModalContent alignItems="center">
        <ModalHeader mb={2} alignSelf="start">
          Aktive stasjonshendelser
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody display="grid" gap={2} w="100%">
          {isQueriesPending && <Skeleton height={6} />}
          {isAllQueriesError && (
            <DropsStaticAlert variant="error">
              Kunne ikke hente hendelser for stasjon.
            </DropsStaticAlert>
          )}
          {!isQueriesPending && !isAllQueriesError && (
            <Flex flexDir="column" gap="12px">
              {queries.map((query, i) => {
                if (query.isSuccess) {
                  const stationEvent = query.data;
                  const stationWithoutInternalMessages = {
                    ...stationEvent,
                    internalMessages: undefined,
                  };
                  return (
                    <EventInfoMessage
                      key={i}
                      title={getAffectedStops(stationEvent)}
                      subTitle={stationEvent.message}
                      severity="info"
                      versions={[
                        internalMessageEnabled
                          ? stationEvent
                          : stationWithoutInternalMessages,
                      ]}
                      showActionButtons
                      askConfirmation={
                        askConfirmationForUuid === query.data.uuid
                      }
                      setAskConfirmation={(value) =>
                        setAskConfirmationForUuid(
                          value ? stationEvent.uuid : null,
                        )
                      }
                      setModalOpen={() =>
                        setStretchBuilderModalState({
                          type: "station",
                          eventInfo: stationEvent,
                          stretchBuilderMode: false,
                        })
                      }
                      close={() => closeStationEvent(stationEvent.uuid)}
                      initialOpenState
                    />
                  );
                }
                if (query.isError) {
                  return (
                    <DropsStaticAlert variant="error" key={i} my={1}>
                      Kunne ikke hente hendelse.
                    </DropsStaticAlert>
                  );
                }
                return null;
              })}
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
