import z from "zod";

export const countryCodeSchema = z.enum(["NO", "SE"]);

export const trainStaffDutySchema = z.object({
  functionalTelephoneNumber: z.string().min(1),
  function: z.string().min(1),
  gsmrNumber: z.string().min(1),
});

export type TrainStaffOnDuty = z.infer<typeof trainStaffDutySchema>;

const trainEventTitlesSchema = z.object({
  type: z.string(),
  label: z.string(),
  priority: z.number(),
});

export type TrainEventTitles = z.infer<typeof trainEventTitlesSchema>;

const trainSchema = z.object({
  destinationStopName: z.string().min(1).nullable(),
  destinationScheduledArrivalTime: z.coerce.date().nullable(),
  originStopName: z.string().min(1).nullable(),
  originScheduledDepartureTime: z.coerce.date().nullable(),
  trainNumber: z.string().min(1),
  trainDate: z.string(),
  delay: z.number().nullable(),
  lineIds: z.array(z.object({ lineId: z.string().min(1) })),
  vehicleSetIds: z.array(z.string().min(1)),
  trainStaffOnDuty: z.array(trainStaffDutySchema),
  active: z.boolean(),
  countryCode: countryCodeSchema,
  trainEventsTitles: z.array(trainEventTitlesSchema),
  trainCompany: z.string().nullable(),
});

export type TrainData = z.infer<typeof trainSchema>;

export const trainListSchema = z.object({
  trainList: z.array(trainSchema),
});

export type TrainListResponse = z.infer<typeof trainListSchema>;
