import { InfrastructureEventType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import {
  BatchSubTypeProps,
  CommonSubTypeProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoFormModal";
import { TrainEventType } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainCancelled } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCancelled";
import { TrainCapacityIncreased } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCapacityIncreased";
import { TrainCapacityReduced } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCapacityReduced";
import { TrainChangedRoute } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainChangedRoute";
import { TrainClosedSet } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainClosedSet";
import { TrainCustom } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainCustom";
import { TrainDelayExpected } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainDelayExpected";
import { TrainDelayed } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainDelayed";
import { TrainLateToTrack } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainLateToTrack";
import { TrainMissingProduct } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainMissingProduct";
import { TrainNotStoppingAtStation } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainNotStoppingAtStation";
import { TrainStopped } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainStopped";
import { TrainStoppingExtraAtStation } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/TrainStoppingExtraAtStation";
import {
  BatchSubfieldWrapper,
  SingleSubfieldWrapper,
  TrainContext,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/utils/formContextWrappers";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { FC } from "react";

type FormProps = {
  type: TrainEventType;
  infrastructureEvent?: InfrastructureEventType;
};

function getFieldByType(
  type: TrainEventTypeEnum,
): FC<CommonTrainInfoFormProps & CommonSubTypeProps & TrainContext> | null {
  switch (type) {
    case TrainEventTypeEnum.TRAIN_STOPPED:
      return TrainStopped;
    case TrainEventTypeEnum.TRAIN_CANCELLED:
      return TrainCancelled;
    case TrainEventTypeEnum.TRAIN_CAPACITY_REDUCED:
      return TrainCapacityReduced;
    case TrainEventTypeEnum.TRAIN_MISSING_PRODUCT:
      return TrainMissingProduct;
    case TrainEventTypeEnum.TRAIN_DELAY_EXPECTED:
      return TrainDelayExpected;
    case TrainEventTypeEnum.TRAIN_CAPACITY_INCREASED:
      return TrainCapacityIncreased;
    case TrainEventTypeEnum.TRAIN_DELAYED:
      return TrainDelayed;
    case TrainEventTypeEnum.TRAIN_LATE_TO_TRACK:
      return TrainLateToTrack;
    case TrainEventTypeEnum.TRAIN_NOT_STOPPING_AT_STATION:
      return TrainNotStoppingAtStation;
    case TrainEventTypeEnum.TRAIN_STOPPING_EXTRA_AT_STATION:
      return TrainStoppingExtraAtStation;
    case TrainEventTypeEnum.TRAIN_CLOSED_SET:
      return TrainClosedSet;
    case TrainEventTypeEnum.TRAIN_CHANGED_ROUTE:
      return TrainChangedRoute;
    case TrainEventTypeEnum.TRAIN_CUSTOM:
      return TrainCustom;
    default:
      return null;
  }
}

export const BatchSubtypeInputs: FC<
  FormProps & CommonTrainInfoFormProps & CommonSubTypeProps & BatchSubTypeProps
> = ({ activeSeriesKey, groupIndex, type, ...props }) => {
  const Field = getFieldByType(type);

  if (!Field) return null;

  return (
    <BatchSubfieldWrapper
      Field={Field}
      activeSeriesKey={activeSeriesKey}
      groupIndex={groupIndex}
      {...props}
    />
  );
};

export const SubTypeInputs: FC<
  FormProps & CommonTrainInfoFormProps & CommonSubTypeProps
> = ({ type, ...props }) => {
  const Field = getFieldByType(type);

  if (!Field) return null;

  return <SingleSubfieldWrapper Field={Field} {...props} />;
};
