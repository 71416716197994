import { Input } from "@vygruppen/spor-react";
import { DurationInput } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/DurationInput";
import { getInitialTimedDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { InfrastructureForm } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const PlannedInfrastructureEvent: FC = () => {
  const { register, watch, setValue } = useFormContext<InfrastructureForm>();
  const durationType = watch("infrastructureForm.durations.type");

  useEffect(() => {
    if (durationType === "OPEN_ENDED") {
      setValue("infrastructureForm.durations", getInitialTimedDuration());
    }
  }, [durationType]);

  return (
    <>
      <Input
        width="13.5rem"
        label="I-sirk"
        type="string"
        {...register("infrastructureForm.isirkId")}
      />
      <DurationInput />
    </>
  );
};
