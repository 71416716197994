import { StaticAlert, BoxProps } from "@vygruppen/spor-react";
import { ReactNode } from "react";
import styled, { DefaultTheme, useTheme } from "styled-components";

export type Variant =
  | "info"
  | "success"
  | "warning"
  | "alt-transport"
  | "error";

export const getColorSchemeForVariant = (
  variant: Variant,
  theme: DefaultTheme,
) => {
  switch (variant) {
    case "info":
      return {
        backgroundColor: theme.colorInfoFill,
        borderColor: theme.colorInfoOutline,
        borderWidth: "1px",
        borderRadius: "12px",
        color: theme.colorInfoTextMain,
        $color: theme.colorInfo,
      };
    case "success":
      return {
        backgroundColor: theme.colorSuccessFill,
        borderColor: theme.colorSuccessOutline,
        borderWidth: "1px",
        borderRadius: "12px",
        color: theme.colorSuccessTextMain,
        $color: theme.colorSuccess,
      };
    case "warning":
      return {
        backgroundColor: theme.colorWarningFill,
        borderColor: theme.colorWarningOutline,
        borderWidth: "1px",
        borderRadius: "12px",
        color: theme.colorWarningTextMain,
        $color: theme.colorWarning,
      };
    case "alt-transport":
      return {
        backgroundColor: theme.colorInfoFill,
        borderColor: theme.colorInfoOutline,
        borderWidth: "1px",
        borderRadius: "12px",
        color: theme.colorInfoTextMain,
        $color: theme.colorInfo,
      };
    case "error":
      return {
        backgroundColor: theme.colorAlarmFill,
        borderColor: theme.colorAlarmOutline,
        borderWidth: "1px",
        borderRadius: "12px",
        color: theme.colorAlarmTextMain,
        $color: theme.colorAlarm,
      };
    default:
      return {
        backgroundColor: theme.colorNeutralFill,
        borderColor: theme.colorNeutralOutline,
        borderWidth: "1px",
        borderRadius: "12px",
        color: theme.colorNeutralTextMain,
        $color: theme.colorNeutral,
      };
  }
};

const StaticAlertStyled = styled(StaticAlert)<{
  $color: string;
}>`
  svg {
    color: ${({ $color }) => $color};
  }
`;

export const DropsStaticAlert = ({
  variant,
  children,
  ...rest
}: {
  variant: Variant;
  children: ReactNode;
} & BoxProps) => {
  const theme = useTheme();

  return (
    <StaticAlertStyled
      variant={variant}
      border="1px solid"
      {...rest}
      {...getColorSchemeForVariant(variant, theme)}
    >
      {children}
    </StaticAlertStyled>
  );
};
