import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { Flex, Text, Textarea } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { VisibilityInput } from "features/CenterContent/RoleContent/GlobalEvents/VisibilityInput";
import {
  VisibilitySchema,
  visibilityFormSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InternalMessageRequest } from "features/CenterContent/RoleContent/InternalMessage/schema/requestSchema";
import {
  internalMessagesFormDefault,
  internalMessagesFormToRequest,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { INTERNAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import {
  getCurrentCalendarDateTime,
  getNextDayStartOfPlanCalendarDateTime,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { Stop } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import { BadgeText as BadgeTextStation } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import { BadgeText } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainInternalMessagesQueryKey } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/useTrainInternalMessages";
import { getVisibilityEndTimeSafe } from "features/CenterContent/VehicleDetails/TrainDetails/getVisibilityEndTime";
import { useTrainRoute } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { RenderErrorInPath } from "shared/components/forms/RenderErrorInPath";
import { AllOrNone } from "shared/utils/objectUtils";
import { StretchBuilderModalState } from "stores/useStretchBuilder";
import { z } from "zod";

type InternalMessageType = {
  isGlobal: boolean;
  isTrain: boolean;
  isStation: boolean;
};

const getInternalMessageType = (
  train?: TrainIdentifier,
  stations: Stop[] = [],
): InternalMessageType => ({
  isGlobal: !train && stations.length === 0,
  isTrain: train !== undefined,
  isStation: stations.length >= 1,
});

const makeTitle = (
  internalMessageType: InternalMessageType,
  stations: Stop[],
) => {
  const { isGlobal, isTrain } = internalMessageType;
  if (isGlobal) return `Ny global intern melding`;
  if (isTrain) return `Ny intern melding til tog`;
  return `Ny intern melding til ${stations.length >= 2 ? "stasjoner" : "stasjon"}`;
};

type StationProps = {
  stations?: Stop[];
  allStopsSelected?: boolean;
  stretchBuilderModalState?: StretchBuilderModalState;
  setStretchBuilderModalState?: (value: StretchBuilderModalState) => void;
  onClose?: () => void;
};

const createInternalMessageFormSchema = z.object({
  internalMessage: z.object({
    message: z.string().min(1, "Beskrivelse må fylles ut"),
    visibility: visibilityFormSchema,
  }),
});

type CreateInternalMessageFormSchema = z.infer<
  typeof createInternalMessageFormSchema
>;

type CreateInternalMessageModalProps = {
  setModalOpen: (value: boolean) => void;
  visibility?: VisibilitySchema;
  train?: TrainIdentifier;
} & AllOrNone<StationProps>;

const CreateInternalMessageModalContent: FC<
  CreateInternalMessageModalProps
> = ({
  setModalOpen,
  visibility,
  train,
  stations = [],
  allStopsSelected,
  stretchBuilderModalState,
  setStretchBuilderModalState,
  onClose = () => {},
}) => {
  const internalMessageType = getInternalMessageType(train, stations);
  const { isGlobal, isTrain, isStation } = internalMessageType;
  const formMethods = useForm<CreateInternalMessageFormSchema>({
    resolver: zodResolver(createInternalMessageFormSchema),
    defaultValues: {
      internalMessage: {
        visibility: visibility ?? {
          type: "TIMED",
          fromTime: getCurrentCalendarDateTime(),
          toTime: getNextDayStartOfPlanCalendarDateTime(),
        },
      },
    },
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const { mutate: submitRequest, status: submitStatus } = useMutation({
    mutationFn: (data: InternalMessageRequest) =>
      mutationFnPOST<undefined, InternalMessageRequest>(
        `${getBackendUrl()}/global-information/create`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_EVENTS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: TrainInternalMessagesQueryKey(
          train?.nominalDate,
          train?.identifier,
        ),
      });
    },
  });

  const onSubmit: SubmitHandler<CreateInternalMessageFormSchema> = ({
    internalMessage,
  }) => {
    const form = internalMessagesFormDefault({
      message: internalMessage.message,
    });
    const request = internalMessagesFormToRequest(
      form,
      internalMessage.visibility,
      train,
      stations,
    );
    if (!request) return;
    submitRequest(request);
  };

  return (
    <ActionModal
      title={makeTitle(internalMessageType, stations)}
      titleBadge={isGlobal ? "Uavhengig" : undefined}
      actionTitle="Send melding"
      onClose={() => {
        setModalOpen(false);
        onClose();
      }}
      onSubmit={handleSubmit(onSubmit)}
      isLoading={submitStatus === "pending"}
      isSuccess={submitStatus === "success"}
      isError={submitStatus === "error"}
      successMessage="Hendelse opprettet"
      failureMessage="Kunne ikke opprette hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
    >
      <ErrorBoundary>
        <Flex flexDir="column" gap={5} w="100%">
          {isTrain && <BadgeText selectedTrain={train!} />}
          {isStation && (
            <BadgeTextStation
              selectedStops={stations!}
              allStopsSelected={allStopsSelected!}
              stretchBuilderModalState={stretchBuilderModalState!}
              setStretchBuilderModalState={() => {
                // Workaround for navigating back to stretchBuilder
                setStretchBuilderModalState!({
                  type: "station",
                  stretchBuilderMode: true,
                });
                setModalOpen(false);
              }}
            />
          )}
          <Textarea
            {...register("internalMessage.message")}
            label="Skriv din melding..."
            resize="none"
          />
          <RenderErrorInPath
            mt="-12px"
            errors={errors}
            errorPath="internalMessage.message"
          />
          {!isTrain && (
            <VisibilityInput
              formMethods={formMethods}
              formField="internalMessage.visibility"
              isRelatedToIncident={false}
            />
          )}
          {isGlobal && (
            <Text>
              <b>OBS!</b> Globale meldinger blir synlig for all personell på
              alle linjer
            </Text>
          )}
        </Flex>
      </ErrorBoundary>
    </ActionModal>
  );
};

const CreateInternalMessageModal: FC<CreateInternalMessageModalProps> = ({
  train,
  ...props
}) => {
  const trainRoute = useTrainRoute(train ?? {});
  if (train && !trainRoute.data) return null;
  const visibility = {
    type: "TIMED",
    fromTime: getCurrentCalendarDateTime(),
    toTime: getVisibilityEndTimeSafe(trainRoute.data?.stops ?? []),
  } as const;
  return (
    <CreateInternalMessageModalContent
      train={train}
      visibility={train ? visibility : undefined}
      {...props}
    />
  );
};

export default CreateInternalMessageModal;
