import { FC, ReactNode } from "react";
import { Box, BoxProps, Tab } from "@vygruppen/spor-react";
import styled from "styled-components";

const TabStyled = styled(Box).withConfig({
  shouldForwardProp: (prop) => prop !== "pulsing",
})<{
  pulsing: boolean;
}>`
  height: 100%;
  width: 100%;
  > * {
    background-color: ${({ theme, pulsing }) =>
      pulsing ? theme.colorSuccessOutline : "transparent"};
    transition: opacity 200ms ease-in-out;
  }
`;

type TabWithPulseProps = {
  children: ReactNode;
  pulsing: boolean;
} & BoxProps;

const TabWithPulse: FC<TabWithPulseProps> = ({
  children,
  pulsing,
  ...props
}) => (
  <TabStyled pulsing={pulsing} {...props}>
    <Tab>{children}</Tab>
  </TabStyled>
);

export default TabWithPulse;
