import {
  AltTransportFill18Icon,
  AltTransportOutline18Icon,
  BusFill18Icon,
  BusOutline18Icon,
  TrainFill18Icon,
  TrainOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { Box, Flex } from "@vygruppen/spor-react";
import {
  getLocalStorageVehicleAffectedSidePanelSettings,
  setLocalStorageVehicleAffectedSidePanelSettings,
} from "api/dropsLocalStorage";
import CollapseButton from "features/CenterContent/shared/Tabs/CollapseButton";
import { Tab, TabsSides } from "features/CenterContent/shared/Tabs/TabsSides";
import AffectedTrains from "features/VehicleSidebar/AffectedTrains/AffectedTrains";
import { useAffectedTrains } from "features/VehicleSidebar/AffectedTrains/useAffectedTrains";
import VehicleList from "features/VehicleSidebar/VehicleList/VehicleList";
import { useEffect, useRef, useState } from "react";
import { CollapsibleWrapper } from "shared/components/CollapseableWrapper";
import {
  InfrastructureIncidentIcon,
  InfrastructureIncidentIconTabActive,
} from "shared/icons/InfrastructureIncidentIcon";
import { useDropsRole } from "stores/useDropsRole";
import { useSidePanelsState } from "stores/useSidePanelsState";
import styled from "styled-components";

export const CloseButtonWrapper = styled.div`
  grid-area: close;
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 3rem;
  border-radius: 6px;
  padding: 3px 5px;
  z-index: 1;
`;

const CustomBox = styled(Box)`
  overflow-y: auto;
  -webkit-scrollbar: none; /* WebKit */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  width: 100%;
  height: 100%;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
`;

const VehicleWorkList = () => {
  const [isOpen, setIsOpen] = useSidePanelsState((state) => [
    state.trainListOpen,
    state.setTrainListOpen,
  ]);

  useEffect(() => {
    setIsOpen(getLocalStorageVehicleAffectedSidePanelSettings());
  }, []);

  useEffect(() => {
    setLocalStorageVehicleAffectedSidePanelSettings(isOpen);
  }, [isOpen]);

  const affectedTrainsResult = useAffectedTrains();
  const { data, isSuccess } = affectedTrainsResult;

  const scrollBoxRef = useRef<HTMLDivElement>(null);

  const numberOfAffectedTrains = data?.affectedTrains.length ?? undefined;

  const trainTab: Tab = {
    id: "trainList",
    iconActive: <TrainFill18Icon />,
    iconInactive: <TrainOutline18Icon />,
    text: "Togliste",
  };

  const affectedTrainTab: Tab = {
    id: "affectedTrains",
    iconActive: <InfrastructureIncidentIconTabActive />,
    iconInactive: <InfrastructureIncidentIcon />,
    text: `Arbeidsliste`,
  };

  const busTabs: Tab[] = [
    {
      id: "busList",
      iconActive: <BusFill18Icon />,
      iconInactive: <BusOutline18Icon />,
      text: "Buss",
    },
    {
      id: "reserveBusList",
      iconActive: <AltTransportFill18Icon />,
      iconInactive: <AltTransportOutline18Icon />,
      text: "Reservebuss",
    },
  ];

  const [selectedTabIds, setSelectedTabIds] = useState(["trainList"]);

  const { isVaktleder, isTrafikkplanlegger } = useDropsRole();
  const showAffectedTrains = isVaktleder();
  const showBus = isTrafikkplanlegger();
  // Switch to trainList at role switch
  useEffect(() => {
    setSelectedTabIds(["trainList"]);
  }, [showAffectedTrains, showBus]);

  const tabs = [
    trainTab,
    ...(showAffectedTrains ? [affectedTrainTab] : []),
    ...(showBus ? busTabs : []),
  ];

  return (
    <CollapsibleWrapper
      $gridArea="vehicleAffected"
      $side="left"
      $isOpen={isOpen}
    >
      <Flex flexDirection="column" w="100%">
        <Flex
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          flexDir="row-reverse"
        >
          <TabsSides
            justifyContent="start"
            tabs={tabs}
            selectedTabIds={selectedTabIds}
            setSelectedTabIds={setSelectedTabIds}
            notifications={[
              0,
              showAffectedTrains ? (numberOfAffectedTrains ?? 0) : 0,
            ]}
          />
          <CloseButtonWrapper style={{ position: "absolute", zIndex: 10 }}>
            <CollapseButton
              isOpen={!isOpen}
              setOpen={() => setIsOpen(!isOpen)}
              title="Skjul togliste"
            />
          </CloseButtonWrapper>
        </Flex>
        <CustomBox>
          {selectedTabIds.includes("trainList") && (
            <VehicleList vehicleTabIndex={0} scrollBoxRef={scrollBoxRef} />
          )}
          {selectedTabIds.includes("busList") && (
            <VehicleList vehicleTabIndex={1} scrollBoxRef={scrollBoxRef} />
          )}
          {selectedTabIds.includes("reserveBusList") && (
            <VehicleList vehicleTabIndex={2} scrollBoxRef={scrollBoxRef} />
          )}
          {selectedTabIds.includes("affectedTrains") && (
            <AffectedTrains affectedTrainsResult={affectedTrainsResult} />
          )}
        </CustomBox>
      </Flex>
    </CollapsibleWrapper>
  );
};

export default VehicleWorkList;
