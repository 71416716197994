import { Button, ButtonProps } from "@vygruppen/spor-react";
import { FC } from "react";
import { getButtonStylePrimary } from "shared/components/buttons/SemanticButtonPrimary";
import { getButtonStyleTertiary } from "shared/components/buttons/SemanticButtonTertiary";
import { Severity } from "shared/components/feedback/InfoMessage/severity";
import { useTheme } from "styled-components";

export type ButtonSeverity =
  | "info"
  | "alarm"
  | "secondaryAlarm"
  | "warning"
  | "neutral"
  | "success";

export const toButtonSeverity = (severity: Severity) => {
  switch (severity) {
    case "info":
      return "info";
    case "success":
      return "success";
    case "error":
      return "alarm";
    case "warning":
      return "warning";
    case "neutral":
    default:
      return "neutral";
  }
};

export type ButtonColors = {
  severityTextMain: string;
  severity: string;
  severityFill: string;
  severityFillPulse: string;
};

interface SemanticButtonProps extends ButtonProps {
  severity?: ButtonSeverity;
  size?: "xs" | "sm" | "md";
  variant?: "primary" | "tertiary";
}

export const SemanticButton: FC<SemanticButtonProps> = ({
  severity,
  size = "xs",
  variant = "tertiary",
  ...props
}) => {
  const theme = useTheme();
  const getButtonStyle =
    variant === "primary" ? getButtonStylePrimary : getButtonStyleTertiary;
  return (
    <Button
      size={size}
      variant={variant}
      {...props}
      sx={
        severity
          ? getButtonStyle(severity, theme)
          : { backgroundColor: theme.colorBackgroundSecondary }
      }
    >
      {props.children}
    </Button>
  );
};
