import { CloseOutline18Icon } from "@vygruppen/spor-icon-react";
import { Text } from "@vygruppen/spor-react";
import { ButtonTextContainer } from "features/CenterContent/shared/Tabs/TabsCenter";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { CrossButton } from "shared/components/buttons/CrossButton";
import * as ROUTES from "shared/utils/routes";
import { ActiveTab, InactiveTab } from "./TabsStyle";
import { BusTab } from "./useTabsContext";

export const BusTabElement = ({
  busTab,
  index,
  activeVehicleTabs,
  setActiveVehicleTabs,
}: {
  busTab: BusTab;
  index: number;
  activeVehicleTabs: any;
  setActiveVehicleTabs: any;
}) => {
  const { busId, tabId } = busTab;
  const { vehicleUuid } = useParams();
  const navigate = useNavigate();
  const selected = busId === vehicleUuid;
  const TabElement = selected ? ActiveTab : InactiveTab;
  return (
    <TabElement
      title={`${busId}`}
      key={index}
      onClick={(event) => {
        event.preventDefault();
        if (busId) {
          navigate(
            generatePath(ROUTES.BUS, {
              vehicleUuid: busId,
            }),
          );
        }
      }}
      style={{
        padding: "4px 10px",
        borderRadius: "6px 6px 0px 0px",
      }}
    >
      <ButtonTextContainer>
        <Text variant="xs" margin="auto">
          {tabId || ""}
        </Text>
        <CrossButton
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            if (selected) {
              if (activeVehicleTabs.length === 1) {
                sessionStorage.removeItem("openTabs");
                navigate(ROUTES.DASHBOARD);
              } else {
                const prevTab =
                  activeVehicleTabs[index - 1] || activeVehicleTabs[index + 1];
                if (prevTab && prevTab.busId) {
                  navigate(
                    generatePath(ROUTES.BUS, {
                      vehicleUuid: prevTab.busId,
                    }),
                  );
                }
              }
            }
            setActiveVehicleTabs((prevArr: any) => {
              prevArr.splice(index, 1);
              return Array.from(prevArr);
            });
          }}
        >
          <CloseOutline18Icon marginLeft="4px" />
        </CrossButton>
      </ButtonTextContainer>
    </TabElement>
  );
};
