import { getLocalTimeZone } from "@internationalized/date";
import { isPast } from "date-fns";
import {
  dateToCalendarDateTime,
  getCurrentCalendarDateTime,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";

const plusHours = (date: Date | null, hours: number) => {
  if (!date) return null;
  const newDate = new Date(date);
  newDate.setHours(newDate.getHours() + hours);
  return dateToCalendarDateTime(newDate);
};

// There is a similiar function in drops-backend in TrainInformationServiceImpl.kt
const getVisibilityEndTime = (stops: Stop[]) =>
  plusHours(stops[stops.length - 1]?.estimatedArrivalTime, 1) ??
  plusHours(stops[stops.length - 1]?.scheduledArrivalTime, 1) ??
  getCurrentCalendarDateTime(10);

export const getVisibilityEndTimeSafe = (stops: Stop[]) => {
  const visibilityEndTime = getVisibilityEndTime(stops);
  if (isPast(visibilityEndTime.toDate(getLocalTimeZone()))) {
    return getVisibilityEndTime([]);
  }
  return visibilityEndTime;
};
