import { createWithEqualityFn } from "zustand/traditional";
import { shallow } from "zustand/shallow";

type State = {
  trainMapOverlay: boolean;
};

type Action = {
  setTrainMapOverlay: (newState: State["trainMapOverlay"]) => void;
};

export const useTrainMapOverlayProvider = createWithEqualityFn<State & Action>(
  (set) => ({
    trainMapOverlay: false,
    setTrainMapOverlay: (newState) =>
      set(() => ({ trainMapOverlay: newState })),
  }),
  shallow,
);
