import { useQuery } from "@tanstack/react-query";
import { useToast } from "@vygruppen/spor-react";
import { getBackendUrl, getEnvParsedSafe } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { Environment, environment } from "app/environment/environment";
import { log, LogLevel } from "logging/datadogBrowserLogs";
import { useEffect } from "react";

type Attribute = {
  [key: string]: number | string | number[] | string[] | boolean;
};

type FeatureFlag = {
  name: string;
  enabled: boolean;
  attributes: Attribute[];
};

// Only necessary to notify about the flags beings enabled once
const featureFlagsNotified: string[] = [];

const checkEnabledLocally = (name: string) => {
  const isDevelopment = environment() === Environment.Development;
  if (!isDevelopment) return false;
  const enabledFlags = getEnvParsedSafe("VITE_ENABLED_FEATURE_FLAGS") ?? [];
  return enabledFlags.includes(name);
};

const useEnabledLocally = (name: string) => {
  const toast = useToast();
  const enabledLocally = checkEnabledLocally(name);
  useEffect(() => {
    if (!enabledLocally) return;
    if (featureFlagsNotified.includes(name)) return;
    featureFlagsNotified.push(name);
    // the toast must be in a useEffect, else it would cause errors because of rerendering
    toast({
      variant: "info",
      text: `Env: Feature flag '${name}' enabled`,
      isClosable: true,
    });
  }, []);
  return enabledLocally;
};

const enabledFlag = (name: string) => ({ name, enabled: true, attributes: [] });

export function useFeatureFlag(name: string): FeatureFlag {
  const { data, error } = useQuery({
    queryKey: ["featureFlags"],
    queryFn: ({ signal }) =>
      queryFnGET<FeatureFlag[]>({ signal, url: `${getBackendUrl()}/features` }),
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const enabledLocally = useEnabledLocally(name);
  if (enabledLocally) return enabledFlag(name);

  if (error) {
    log(
      LogLevel.error,
      "useFeatureFlag",
      `GET /features call failed with error: ${error}`,
    );
  }

  return (
    (data && data.length ? data.find((flag) => flag.name === name) : null) ?? {
      name,
      enabled: false,
      attributes: [],
    }
  );
}

/* // Use at your own risk, breaks all tests
export const getFeatureFlag = async (name: string) => {
  const data = await queryFnGET<FeatureFlag[]>({
    signal: undefined,
    url: `${getBackendUrl()}/features`,
  });

  return (
    (data && data.length ? data.find((flag) => flag.name === name) : null) ?? {
      name,
      enabled: false,
      attributes: [],
    }
  );
}; */
