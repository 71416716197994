import {
  TimeOutline18Icon,
  TownOutline18Icon,
  TrainOutline18Icon,
  WarningFill18Icon,
  WarningOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { BoxProps, HStack } from "@vygruppen/spor-react";
import {
  DisplayDuration,
  getDisplayDurationString,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { FC } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import { InfrastructureIncidentIcon } from "shared/icons/InfrastructureIncidentIcon";
import styled from "styled-components";

const CustomOutlineBadge = styled.div`
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.colorOutline};
  padding: 2px 12px;
`;

const getLabel = (category: EventCategory) => {
  switch (category) {
    case "plannedInfrastructure":
      return "Planlagt";
    case "futureInfrastructure":
      return "Oppstått";
    case "global":
      return "Global driftsmelding";
    case "internal":
      return "Global intern melding";
    case "station":
      return "Stasjon";
    case "train":
      return "Tog";
    case "infrastructure":
      return "Infrastruktur";
    case "trainEvent":
      return "Toghendelse";
    case "stationEvent":
      return "Stasjonshendelse";
    default:
      return "";
  }
};

const getIcon = (eventType: EventCategory) => {
  switch (eventType) {
    case "plannedInfrastructure":
      return <TimeOutline18Icon />;
    case "futureInfrastructure":
      return <InfrastructureIncidentIcon />;
    case "global":
      return <WarningFill18Icon />;
    case "internal":
      return <WarningFill18Icon />;
    case "station":
      return <TownOutline18Icon />;
    case "train":
      return <TrainOutline18Icon />;
    case "infrastructure":
      return <InfrastructureIncidentIcon />;
    case "trainEvent":
      return <WarningOutline18Icon />;
    case "stationEvent":
      return <WarningOutline18Icon />;
    default:
      return null;
  }
};

export type EventCategory =
  | "plannedInfrastructure"
  | "futureInfrastructure"
  | "global"
  | "internal"
  | "station"
  | "train"
  | "infrastructure"
  | "trainEvent"
  | "stationEvent";

type BadgeProps = {
  eventCategory: EventCategory;
  displayDuration: DisplayDuration | null;
} & BoxProps;

export const Badges: FC<BadgeProps> = ({
  eventCategory,
  displayDuration,
  ...props
}) => (
  <HStack {...props}>
    <CustomOutlineBadge>
      <HStack gap={1}>
        {getIcon(eventCategory)}
        <Text>{getLabel(eventCategory)}</Text>
      </HStack>
    </CustomOutlineBadge>
    <CustomOutlineBadge>
      <Text>{getDisplayDurationString(displayDuration)}</Text>
    </CustomOutlineBadge>
  </HStack>
);
