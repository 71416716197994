import { DateValue, getLocalTimeZone, today } from "@internationalized/date";

export enum COMPANYCODE {
  VYT = "VYT",
  VY = "VY",
  VYT_VY = "VY / VYT",
}

export enum ROLE {
  LOK = "LOK",
  KOND = "KOND",
  BOTH = "BOTH",
  NONE = "NONE",
}

export type FilterType = {
  showClosed: boolean;
  showFuture: boolean;
  showDeleted: boolean;
  role: string;
  companyCode: string;
  chosenDate: DateValue;
};

export const initStates = {
  showClosed: false,
  showFuture: false,
  showDeleted: false,
  role: ROLE.BOTH,
  companyCode: COMPANYCODE.VYT_VY,
  chosenDate: today(getLocalTimeZone()),
};
