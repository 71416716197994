import { compareAsc, isFuture } from "date-fns";

type AlternativeTransportTimeDestination = {
  scheduledArrivalTimeDestination: Date | null;
};

type ReserveAlternativeTransportTimeDestination = {
  shiftEndTime: Date | null;
};

type Time =
  | AlternativeTransportTimeDestination
  | ReserveAlternativeTransportTimeDestination;

type NonNullableDateProp<V> = {
  [K in keyof V]: Date;
};

type Narrowed<V extends Time, K extends keyof V> = V &
  NonNullableDateProp<Pick<V, K>>;

type PropMustHave<
  Object,
  Key extends keyof Object,
  MustHaveType,
> = Object[Key] extends MustHaveType ? Key : never;

export function processTransportList<T extends Time, K extends keyof T>({
  transportList,
  showOldVehicles,
  timeKey,
}: {
  transportList: T[];
  showOldVehicles: boolean;
  timeKey: PropMustHave<T, K, Date | null>;
}) {
  const sortedList = transportList
    .filter((at): at is Narrowed<T, typeof timeKey> => at[timeKey] !== null)
    .sort((a, b) => compareAsc(a[timeKey], b[timeKey]));

  const futureIndex = sortedList.findIndex((at) => isFuture(at[timeKey]));

  if (futureIndex === -1) {
    return [];
  }

  if (showOldVehicles) {
    return sortedList.slice(0, futureIndex);
  }

  return sortedList.slice(futureIndex);
}
