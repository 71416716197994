import { HStack, useCheckboxGroup } from "@chakra-ui/react";
import { Expandable, ChoiceChip } from "@vygruppen/spor-react";
import {
  TodoForm,
  VEHICLE_SET_TYPES,
} from "features/TodoOploggSidebar/formSchema";
import { useFormContext } from "react-hook-form";

export const VehicleSetOptions = () => {
  const { watch, setValue } = useFormContext<TodoForm>();

  const value = watch("vehicleSetTypes");
  const { getCheckboxProps } = useCheckboxGroup({
    onChange: (val: string[]) => {
      setValue("vehicleSetTypes", val);
    },
  });

  return (
    <Expandable title="Marker med" allowToggle>
      <HStack maxWidth="340px" wrap="wrap">
        {VEHICLE_SET_TYPES.map((vh, i) => (
          <ChoiceChip
            key={i}
            isChecked={value?.includes(vh.value)}
            {...getCheckboxProps({ value: vh.value })}
          >
            {vh.text}
          </ChoiceChip>
        ))}
      </HStack>
    </Expandable>
  );
};
