import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Textarea,
} from "@vygruppen/spor-react";
import {
  SingleTrainFormSchema,
  TrainCustomEventSchema,
  TrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { CommonTrainInfoFormProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { FC, useEffect, useState } from "react";
import { FieldErrorsImpl, useFormContext } from "react-hook-form";

type DropsLogTextInputProps = Pick<CommonTrainInfoFormProps, "mode"> & {
  defaultText?: string;
};

export const DropsLogTextInput: FC<DropsLogTextInputProps> = ({
  mode,
  defaultText,
}) => {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);

  const { getValues, setValue, register, formState } =
    useFormContext<SingleTrainFormSchema>();

  // TRAIN_CUSTOM is the only field which doesn't have dropsLogText, in which case this component isn't shown
  // so we can safely narrow the type of the error object
  const formErrors = formState.errors.trainForm as
    | FieldErrorsImpl<Exclude<TrainFormSchema, TrainCustomEventSchema>>
    | undefined;

  const [fieldDirty, setFieldDirty] = useState<boolean>(false);

  const trainEvent = getValues("trainForm.type");

  const dropsLogTextEnabled = getValues("trainForm.dropsLogText.enabled");
  const dropsLogText = getValues("trainForm.dropsLogText.texts.NOB");
  const { onChange, onBlur, name, ref } = register(
    "trainForm.dropsLogText.texts.NOB",
  );

  useEffect(() => {
    // Update the text field contents if the preview changes and the text field has not been manually changed
    if (!fieldDirty && defaultText && !firstLoad) {
      setValue("trainForm.dropsLogText.texts.NOB", defaultText, {
        shouldValidate: true,
      });
    }
  }, [fieldDirty, defaultText]);

  useEffect(() => {
    // If the text field is changed manually back to the default, allow it to be automatically updated again
    if (fieldDirty && defaultText === dropsLogText) {
      setFieldDirty(false);
    }
  }, [fieldDirty, defaultText, dropsLogText]);

  useEffect(() => {
    // Once on load: set the message if it already exists on the event.
    // If the text on the event is different from the preview default, it has
    // been manually changed and should not be automatically updated.
    if (firstLoad && mode === "edit" && dropsLogText) {
      if (defaultText !== dropsLogText) {
        setFieldDirty(true);
      }
    }
    setFirstLoad(false);
  }, [firstLoad, mode, dropsLogText, defaultText]);

  useEffect(() => {
    // Enable DROPS_LOG by default for TRAIN_STOPPED
    if (trainEvent === TrainEventTypeEnum.TRAIN_STOPPED) {
      setValue("trainForm.dropsLogText.enabled", true);
    }

    // Always reset text when changing event type, except on first load
    // Leave firstLoad out of depencency array because it changes immediately,
    // before we get a new preview text to fill with.
    // Otherwise this would empty dropsLogText on first load in edit modal, losing
    // the text from the last version.
    if (!firstLoad) {
      setFieldDirty(false);
      setValue("trainForm.dropsLogText.texts.NOB", "", {
        shouldValidate: true,
      });
    }
  }, [trainEvent]);

  return (
    <Flex gap={2} flexDir="column">
      <Checkbox
        isChecked={dropsLogTextEnabled}
        {...register("trainForm.dropsLogText.enabled")}
      >
        Send melding til Operativ logg
      </Checkbox>
      {dropsLogTextEnabled && (
        <FormControl isInvalid={!!formErrors?.dropsLogText}>
          <Textarea
            label="Melding"
            name={name}
            onChange={(e) => {
              setFieldDirty(true);
              onChange(e);
            }}
            onBlur={onBlur}
            ref={ref}
          />
          <FormErrorMessage>
            {formErrors?.dropsLogText?.texts?.NOB?.message}
          </FormErrorMessage>
        </FormControl>
      )}
    </Flex>
  );
};
