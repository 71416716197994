import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  VStack,
  Button,
  ButtonGroup,
  ChoiceChip,
  ModalHeader,
} from "@vygruppen/spor-react";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import { FC } from "react";
import { ModalScrollableBody } from "shared/components/ActionModal";
import { Text } from "shared/components/typography/TitleStyles";

type CloseTrainsModalProps = {
  closeTrains: () => void;
  selectedTrainIds: AffectedTrain[];
  setSelectedTrainIds: (affectedTrain: AffectedTrain[]) => void;
  closeModal: () => void;
};

export const CloseTrainsModal: FC<CloseTrainsModalProps> = ({
  closeTrains,
  selectedTrainIds,
  setSelectedTrainIds,
  closeModal,
}) => (
  <Modal isOpen onClose={closeModal} size="xl">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader mb={2}>Friskmelde tog</ModalHeader>
      <ModalCloseButton />
      <ModalScrollableBody>
        <VStack alignItems="start" gap={3}>
          <Text>Er du sikker på at du vil friskmelde følgende tog?</Text>
          <Flex alignItems="start" flexWrap="wrap" gap={1}>
            {selectedTrainIds.map((trainId) => (
              <ChoiceChip
                key={`${trainId.trainId.identifier}_${trainId.trainId.nominalDate}`}
                size="sm"
                chipType="filter"
                isChecked
                onChange={() => {
                  const newSelectedTrainIds = selectedTrainIds.filter(
                    (it) => it !== trainId,
                  );
                  setSelectedTrainIds(newSelectedTrainIds);
                  if (newSelectedTrainIds.length === 0) closeModal();
                }}
                style={{
                  pointerEvents: "auto",
                }}
              >
                {trainId.trainId.identifier}
              </ChoiceChip>
            ))}
          </Flex>
        </VStack>
      </ModalScrollableBody>
      <ModalFooter flexDir="column">
        <ButtonGroup mt={2} w="full" justifyContent="space-between">
          <Button type="button" variant="tertiary" onClick={closeModal}>
            Avbryt
          </Button>
          <Button
            onClick={() => {
              closeTrains();
              closeModal();
            }}
            variant="primary"
          >
            Friskmeld tog
          </Button>
        </ButtonGroup>
      </ModalFooter>
    </ModalContent>
  </Modal>
);
