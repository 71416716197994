import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  DeleteOutline30Icon,
  EditOutline24Icon,
  KlippekortOutline30Icon,
  LinkOutOutline18Icon,
  WagonFill30Icon,
} from "@vygruppen/spor-icon-react";
import {
  Accordion,
  AccordionIcon,
  Button,
  ContentLoader,
  Flex,
  Grid,
  Text,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnDELETE } from "api/tanStackQuery/helpers";
import { format, parseISO } from "date-fns";
import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { FC, useState } from "react";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { useTheme } from "styled-components";
import {
  AccordionButtonStyled,
  AccordionItemStyled,
  AccordionPanelStyled,
  ChecklistCustomTableStyle,
  EmptyTableText,
  GridStyled,
  NoDivOverflowButtonIcon,
} from "./ChecklistCustomStyle";
import ChecklistModal from "./ChecklistModal";
import DeleteChecklistModal from "./DeleteChecklistModal";
import { FillOut } from "./schema/responseSchema";
import { companyId, getChecklistText, getCompanyName } from "./utils";

interface ChecklistTableProps {
  fillouts: FillOut[];
  totalFillOuts: number;
  isLoading: boolean;
  refetch: () => void;
}

export function formatTime(dateTime: string) {
  const date = parseISO(dateTime);
  return format(date, "HH:mm");
}

export function formatDate(dateTime: string) {
  const date = parseISO(dateTime);
  return format(date, "dd.MM.yyyy");
}

export function formatDateTime(dateTime: string) {
  const date = parseISO(dateTime);
  return `${format(date, "dd.MM.yyyy")} kl ${format(date, "HH:mm")}`;
}

const ChecklistTable: FC<ChecklistTableProps> = ({
  fillouts,
  totalFillOuts,
  isLoading,
  refetch,
}) => {
  const theme = useTheme();

  const [currentFillOut, setCurrentFillOuts] = useState<FillOut | null>(null);
  const [modal, setModal] = useState("");
  const queryClient = useQueryClient();

  const { mutate: deleteChecklist } = useMutation({
    mutationFn: (id: string) =>
      mutationFnDELETE<undefined>(
        `${getBackendUrl()}/checklist/fill-out/${id}`,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["checklist"] });
    },
  });

  const handleDelete = (checklistToDelete: FillOut) => {
    deleteChecklist(checklistToDelete.fillOutId, {
      onSuccess: () => {
        setModal("");
      },
    });
  };

  const handleEditClick = (checklist: FillOut) => {
    setCurrentFillOuts(checklist);
    setModal("edit");
  };

  const handleModalClose = () => {
    setModal("");
    setCurrentFillOuts(null);
  };

  return (
    <>
      <ChecklistCustomTableStyle variant="simple" size="lg">
        <GridStyled>
          <Text>Dato</Text>
          <Text>Endret</Text>
          <Text>Kvittering</Text>
          <Text>Kjøretøy-ID</Text>
          <Text>Opprettet av</Text>
          <Text>Endret av</Text>
          <Text textAlign="end" fontWeight="500">
            <span style={{ fontWeight: "bold" }}>{totalFillOuts}</span> Treff
          </Text>
        </GridStyled>
        {isLoading && <ContentLoader pt="20%" />}
        {!isLoading && fillouts.length === 0 && (
          <EmptyTableText>
            <KlippekortOutline30Icon mb="20px" />
            <Text variant="md" width="400px" textAlign="center">
              Søk på kjøretøy-ID for å se tilhørende kvitteringer
            </Text>
          </EmptyTableText>
        )}
        {!isLoading &&
          fillouts.map((checklist, index) => (
            <Accordion allowToggle key={index}>
              <AccordionItemStyled key={index}>
                <AccordionButtonStyled>
                  <div>
                    <Text>{formatDate(checklist.executedAt.toString())}</Text>
                    <Text fontWeight={500} color={theme.colorTextSecondary}>
                      {formatTime(checklist.executedAt.toString())}
                    </Text>
                  </div>
                  <div>
                    <Text>{formatDate(checklist.changedAt)}</Text>
                    <Text fontWeight={500} color={theme.colorTextSecondary}>
                      {formatTime(checklist.changedAt)}
                    </Text>
                  </div>
                  <NoDivOverflowButtonIcon>
                    <Button
                      ml="-20px"
                      position="absolute"
                      variant="tertiary"
                      size="xs"
                      onClick={() => {
                        window.open(
                          "https://nsbas.sharepoint.com/sites/Sjekklisterforkjrety/Lists/Sjekklister/AllItems.aspx",
                          "blank",
                        );
                      }}
                      rightIcon={<LinkOutOutline18Icon />}
                    >
                      {getChecklistText(checklist.checklistType)}
                    </Button>
                  </NoDivOverflowButtonIcon>
                  <Flex alignItems="center" gap={1}>
                    <WagonFill30Icon />
                    <Text>
                      {checklist.rollingStock ? checklist.rollingStock : null}
                    </Text>
                  </Flex>

                  <Text fontWeight={500}>{checklist.executor ?? "N/A"}</Text>
                  <Text fontWeight={500}>
                    {checklist.changedBy === "" ? (
                      <DropsBadge semantics="alarm">Uendret</DropsBadge>
                    ) : (
                      checklist.changedBy
                    )}
                  </Text>
                  <Flex justifyContent="end">
                    <AccordionIcon />
                  </Flex>
                </AccordionButtonStyled>

                <AccordionPanelStyled overflow="visible">
                  <DashedLine
                    color={theme.colorBackgroundTertiary}
                    mt="-14px"
                  />

                  {checklist.history ? (
                    Object.entries(checklist.history).map(
                      ([version, change], i, array) => {
                        const previousRollingStock =
                          i > 0 ? array[i - 1][1].rollingStock : null;
                        const previousExecutedAt =
                          i > 0 ? array[i - 1][1].executedAt : null;
                        const previousFillOutType =
                          i > 0 ? array[i - 1][1].checklistType : null;
                        const previousCompany_id =
                          i > 0 ? array[i - 1][1].companyId : null;

                        return (
                          <Grid
                            key={version}
                            templateColumns="repeat(7, 1fr)"
                            marginBottom="20px"
                            marginTop="20px"
                          >
                            <Text fontWeight="900" gridColumn="2">
                              {change.changedAt.split("T")[0]}
                            </Text>
                            <Text gridColumn="2">
                              {formatTime(change.changedAt)}
                            </Text>
                            {previousRollingStock !== change.rollingStock &&
                              previousRollingStock && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Kjøretøy-ID, fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {previousRollingStock}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {change.rollingStock}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            {previousExecutedAt !== change.executedAt &&
                              previousExecutedAt && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Tidspunkt fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {formatDateTime(
                                        previousExecutedAt.toString(),
                                      )}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {formatDateTime(
                                        change.executedAt.toString(),
                                      )}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            {previousFillOutType !== change.checklistType &&
                              previousFillOutType && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Kvitteringstype fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {getChecklistText(previousFillOutType)}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {getChecklistText(change.checklistType)}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            {previousCompany_id !== change.companyId &&
                              previousCompany_id && (
                                <>
                                  <Text fontWeight="900" gridColumn="2">
                                    Endret:
                                  </Text>
                                  <Text gridColumn="3 / 7">
                                    Tog eier fra{" "}
                                    <Text as="span" fontWeight="bold">
                                      {getCompanyName(
                                        previousCompany_id as companyId,
                                      )}
                                    </Text>{" "}
                                    til{" "}
                                    <Text as="span" fontWeight="bold">
                                      {getCompanyName(
                                        change.companyId as companyId,
                                      )}
                                    </Text>
                                  </Text>
                                </>
                              )}
                            <Text fontWeight="900" gridColumn="2">
                              Endret av:
                            </Text>
                            <Text gridColumn="3 / 7">{change.changedBy}</Text>
                            <Text fontWeight="900" gridColumn="2">
                              Kommentar:
                            </Text>
                            <Text gridColumn="3 / 7">
                              {change.comment || "Ingen kommentar"}
                            </Text>
                          </Grid>
                        );
                      },
                    )
                  ) : (
                    <Text>Ingen historikk tilgjengelig</Text>
                  )}

                  <Flex justifyContent="flex-end">
                    <Button
                      aria-label="Slett kvittering"
                      variant="ghost"
                      size="xs"
                      onClick={() => {
                        setCurrentFillOuts(checklist);
                        setModal("delete");
                      }}
                      leftIcon={<DeleteOutline30Icon />}
                    >
                      Slett kvittering
                    </Button>

                    <Button
                      aria-label="Rediger kvittering"
                      variant="tertiary"
                      size="xs"
                      rightIcon={<EditOutline24Icon />}
                      onClick={() => handleEditClick(checklist)}
                    >
                      Endre
                    </Button>
                  </Flex>
                </AccordionPanelStyled>
              </AccordionItemStyled>
            </Accordion>
          ))}
      </ChecklistCustomTableStyle>

      {modal === "edit" && currentFillOut && (
        <ChecklistModal
          onClose={handleModalClose}
          currentFillOut={currentFillOut}
          refetch={refetch}
        />
      )}

      {currentFillOut && (
        <DeleteChecklistModal
          isOpen={modal === "delete"}
          onClose={() => setModal("")}
          currentFillOut={currentFillOut}
          handleDelete={handleDelete}
          refetch={refetch}
        />
      )}
    </>
  );
};

export default ChecklistTable;
