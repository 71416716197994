import { RefObject, useEffect, useState } from "react";

export const useIsTruncated = (
  textRef: RefObject<HTMLElement>,
  text: string | undefined, // Necessary to update state
  isExpanded: boolean, // Necessary to update state
) => {
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (!textRef.current) return;
    const { scrollWidth, clientWidth } = textRef.current;
    setIsTruncated(scrollWidth > clientWidth);
  }, [text, isExpanded]);

  return isTruncated;
};
