import {
  CalendarDateTime,
  fromDate,
  getLocalTimeZone,
  toCalendarDateTime,
  toZoned,
} from "@internationalized/date";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import { describedTypeSchema } from "features/CenterContent/shared/operationalInformation/utils";
import { z } from "zod";

export const dateTimeSchemaResponse = z.coerce
  .date()
  .transform((time) => toCalendarDateTime(fromDate(time, getLocalTimeZone())));
export type DateTimeResponse = z.infer<typeof dateTimeSchemaResponse>;

// We have to use CalendarDateTime instead of ZonedDateTime for formSchema
// to ensure that summertime/wintertime is correctly handled
// E.x. current time is 1. march (winter time) and you select 1. april (summer time)
export const dateTimeSchemaForm = z.custom<CalendarDateTime>();
export type DateTimeForm = z.infer<typeof dateTimeSchemaForm>;

export const dateTimeSchemaRequest = z
  .custom<CalendarDateTime>()
  .transform((date) => toZoned(date, getLocalTimeZone()).toString());
export type DateTimeRequest = z.infer<typeof dateTimeSchemaRequest>;
export type BaseSchema = z.infer<typeof baseSchema>;

export const reasonErrorMessage =
  "Du må velge årsak for å kunne opprette hendelsen";

export const baseSchema = z.object({
  type: z.enum(
    [
      "INFRASTRUCTURE_PLANNED_MAINTENANCE",
      "INFRASTRUCTURE_STRETCH_CLOSED",
      "INFRASTRUCTURE_STRETCH_PARTIALLY_CLOSED",
      "INFRASTRUCTURE_SLOW_DRIVING",
    ],
    {
      errorMap: () => ({
        message: "Du må velge type hendelse for å kunne opprette hendelsen",
      }),
    },
  ),
  affectedLegs: z
    .array(z.object({ fromStop: z.string(), toStop: z.string() }))
    .min(1),
  stretchName: z.string().min(1),
  reason: describedTypeSchema.nullable().refine((val) => !val || val.type, {
    message: reasonErrorMessage,
    path: ["type"], // Ensure the error is assigned to the 'type' field
  }),
  action: describedTypeSchema.nullable(),
  consequence: describedTypeSchema.nullable(),
  infrastructureProvider: z.nativeEnum(InfrastructureProviderEnum).nullable(),
});
