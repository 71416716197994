import { internalMessagesFormSchema } from "features/CenterContent/RoleContent/InternalMessage/schema/formSchema";
import { WeekRepeat } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/PeriodicDuration";
import {
  baseSchema,
  dateTimeSchemaForm,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/baseSchema";
import { describedTypeSchema } from "features/CenterContent/shared/operationalInformation/utils";
import { z } from "zod";

const openEndedDurationSchema = z.object({
  type: z.literal("OPEN_ENDED"),
  from_time: dateTimeSchemaForm,
  extension_time_in_minutes: z.string().refine((val) => Number(val) > 0),
});
const timedDurationSchema = z.object({
  type: z.literal("TIMED"),
  from_time: dateTimeSchemaForm,
  to_time: dateTimeSchemaForm,
});
const ruleSchema = z.object({
  id: z.string(),
  startDateTime: dateTimeSchemaForm,
  endDateTime: dateTimeSchemaForm,
  days: z.array(z.number()),
  repeat: z.nativeEnum(WeekRepeat),
});
const periodicDurationSchema = z.object({
  type: z.literal("PERIODIC"),
  rules: z.array(ruleSchema).min(1),
});
export const formSchemaDuration = z.discriminatedUnion("type", [
  openEndedDurationSchema,
  timedDurationSchema,
  periodicDurationSchema,
]);

const plannedMaintenanceSchema = z.object({
  type: z.enum(["INFRASTRUCTURE_PLANNED_MAINTENANCE"]),
  durations: z.discriminatedUnion("type", [
    timedDurationSchema,
    periodicDurationSchema,
  ]),
  isirkId: z.string().nullable(),
});

const infrastructureCommonSchema = z.object({
  type: z.enum([
    "INFRASTRUCTURE_STRETCH_CLOSED",
    "INFRASTRUCTURE_STRETCH_PARTIALLY_CLOSED",
    "INFRASTRUCTURE_SLOW_DRIVING",
  ]),
  actionCard: z.object({ type: z.string().nullable() }).nullable(),
  redirectStretches: z
    .array(
      z.object({
        name: z.string(),
        legs: z.array(z.object({ fromStop: z.string(), toStop: z.string() })),
      }),
    )
    .max(1),
  durations: openEndedDurationSchema,
  prognosisType: describedTypeSchema.nullable(),
  prognosisTime: dateTimeSchemaForm,
});

export const infrastructureParametersSchema = baseSchema.and(
  z
    .discriminatedUnion("type", [
      infrastructureCommonSchema,
      plannedMaintenanceSchema,
    ])
    .and(
      z.object({
        internalMessages: internalMessagesFormSchema.or(
          z.array(z.any()).nullish(),
        ),
      }),
    ),
);

export const formSchema = z.object({
  infrastructureForm: infrastructureParametersSchema,
});

export type InfrastructureInformationType = z.infer<
  typeof infrastructureParametersSchema
>["type"];

export type InfrastructureCommon = z.infer<typeof infrastructureCommonSchema>;

export type InfrastructureForm = z.infer<typeof formSchema>;

export type Rule = z.infer<typeof ruleSchema>;

export type OpenEndedFormDuration = z.infer<typeof openEndedDurationSchema>;

export type TimedFormDuration = z.infer<typeof timedDurationSchema>;

export type PeriodicFormDuration = z.infer<typeof periodicDurationSchema>;

export type FormSchemaDuration = z.infer<typeof formSchemaDuration>;
