import {
  TracksFill18Icon,
  TracksOutline18Icon,
  WarningFill18Icon,
} from "@vygruppen/spor-icon-react";
import styled from "styled-components";

const IconContainer = styled.span`
  position: relative;
  display: flex;
  max-width: min-content;
`;

const StyledWarningIcon = styled(WarningFill18Icon)`
  position: absolute;
  right: -4px;
  bottom: -2px;
`;

export const InfrastructureIncidentIcon = () => (
  <IconContainer>
    <TracksOutline18Icon />
    <StyledWarningIcon height="2" fill="black" />
  </IconContainer>
);

export const InfrastructureIncidentIconTabActive = () => (
  <IconContainer>
    <TracksFill18Icon />
    <StyledWarningIcon height="2" fill="black" />
  </IconContainer>
);
