import { Box, CardSelect, HStack } from "@vygruppen/spor-react";
import { ReactNode } from "react";
import styled from "styled-components";

// Only solution I found to round CardSelect.
// Setting style at the CardSelect
// component itself had no effect.
const CardSelectContainer = styled(Box)`
  * {
    font-weight: bold;
  }

  span {
    order: -1; // Moves label to the left of icon
  }

  & button {
    padding-inline: 0;
    padding: 5.5px 12px;
  }
`;

const CardSelectPopover = styled(HStack)`
  padding: 20px;
  border-radius: 12px;
  margin: -18px;
  max-width: 300px;
  flex-wrap: wrap;
`;

const IconNumber = styled.div`
  border-radius: 99px;
  background-color: ${({ theme }) => theme.colorSuccess};
  color: ${({ theme }) => theme.colorSuccessFill};
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

export const FilterCardSelect = ({
  label,
  children,
  selectedAmount,
}: {
  label: string;
  children: ReactNode;
  selectedAmount: number;
}) => (
  <CardSelectContainer>
    <CardSelect
      label={label}
      variant="base"
      size="sm"
      minWidth="max-content"
      icon={selectedAmount > 0 && <IconNumber>{selectedAmount}</IconNumber>}
    >
      <CardSelectPopover>{children}</CardSelectPopover>
    </CardSelect>
  </CardSelectContainer>
);
