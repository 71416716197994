import { ChoiceChip, Flex } from "@vygruppen/spor-react";
import { fadein } from "shared/components/transitions/fade";
import styled from "styled-components";

export const TodoHeader = styled.span`
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.colorTextMain};
  font-family: "Vy Sans";
`;

export const TodoMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: messages;
  overflow-y: auto;
  width: 100%;
  position: relative;
  padding-top: 2px;
`;

export const TodoElementWrapper = styled.li`
  animation: ${fadein} 300ms ease-in;
  padding: 6px 1rem 1rem 1rem;
  border-right: 1px solid ${({ theme }) => theme.colorBorder};
  border-left: 1px solid ${({ theme }) => theme.colorBorder};
  background: ${({ theme }) => theme.colorBackgroundSecondary};
  border-radius: 6px;
  width: 100%;

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colorBorder};
  }
`;

export const FilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
`;

export const TagWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 12px;
`;

export const CheckboxListContainer = styled.div`
  display: flex;
  align-content: center;
  align-self: center;
  width: 100%;
  gap: 6px;
`;

export const TodoRowContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 12px;
  gap: 12px;
`;

const TodoSection = styled.div`
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
`;

export const ListContainer = styled(TodoSection)`
  padding: 0;
  display: flex;
  overflow-y: scroll;
  scrollbar-color: ${({ theme }) => theme.colorSelected} rgba(0, 0, 0, 0);
  overflow-x: hidden;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
  -webkit-scrollbar: none; /* WebKit */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
`;

export const CircleIndicator = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  background-color: ${({ theme }) => theme.colorSuccess};
  border-radius: 50%;
`;

export const FilterButtonWrapper = styled.div`
  position: relative;
  display: inline-blo;
`;

export const FilterContainer = styled.div`
  padding: 1.5rem;
  width: 100%;
  box-sizing: border-box;
  overflow-y: hidden;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border: 1px solid ${({ theme }) => theme.colorBorder};
  border-radius: 6px;
  z-index: 999;
  margin-top: 6px;
`;

export const PaddedContainer = styled.div`
  padding: 0.2rem;

  &:first-of-type {
    margin-bottom: 0rem;
  }
`;

export const WrappingContainer = styled(Flex)`
  flex-wrap: wrap;
  gap: 3px;
  max-width: 300px;
`;

export const StyledChoiceChip = styled(ChoiceChip)`
  white-space: normal;
  padding: 3px 1px;
  max-width: 100%;
  flex-grow: 1;
`;

export const TopFlex = styled(Flex)`
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border-color: ${({ theme }) => theme.colorBorder};
  border-width: 1px;
  border-radius: 6px;
`;

export const TodoNoMessage = styled.div`
  background: blue;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  border-color: ${({ theme }) => theme.colorBorder};
  border-width: 1px;
  border-radius: 6px;
`;
