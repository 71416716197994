import { Button } from "@vygruppen/spor-react";
import { getLocalStorageUsername } from "api/dropsLocalStorage";
import { format } from "date-fns/format";
import { parseISO } from "date-fns/parseISO";
import { log, LogLevel } from "logging/datadogBrowserLogs";
import { useState } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import { getRoleText } from "shared/types/roles";
import { removeDomainNameIfPresent } from "../../../common/utils";
import { ErrorTextDescriptionElement } from "../../common/ErrorMessage";
import { VEHICLE_SET_TYPES } from "../../todoFilter/useTodoFilterState";
import { TagWrapper, TodoHeader, TodoRowContainer } from "../../TodoStyles";
import { updateExistingTodo } from "../todoListApi";
import { Todo } from "./TodoElement";

interface ITodoBanner {
  todo: Todo;
}

// TodoBanner views
const RenderTags = ({ todo }: { todo: Todo }) => {
  if (todo.vehicleSetTypes === null && todo.trainNumber === null) {
    return null;
  }

  function sortAndMapVehicleSetTypes() {
    return (
      todo.vehicleSetTypes &&
      todo.vehicleSetTypes
        .split(",")
        .sort((a: string, b: string) => {
          if (Number.isNaN(Number(a)) || Number.isNaN(Number(b))) {
            return -1;
          }
          return Number(a) - Number(b);
        })
        .map((it) => {
          const foundSetType = VEHICLE_SET_TYPES.find(
            (setType: any) => setType.value === it,
          );
          return foundSetType ? ` ${foundSetType.text}` : "";
        })
        .toString()
    );
  }

  return (
    <TagWrapper
      style={todo.status !== "active" ? { textDecoration: "line-through" } : {}}
    >
      {todo.vehicleSetTypes && (
        <TodoHeader>{sortAndMapVehicleSetTypes()}</TodoHeader>
      )}
      {todo.trainNumber && (
        <TodoHeader>
          {todo.vehicleSetTypes ? `, ${todo.trainNumber}` : todo.trainNumber}
        </TodoHeader>
      )}
    </TagWrapper>
  );
};

const RenderMetaInfo = (props: ITodoBanner) => {
  const { todo } = props;
  const [showError, setShowError] = useState(false);

  return (
    <>
      <TagWrapper
        style={{
          justifyContent: "space-between",
        }}
      >
        <TodoRowContainer>
          <Text bold style={{ whiteSpace: "nowrap" }}>
            {format(parseISO(todo.dateCreated), "HH:mm")}
          </Text>
          <Text secondary style={{ whiteSpace: "nowrap" }}>
            {new Date(todo.dateCreated).toLocaleDateString("no-nb", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })}
          </Text>
          <Text secondary>{`${getRoleText(
            todo.role,
          )} / ${removeDomainNameIfPresent(todo.userId)}`}</Text>
        </TodoRowContainer>
        {todo.status === "active" && (
          <Button
            variant="tertiary"
            size="xs"
            mt="12px !important"
            onClick={() => {
              updateExistingTodo({
                ...todo,
                createdAt: todo.dateCreated,
                todoId: todo.id,
                username: getLocalStorageUsername() ?? todo.userId,
                status: "inActive",
                markedAsRead: true,
              }).catch((exception: Error) => {
                setShowError(true);
                log(
                  LogLevel.error,
                  "TodoBanner",
                  `Could not perform api call for updating (CLOSING) Todo message with exception: ${exception.message} `,
                );
              });
            }}
          >
            Fullført
          </Button>
        )}
      </TagWrapper>
      {ErrorTextDescriptionElement(
        showError,
        "Kunne ikke fullføre melding. Vennligst prøv igjen.",
        { marginBottom: "0.5rem" },
      )}
    </>
  );
};

const TodoBanner = (props: ITodoBanner) => {
  const { todo } = props;

  return (
    <div>
      <RenderMetaInfo todo={todo} />
      <RenderTags todo={todo} />
    </div>
  );
};

export default TodoBanner;
