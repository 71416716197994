import { axiosClient } from "api/http/config";

type QueryFnProps = {
  signal: AbortSignal | undefined;
  url: string;
};

type QueryFnPropsWithBody = QueryFnProps & { body?: any };

export const queryFnGET = async <ResponseType,>({
  signal,
  url,
}: QueryFnProps) => {
  const resp = await axiosClient.get<ResponseType>(url, { signal });
  return resp.data;
};

export const queryFnPOST = async <ResponseType,>({
  url,
  body,
  signal,
}: QueryFnPropsWithBody) => {
  const resp = await axiosClient.post<ResponseType>(url, body, { signal });
  return resp.data;
};

export const mutationFnPOST = async <ResponseType, BodyType>(
  url: string,
  body?: BodyType,
) => {
  const resp = await axiosClient.post<ResponseType>(url, body);
  return resp.data;
};

export const mutationFnDELETE = async <ResponseType,>(
  url: string,
): Promise<ResponseType> => {
  const resp = await axiosClient.delete<ResponseType>(url);
  return resp.data;
};

export const mutationFnPUT = async <ResponseType, BodyType>(
  url: string,
  body?: BodyType,
) => {
  const resp = await axiosClient.put<ResponseType>(url, body);
  return resp.data;
};
