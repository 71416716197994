import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { Flex, IconButton, Input, Stack, Text } from "@vygruppen/spor-react";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { Preview } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Preview";
import { FormInputs } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/FormInputs";
import { usePreview } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/usePreview";
import { InfrastructureForm } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { formStateHasCustomField } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { FC, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { useStretchBuilder } from "stores/useStretchBuilder";

type ModalContentProps = {
  stretchTitle: string;
};

export const InfrastructureModalContent: FC<ModalContentProps> = ({
  stretchTitle,
}) => {
  const { control, register, setValue } = useFormContext<InfrastructureForm>();
  const formState = useWatch({
    control,
    name: "infrastructureForm",
  });

  const [selectedLegs, stretchBuilderModalState, setStretchBuilderModalState] =
    useStretchBuilder((state) => [
      state.selectedLegs,
      state.stretchBuilderModalState,
      state.setStretchBuilderModalState,
    ]);

  const modalState = stretchBuilderModalState.type;

  useEffect(() => {
    if (selectedLegs.length > 0) {
      setValue(
        "infrastructureForm.affectedLegs",
        selectedLegs.map((leg) => ({
          fromStop: leg.fromStop.id,
          toStop: leg.toStop.id,
        })),
      );
      setValue("infrastructureForm.stretchName", stretchTitle);
    }
  }, []);

  const { previewData, previewStatus } = usePreview(formState);

  if (modalState !== "infrastructure") return null;

  return (
    <ErrorBoundary>
      <Stack spacing={5} width="100%">
        <Flex gap={1} alignItems="center">
          <Flex gap={0.5} flexWrap="wrap" alignItems="center">
            <Text variant="xs">Strekning: </Text>
            {stretchTitle.split(", ").map((it) => (
              <DropsBadge
                key={it}
                borderRadius="9px"
                semantics="success"
                paddingY={0.5}
                marginX={0.5}
              >
                {it}
              </DropsBadge>
            ))}
          </Flex>
          <IconButton
            size="xs"
            variant="tertiary"
            icon={<EditOutline18Icon />}
            aria-label="Endre strekning"
            onClick={() =>
              setStretchBuilderModalState({
                ...stretchBuilderModalState,
                stretchBuilderMode: true,
              })
            }
          />
        </Flex>
        <Stack spacing={3} width="100%">
          <Input
            label="Endre strekningsnavn"
            type="string"
            {...register("infrastructureForm.stretchName")}
          />
          <FormInputs />
        </Stack>
        <Preview
          previewStatus={previewStatus}
          previewText={previewData?.internalMessage ?? ""}
        />
        {formStateHasCustomField(formState) && (
          <DropsStaticAlert variant="info" id="infoBoxActionModal">
            Hendelsen inneholder egendefinert tekst. Sjekk at oppsummeringen ser
            riktig ut før du går videre.
          </DropsStaticAlert>
        )}
      </Stack>
    </ErrorBoundary>
  );
};
