import {
  NightOutline24Icon,
  PersonOutline24Icon,
  SpeechBubbleOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Button, Switch, Text, useColorMode } from "@vygruppen/spor-react";
import { logout } from "app/Auth/auth";
import styled from "styled-components";
import { mailLink } from "./MailLink";

const AdminLinkList = styled.ul`
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0rem 0 0;
  padding-left: 2%;

  padding-top: 18px;
  padding-bottom: 10px;

  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
`;

const AdminLinkItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.6rem;
  padding-left: 18px;
  padding-right: 18px;
  color: ${({ theme }) => theme.colorTextMain};

  &:hover {
    background-color: ${({ theme }) => theme.colorBackgroundHover};
    color: ${({ theme }) => theme.colorPrimaryHover};
  }
`;

const RowContainer = styled.a`
  display: flex;
  width: 100%;
  flex-direction: row;
  text-decoration: none;
  transition: color 0.2s linear;

  padding-right: 2%;

  &:hover {
    color: ${({ theme }) => theme.colorTextDisabled};

    & svg path {
      fill: ${({ theme }) => theme.colorTextMain};
    }
  }
`;

const StyledSwitch = styled(Switch)`
  position: fixed;
  margin-left: auto;
`;

const StyledButton = styled(Button)`
  :first-child {
    justify-content: start;
  }
`;

const AdminLinks = () => {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <AdminLinkList>
      <AdminLinkItem>
        <NightOutline24Icon ml="2px" />
        <Text variant="sm" pl="0.375rem">
          Mørk modus
        </Text>
        <StyledSwitch
          size="sm"
          isChecked={colorMode === "dark"}
          onChange={toggleColorMode}
          title="Mørk modus"
        />
      </AdminLinkItem>

      <RowContainer href={mailLink()} target="_blank" rel="noopener noreferrer">
        <StyledButton
          leftIcon={<SpeechBubbleOutline24Icon />}
          variant="ghost"
          size="sm"
          width="100%"
        >
          <Text variant="sm" fontWeight={100}>
            Tilbakemelding
          </Text>
        </StyledButton>
      </RowContainer>
      <RowContainer>
        <StyledButton
          onClick={() => logout()}
          leftIcon={<PersonOutline24Icon />}
          variant="ghost"
          size="sm"
          width="100%"
          aria-label="Log out"
        >
          <Text variant="sm" fontWeight={100}>
            Logg ut av Dashbord
          </Text>
        </StyledButton>
      </RowContainer>
    </AdminLinkList>
  );
};

export default AdminLinks;
