import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { HStack, IconButton, Stack } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { GlobalEventResponse } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { closeGlobalInternalMessage } from "features/CenterContent/RoleContent/GlobalEvents/useGlobalEventDetailed";
import { Badges } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import { GLOBAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/useGlobalInformation";
import {
  getDisplayDurationFromVisibility,
  sortByVisibility,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { DepublishInformation } from "features/OperationalInformation/DepublishInformation";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { Text } from "shared/components/typography/TitleStyles";
import { useTheme } from "styled-components";
import { useDropsRole } from "stores/useDropsRole";

type GlobalEventsProps = {
  data: GlobalEventResponse[];
  onEditClick: (event: GlobalEventResponse) => void;
};

export const GlobalEventsList = ({ data, onEditClick }: GlobalEventsProps) => {
  const theme = useTheme();
  const { isVaktleder } = useDropsRole();
  if (data.length === 0)
    return <NoDataMessage reason="Det er ikke registrert noen hendelser" />;
  return (
    <Stack gap={3}>
      {data.sort(sortByVisibility).map((event) => {
        const text =
          event.customTexts.length > 0
            ? event.customTexts[0].texts.NOB
            : { title: "", description: "" };
        return (
          <Stack
            key={event.uuid}
            border={`1px solid ${theme.colorHoverItem}`}
            background={theme.colorBackgroundTertiary}
            borderRadius="sm"
            p={3}
            gap={1}
          >
            <HStack alignItems="center" justifyContent="space-between">
              <Text
                bold
                truncated
                style={{
                  flexShrink: "2",
                }}
              >
                {text.title}
              </Text>
              {isVaktleder() && (
                <HStack minWidth="fit-content">
                  <DepublishInformation
                    uuid={event.uuid}
                    isDisabled={false}
                    queryKey={[GLOBAL_EVENTS_QUERY_KEY]}
                    closeInternalMessage={() =>
                      closeGlobalInternalMessage(event.uuid)
                    }
                  />
                  <IconButton
                    onClick={() => onEditClick(event)}
                    size="sm"
                    aria-label="Rediger"
                    variant="tertiary"
                    icon={<EditOutline18Icon />}
                  />
                </HStack>
              )}
            </HStack>
            <Badges
              eventCategory="global"
              displayDuration={getDisplayDurationFromVisibility(
                event.visibility,
              )}
            />
            <Text>{text.description}</Text>
            <Text secondary>
              {format(
                event.updatedAt.toDate(),
                "'Sist oppdatert' dd.MM.yyyy 'kl.' HH:mm",
              )}
            </Text>
          </Stack>
        );
      })}
    </Stack>
  );
};
