import {
  NotificationFill30Icon,
  NotificationOutline30Icon,
} from "@vygruppen/spor-icon-react";
import { IconButton } from "@vygruppen/spor-react";
import { useEffect, useState } from "react";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import { useOnOutsideMouseclick } from "shared/hooks/useOnOutsideMouseclick";
import { useTheme } from "styled-components";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { AlertsModal } from "./AlertsModal";
import { TrainResumed, handleTrainResumedResponse } from "./useTrainResumed";

export const OpenAlerts = () => {
  const { message: dropsWebsocketMessage } = useDropsWebsocket("trainResumed", [
    "TRAIN_RESUMED",
    "TRAIN_INFORMATION",
  ]);

  // Consider useStateTrainsResumedMock.tsx if you want trainsResumed(mock) without using websockets
  const [trainsResumed, setTrainsResumed] = useState<TrainResumed[]>([]);

  useEffect(() => {
    if (
      dropsWebsocketMessage.status === "received" &&
      dropsWebsocketMessage.data.topic === "TRAIN_RESUMED"
    ) {
      handleTrainResumedResponse(
        dropsWebsocketMessage.data.message,
        trainsResumed,
        setTrainsResumed,
      );
    }
    if (
      dropsWebsocketMessage.status === "received" &&
      dropsWebsocketMessage.data.topic === "TRAIN_INFORMATION"
    ) {
      const trainInfoMessage = dropsWebsocketMessage.data.message;
      const matchingVersion = trainInfoMessage.versions.find(
        (version) =>
          version.trainIdentifier.nominal_date &&
          trainsResumed.find(
            (it) =>
              it.trainIdentifier.identifier ===
              version.trainIdentifier.operational_identifier,
          ),
      );
      if (
        matchingVersion?.state === "CLOSED" ||
        matchingVersion?.state === "REPLACED"
      ) {
        const matchingTrainId =
          matchingVersion.trainIdentifier.operational_identifier;
        setTrainsResumed(
          trainsResumed.filter(
            (it) => it.trainIdentifier.identifier !== matchingTrainId,
          ),
        );
      }
    }
  }, [dropsWebsocketMessage]);

  const theme = useTheme();

  const [showModal, setShowModal] = useState(false);

  const hasAlerts = trainsResumed.length > 0;

  const { mouseclickRef } = useOnOutsideMouseclick(() => {
    setShowModal(false);
  });
  return (
    <div ref={mouseclickRef} style={{ position: "relative" }}>
      {showModal && (
        <AlertsModal
          trainsResumed={trainsResumed}
          setTrainsResumed={setTrainsResumed}
          setShowModal={setShowModal}
        />
      )}
      {hasAlerts && (
        <AlertsCounter
          position="absolute"
          right="9px"
          top="-3px"
          alerts={trainsResumed.length}
          severity="secondaryAlarm"
          zIndex={1}
        />
      )}
      <IconButton
        mx={2}
        variant="tertiary"
        aria-label="Train alerts"
        icon={
          hasAlerts ? <NotificationFill30Icon /> : <NotificationOutline30Icon />
        }
        onClick={() => {
          setShowModal(!showModal);
        }}
        color={hasAlerts ? theme.colorTextMain : undefined}
      />
    </div>
  );
};
