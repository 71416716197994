import {
  CustomTexts,
  globalEventFormSchema,
  GlobalEventRequest,
  GlobalVisibilityFormSchema,
  VisibilityRequest,
  VisibilitySchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { formatTime } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import { z } from "zod";

export const contextsForGlobalWarning = [
  "DROPS",
  // "PASSENGER_INFORMATION_SYSTEM",
  "TRAIN_DRIVER",
  "CUSTOMER_ADVISOR",
  "CONDUCTOR",
  "VY_FRONT_PAGE",
  "DROPS_LOG",
];

export const getRequestVisibility = (
  visibility: VisibilitySchema,
): VisibilityRequest => {
  switch (visibility.type) {
    case "CONSTANT":
      return {
        type: "CONSTANT",
        visible: true,
      };
    case "TIMED":
      return {
        type: "TIMED",
        fromTime: formatTime(visibility.fromTime),
        toTime: formatTime(visibility.toTime),
      };
    case "OPEN_ENDED":
    default:
      return {
        type: "OPEN_ENDED",
        fromTime: formatTime(visibility.fromTime),
      };
  }
};

export const getGlobalRequestVisibility = (
  visibility: GlobalVisibilityFormSchema,
): VisibilityRequest => ({
  type: "OPEN_ENDED",
  fromTime: formatTime(visibility.fromTime),
});

const getTexts = (customTexts: CustomTexts) => {
  if (
    customTexts.texts.ENG &&
    (customTexts.texts.ENG.title.length > 0 ||
      customTexts.texts.ENG?.description.length > 0)
  ) {
    return customTexts.texts;
  }
  return { NOB: { ...customTexts.texts.NOB } };
};

export const formToGlobalInformationRequest = (
  formState: z.infer<typeof globalEventFormSchema>,
  referencedIncidentId?: string,
): GlobalEventRequest => ({
  globalInformationType: "GLOBAL_GENERAL_WARNING",
  visibility: getGlobalRequestVisibility(formState.visibility),
  incidentIds: referencedIncidentId ? [referencedIncidentId] : [],
  countryCode: "NO",
  customTexts: [
    {
      contexts: formState.customTexts.contexts,
      texts: getTexts(formState.customTexts),
    },
  ],
});
