import { useQuery } from "@tanstack/react-query";
import { useToast } from "@vygruppen/spor-react";
import { AffectedTrains } from "@vygruppen/vy-train-map";
import { getTrafficguiBaseUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { useEffect } from "react";

export const useAffectedTrainsForMap = (
  incidentIds: string[] | undefined,
  isInfraInfoSuccess: boolean,
) => {
  const toast = useToast();

  const {
    data: affectedTrains,
    isSuccess: isAffectedTrainsSuccess,
    status,
  } = useQuery({
    queryKey: ["affectedTrains", incidentIds],
    enabled: isInfraInfoSuccess && incidentIds && incidentIds?.length > 0,
    refetchInterval: 5 * 60 * 1000, // Every five minutes
    queryFn: ({ signal }) =>
      queryFnGET<AffectedTrains>({
        signal,
        url: `${getTrafficguiBaseUrl()}/trainmap-backend/affected-trains?incidentIds=${incidentIds?.join(
          ",",
        )}`,
      }),
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (status === "error") {
      toast({
        variant: "error",
        text: "Kunne ikke hente påvirkede tog",
      });
    }
  }, [status]);

  return { affectedTrains, isAffectedTrainsSuccess };
};
