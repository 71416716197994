import { FC } from "react";
import { Tooltip as ReactLeafletTooltip, TooltipProps } from "react-leaflet";
import colors from "shared/theme/colors";
import styled from "styled-components";

interface LeafletTooltipProps extends TooltipProps {}

export const TooltipArrowColor = colors.darkTeal;

const Tooltip = styled(ReactLeafletTooltip)<LeafletTooltipProps>`
  background-color: ${colors.darkTeal};
  color: ${colors.lightGrey};
  border-radius: 6px;
  border: none;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);

  :before {
    border-top-color: transparent;
    border-bottom-color: transparent;
  }
`;

const LeafletTooltip: FC<LeafletTooltipProps> = ({
  children,
  direction,
  offset,
  permanent,
  opacity,
  ...props
}) => (
  <Tooltip
    permanent={permanent}
    direction={direction || "top"}
    opacity={opacity || 100}
    offset={offset || [0, -16]}
    {...props}
  >
    {children}
  </Tooltip>
);

export default LeafletTooltip;
