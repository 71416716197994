import { Box, Text } from "@vygruppen/spor-react";
import { FC } from "react";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";

export type PreviewStatus = "pending" | "success" | "error" | "idle";

type PreviewProps = {
  previewStatus: PreviewStatus;
  previewText: string;
  isFormValid?: boolean;
  title?: string;
};

const subtitleFromProps = ({
  previewStatus,
  previewText,
  isFormValid,
}: PreviewProps): string | undefined => {
  if (!isFormValid) {
    return "En oppsummering av hendelsen.";
  }

  switch (previewStatus) {
    case "idle":
      return "En oppsummering av hendelsen.";
    case "pending":
      return undefined;
    case "success":
      return previewText;
    case "error":
    default:
      return "Kunne ikke hente oppsummering";
  }
};

export const Preview: FC<PreviewProps> = ({
  previewText,
  previewStatus,
  isFormValid = true,
  title = "Oppsummering",
}) => {
  const subtitle = subtitleFromProps({
    previewText,
    previewStatus,
    isFormValid,
  });
  return (
    <Box>
      <ExpandableInfoMessage
        title={title}
        severity="info"
        tooltip="Dette er en oppsummering av informasjon som sendes ut om denne hendelsen. Ordlyden tilpasses etter mottaker."
        // Subtitle is passed as a prop and as a child
        // as the prop is shown truncated and the child is shown fully
        subTitle={subtitle}
        skeleton={!subtitle}
        showExpandWhenTruncated
      >
        <Text
          whiteSpace="pre-wrap"
          data-testid="previewMessage"
          variant="xs"
          fontWeight="normal"
        >
          {subtitle}
        </Text>
      </ExpandableInfoMessage>
    </Box>
  );
};
