import {
  getLocalStorageRole,
  setLocalStorageRole,
} from "api/dropsLocalStorage";
import { UserRole } from "shared/types/roles";
import { create } from "zustand";

const getInitialRole = () => {
  const role = getLocalStorageRole();
  if (role === null || !Object.values(UserRole).includes(role)) {
    // default role is vaktleder
    const defaultRole = UserRole.vaktleder;
    setLocalStorageRole(defaultRole);
    return defaultRole;
  }
  return role;
};

type State = {
  role: UserRole;
};

type Action = {
  setRole: (newRole: State["role"]) => void;
  isVaktleder: () => boolean;
  isTrafikkplanlegger: () => boolean;
  isLokleder: () => boolean;
};

export const useDropsRole = create<State & Action>((set, get) => ({
  role: getInitialRole(),
  setRole: (newRole) => {
    set(() => {
      setLocalStorageRole(newRole);
      return { role: newRole };
    });
  },
  isVaktleder: () => {
    const userRole = get().role;
    return (
      userRole === UserRole.vaktleder || userRole === UserRole.transportleder
    );
  },
  isTrafikkplanlegger: () => get().role === UserRole.trafikkplanlegger,
  isLokleder: () =>
    [UserRole.lokleder, UserRole.lokleder1, UserRole.lokleder2].includes(
      get().role,
    ),
}));
