import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Flex, Text } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { VisibilityInput } from "features/CenterContent/RoleContent/GlobalEvents/VisibilityInput";
import { visibilityFormSchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InternalMessageRequest } from "features/CenterContent/RoleContent/InternalMessage/schema/requestSchema";
import { MessageList } from "features/CenterContent/RoleContent/InternalMessage/shared/MessageList";
import {
  getVisibility,
  internalMessagesFormToRequest,
  internalMessagesResponseToForm,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { INTERNAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import { BadgeText as BadgeTextStation } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/StationEventModal";
import { BadgeText } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/TrainInfoForm";
import { TrainInternalMessagesQueryKey } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/useTrainInternalMessages";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { useToastOnError } from "shared/components/dataDisplay/useToastOnError";
import { z } from "zod";
import {
  Stop as StopSimple,
  operationalIdentifierJsonToTrainIdentifier,
} from "../TrainMap/StretchBuilder/infrastructureEvents/utils";
import { InternalMessageResponse } from "../TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";
import { internalMessagesFormSchema } from "./schema/formSchema";

const INTERNAL_MESSAGES_DETAILS_QUERY_KEY = "internalMessagesDetails";

type InternalMessageType = {
  isGlobal: boolean;
  isTrain: boolean;
  isStation: boolean;
};

const getInternalMessageType = (
  train?: TrainIdentifier,
  stations: StopSimple[] = [],
): InternalMessageType => ({
  isGlobal: !train && stations.length === 0,
  isTrain: !!train,
  isStation: stations.length >= 1,
});

const makeTitle = (
  internalMessageType: InternalMessageType,
  stations: StopSimple[],
) => {
  const { isGlobal, isTrain } = internalMessageType;
  if (isGlobal) return `Oppdater global intern melding`;
  if (isTrain) return `Oppdater intern melding til tog`;
  return `Oppdater intern melding til ${stations.length >= 2 ? "stasjoner" : "stasjon"}`;
};

const editInternalMessageFormSchema = z.object({
  editInternalMessageForm: z.object({
    internalMessages: internalMessagesFormSchema,
    visibility: visibilityFormSchema,
  }),
});

type EditInternalMessageFormSchema = z.infer<
  typeof editInternalMessageFormSchema
>;

const getTrain = (event: InternalMessageResponse) =>
  event.relevantTrain
    ? operationalIdentifierJsonToTrainIdentifier(event.relevantTrain)
    : undefined;

const getStations = (event: InternalMessageResponse) =>
  event.relevantStops.map((it) => ({
    id: it.ids[0].id,
    name: it.ids[0].name ?? it.ids[0].id,
  }));

const EditInternalMessageModalContent = ({
  setModalOpen,
  events,
}: {
  setModalOpen: (value: boolean) => void;
  events: InternalMessageResponse[];
}) => {
  // events cannot be empty, this is checked in parent component
  const event = events[events.length - 1];
  const train = getTrain(event);
  const stations = getStations(event);
  const internalMessageType = getInternalMessageType(train, stations);
  const { isGlobal, isTrain, isStation } = internalMessageType;
  const formMethods = useForm<EditInternalMessageFormSchema>({
    resolver: zodResolver(editInternalMessageFormSchema),
    defaultValues: {
      editInternalMessageForm: {
        internalMessages: internalMessagesResponseToForm(events),
        visibility: getVisibility(event.visibility),
      },
    },
  });

  const { mutate: submitRequest, status: submitStatus } = useMutation({
    mutationFn: (data: InternalMessageRequest) =>
      mutationFnPOST<undefined, InternalMessageRequest>(
        `${getBackendUrl()}/global-information/${event.uuid}/change`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_EVENTS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [INTERNAL_MESSAGES_DETAILS_QUERY_KEY, event.uuid],
      });
      queryClient.invalidateQueries({
        queryKey: TrainInternalMessagesQueryKey(
          train?.nominalDate,
          train?.identifier,
        ),
      });
    },
  });

  const onSubmit: SubmitHandler<EditInternalMessageFormSchema> = ({
    editInternalMessageForm,
  }) => {
    const { internalMessages, visibility } = editInternalMessageForm;
    const request = internalMessagesFormToRequest(
      internalMessages,
      visibility,
      train,
      stations,
    );
    if (!request) return;
    submitRequest(request);
  };

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        title={makeTitle(internalMessageType, stations)}
        titleBadge={isGlobal ? "Uavhengig" : undefined}
        actionTitle="Send melding"
        onClose={() => setModalOpen(false)}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        isLoading={submitStatus === "pending"}
        isSuccess={submitStatus === "success"}
        isError={submitStatus === "error"}
        successMessage="Hendelse endret"
        failureMessage="Kunne ikke endre hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer"
      >
        <ErrorBoundary>
          <Flex flexDir="column" gap="30px" w="100%">
            {isTrain && <BadgeText selectedTrain={train!} />}
            {isStation && <BadgeTextStation selectedStops={stations!} />}
            <MessageList
              formField="editInternalMessageForm.internalMessages"
              collapsed={false}
              maxHeight={undefined}
            />
            {!isTrain && (
              <VisibilityInput
                formMethods={formMethods}
                formField="editInternalMessageForm.visibility"
                isRelatedToIncident={false}
              />
            )}
            {isGlobal && (
              <Text>
                <b>OBS!</b> Globale meldinger blir synlig for all personell på
                alle linjer
              </Text>
            )}
          </Flex>
        </ErrorBoundary>
      </ActionModal>
    </FormProvider>
  );
};

const EditInternalMessageModal = ({
  setModalOpen,
  uuid,
}: {
  setModalOpen: (value: boolean) => void;
  uuid: string;
}) => {
  const { data, isError } = useQuery({
    queryKey: [INTERNAL_MESSAGES_DETAILS_QUERY_KEY, uuid],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<
          InternalMessageResponse[]
        >(`${getBackendUrl()}/global-information/by-uuid/${uuid}?latest=false`, { signal })
        .then((res) => res.data.reverse()),
  });
  const isEmptyOrError = data?.length === 0 || isError;

  useToastOnError(isEmptyOrError, () => setModalOpen(false));

  if (!data || isEmptyOrError) return null;

  return (
    <EditInternalMessageModalContent
      events={data}
      setModalOpen={setModalOpen}
    />
  );
};

export default EditInternalMessageModal;
