import { Stack, Text } from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { Preview } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Preview";
import { TrainFormFieldWithCustomInput } from "features/CenterContent/shared/operationalInformation/components/TrainFormFieldWithCustomInput";
import {
  EventGroup,
  OperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import {
  filterActions,
  sortAndFilterReasons,
} from "features/CenterContent/shared/operationalInformation/utils";
import { DropsLogTextInput } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/DropsLogTextInput";
import { FormControlErrorMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/FormControlErrorMessage";
import {
  FieldErrorOrUndefined,
  SingleTrainFormSchema,
  TrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { SubTypeInputs } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs";
import { SelectIncidentsForTrain } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/subTypeInputs/IncidentsForTrain";
import { GroupedInformationResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/useInfrastructureInformationForTrain";
import { useTrainInfoPreview } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/useTrainInfoPreview";
import { formStateHasCustomField } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { supportsMultipleEvents } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { FC, useMemo } from "react";
import { UseFormReturn } from "react-hook-form";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";

type TrainInfoFormProps = {
  selectedTrain: TrainIdentifier;
  trainInfoWithOpenState: string[];
  opInfoTypes: OperationalInformationTypes;
  infrastructureData: GroupedInformationResponse | undefined;
  formState: UseFormReturn<SingleTrainFormSchema>["formState"];
  currentFormValues: TrainFormSchema;
  previousEventType?: TrainEventTypeEnum;
};

export type CommonTrainInfoFormProps = {
  mode: "edit" | "create";
  uuid?: string;
};

export const BadgeText = ({
  selectedTrain,
}: {
  selectedTrain: TrainIdentifier;
}) => (
  <Text variant="xs">
    Tog:
    <DropsBadge
      borderRadius="9px"
      paddingY={0.5}
      marginX={0.5}
      semantics="success"
    >
      {selectedTrain.identifier}
    </DropsBadge>
  </Text>
);

const TrainInfoForm: FC<CommonTrainInfoFormProps & TrainInfoFormProps> = ({
  mode,
  uuid,
  selectedTrain,
  trainInfoWithOpenState,
  opInfoTypes,
  infrastructureData,
  formState,
  currentFormValues,
  previousEventType,
}) => {
  const reasons = useMemo(() => {
    if (opInfoTypes) {
      return sortAndFilterReasons(opInfoTypes.reasons, "TRAIN");
    }
    return [];
  }, [opInfoTypes?.reasons]);

  const { previewData, previewStatus } = useTrainInfoPreview({
    train: selectedTrain,
    formValues: currentFormValues,
  });

  const formErrors = formState.errors.trainForm;

  return (
    <Stack gap={5}>
      <BadgeText selectedTrain={selectedTrain} />
      <Stack gap={2}>
        {infrastructureData && (
          <Stack marginBottom={3} gap={2}>
            <SelectIncidentsForTrain items={infrastructureData} />
          </Stack>
        )}
        <TrainFormFieldWithCustomInput
          items={opInfoTypes.events.filter(
            (event) => event.group === EventGroup.TRAIN,
          )}
          formFieldType="event"
          optionDisabledPredicate={(event) =>
            previousEventType !== event.type &&
            !supportsMultipleEvents(event) &&
            trainInfoWithOpenState?.some((info) => info === event.type)
          }
        />
        <FormControlErrorMessage
          field={formErrors?.type as FieldErrorOrUndefined}
        />
        <TrainFormFieldWithCustomInput items={reasons} formFieldType="reason" />
        <FormControlErrorMessage
          field={formErrors?.reason?.type as FieldErrorOrUndefined}
        />
        <TrainFormFieldWithCustomInput
          items={filterActions(opInfoTypes.actions)}
          formFieldType="action"
        />
      </Stack>

      {currentFormValues?.type && (
        <Stack>
          <SubTypeInputs
            type={currentFormValues?.type}
            mode={mode}
            trainId={selectedTrain}
            uuid={uuid}
          />

          {currentFormValues.type !== TrainEventTypeEnum.TRAIN_CUSTOM && (
            <DropsLogTextInput
              mode={mode}
              defaultText={previewData?.internalMessage ?? ""}
            />
          )}
        </Stack>
      )}

      <Preview
        previewStatus={previewStatus}
        previewText={previewData?.internalMessage ?? ""}
        isFormValid={formState.isValid}
      />

      {formStateHasCustomField(currentFormValues) && (
        <DropsStaticAlert variant="info" id="infoBoxActionModal">
          Hendelsen inneholder egendefinert tekst. Sjekk at oppsummeringen ser
          riktig ut før du går videre.
        </DropsStaticAlert>
      )}
    </Stack>
  );
};

export { TrainInfoForm };
