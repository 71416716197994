import { z } from "zod";
import { CheckListType, companyId } from "../utils";
import { dateTimeSchemaForm } from "./formSchema";

const FillOutBaseSchema = z.object({
  fillOutId: z.string(),
  executedAt: dateTimeSchemaForm,
  companyId: z.custom<companyId>(),
  vehicleType: z.string(),
  checklistType: z.custom<CheckListType>(),
  rollingStock: z.string(),
  rollingStockType: z.string(),
  executor: z.string(),
  changedBy: z.string(),
  changedAt: z.string(),
  comment: z.string().nullable().optional(),
});

const HistoryVersionSchema = FillOutBaseSchema.extend({
  history: z.null().optional(),
});

const HistorySchema = z.record(z.string(), HistoryVersionSchema);

export const FillOutSchema = FillOutBaseSchema.extend({
  history: HistorySchema.optional(),
}).transform((val) => ({ ...val, rollingStockId: val.rollingStock }));

export const FilloutSchemaDetailed = FillOutSchema;

export type FillOut = z.infer<typeof FilloutSchemaDetailed>;

export const FillOutsSchema = z.object({
  fillOuts: z.array(FillOutSchema),
});

export type FillOuts = z.infer<typeof FillOutsSchema>;
