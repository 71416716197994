import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { TrainStop } from "features/CenterContent/VehicleDetails/TrainDetails/TrainRoute/TrainStop/TrainStop";
import { isStopWithNoPassing } from "features/CenterContent/VehicleDetails/TrainDetails/TrainRoute/TrainStop/utils";
import { TrainSummary } from "features/CenterContent/VehicleDetails/TrainDetails/TrainRoute/TrainSummary/TrainSummary";
import { FillrateResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainRoute/types";
import { useTrainRouteWithPeriodicRefetch } from "features/CenterContent/VehicleDetails/TrainDetails/useTrainRoute";
import { FC, useEffect } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";

const TrainRouteWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: flex-start;
  height: max-content;
  overflow-x: scroll;
  scrollbar-color: ${({ theme }) => theme.colorSelected} rgba(0, 0, 0, 0);
  padding-left: 24px;
`;

type TrainRouteProps = {
  trainNumber?: string;
  nominalDate?: string;
};

function performScrollOnCurrentLocation(length: number, lastIndex: number) {
  const trainContainerEl = document.getElementsByClassName("route-wrapper")[0];
  if (trainContainerEl) {
    const scrollUnit = trainContainerEl.scrollWidth / length;
    const scrollToStop = scrollUnit * lastIndex;
    trainContainerEl.scrollTo({
      top: 0,
      left: scrollToStop - scrollUnit * 5,
      behavior: "smooth",
    });
  }
}

const TrainRoute: FC<TrainRouteProps> = ({ trainNumber, nominalDate }) => {
  const { data, status, isSuccess } = useTrainRouteWithPeriodicRefetch({
    identifier: trainNumber,
    nominalDate,
  });

  const { status: fillRateStatus, data: fillRate } = useQuery({
    queryKey: ["fillRate", trainNumber, nominalDate],
    refetchOnWindowFocus: false,
    retry: 1,
    refetchInterval: 60000,
    queryFn: ({ signal }) =>
      queryFnGET<FillrateResponse>({
        signal,
        url: `${getBackendUrl()}/fillrate?trainId=${trainNumber}&nominalDate=${nominalDate}`,
      }),
  });

  useEffect(() => {
    if (status === "success") {
      if (data && data.stops.length > 0) {
        const lastArrivedIndex = data.stops
          .map((stop) => stop.isArrived)
          .lastIndexOf(true);

        performScrollOnCurrentLocation(data.stops.length, lastArrivedIndex + 1);
      }
    }
  }, [isSuccess]);

  const getTrainRoute = () => {
    switch (status) {
      case "pending":
        return <SkeletonLoader skeletonType="trainStops" />;
      case "success": {
        if (data && data.stops.length > 0) {
          const lastArrivedIndex = data.stops
            .map((s) => s.isArrived)
            .lastIndexOf(true);

          return (
            <>
              <TrainSummary
                train={data}
                fillrateFailure={fillRateStatus === "error"}
              />
              <TrainRouteWrapper className="route-wrapper">
                {data.stops.map((stop, index, array) => {
                  const nextStop = array[index + 1];
                  const isLastStop = index + 1 === array.length;
                  return (
                    <TrainStop
                      key={stop.stopId}
                      stop={stop}
                      isLastStop={isLastStop}
                      isStopWithoutPassing={isStopWithNoPassing(
                        index,
                        lastArrivedIndex,
                        stop,
                      )}
                      nextStop={nextStop}
                      nextStopIsStopWithoutPassing={isStopWithNoPassing(
                        index + 1,
                        lastArrivedIndex,
                        nextStop,
                      )}
                      fillrate={fillRate?.fillrate ?? null}
                    />
                  );
                })}
              </TrainRouteWrapper>
            </>
          );
        }
        return <NoDataMessage reason="Fant ingen rute for dette toget." />;
      }
      case "error":
      default:
        return <FailureMessage />;
    }
  };

  return getTrainRoute();
};

export default withErrorBoundary(TrainRoute);
