import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useExistingTrainCancelledEvents";
import { OriginSystem } from "features/History/HistoryDetail/fetchHistoryDetail";
import { z } from "zod";
import { InternalMessageCommon } from "./baseSchema";

export type InternalMessageResponse = {
  type: "GLOBAL_INTERNAL_INFO";
  uuid: string;
  updatedAt: string;
  referencedIncidentIds: string[];
  originSystem: OriginSystem;
  relevantStops: Stop[];
} & InternalMessageCommon;

export const InternalMessagesResponseSchema = z
  .custom<InternalMessageResponse>()
  .array()
  .nullish();
