import { useToast } from "@vygruppen/spor-react";
import { useEffect } from "react";

export function useToastOnError(isError: boolean, onClose: () => void) {
  const toast = useToast();
  useEffect(() => {
    if (!isError) return;
    toast({ variant: "error", text: `Noe gikk galt ved innlasting av data` });
    onClose();
  }, [isError]);
}
