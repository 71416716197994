import { Input, Tooltip } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import {
  EditOutline24Icon,
  InformationOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Button, Checkbox, Divider, Flex, Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { clamp } from "features/CenterContent/shared/utils";
import { getInfoTitleFromParams } from "features/VehicleSidebar/AffectedTrains/utils/utils";
import { useEffect, useState } from "react";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import styled, { useTheme } from "styled-components";
import { Preview } from "../../CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Preview";
import { TrainResumed } from "./useTrainResumed";

const NoLabelInput = styled(Input)`
  &&& {
    padding-top: 0px;
  }
`;

export const TrainResumedElement = ({
  trainResumed,
  trainResumedIsFinished,
}: {
  trainResumed: TrainResumed;
  trainResumedIsFinished: () => void;
}) => {
  const {
    eventType,
    trainIdentifier,
    affectedStopName,
    originStopName,
    destinationStopName,
    actualTime,
    scheduledTime,
    trainInformationUuid,
    trainInformationSummary,
  } = trainResumed;
  const minutesLate = () => {
    const actualTimeDate = new Date(actualTime);
    const scheduledTimeDate = new Date(scheduledTime);
    const differenceInMinutes = Math.floor(
      (actualTimeDate.getTime() - scheduledTimeDate.getTime()) / (1000 * 60),
    );
    return differenceInMinutes;
  };

  const MIN_VALUE = 1;
  const MAX_VALUE = 60 * 24;
  const minutesLateClamped = () => clamp(MIN_VALUE, MAX_VALUE, minutesLate());

  const theme = useTheme();
  const [askConfirmation, setAskConfirmation] = useState(false);
  const [registerDelay, setRegisterDelay] = useState(false);
  const [editDelay, setEditDelay] = useState(false);
  const [delay, setDelay] = useState(minutesLateClamped());
  const [inputDelay, setInputDelay] = useState<number | null>(null);

  const isTrainStopped = eventType === TrainEventTypeEnum.TRAIN_STOPPED;
  const delayPath = isTrainStopped
    ? "close-and-register-delay"
    : "change-to-delay";
  const buttonText = isTrainStopped ? "Friskmeld" : "Behandle";
  const closePath = registerDelay ? delayPath : "close";
  const description =
    !isTrainStopped && registerDelay
      ? "Er du sikker på at du vil endre hendelsen?"
      : "Er du sikker på at du vil friskmelde hendelsen?";
  const {
    mutate: closeTrainStatus,
    isError: isErrorClose,
    isSuccess: isSuccessClose,
  } = useMutation({
    mutationKey: ["closeTrainStatus", trainInformationUuid],
    mutationFn: () =>
      mutationFnPOST<string, { delay?: number }>(
        `${getBackendUrl()}/trainInformation/information/${trainInformationUuid}/${closePath}`,
        { delay },
      ),
  });

  const {
    mutate: ignoreTrain,
    isError: isErrorIgnore,
    isSuccess: isSuccessIgnore,
  } = useMutation({
    mutationKey: ["ignoreTrain", trainInformationUuid],
    mutationFn: () =>
      mutationFnPOST<string, undefined>(
        `${getBackendUrl()}/train-resumed/ignore-train/${trainResumed.trainInformationUuid}`,
      ),
  });

  const [showSpinner, setShowSpinner] = useState(false);

  const isError = isErrorClose || isErrorIgnore;
  const isSuccess = isSuccessClose || isSuccessIgnore;

  const eventTypeName = getInfoTitleFromParams(eventType, affectedStopName);

  useEffect(() => {
    if (!isSuccess) return;
    setTimeout(() => {
      setShowSpinner(false);
      setAskConfirmation(false);
      trainResumedIsFinished();
    }, 1000);
  }, [isSuccess]);

  useEffect(() => {
    if (!isError) return;
    setShowSpinner(false);
  }, [isError]);

  const onChangeCheckbox = () => {
    setRegisterDelay(!registerDelay);
    setEditDelay(false);
  };

  return (
    <Flex flexDir="column" gap={2} pt={2} px={2}>
      <Flex flexDir="column" py={1} gap={1}>
        <Flex gap={2}>
          <Flex flexDir="column" gap={0.5} width={0}>
            <Flex gap={1.5}>
              <Text whiteSpace="pre" variant="xs">
                <b>Tog kjører igjen</b>
              </Text>
              <Text
                variant="xs"
                whiteSpace="pre"
                style={{
                  color: theme.colorTextSecondary,
                }}
              >
                {minutesLate()} min forsinket
              </Text>
              <Tooltip
                hasArrow
                label={trainInformationSummary}
                minWidth="max-content"
              >
                <InformationOutline24Icon minWidth={4} minHeight={4} />
              </Tooltip>
            </Flex>
            <Text
              variant="xs"
              style={{
                width: "275px",
                // Behaviour: Use max 3 lines and show "..." at overflow
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {trainIdentifier.identifier} {originStopName} -{" "}
              {destinationStopName}
            </Text>
          </Flex>
        </Flex>
        <Flex>
          <DropsBadge semantics="warning">{eventTypeName}</DropsBadge>
        </Flex>
        <Flex gap="6px" alignItems="center" justifyContent="flex-end">
          {!askConfirmation && (
            <>
              <Tooltip
                label="Fjerner meldingen fra lista. Ny melding kommer ved evt neste passeringsvarsel."
                hasArrow
              >
                <Button
                  variant="tertiary"
                  isDisabled={showSpinner}
                  onClick={() => {
                    setShowSpinner(true);
                    ignoreTrain();
                  }}
                  alignSelf="end"
                  mr={0.5}
                  size="sm"
                >
                  <Text variant="sm">Ignorer</Text>
                </Button>
              </Tooltip>

              <Button
                variant="secondary"
                onClick={() => {
                  setAskConfirmation(true);
                }}
                alignSelf="end"
                mr="4px"
                isDisabled={!trainInformationUuid}
                size="sm"
              >
                <Text variant="sm">{buttonText}</Text>
              </Button>
            </>
          )}
        </Flex>
      </Flex>
      {askConfirmation && (
        <>
          <Divider />
          <Text>{description}</Text>
          <Flex gap={2} minHeight={6}>
            <Checkbox isChecked={registerDelay} onChange={onChangeCheckbox} />
            <Text my="auto" onClick={onChangeCheckbox}>
              Registrer forsinkelse
              {registerDelay ? ` (${delay} min)` : ``}
            </Text>
            {registerDelay && !editDelay && (
              <Button
                size="xs"
                variant="ghost"
                leftIcon={<EditOutline24Icon />}
                onClick={() => {
                  setEditDelay(true);
                }}
              >
                Endre
              </Button>
            )}
          </Flex>
          {registerDelay && !editDelay && (
            <Preview
              previewStatus="success"
              previewText={trainResumed.previewText.replace(
                // We make the preview using delay=1 in the backend, and replace
                // the delay in frontend to prevent further unnecessary api calls
                "1 min",
                `${delay} min`,
              )}
            />
          )}
          {!editDelay && (
            <Flex gap={2} alignSelf="end">
              <Button
                size="sm"
                variant="tertiary"
                onClick={() => {
                  setAskConfirmation(false);
                  setRegisterDelay(false);
                  setDelay(minutesLateClamped());
                }}
              >
                <Text variant="sm">Avbryt</Text>
              </Button>
              <Button
                size="sm"
                isLoading={showSpinner}
                onClick={() => {
                  setShowSpinner(true);
                  closeTrainStatus();
                }}
                variant="primary"
              >
                <Text variant="sm" fontWeight={700}>
                  Ja
                </Text>
              </Button>
            </Flex>
          )}
        </>
      )}
      {editDelay && (
        <>
          <DashedLine color={theme.colorSeparationLine} />
          <Flex flexDir="column" gap={1} mt={2}>
            <Text>Endre antall minutter forsinkelse</Text>
            <NoLabelInput
              type="number"
              placeholder={`${delay} (min)`}
              width="30%"
              _placeholder={{ opacity: 0.75, color: theme.colorTextMain }}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setInputDelay(
                    clamp(MIN_VALUE, MAX_VALUE, parseInt(e.target.value, 10)),
                  );
                } else {
                  setInputDelay(null);
                }
              }}
            />
            <Flex alignSelf="end">
              <Button
                onClick={() => {
                  if (inputDelay !== null) {
                    setDelay(inputDelay);
                  }
                  setInputDelay(null);
                  setEditDelay(false);
                }}
                variant="primary"
              >
                Lagre
              </Button>
            </Flex>
          </Flex>
        </>
      )}
      {isError && (
        <DropsStaticAlert variant="error">
          Noe gikk galt, vennligst prøv igjen
        </DropsStaticAlert>
      )}
      {isSuccessClose && (
        <DropsStaticAlert variant="success">
          Hendelse opprettet
        </DropsStaticAlert>
      )}
      <DashedLine color={theme.colorSeparationLine} />
    </Flex>
  );
};
