import { CommonInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/subTypeInputs/CommonInfrastructureEvent";
import { PlannedInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/subTypeInputs/PlannedInfrastructureEvent";
import { InfrastructureInformationType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { FC } from "react";

type FormProps = {
  type: InfrastructureInformationType;
};

export const SubTypeInputs: FC<FormProps> = ({ type }: FormProps) => {
  switch (type) {
    case "INFRASTRUCTURE_PLANNED_MAINTENANCE":
      return <PlannedInfrastructureEvent />;
    case "INFRASTRUCTURE_STRETCH_CLOSED":
    case "INFRASTRUCTURE_STRETCH_PARTIALLY_CLOSED":
    case "INFRASTRUCTURE_SLOW_DRIVING":
      return <CommonInfrastructureEvent />;
    default:
      return null;
  }
};
