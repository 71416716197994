import { useQuery } from "@tanstack/react-query";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { queryFnGET } from "api/tanStackQuery/helpers";

export type TrainRouteResponse = TrainRoute;

export type TrainRoute = {
  trainId: string;
  lineId: string | null;
  origin: string | null;
  destination: string | null;
  originTime: Date;
  destinationTime: Date;
  nominalDate: Date;
  cancelled: CancelledTrain;
  trainCategory: TrainCategory;
  stops: Stop[];
  plannedRouteStops: Stop[];
  currentTrainRouteSection: CurrentTrainRouteSection | null;
};

type CurrentTrainRouteSection = {
  fromStopId: string;
  toStopId: string | null;
};

export type Stop = {
  stopId: string;
  name: string;
  justPassingThrough: boolean;
  actualArrivalTime: Date | null;
  estimatedArrivalTime: Date | null;
  scheduledArrivalTime: Date | null;
  actualDepartureTime: Date | null;
  estimatedDepartureTime: Date | null;
  scheduledDepartureTime: Date | null;
  arrivalDelay: number | null;
  departureDelay: number | null;
  cancelled: CancelledStop;
  isArrived: boolean;
  track: string | null;
  isPassengerTrainAtStop: boolean;
};

type CancelledTrain = "CANCELLED" | "PARTIALLY_CANCELLED" | "NOT_CANCELLED";

type CancelledStop =
  | "CANCELLED"
  | "NOT_CANCELLED"
  | "ARRIVAL_CANCELLED"
  | "DEPARTURE_CANCELLED";

export type TrainCategory =
  | "Bus"
  | "FM"
  | "GR"
  | "GL"
  | "LT"
  | "DT"
  | "ET"
  | "IC"
  | "NT"
  | "LX"
  | "RT"
  | "GMB"
  | "CHT"
  | "PRK"
  | "TEK"
  | "T"
  | "UNKNOWN";

export type PartialTrainIdentifier = Partial<TrainIdentifier>;

export const useTrainRouteWithPeriodicRefetch = ({
  identifier,
  nominalDate,
  countryCode,
}: PartialTrainIdentifier) => {
  const { status, data, isSuccess } = useQuery({
    queryKey: ["trainRoutePeriodic", identifier, nominalDate, countryCode],
    refetchInterval: 60000,
    queryFn: ({ signal }) =>
      queryFnGET<TrainRouteResponse>({
        signal,
        url: `${getBackendUrl()}/trainRoute/train/${countryCode ?? "NO"}/${identifier}/${nominalDate}`,
      }),
  });

  return { status, data, isSuccess };
};

export const useTrainRoute = ({
  identifier,
  nominalDate,
  countryCode,
}: PartialTrainIdentifier) => {
  const { status, data, isSuccess, refetch } = useQuery({
    queryKey: ["trainRoute", identifier, nominalDate, countryCode],
    enabled: !!identifier && !!nominalDate,
    queryFn: ({ signal }) =>
      axiosClient
        .get<TrainRouteResponse>(
          `${getBackendUrl()}/trainRoute/train/${countryCode ?? "NO"}/${identifier}/${nominalDate}`,
          { signal },
        )
        .then((res) => {
          if (res.data) {
            const passengerTrainStops = res.data.stops.filter(
              (stop) => stop.isPassengerTrainAtStop,
            );
            const stopsToInclude =
              passengerTrainStops && passengerTrainStops.length > 0
                ? passengerTrainStops
                : res.data.stops;
            return {
              ...res.data,
              stops: stopsToInclude,
            };
          }
          return undefined;
        }),
  });

  return { status, data, isSuccess, refetch };
};
