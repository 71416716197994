import { Button, Checkbox, Flex, Heading, Text } from "@vygruppen/spor-react";
import {
  getLocalStorageTodoFilterSettings,
  setLocalStorageTodoFilterSettings,
} from "api/dropsLocalStorage";

import {
  FILTER_STATUS_LIST,
  isDefault,
  TodoFilterState,
  TodoFilterStatus,
  VEHICLE_SET_TYPES,
} from "features/TodoOploggSidebar/Todo/todoFilter/useTodoFilterState";
import { useEffect } from "react";
import { TODO_ROLES, UserRole } from "shared/types/roles";
import { useDropsRole } from "stores/useDropsRole";

import { SeperationLine } from "features/CenterContent/VehicleDetails/shared/SeperationLine";
import { useTheme } from "styled-components";
import {
  CheckboxListContainer,
  FilterContainer,
  PaddedContainer,
  StyledChoiceChip,
  WrappingContainer,
} from "../TodoStyles";

interface TodoFilterProps {
  todoFilterState: TodoFilterState;
  setTodoFilterState: (state: TodoFilterState) => void;
  clearTodoFilterState: () => void;
  toggleRoles: (role: UserRole) => void;
  toggleVehicleSetTypes: (vehicleSetType: string) => void;
  toggleTodoStatus: (todoStatus: TodoFilterStatus) => void;
}

const TodoFilter = ({
  todoFilterState,
  setTodoFilterState,
  clearTodoFilterState,
  toggleRoles,
  toggleVehicleSetTypes,
  toggleTodoStatus,
}: TodoFilterProps) => {
  const { role: currentRole } = useDropsRole();

  useEffect(() => {
    const savedFilterState = getLocalStorageTodoFilterSettings();
    if (
      savedFilterState &&
      JSON.stringify(savedFilterState) !== JSON.stringify(todoFilterState)
    ) {
      setTodoFilterState(savedFilterState);
    }
  }, []);

  useEffect(() => {
    setLocalStorageTodoFilterSettings(todoFilterState);
  }, [todoFilterState]);

  const theme = useTheme();

  return (
    <FilterContainer>
      <PaddedContainer>
        <Heading as="h2" variant="sm" fontWeight="bold">
          Filtrer på:
        </Heading>
      </PaddedContainer>

      <PaddedContainer>
        <Text>Rolle:</Text>
        <CheckboxListContainer>
          <Flex flex={2} flexDirection="column" gap={1}>
            {TODO_ROLES.map((role) => (
              <Checkbox
                key={role.value}
                isChecked={todoFilterState.roles.includes(role.value)}
                onChange={() => toggleRoles(role.value)}
              >
                {role.text}
              </Checkbox>
            ))}
          </Flex>
        </CheckboxListContainer>
      </PaddedContainer>
      <SeperationLine color={theme.colorSeparationLine} marginY={4} />
      <PaddedContainer>
        <Text>Togtype:</Text>
        <WrappingContainer>
          {VEHICLE_SET_TYPES.map(({ text, value }) => (
            <StyledChoiceChip
              key={text}
              size="sm"
              isChecked={todoFilterState.vehicleSetTypes.includes(value)}
              onChange={() => toggleVehicleSetTypes(value)}
            >
              {text}
            </StyledChoiceChip>
          ))}
        </WrappingContainer>
      </PaddedContainer>
      <SeperationLine color={theme.colorSeparationLine} marginY={4} />
      <PaddedContainer>
        <Text>Status:</Text>
        <WrappingContainer>
          {FILTER_STATUS_LIST.map(({ text, value }) => (
            <StyledChoiceChip
              key={text}
              size="sm"
              isChecked={todoFilterState.todoStatus.includes(value)}
              onChange={() => toggleTodoStatus(value)}
            >
              {text}
            </StyledChoiceChip>
          ))}
        </WrappingContainer>
      </PaddedContainer>
      {!isDefault(todoFilterState, currentRole) && (
        <>
          <SeperationLine color={theme.colorSeparationLine} marginY={4} />
          <Button onClick={clearTodoFilterState} variant="tertiary" size="xs">
            Nullstill filter
          </Button>
        </>
      )}
    </FilterContainer>
  );
};

export default TodoFilter;
