import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import {
  baseSchema,
  dateTimeSchemaResponse,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/baseSchema";
import { describedTypeSchema } from "features/CenterContent/shared/operationalInformation/utils";
import { z } from "zod";

const openEndedDurationSchema = z.object({
  type: z.literal("OPEN_ENDED"),
  from_time: dateTimeSchemaResponse,
  extension_time_in_minutes: z
    .number()
    .transform((extensionTime) => extensionTime.toString()),
});

const timedDurationSchema = z.object({
  type: z.literal("TIMED"),
  from_time: dateTimeSchemaResponse,
  to_time: dateTimeSchemaResponse,
});

const periodSchema = z.object({
  id: z.string().nullable(),
  fromTime: dateTimeSchemaResponse,
  toTime: dateTimeSchemaResponse,
});

const periodicDurationSchema = z.object({
  type: z.literal("PERIODIC"),
  periods: z.array(periodSchema),
});

const durationSchema = z.discriminatedUnion("type", [
  openEndedDurationSchema,
  timedDurationSchema,
  periodicDurationSchema,
]);

const responseSchema = baseSchema.and(
  z.object({
    uuid: z.string(),
    referenceId: z.string(),
    stretchName: z.string(),
    countryCode: z.literal("NO"),
    durations: durationSchema,
    message: z.string(),
    updatedAt: dateTimeSchemaResponse,
    title: z.string(),
    priority: z.number().int(),
    incidentId: z.string().nullish(),
    prognosis: describedTypeSchema
      .nullish()
      .and(
        z.object({
          estimatedResolved: dateTimeSchemaResponse.nullish(),
          estimatedInfoUpdate: dateTimeSchemaResponse.nullish(),
        }),
      )
      .nullable(),
    internalMessages: z
      .custom<InternalMessageResponse[]>()
      .or(z.array(z.any()).nullish()),
    actionCard: z.object({ type: z.string().nullable() }).nullable(),
    isirkId: z.string().nullable(),
    redirectStretches: z
      .array(
        z.object({
          name: z.string(),
          legs: z.array(z.object({ fromStop: z.string(), toStop: z.string() })),
        }),
      )
      .max(1),
  }),
);

export type InfrastructureResponse = z.infer<typeof responseSchema>;

export const infrastructureEventResponseSchema = z.object({
  infrastructureInformation: responseSchema,
});

export const upcomingEventsResponseSchema = z.array(responseSchema);

export type InfrastructureEventType = z.infer<
  typeof infrastructureEventResponseSchema
>;

export type InfrastructureEventResponse = {
  [uuid: string]: InfrastructureEventType;
};

export type PeriodicDuration = z.infer<typeof periodicDurationSchema>;

export type Duration = z.infer<typeof durationSchema>;
