export const sum = (numbers: number[]) => {
  if (numbers.length === 0) return 0;
  return numbers.reduce((a, b) => a + b);
};

export const unique = (list: any[]) => [...new Set(list)];

export const sortDate = (a: Date, b: Date) => b.getTime() - a.getTime();

/**
 * Splits the original collection into pair of lists,
 * where first list contains elements for which predicate yielded true,
 * while second list contains elements for which predicate yielded false.
 * @param list
 * @param filter
 */
export const partition = <T>(list: T[], predicate: (element: T) => boolean) => {
  const [filtered, unfiltered]: [T[], T[]] = [[], []];
  list.forEach((it) => (predicate(it) ? filtered : unfiltered).push(it));
  return [filtered, unfiltered];
};
