import {
  ZonedDateTime,
  fromDate,
  getLocalTimeZone,
} from "@internationalized/date";
import { z } from "zod";

export enum CheckListType {
  BRAKE_TEST = "BRAKE_TEST", // Bremseprøve
  IKS = "IKS", // Innsett (IKS)
  UKS = "UKS", // Uttak (UKS)
}

export enum RollingSockType {
  VEHICLE_SET = "VEHICLE_SET",
  VEHICLE = "VEHICLE",
}

export enum companyId {
  VYGRUPPEN = "110",
  VYTOG = "120",
  CARGONET = "121",
}

export const getCompanyName = (id: companyId) => {
  switch (id) {
    case companyId.VYGRUPPEN:
      return "Vygruppen (Øst)";
    case companyId.VYTOG:
      return "Vy Tog (Vest)";
    case companyId.CARGONET:
      return "CargoNet";
    default:
      throw new Error("Invalid company id");
  }
};

export const getChecklistText = (type: CheckListType | CheckListType) => {
  switch (type) {
    case "BRAKE_TEST":
      return "Bremseprøve";
    case "IKS":
      return "Innsett (IKS)";
    case "UKS":
      return "Uttak (UKS)";
    default:
      throw new Error("Invalid company id");
  }
};

export const dateTimeSchemaResponse = z.coerce
  .date()
  .transform((time) => fromDate(time, getLocalTimeZone()));
export type DateTimeResponse = z.infer<typeof dateTimeSchemaResponse>;

export const dateTimeSchemaForm = z.custom<ZonedDateTime>();
export type DateTimeForm = z.infer<typeof dateTimeSchemaForm>;

export const dateTimeSchemaRequest = z
  .custom<ZonedDateTime>()
  .transform((date) => date.toString());
export type DateTimeRequest = z.infer<typeof dateTimeSchemaRequest>;
