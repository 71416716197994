import {
  TownFill24Icon,
  TownOutline24Icon,
  TrainFill24Icon,
  TrainOutline24Icon,
  WarningOutline24Icon,
} from "@vygruppen/spor-icon-react";
import {
  Button,
  ChoiceChip,
  DatePicker,
  Divider,
  FormControl,
  HStack,
  SearchInput,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { FilterCardSelect } from "features/History/Filter/FilterCardSelect";
import { FilterData } from "features/History/Filter/FilterData";
import { calendarDateToday } from "features/VehicleSidebar/VehicleList/VehicleList";
import {
  InfrastructureIncidentIcon,
  InfrastructureIncidentIconTabActive,
} from "shared/icons/InfrastructureIncidentIcon";
import { DefaultTheme, useTheme } from "styled-components";
import { ResponsiveHStack } from "./utils/HistoryCustomTableStyle";

const VerticalDivider = ({ theme }: { theme: DefaultTheme }) => (
  <Divider
    height={6}
    orientation="vertical"
    borderWidth="1px"
    marginX={0.5}
    boxSizing="border-box"
    borderColor={theme.colorSeparationLine}
  />
);

type FilterProps = {
  filter: FilterData;
  setFilter: (filter: FilterData) => void;
};

export const Filter = ({ filter, setFilter }: FilterProps) => {
  const theme = useTheme();

  return (
    <VStack
      mt={7}
      mr={1.5}
      mb={2}
      ml={1.5}
      alignItems="start"
      gap={3}
      overflowX="auto"
    >
      <HStack>
        <SearchInput
          width="440px"
          label="Søk etter tognummer, stasjon, stikkord"
          value={filter.searchString}
          onChange={(e) => setFilter(filter.updateSearchString(e.target.value))}
        />
        <FormControl width="auto">
          <DatePicker
            label="Dato"
            variant="base"
            value={filter.date}
            onChange={(value) =>
              setFilter(filter.updateDate(value ?? calendarDateToday()))
            }
          />
        </FormControl>
      </HStack>
      <ResponsiveHStack gap={1.5}>
        <HStack>
          <Text whiteSpace="nowrap">Filtrér på:</Text>
          <ChoiceChip
            isChecked={filter.actions.includes("train")}
            onChange={() => setFilter(filter.updateAction("train"))}
            icon={{
              default: <TrainOutline24Icon />,
              checked: <TrainFill24Icon />,
            }}
          >
            <Text whiteSpace="nowrap">Tog</Text>
          </ChoiceChip>
          <ChoiceChip
            isChecked={filter.actions.includes("infrastructure")}
            onChange={() => setFilter(filter.updateAction("infrastructure"))}
            icon={{
              default: <InfrastructureIncidentIcon />,
              checked: <InfrastructureIncidentIconTabActive />,
            }}
          >
            <Text whiteSpace="nowrap">Infrastruktur</Text>
          </ChoiceChip>
          <ChoiceChip
            isChecked={filter.actions.includes("station")}
            onChange={() => setFilter(filter.updateAction("station"))}
            icon={{
              default: <TownOutline24Icon />,
              checked: <TownFill24Icon />,
            }}
          >
            <Text whiteSpace="nowrap">Stasjon</Text>
          </ChoiceChip>
          <ChoiceChip
            isChecked={filter.actions.includes("global")}
            onChange={() => setFilter(filter.updateAction("global"))}
            icon={{
              default: <WarningOutline24Icon />,
              checked: <WarningOutline24Icon />,
            }}
          >
            <Text whiteSpace="nowrap">Global driftsmelding</Text>
          </ChoiceChip>

          <div className="DontShowIfSmallWidth">
            <VerticalDivider theme={theme} />
          </div>
        </HStack>

        <HStack>
          <FilterCardSelect
            label="Vis skjulte"
            selectedAmount={[filter.plannedEvents, filter.groupTravel].reduce(
              (sum, current) => (current ? sum + 1 : sum),
              0,
            )}
          >
            <ChoiceChip
              isChecked={filter.plannedEvents}
              onChange={() =>
                setFilter(filter.updatePlannedEvent(!filter.plannedEvents))
              }
            >
              Vis planlagte hendelser
            </ChoiceChip>

            <ChoiceChip
              isChecked={filter.groupTravel}
              onChange={() =>
                setFilter(filter.updateGroupTravel(!filter.groupTravel))
              }
            >
              Vis gruppereise/assistanse
            </ChoiceChip>
          </FilterCardSelect>

          <VerticalDivider theme={theme} />

          <FilterCardSelect
            label="Tilstand"
            selectedAmount={filter.condition !== null ? 1 : 0}
          >
            <ChoiceChip
              isChecked={filter.condition === "open"}
              onChange={() => setFilter(filter.updateCondition("open"))}
            >
              Åpen
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.condition === "closed"}
              onChange={() => setFilter(filter.updateCondition("closed"))}
            >
              Friskmeldt
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.condition === "unhandled"}
              onChange={() => setFilter(filter.updateCondition("unhandled"))}
            >
              Må behandles
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.condition === "replaced"}
              onChange={() => setFilter(filter.updateCondition("replaced"))}
            >
              Erstattet
            </ChoiceChip>
          </FilterCardSelect>
          <FilterCardSelect
            label="Synlighet"
            selectedAmount={filter.visibility !== null ? 1 : 0}
          >
            <ChoiceChip
              isChecked={filter.visibility === "visible"}
              onChange={() => setFilter(filter.updateVisibility("visible"))}
            >
              Synlig nå
            </ChoiceChip>
            <ChoiceChip
              isChecked={filter.visibility === "outdated"}
              onChange={() => setFilter(filter.updateVisibility("outdated"))}
            >
              Utgått
            </ChoiceChip>
          </FilterCardSelect>
          <FilterCardSelect
            label="Togserie"
            selectedAmount={filter.trainSeries.length}
          >
            <ChoiceChip
              isChecked={false}
              onChange={() => setFilter(filter.selectAllTrainIds())}
            >
              Velg alle
            </ChoiceChip>
            {filter.allTrainIds.map((trainId) => (
              <ChoiceChip
                key={trainId}
                isChecked={filter.trainSeries.includes(trainId)}
                onChange={() => setFilter(filter.updateTrainIds(trainId))}
                marginBottom="auto"
              >
                {trainId}
              </ChoiceChip>
            ))}
            <HStack justifyContent="flex-end" width="100%" marginTop={2}>
              <Button
                size="xs"
                justifySelf="right"
                variant="tertiary"
                onClick={() => setFilter(filter.deselectAllTrainIds())}
              >
                Fjern valgte
              </Button>
            </HStack>
          </FilterCardSelect>
          {!filter.isEmpty() && (
            <Button
              variant="ghost"
              size="xs"
              onClick={() => {
                setFilter(new FilterData());
              }}
            >
              Fjern filtre
            </Button>
          )}
        </HStack>
      </ResponsiveHStack>
    </VStack>
  );
};
