/* eslint-disable no-prototype-builtins */

export const capitalize = (text: string) => {
  if (!text || text.length === 0) return text;
  return text.slice(0, 1).toUpperCase() + text.slice(1);
};

export const getFieldFromNestedObject = <V>(
  obj: object | undefined,
  path: string,
): V | undefined => {
  if (!obj) {
    return undefined;
  }

  const [first, ...rest] = path.split(".");

  if (rest.length === 0) {
    if (!obj.hasOwnProperty(first)) {
      return undefined;
    }

    return obj[first as keyof object] as V;
  }

  if (!obj.hasOwnProperty(first)) {
    return undefined;
  }

  return getFieldFromNestedObject(obj[first as keyof object], rest.join("."));
};

const snakeCase = (str: string) => str.replace(/([A-Z])/g, "_$1").toLowerCase();

type CamelToSnake<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnake<U>}`
  : S;

export type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnake<string & K>]: T[K];
};

export const keysSnakeCase = <T extends Record<string, any>>(
  obj: T,
): KeysToSnakeCase<T> =>
  Object.keys(obj).reduce((acc, key) => {
    const snakeKey = snakeCase(key) as keyof KeysToSnakeCase<T>; // Convert to snake_case and assert type
    acc[snakeKey] = obj[key]; // Use type assertion to safely assign the value
    return acc;
  }, {} as KeysToSnakeCase<T>);

export const keysSnakeCaseOptional = <
  T extends Record<string, any> | undefined,
>(
  obj: T,
): KeysToSnakeCase<T> | undefined => (obj ? keysSnakeCase(obj) : undefined);

// All or none fields must be included
export type AllOrNone<T> =
  | {
      [K in keyof T]?: undefined;
    }
  | {
      [K in keyof T]-?: T[K];
    };

// string must end with Suffix
export type EndsWith<Suffix extends string> = `${string}${Suffix}`;

type AtLeastOne<T> = {
  [K in keyof T]-?: Required<Pick<T, K>> &
    Partial<Pick<T, Exclude<keyof T, K>>>;
}[keyof T];

type AtMostOne<T> = {
  [K in keyof T]: { [P in K]: T[K] } & {
    [P in Exclude<keyof T, K>]?: never;
  };
}[keyof T];

// Exactly one field is required
export type SingleOption<T extends object> = AtMostOne<T> & AtLeastOne<T>;

export type Nullable<T> = T | null;
export type Nullish<T> = T | null | undefined;
