import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { InfrastructureEventType } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import {
  GroupedTrainFormSchema,
  TrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { singleTrainFormToRequestBody } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { TrainInfoMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { RepresentativeTrainIdentifier } from "features/CenterContent/VehicleDetails/shared/TrainInfoBatchModal";
import { TrainsGroupedBySeries } from "features/History/groupTrainSeries";
import { Semantics } from "shared/components/badge/DropsBadge";

export const getInfoTitleFromParams = (
  type: string,
  fromStop: string | null | undefined,
  title?: string,
) => {
  const prefacedFromStop = (preface: string) => {
    if (!fromStop) {
      return "";
    }
    return ` ${preface} ${fromStop}`;
  };

  switch (type) {
    case "TRAIN_CANCELLED":
      return `Innstilt${prefacedFromStop("fra")}`; // Tog innstilt
    case "TRAIN_STOPPING_EXTRA_AT_STATION":
      return `Stopper ekstra${prefacedFromStop("på")}`; // Stopper ekstra på stasjon
    case "TRAIN_DELAYED":
      return `Forsinket${prefacedFromStop("fra")}`; // Tog forsinket
    case "TRAIN_CAPACITY_REDUCED":
      return "Redusert kapasitet"; // Redusert setekapasitet
    case "TRAIN_CAPACITY_INCREASED":
      return "Økt kapasitet"; // Økt setekapasitet
    case "TRAIN_STOPPED":
      return `Står${prefacedFromStop("på")}`; // Tog står
    case "TRAIN_NOT_STOPPING_AT_STATION":
      return `Stopper ikke${prefacedFromStop("på")}`; // Stopper ikke på stasjon
    case "TRAIN_LATE_TO_TRACK":
      return "Sent satt opp"; // Sent satt opp (ikke forsinket)
    case "TRAIN_DELAY_EXPECTED":
      return "Forsinkelse forventes"; // Forsinkelse forventes
    case "TRAIN_MISSING_PRODUCT":
      return "Produkt mangler"; // Produkt mangler
    case "TRAIN_CLOSED_SET":
      return "Avstengt togsett"; // Avstengt togsett
    default:
      return title;
  }
};

const eventTypeToSemanticsMap: { [key: string]: Semantics } = {
  TRAIN_CANCELLED: "alarm",
  TRAIN_NOT_STOPPING_AT_STATION: "alarm",
  TRAIN_CHANGED_ROUTE: "alarm",
  TRAIN_GENERAL_WARNING: "alarm",
  TRAIN_DELAYED: "warning",
  TRAIN_DELAY_EXPECTED: "warning",
  TRAIN_STOPPED: "warning",
  TRAIN_STOPPING_AND_WAITING_AT_STATION: "warning",
  TRAIN_STOPPING_EXTRA_AT_STATION: "warning",
  TRAIN_LATE_TO_TRACK: "info",
  TRAIN_CAPACITY_REDUCED: "info",
  TRAIN_CAPACITY_INCREASED: "info",
  TRAIN_CLOSED_SET: "info",
  TRAIN_MISSING_PRODUCT: "info",
  TRAIN_GROUP_TRAVEL: "info",
  TRAIN_WHEELCHAIR_PASSENGER: "info",
  TRAIN_REDUCED_MOBILITY_PASSENGER: "info",
  TRAIN_VISUALLY_IMPAIRED_PASSENGER: "info",
  TRAIN_GENERAL_INFO: "info",
  TRAIN_UNKNOWN_EVENT: "info",
};

export const getEventSemantics = (key: string): Semantics =>
  eventTypeToSemanticsMap[key] || "info";
/**
 * Create shorter names for train info, with fallback to title. CLOSED events will be
 * translated to "Friskmeldt".
 */
export const getInfoTitle = (trainInfo: TrainInfoMessage) => {
  const { type, fromStop, title } = trainInfo;
  return getInfoTitleFromParams(type, fromStop, title);
};

// Find last digit, as last char in identifier is not necessarily last char in identifier
const getDirectionDigit = (identifier: string) => {
  const lastDigit = identifier
    .split("")
    .findLast((c) => !Number.isNaN(parseInt(c, 10)));
  return lastDigit !== undefined ? parseInt(lastDigit, 10) : -1;
};

const trainIdIsEven = (trainId: string) => {
  const directionDigit = getDirectionDigit(trainId);
  if (directionDigit === -1) {
    return false;
  }
  return directionDigit % 2 === 0;
};

// Assume last digit in given trainIdentifier dictates direction
export const findRepresentativeTrainId = (
  trains: AffectedTrain[],
  evenOrOdd: "even" | "odd",
): RepresentativeTrainIdentifier | undefined => {
  const evenOrOddTrainId = trains.find((train) =>
    evenOrOdd === "even"
      ? trainIdIsEven(train.trainId.identifier)
      : !trainIdIsEven(train.trainId.identifier),
  );

  return evenOrOddTrainId
    ? {
        trainId: evenOrOddTrainId.trainId.identifier,
        nominalDate: evenOrOddTrainId.trainId.nominalDate,
      }
    : undefined;
};

export type InfrastructureConsequence =
  | "TRAIN_CANCELLATIONS"
  | "TRAIN_DELAYS"
  | "TRAIN_CANCELLATIONS_AND_DELAYS";

export const mapInfrastructureConsequence = (
  infraConsequence: InfrastructureConsequence,
): TrainEventTypeEnum.TRAIN_CANCELLED | TrainEventTypeEnum.TRAIN_DELAYED => {
  switch (infraConsequence) {
    case "TRAIN_CANCELLATIONS":
      return TrainEventTypeEnum.TRAIN_CANCELLED;
    case "TRAIN_DELAYS":
      return TrainEventTypeEnum.TRAIN_DELAYED;
    default:
      return TrainEventTypeEnum.TRAIN_CANCELLED;
  }
};

export const mapInfrastructureEventToTrainEvent = (
  data: InfrastructureEventType,
) => {
  const { consequence, action, reason } = data.infrastructureInformation;

  const schema: Partial<TrainFormSchema> = {
    type: mapInfrastructureConsequence(
      consequence?.type as InfrastructureConsequence,
    ),
    action,
    reason: reason ?? undefined,
    dropsLogText: { enabled: false, texts: { NOB: "" } },
    incidentId: data.infrastructureInformation.incidentId ?? undefined,
  };

  return schema;
};

export const trainIdKey = (trainId: TrainIdentifier) =>
  `${trainId.countryCode}_${trainId.identifier}_${trainId.nominalDate}`;

// Constructs an initial batch form state from the selected train groups
export function createInitialBatchFormStateFromTrainSeries(
  trainSeries: TrainsGroupedBySeries,
  infrastructureEvent?: InfrastructureEventType,
): GroupedTrainFormSchema {
  const partialFormFromInfrastructureEvent = infrastructureEvent
    ? mapInfrastructureEventToTrainEvent(infrastructureEvent)
    : {};

  const groupedFormState = Object.entries(trainSeries).map(
    ([trainSeriesKey, affectedTrains]) => {
      const [oddTrains, evenTrains] = affectedTrains.reduce(
        ([odd, even]: AffectedTrain[][], current: AffectedTrain) =>
          trainIdIsEven(current.trainId.identifier)
            ? [odd, [...even, current]]
            : [[...odd, current], even],
        [[], []],
      );

      const oddGroup = oddTrains.length
        ? {
            trainSeries: trainSeriesKey,
            groupLabel: trainSeriesKey,
            route: oddTrains[0].eventStretchName, // TODO: Should be route but we don't have that data here yet
            direction: "ODD",
            trainIdsInGroup: oddTrains.map<TrainIdentifier>(
              (train) => train.trainId,
            ),
            trainForm: {
              type: undefined,
              reason: { type: "" },
              action: { type: "" },
              dropsLogText: { enabled: false, texts: { NOB: "" } },
              ...partialFormFromInfrastructureEvent,
            },
          }
        : undefined;

      const evenGroup = evenTrains.length
        ? {
            trainSeries: trainSeriesKey,
            groupLabel: trainSeriesKey,
            route: evenTrains[0].eventStretchName, // TODO: Should be route but we don't have that data here yet
            direction: "EVEN",
            trainIdsInGroup: evenTrains.map<TrainIdentifier>(
              (train) => train.trainId,
            ),
            trainForm: {
              type: undefined,
              reason: { type: "" },
              action: { type: "" },
              dropsLogText: { enabled: false, texts: { NOB: "" } },
              ...partialFormFromInfrastructureEvent,
            },
          }
        : undefined;

      return [
        trainSeriesKey,
        [oddGroup, evenGroup].filter((g) => g !== undefined),
      ];
    },
  );

  return Object.fromEntries(groupedFormState);
}

export const batchTrainFormToRequestBody = (
  formState: GroupedTrainFormSchema,
): TrainInfoRequest[] => {
  const groups = Object.values(formState).flatMap((a) => a);

  return groups
    .flatMap((group) =>
      group.trainIdsInGroup.map((trainId) =>
        singleTrainFormToRequestBody(group.trainForm, trainId),
      ),
    )
    .filter((body) => body !== null);
};
