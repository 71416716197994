import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { OperationalVehiclesCollection } from "features/VehicleSidebar/VehicleList/AlternativeTransportList/AlternativeTransportList";

export const useVyatData = () => {
  const { data: vyAtData, isError } = useQuery({
    queryKey: ["vyat"],
    refetchInterval: 30000,
    queryFn: async ({ signal }) =>
      queryFnGET<OperationalVehiclesCollection>({
        signal,
        url: `${getBackendUrl()}/bus/operational-vehicles/NO`,
      }),
  });

  return { vyAtData, isError };
};
