import {
  CalendarDateTime,
  toCalendarDate,
  toTime,
} from "@internationalized/date";
import {
  Box,
  DatePicker,
  FormControl,
  Text,
  TimePicker,
} from "@vygruppen/spor-react";
import { GlobalFormSchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { FC } from "react";
import { UseFormReturn, UseFormSetValue } from "react-hook-form";

const Title = () => (
  <Text variant="xs" gridColumn="span 2">
    Synlighet:
  </Text>
);

type DateTimeInputProps = {
  visibilityFromTime: CalendarDateTime;
  setValue: UseFormSetValue<GlobalFormSchema>;
  allowEdit: boolean;
};

const DateTimeInput: FC<DateTimeInputProps> = ({
  visibilityFromTime,
  setValue,
  allowEdit,
}) =>
  allowEdit ? (
    <>
      <Title />
      <FormControl>
        <DatePicker
          withPortal={false}
          width="100%"
          variant="base"
          label="Fra"
          value={toCalendarDate(visibilityFromTime)}
          onChange={(date) => {
            if (date !== null) {
              setValue(
                "globalForm.visibility.fromTime",
                visibilityFromTime.set({ ...date }),
              );
            }
          }}
        />
      </FormControl>
      <TimePicker
        width="100%"
        label="Starttidspunkt"
        value={toTime(visibilityFromTime)}
        minuteInterval={5}
        onChange={(time) => {
          if (time !== null) {
            setValue(
              "globalForm.visibility.fromTime",
              visibilityFromTime.set({ ...time }),
            );
          }
        }}
      />
    </>
  ) : null;

const RelatedToIncidentText = () => (
  <>
    <Title />
    <Text variant="xs" fontWeight="bold" gridColumn="span 2">
      Følger synlighet til tilhørende infrastrukturmelding
    </Text>
  </>
);

type VisibilityInputProps = {
  isRelatedToIncident: boolean;
  allowEdit: boolean;
  formMethods: UseFormReturn<GlobalFormSchema>;
};

export const VisibilityInput: FC<VisibilityInputProps> = ({
  isRelatedToIncident,
  allowEdit,
  formMethods,
}) => {
  const { watch, setValue } = formMethods;
  const visibilityFromTime = watch("globalForm.visibility.fromTime");

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      rowGap={1}
      columnGap={2}
      width="100%"
      paddingY={2}
    >
      {isRelatedToIncident ? (
        <RelatedToIncidentText />
      ) : (
        <DateTimeInput
          visibilityFromTime={visibilityFromTime}
          setValue={setValue}
          allowEdit={allowEdit}
        />
      )}
    </Box>
  );
};
