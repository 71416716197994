import {
  Preview,
  PreviewStatus,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Preview";
import { FC } from "react";

type BatchPreviewProps = {
  previewStatus: PreviewStatus;
  previewTexts: string[] | undefined;
  isFormValid?: boolean;
};

export const BatchPreview: FC<BatchPreviewProps> = ({
  previewTexts = [],
  previewStatus,
  isFormValid = true,
}) => {
  const titleBatch = `Oppsummeringer (${previewTexts.length})`;
  const useTitleBatch = previewStatus === "success" && previewTexts.length > 1;
  return (
    <Preview
      previewText={previewTexts.join("\n\n")}
      previewStatus={previewStatus}
      isFormValid={isFormValid}
      title={useTitleBatch ? titleBatch : undefined}
    />
  );
};
