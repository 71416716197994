import { getBackendUrl } from "api/common";
import { storeLocalUserData } from "api/dropsLocalStorage";
import { getCognitoBaseUrl, getCognitoClientId } from "app/Auth/authConfig";
import { authorizationHeader } from "app/Auth/axiosAuthInterceptor";
import axios, { AxiosError } from "axios";
import { Buffer } from "buffer";
import { log, LogLevel } from "logging/datadogBrowserLogs";
import pkceChallenge from "pkce-challenge";

export const resetSession = () => {
  localStorage.removeItem("ACCESS_TOKEN");
  localStorage.removeItem("REFRESH_TOKEN");
  localStorage.removeItem("COGNITO_STATE_PARAM");
  localStorage.removeItem("username");
  localStorage.removeItem("fullName");
  sessionStorage.removeItem("historyFilterData");
  sessionStorage.removeItem("openTabs");
  sessionStorage.removeItem("unhandledTrainsFilter");
};

export const resetSessionAndRedirectToRoot = () => {
  resetSession();
  window.location.href = "/";
};

export const logout = (): void => {
  const initiateLogoutUrl = `${getCognitoBaseUrl()}/logout?client_id=${getCognitoClientId()}&logout_uri=${
    window.location.origin
  }`;

  resetSession();
  window.location.assign(initiateLogoutUrl);
};

export const isExpired = (token: string) => {
  try {
    const jwtPayload = token.split(".")[1] || "";
    const decoded = JSON.parse(Buffer.from(jwtPayload, "base64").toString());
    return decoded.exp < Date.now() / 1000;
  } catch (e) {
    return true;
  }
};

export const getPKCETokens = async () => {
  const pkceChallengeObject = await pkceChallenge();
  localStorage.setItem("PKCE_CHALLENGE", pkceChallengeObject.code_challenge);
  localStorage.setItem("PKCE_CODE_VERIFIER", pkceChallengeObject.code_verifier);
  return pkceChallengeObject;
};

/**
 * A simple axios client intended only for using when checking the user permissions.
 * We need this axios client because the other axios clients has an interceptor for retrying
 * requests upon receiving 4xx from the server.
 */
const authAxiosClient = axios.create();

authAxiosClient.interceptors.request.use((config) => {
  config.headers.Authorization = authorizationHeader().Authorization;
  return config;
});

authAxiosClient.interceptors.response.use(
  (response) => response,
  (error: Error | AxiosError) => {
    if (axios.isAxiosError(error)) {
      log(
        LogLevel.error,
        "authAxiosClient",
        `Call to ${error.response?.request?.responseURL} failed with status ${error?.response?.status} - ${error?.response?.statusText}: ${error?.message}`,
      );

      return Promise.reject(error);
    }

    log(
      LogLevel.error,
      "authAxiosClient",
      `A generic non-axios error occurred: [NAME]: ${error.name}, [MESSAGE]: ${error.message}, ${error.stack}`,
    );
    return Promise.reject(error);
  },
);

type DynamoDBUser = {
  email: string;
  username: string;
  role: string;
  phoneNumber?: string;
  loggedIn?: boolean;
  lastUpdated?: string;
  isAdmin?: boolean;
  fullName?: string;
  dateCreated?: string;
};

type Permission =
  | {
      permission: false;
      reason?: {
        type: "ERR_NETWORK" | "ERR_AXIOS" | "ERR_AUTH";
        message?: string;
      };
    }
  | { permission: true };

export const checkUserPermissions = (): Promise<Permission> =>
  authAxiosClient
    .get<DynamoDBUser>(`${getBackendUrl()}/admin/userInfo`)
    .then((user) => {
      const userHasPermissions = user.data != null;
      log(
        LogLevel.info,
        "authAxiosClient",
        `User has permissions: ${userHasPermissions} - username: ${user.data.username}, isAdmin: ${user.data.isAdmin}, role: ${user.data.role}.`,
      );

      // Store username in local storage if user is authorized
      if (userHasPermissions) {
        storeLocalUserData(user.data.username, user.data.email);
      }

      if (userHasPermissions) {
        return { permission: true };
      }
      return { permission: false };
    })
    .catch((error: Error | AxiosError) => {
      let errorMessage;
      if (axios.isAxiosError(error)) {
        errorMessage = `checkUserPermissions failed with reason '${error}', code=${error?.code}, message=${error?.message}, config.url=${error?.config?.url}`;
        log(LogLevel.error, "authAxiosClient", errorMessage);
        if (error.code === "ERR_NETWORK") {
          return {
            permission: false,
            reason: {
              type: "ERR_NETWORK",
              message: errorMessage,
            },
          };
        }
        return {
          permission: false,
          reason: {
            type: "ERR_AXIOS",
            message: errorMessage,
          },
        };
      }

      errorMessage = `A generic non-axios error occurred: [NAME]: ${error.name}, [MESSAGE]: ${error.message}, ${error.stack}`;
      log(LogLevel.error, "authAxiosClient", errorMessage);
      return {
        permission: false,
        reason: {
          type: "ERR_AUTH",
          message: errorMessage,
        },
      };
    });
