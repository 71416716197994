import { useState } from "react";

export const useFilter = <T extends string>(filterOptions: T[]) => {
  const [selectedFilter, setSelectedFilter] = useState<T | "">("");

  const handleFilterChange = (filter: T) => {
    if (filter === selectedFilter) {
      setSelectedFilter("");
      return;
    }
    setSelectedFilter(filter);
  };

  return {
    selectedFilter,
    handleFilterChange,
    filterOptions,
  };
};
