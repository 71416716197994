import { Tooltip } from "@chakra-ui/react";
import { Checkbox, HStack, VStack } from "@vygruppen/spor-react";
import { isToday } from "date-fns";
import { AffectedTrain as AffectedTrainType } from "features/CenterContent/RoleContent/Vaktleder/types";
import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { AffectedTrainGrid } from "features/VehicleSidebar/AffectedTrains/styles";
import { makeTimeDisplay } from "features/VehicleSidebar/AffectedTrains/utils/timeFormatting";
import {
  getEventSemantics,
  getInfoTitle,
} from "features/VehicleSidebar/AffectedTrains/utils/utils";
import { FC } from "react";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { Text } from "shared/components/typography/TitleStyles";
import { InfrastructureIncidentIcon } from "shared/icons/InfrastructureIncidentIcon";
import { formatDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";

type AffectedTrainProps = {
  train: AffectedTrainType;
  selectedTrains: AffectedTrainType[];
  updateSelectedTrains: (train: AffectedTrainType) => void;
  isPending: boolean;
  currentTime: Date;
};

const AffectedTrain: FC<AffectedTrainProps> = ({
  train,
  selectedTrains,
  updateSelectedTrains,
  isPending,
  currentTime,
}) => {
  const theme = useTheme();
  const trainDateIsToday = isToday(new Date(train.trainId.nominalDate));
  const additionalInfo = train.otherOperationalTrainInformation.at(0);
  const newestVersion = additionalInfo?.versions.at(0);
  const newestVersionIsOpen = newestVersion?.state === "OPEN";

  const inSelectedBatch = selectedTrains.some(
    ({ trainId, incidentId }) =>
      train.trainId.identifier === trainId.identifier &&
      train.incidentId === incidentId &&
      train.trainId.nominalDate === trainId.nominalDate,
  );

  const matchSelectedIncidentId =
    selectedTrains.length > 0
      ? train.incidentId === selectedTrains[0].incidentId
      : true;

  const timeDisplay = makeTimeDisplay(train, currentTime);

  return (
    <>
      <AffectedTrainGrid $borderColor={theme.colorBorder}>
        <Tooltip
          hasArrow
          placement="right"
          label="Ikke fra samme brudd som valgte tog"
          isDisabled={matchSelectedIncidentId}
          gutter={0}
          shouldWrapChildren
        >
          <Checkbox
            mr={3}
            gridColumn="1"
            opacity={matchSelectedIncidentId ? 1 : 0.15}
            isDisabled={isPending || !matchSelectedIncidentId}
            isChecked={inSelectedBatch}
            onChange={() => {
              updateSelectedTrains(train);
            }}
          />
        </Tooltip>

        <VStack gap={0.5} alignItems="flex-start">
          <Text bold>{train.trainId.identifier}</Text>
          <Text secondary>{timeDisplay}</Text>
          <Text>
            {train.origin} - {train.destination}
          </Text>

          {!trainDateIsToday && (
            <Text secondary>
              {formatDateString(train.trainId.nominalDate, "dd.MM.yy")}
            </Text>
          )}
        </VStack>
        <VStack ml="auto" alignItems="flex-end">
          <HStack id="incident" ml="auto">
            <InfrastructureIncidentIcon />
            <Text
              style={{
                // Behaviour: Use max 3 lines and show "..." at overflow
                overflow: "hidden",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {isPending ? "Behandles..." : train.eventStretchName}
            </Text>
          </HStack>
          {newestVersionIsOpen && additionalInfo && (
            <HStack id="existing-event">
              <DropsBadge
                semantics={getEventSemantics(newestVersion.type)}
                key={additionalInfo.latestInfoUuid}
              >
                {getInfoTitle(newestVersion)}
              </DropsBadge>
            </HStack>
          )}
        </VStack>
      </AffectedTrainGrid>
      <DashedLine color={theme.colorBackgroundSecondary} />
    </>
  );
};

export default AffectedTrain;
