import { zodResolver } from "@hookform/resolvers/zod";
import { Text } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { GlobalEventModalContent } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModalContent";
import { GlobalEventWarningText } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventWarningText";
import { VisibilityInput } from "features/CenterContent/RoleContent/GlobalEvents/GlobalVisibilityInput";
import { RelatedIncident } from "features/CenterContent/RoleContent/GlobalEvents/RelatedIncident";
import { formToGlobalInformationRequest } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import { getDefaultValues } from "features/CenterContent/RoleContent/GlobalEvents/globalEventDefaultValues";
import {
  GlobalEventResponse,
  GlobalFormSchema,
  globalFormSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { useGlobalEventDetailed } from "features/CenterContent/RoleContent/GlobalEvents/useGlobalEventDetailed";
import { usePostGlobalInfo } from "features/CenterContent/RoleContent/GlobalEvents/usePostGlobalInfo";
import { createInternalMessage } from "features/CenterContent/RoleContent/InternalMessage/utils/queries";
import { InfrastructureEventCreatedResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { useToastOnError } from "shared/components/dataDisplay/useToastOnError";
import { isBeforeNow } from "shared/utils/dateTimeUtils";

type GlobalEventModalProps = {
  onClose: () => void;
  uuid?: string;
  event?: GlobalEventResponse;
  relatedInfrastructureEvent?: InfrastructureEventCreatedResponse;
};

export const GLOBAL_EVENT_QUERY_KEY = "globalEvent";

const GlobalEventModalForm: FC<GlobalEventModalProps> = ({
  onClose,
  event,
  relatedInfrastructureEvent,
}) => {
  const isEditMode = !!event;
  const isRelatedToIncident =
    !!relatedInfrastructureEvent ||
    (isEditMode && event?.referencedIncidentIds.length > 0);
  const incidentId =
    isEditMode && event?.referencedIncidentIds.length > 0
      ? event.referencedIncidentIds[0]
      : relatedInfrastructureEvent?.incidentId;

  const formMethods = useForm<GlobalFormSchema>({
    resolver: zodResolver(globalFormSchema),
    defaultValues: getDefaultValues(relatedInfrastructureEvent, event),
    mode: "onBlur",
  });

  const allowVisibilityEdit =
    !isEditMode ||
    (event.visibility?.type !== "CONSTANT" &&
      !isBeforeNow(event.visibility.fromTime));

  const { mutateAsync: postGlobalInfo, status: postGlobalInfoStatus } =
    usePostGlobalInfo(event?.uuid);
  return (
    <FormProvider {...formMethods}>
      <ActionModal
        title={`${isEditMode ? "Endre" : "Opprett"} global driftsmelding`}
        actionTitle={`${isEditMode ? "Endre" : "Opprett"} hendelse`}
        titleBadge={isRelatedToIncident ? "Infrastrukturavhengig" : "Uavhengig"}
        onClose={onClose}
        onSubmit={formMethods.handleSubmit((data) =>
          postGlobalInfo(
            formToGlobalInformationRequest(data.globalForm, incidentId),
          ).then(createInternalMessage(data.globalForm.internalMessages)),
        )}
        isLoading={postGlobalInfoStatus === "pending"}
        isSuccess={postGlobalInfoStatus === "success"}
        isError={postGlobalInfoStatus === "error"}
        successMessage={isEditMode ? "Hendelse endret" : "Hendelse opprettet"}
        failureMessage={`Kunne ikke ${isEditMode ? "endre" : "opprette"} hendelsen. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
        internalMessagesFormField="globalForm.internalMessages"
        internalMessagesMode={isEditMode ? "edit" : "create"}
      >
        {isEditMode && event.referencedIncidentIds.length > 0 && (
          <RelatedIncident incidentId={event.referencedIncidentIds[0]} />
        )}
        {isEditMode && (
          <Text variant="xs" width="100%" paddingY={2}>
            <span style={{ fontWeight: "bold" }}>Sist oppdatert:</span>{" "}
            {format(event.updatedAt.toDate(), "dd.MM.yyyy 'kl.' HH:mm")}
          </Text>
        )}
        <GlobalEventModalContent formMethods={formMethods} />
        <VisibilityInput
          formMethods={formMethods}
          isRelatedToIncident={isRelatedToIncident}
          allowEdit={allowVisibilityEdit}
        />
        <GlobalEventWarningText />
      </ActionModal>
    </FormProvider>
  );
};

export const CreateGlobalEventModal: FC<GlobalEventModalProps> = ({
  onClose,
  uuid,
  event,
  ...props // uuid and event is left out since this is create global event
}) => <GlobalEventModalForm onClose={onClose} {...props} />;

export const GlobalEventModal: FC<GlobalEventModalProps> = ({
  onClose,
  uuid,
  ...props
}) => {
  const { data, isError } = useGlobalEventDetailed(uuid);
  useToastOnError(isError, onClose);
  if (!data) return null;
  return <GlobalEventModalForm onClose={onClose} event={data} {...props} />;
};
