import { DateValue, getLocalTimeZone, today } from "@internationalized/date";
import {
  CloseFill24Icon,
  SearchOutline24Icon,
} from "@vygruppen/spor-icon-react";
import {
  Button,
  DatePicker,
  Flex,
  FormControl,
  Grid,
  IconButton,
  TimePicker,
} from "@vygruppen/spor-react";
import { format } from "date-fns/format";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { commonPadding } from "../formSchema";
import { OploggSendMessage } from "./OploggSendMessage";
import { useDate } from "./useOploggContext";

export const OpLoggInput = () => {
  const setSelectedDate = useDate();
  const theme = useTheme();

  const [date, setDate] = useState<DateValue>(today(getLocalTimeZone()));
  const [time, setTime] = useState<string | undefined>(undefined);

  // change date when you pass midnight
  function handleChangeTime(newTime: string | undefined) {
    const selectedTime = time ? time.slice(0, 5) : "00:00";
    const newTimeFormatted = newTime ? newTime.slice(0, 5) : "00:00";

    if (selectedTime === "00:00" && newTimeFormatted === "23:00") {
      setDate(date.subtract({ days: 1 }));
    } else if (selectedTime === "23:00" && newTimeFormatted === "00:00") {
      setDate(date.add({ days: 1 }));
    }

    setTime(newTimeFormatted);
  }

  const [messaging, setMessaging] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);

  useEffect(() => {
    if (date && time) {
      const combinedDateTime = `${format(date.toDate(getLocalTimeZone()), "yyyy-MM-dd")}T${time}`;
      setSelectedDate(combinedDateTime);
    }
  }, [date, time, setSelectedDate]);

  return (
    <div style={{ gridArea: "input" }}>
      <Flex
        p={3}
        mb="2px"
        justifyContent="space-between"
        alignItems="center"
        backgroundColor={theme.colorBackgroundSecondary}
        borderWidth="1px"
        borderColor={theme.colorBorder}
        borderRadius="6px"
      >
        {/* <Text fontWeight="bold">Operativ logg</Text>  Keep until next iteration, will be used again then */}

        <Flex width="100%" justifyContent="space-between">
          <Button
            onClick={() => {
              setMessaging(!messaging);
              setSearching(false);
            }}
            mr="10px!"
          >
            Ny melding
          </Button>
          <IconButton
            aria-label="Søk"
            variant="tertiary"
            date-testid="filter-button"
            style={{
              backgroundColor: searching ? theme.colorSelected : "transparent", // Bright background when filter is active
            }}
            onClick={() => {
              if (searching) {
                setSelectedDate(null);
              } else {
                setDate(today(getLocalTimeZone()));
                setTime(format(new Date(), "HH:mm"));
              }
              setSearching(!searching);
              setMessaging(false);
            }}
            icon={searching ? <CloseFill24Icon /> : <SearchOutline24Icon />}
          />
        </Flex>
      </Flex>

      {searching && (
        <Grid
          gridArea="todooplogg"
          gap={2}
          mt="6px"
          mb="6px"
          padding={commonPadding}
          backgroundColor={theme.colorBackgroundSecondary}
          borderColor={theme.colorBorder}
          borderWidth="1px"
          borderRadius="6px"
          zIndex="9"
        >
          <FormControl>
            <Flex>
              <TimePicker
                label="Tidspunkt"
                minuteInterval={60}
                onChange={(newTime) => handleChangeTime(newTime?.toString())}
                mr="5px"
                style={{ width: "50%" }}
              />
              <DatePicker
                label="Dato"
                variant="base"
                defaultValue={date}
                value={date}
                withPortal={false}
                width="50%"
                minValue={today(getLocalTimeZone()).subtract({ days: 7 })}
                maxValue={today(getLocalTimeZone()).add({ days: 1 })}
                onChange={(value) =>
                  setDate(value ?? today(getLocalTimeZone()))
                }
                showYearNavigation={false}
              />
            </Flex>
          </FormControl>
        </Grid>
      )}

      {messaging && <OploggSendMessage setMessaging={setMessaging} />}
    </div>
  );
};
