import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { getLocalStorageUsername } from "api/dropsLocalStorage";
import { environment, Environment } from "app/environment/environment";

let isInitialized = false;
const enabledEnvironments = [
  Environment.Production,
  Environment.Stage,
  Environment.Test,
];

const env = environment();
export const LogLevel = StatusType; // temporary

export const initDatadogBrowserLogs = () => {
  if (!isInitialized && enabledEnvironments.includes(env)) {
    datadogLogs.init({
      clientToken: "pubd124844af411c02a2b8de9a5fe35085b",
      site: "datadoghq.eu",
      forwardErrorsToLogs: true,
      service: "drops-frontend",
      env: env.toLowerCase(),
      sessionSampleRate: 100,
      trackingConsent: "granted",
      forwardConsoleLogs: ["error"],
    });
    isInitialized = true;

    const username = getLocalStorageUsername();

    if (username) {
      datadogLogs.setUser({ id: username });
    }
  } else if (!isInitialized) {
    console.warn(
      `Datadog Log Collection not initialized for environment: ${env}`,
    );
  }
};

export const log = (level: StatusType, source: string, message: string) => {
  if (isInitialized) {
    datadogLogs.logger.log(message, { source }, level);
  }
};
