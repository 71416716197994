import { Icon } from "leaflet";
import { FC } from "react";
import { Marker, MarkerProps } from "react-leaflet";

interface LeafletMarkerProps extends MarkerProps {}

const LeafletMarker: FC<LeafletMarkerProps> = ({
  children,
  position,
  ...props
}) => (
  <Marker
    position={position}
    icon={
      new Icon({
        iconUrl: "/icons/marker.svg",
        iconSize: [32, 32],
      })
    }
    {...props}
  >
    {children}
  </Marker>
);

export default LeafletMarker;
