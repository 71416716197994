import { EditOutline24Icon } from "@vygruppen/spor-icon-react";
import { VersionProps } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/Version";
import { useEffect, useState } from "react";
import { ActionButton } from "shared/components/feedback/InfoMessage/ActionButtons";

export const useActionButtons = (
  versions: Omit<VersionProps, "severity" | "isLastElement">[],
  setModalOpen: (value: boolean) => void,
  close: () => void,
  askConfirmation: boolean,
  setAskConfirmation: (value: boolean) => void,
  isError: boolean,
): ActionButton[] => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(false);
    setAskConfirmation(false);
  }, [versions.length, isError]);

  const actionButtons: ActionButton[] = [
    {
      title: "Friskmeld",
      isLoading: showSpinner,
      onClick: () => {
        setAskConfirmation(true);
      },
      variant: "primary",
    },
    {
      title: "Endre",
      onClick: () => setModalOpen(true),
      leftIcon: <EditOutline24Icon />,
      variant: "tertiary",
    },
  ];

  const confirmActionButtons: ActionButton[] = [
    {
      title: "Ja, friskmeld",
      variant: "primary",
      isLoading: showSpinner,
      onClick: () => {
        setShowSpinner(true);
        close();
      },
    },
    { title: "Avbryt", onClick: () => setAskConfirmation(false) },
  ];

  if (askConfirmation) {
    return confirmActionButtons;
  }
  return actionButtons;
};
