import colors from "shared/theme/colors";

export const lightTheme = {
  themeName: "light",

  /// ///////////////////////////////////////
  // Use SPOR components and it's color variables if possible
  // If component is custom or overwritten, use the following color variables
  // Don't create color variables inside your custom components;
  // Create them here, supported by appPalette.ts
  // Don't even think about using a color without a variable
  /// ///////////////////////////////////////

  // BACKGROUND
  colorBackgroundMain: colors.platinum,
  colorBackgroundSecondary: colors.white,
  colorBackgroundTertiary: colors.lightGrey,
  colorHoverItem: colors.mint, // change to alpha 50 when spor is updated

  // TEXT
  colorTextMain: colors.jungle, // update to the real Jungle when spor tokens is updated
  colorTextSecondary: colors.blackAlpha["600"],
  colorTextTertiary: colors.dimGrey,
  colorTextDisabled: colors.blackAlpha["300"],
  colorSelectedText: colors.jungle, // update to the real Jungle when spor tokens is updated

  // OUTLINES
  colorSeparationLine: colors.blackAlpha["200"],
  colorOutline: colors.blackAlpha["200"],
  colorBorder: colors.blackAlpha["200"],

  // SEMANTICS
  // Alarm 1
  colorAlarm: colors.raspberry,
  colorAlarmPulse: colors.wine,
  colorAlarmFill: colors.pink,
  colorAlarmFillPulse: colors.lightRed,
  colorAlarmOutline: colors.salmon,
  colorAlarmTextMain: colors.burgundy, // update to the real Burgundy when spor tokens is updated
  colorAlarmTextSecondary: colors.crimson, // update to the real Crimson when spor tokens is updated
  colorAlarmTextTertiary: colors.pink,

  // Alarm 2
  colorSecondaryAlarm: colors.autumn,
  colorSecondaryAlarmPulse: colors.russet, // update to the real Russet when spor tokens is updated
  colorSecondaryAlarmFill: colors.bisque,
  colorSecondaryAlarmFillPulse: colors.champagne,
  colorSecondaryAlarmOutline: colors.rajah,
  colorSecondaryAlarmTextMain: colors.chocolate, // update to the real Chocolate when spor tokens is updated
  colorSecondaryAlarmTextSecondary: colors.russet, // update to the real Chocolate when spor tokens is updated
  colorSecondaryAlarmTextTertiary: colors.bisque,

  // Warning
  colorWarning: colors.burntYellow,
  colorWarningPulse: colors.golden,
  colorWarningFill: colors.cornsilk,
  colorWarningFillPulse: colors.blonde,
  colorWarningOutline: colors.banana,
  colorWarningTextMain: colors.coffee, // update to the real Coffee when spor tokens is updated
  colorWarningTextSecondary: colors.bronce, // update to the real Bronce when spor tokens is updated
  colorWarningTextTertiary: colors.coffee, // update to the real Coffee when spor tokens is updated

  // Success
  colorSuccess: colors.greenHaze,
  colorSuccessPulse: colors.primaryGreen,
  colorSuccessFill: colors.mint,
  colorSuccessFillPulse: colors.seaMist,
  colorSuccessOutline: colors.coralGreen,
  colorSuccessTextMain: colors.night,
  colorSuccessTextSecondary: colors.pine,
  colorSuccessTextTertiary: colors.green25,

  // Info
  colorInfo: colors.ocean,
  colorInfoPulse: colors.darkBlue,
  colorInfoFill: colors.icyBlue,
  colorInfoFillPulse: colors.lightBlue,
  colorInfoOutline: colors.cloudy,
  colorInfoTextMain: colors.navy, // update to the real Navy when spor tokens is updated
  colorInfoTextSecondary: colors.ocean,
  colorInfoTextTertiary: colors.icyBlue,

  // Neutral
  colorNeutral: colors.dimGrey,
  colorNeutralPulse: colors.iron, // update to the real Iron when spor tokens is updated
  colorNeutralFill: colors.platinum,
  colorNeutralFillPulse: colors.silver,
  colorNeutralOutline: colors.steel,
  colorNeutralTextMain: colors.darkGrey,
  colorNeutralTextSecondary: colors.dimGrey,
  colorNeutralTextTertiary: colors.lightGrey,

  // SPECIALS
  trainMapTopology: colors.silver,
  colorSelected: colors.seaMist,
  skeletonElementColor: colors.platinum,
  boxShadow: "0px 2px 18px 1px rgba(1, 38, 34, 0.1)",
  dropShadow: "drop-shadow(0px 2px 18px rgba(1, 38, 34, 0.1))",
};

export type LightTheme = typeof lightTheme;
