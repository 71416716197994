import styled from "styled-components";

export const ResultListWrapper = styled.div`
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  -webkit-scrollbar: none; /* WebKit */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
