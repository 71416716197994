import { environment, Environment } from "../app/environment/environment";

type EnvironmentVariableName =
  | "VITE_USE_LOCAL_BACKEND"
  | "VITE_ENABLED_FEATURE_FLAGS"
  | "VITE_CLOSEABLE_OPLOGG";

// Is called "Safe" since the environment variable is optional, not required
export function getEnvParsedSafe(variableName: EnvironmentVariableName) {
  const envVariable: string | undefined = import.meta.env[variableName];
  return envVariable ? JSON.parse(envVariable) : undefined;
}

// Is called "Safe" since the environment variable is optional, not required
export function getEnvBoolSafe(variableName: EnvironmentVariableName) {
  const envVariable: string | undefined = import.meta.env[variableName];
  return envVariable?.toLowerCase() === "true";
}

export function getEnvBool(variableName: EnvironmentVariableName) {
  const envVariable: string | undefined = import.meta.env[variableName];
  if (!envVariable) {
    throw new Error(
      `Missing required environment variable ${variableName}. See README.md for instructions.`,
    );
  }
  return envVariable.toLowerCase() === "true";
}

const getBackendUrl = () => {
  const env: Environment = environment();

  switch (env) {
    case Environment.Development:
      return getEnvBool("VITE_USE_LOCAL_BACKEND")
        ? "http://localhost:8080/drops-backend"
        : "https://services.test.trafficgui.vydev.io/drops-backend";

    case Environment.Test:
      return "https://services.test.trafficgui.vydev.io/drops-backend";

    case Environment.Stage:
      return "https://services.stage.trafficgui.vydev.io/drops-backend";

    case Environment.Production:
      return "https://services.trafficgui.vydev.io/drops-backend";

    case Environment.Unknown:
      return window.location.origin;
    default:
      return window.location.origin;
  }
};

const getTrafficguiBaseUrl = () => {
  const env: Environment = environment();
  switch (env) {
    case Environment.Development:
      return "https://services.test.trafficgui.vydev.io";

    case Environment.Test:
      return "https://services.test.trafficgui.vydev.io";

    case Environment.Stage:
      return "https://services.stage.trafficgui.vydev.io";

    case Environment.Production:
      return "https://services.trafficgui.vydev.io";

    case Environment.Unknown:
      return window.location.origin;
    default:
      return window.location.origin;
  }
};

const getStatusBaseUrl = () => {
  const env: Environment = environment();

  switch (env) {
    case Environment.Development:
    case Environment.Test:
    case Environment.Stage:
      return "https://status-test.nsb.no";

    case Environment.Production:
      return "https://status.nsb.no";

    case Environment.Unknown:
      return window.location.origin;
    default:
      return window.location.origin;
  }
};

const getIncludedPuzzelGroupNames = (): String[] => {
  const env: Environment = environment();
  switch (env) {
    case Environment.Test:
      return ["Alle agenter", "test_sg23_6etg", "Admin"];
    case Environment.Production:
      return ["Alle agenter"];
    case Environment.Development:
      return ["Alle agenter", "test_sg23_6etg", "Admin"];
    default:
      return ["Alle agenter", "test_sg23_6etg", "Admin"];
  }
};

export {
  getBackendUrl,
  getIncludedPuzzelGroupNames,
  getStatusBaseUrl,
  getTrafficguiBaseUrl,
};
