import * as ROUTES from "shared/utils/routes";
import { DashboardRedirect, getDashboardStartPath } from "shared/utils/routes";

import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "api/tanStackQuery/queryClient";
import "app/App.css";
import { AppProviders, StyleProviders } from "app/AppProviders";
import { AuthBoundary } from "app/Auth/AuthBoundary";
import { ErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { DashboardLayout } from "features/CenterContent/DashboardLayout";
import { RoleContent } from "features/CenterContent/RoleContent/RoleContent";
import AlternativeTransportDetails from "features/CenterContent/VehicleDetails/AlternativeTransportDetails/AlternativeTransportDetails";
import ReserveAlternativeTransportDetails from "features/CenterContent/VehicleDetails/ReserveAlternativeTransportDetails/ReserveAlternativeTransportDetails";
import TrainDetails from "features/CenterContent/VehicleDetails/TrainDetails/TrainDetails";
import Header from "features/Header/Header";
import { HistoryOverview } from "features/History/HistoryOverview";
import { OploggPage } from "features/OploggPage/OploggPage";
import { initDatadogBrowserLogs } from "logging/datadogBrowserLogs";
import {
  Navigate,
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import { useDropsRole } from "stores/useDropsRole";
import styled from "styled-components";
import { useDatadogRum } from "../logging/useDatadogRum";

const AppGrid = styled.div`
  display: grid;
  grid-template-areas:
    "header header header"
    "vehicleAffected main todoOplogg";
  grid-template-columns: auto 1fr auto;
  grid-template-rows: auto 1fr;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  padding-bottom: 12px;
  padding-top: 12px;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colorBackgroundMain};
`;

const App = () => {
  const { role } = useDropsRole();

  // Initialize RUM client
  useDatadogRum();
  initDatadogBrowserLogs();

  return (
    <QueryClientProvider client={queryClient}>
      <StyleProviders>
        <AuthBoundary>
          <AppProviders>
            <Router>
              <ErrorBoundary>
                <AppGrid>
                  <Header />
                  <Routes>
                    <Route
                      path={ROUTES.DASHBOARD}
                      element={<DashboardLayout />}
                    >
                      <Route path={ROUTES.LANDING} element={<RoleContent />} />
                      <Route path={ROUTES.TRAIN} element={<TrainDetails />} />
                      <Route
                        path={ROUTES.BUS}
                        element={<AlternativeTransportDetails />}
                      />
                      <Route
                        path={ROUTES.RESERVEBUS}
                        element={<ReserveAlternativeTransportDetails />}
                      />

                      <Route
                        // Match redirect from root with no path (i.e. to /dashboard/)
                        index
                        element={
                          <Navigate to={getDashboardStartPath(role)} replace />
                        }
                      />
                      <Route
                        // Match redirect with path (e.g. /dashboard/whatever)
                        // This is necessary in addition to the index route above
                        // in order for old links to keep working.
                        path="*"
                        element={
                          <Navigate to={getDashboardStartPath(role)} replace />
                        }
                      />
                    </Route>

                    <Route path={ROUTES.HISTORY} element={<Outlet />}>
                      <Route index element={<HistoryOverview />} />
                    </Route>

                    <Route path={ROUTES.OPLOGG} element={<Outlet />}>
                      <Route index element={<OploggPage />} />
                    </Route>

                    <Route
                      path="*"
                      element={
                        // Attempt to forward routes into /dashboard/ to be backwards compatible
                        // (before history was added, dashboard lived at the root route)
                        <DashboardRedirect />
                      }
                    />
                  </Routes>
                </AppGrid>
              </ErrorBoundary>
            </Router>
          </AppProviders>
        </AuthBoundary>
      </StyleProviders>
    </QueryClientProvider>
  );
};

export default App;
