import { InfoSelect, Item, ItemLabel } from "@vygruppen/spor-react";
import { TabIds } from "features/CenterContent/RoleContent/useTabsForRole";
import { generatePath, useNavigate } from "react-router-dom";
import { isValidUserRole, ROLE_TEXTS } from "shared/types/roles";
import * as ROUTES from "shared/utils/routes";
import styled from "styled-components";
import { useDropsRole } from "stores/useDropsRole";

// This adjusts the position of the role label in InfoSelect. Tried to
// do this globally, but weren't able to modify label -> transform.
const RoleContainer = styled.div<{ longestLabel: number }>`
  button > :first-child > :first-child {
    transform: scale(0.8) translateY(-2px) translateX(-5px);
  }
  min-width: ${({ longestLabel }) => `${longestLabel + 5}ch`};
`;

const SelectRole = () => {
  const navigate = useNavigate();
  const { role, setRole } = useDropsRole();

  const longestLabel = ROLE_TEXTS.reduce(
    (prev, curr) => (curr.text.length > prev ? curr.text.length : prev),
    0,
  );

  return (
    <RoleContainer longestLabel={longestLabel}>
      <InfoSelect
        name="role"
        label="Rolle"
        value={role}
        items={ROLE_TEXTS}
        onChange={(value) => {
          if (typeof value !== "string") return;
          if (!isValidUserRole(value)) return;
          setRole(value);
          navigate(
            generatePath(ROUTES.LANDING, {
              roleIdParam: value,
              tabIdParam: TabIds.START,
            }),
          );
        }}
      >
        {(item) => (
          <Item textValue={item.text} key={item.value}>
            <ItemLabel>{item.text}</ItemLabel>
          </Item>
        )}
      </InfoSelect>
    </RoleContainer>
  );
};

export default SelectRole;
