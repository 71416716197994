import { GenericType } from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { z } from "zod";

export const isCustomEmptyType = (inputType: string | undefined | null) =>
  inputType === "NO_REASON";

export const isCustomInputType = (inputType: string | undefined | null) =>
  inputType === "CUSTOM" || inputType === "TRAIN_GENERAL";

export const descriptionErrorMessage = "Du må fylle ut en beskrivelse";

export const describedTypeSchema = z
  .object({
    type: z.string().nullable(),
    description: z
      .object({
        NOB: z.string().nullable(),
        ENG: z.string().nullable(),
      })
      .optional(),
  })
  .refine((val) => !isCustomInputType(val.type) || val.description?.NOB, {
    message: descriptionErrorMessage,
    path: ["description.NOB"], // Ensure the error is assigned to the 'description' field
  })
  .refine((val) => !isCustomInputType(val.type) || val.description?.ENG, {
    message: descriptionErrorMessage,
    path: ["description.ENG"], // Ensure the error is assigned to the 'description' field
  });

export type DescribedType = z.infer<typeof describedTypeSchema>;

export const distributionInput = z.object({
  // thusanarul: No need for `message` in contextTypes because default value is set in the form that is not visible in the UI
  contextTypes: z.array(z.string()).min(1),
  texts: z.object({
    NOB: z.string().min(1, { message: descriptionErrorMessage }),
    ENG: z.string().min(1, { message: descriptionErrorMessage }),
  }),
});

export const dropsLogTextInput = z.object({
  enabled: z.boolean(),
  texts: z.object({
    NOB: z.string(),
  }),
});

/*
 * Filter out irrelevant or non-supported reasons.
 * Sort the remaining reasons alphabetically.
 * */
export function sortAndFilterReasons(
  reasons: GenericType[],
  filterTag: "TRAIN" | "INFRASTRUCTURE",
) {
  return reasons
    .filter((r) => r.type !== "OTHER" && r.tags.includes(filterTag))
    .sort((a, b) => {
      if (a.label < b.label) {
        return -1;
      }
      if (a.label > b.label) {
        return 1;
      }
      return 0;
    });
}

const plannedEventActions = [
  "PLANNED_ALTRANS_NOT_UPDATED_LONG_DISTANCE",
  "PLANNED_ALTRANS_NOT_UPDATED_LOCAL",
  "PLANNED_ALTRANS_UPDATED",
];

export const filterActions = (
  actions: GenericType[],
  isPlanned: boolean = false,
) =>
  actions.filter((action) =>
    isPlanned
      ? plannedEventActions.includes(action.type) ||
        isCustomInputType(action.type)
      : !plannedEventActions.includes(action.type),
  );
