import { useQuery } from "@tanstack/react-query";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import { PreviewResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import {
  TrainFormSchema,
  trainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { singleTrainFormToRequestBody } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/utils";
import { TrainEventTypeEnum } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainEventTypeEnum";
import { TrainInfoRequest } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationRequest";
import { useEffect } from "react";

type PreviewProps = {
  formValues: TrainFormSchema;
  train: TrainIdentifier;
};

function getPreviewFormValues(formValues: TrainFormSchema): TrainFormSchema {
  switch (formValues.type) {
    case TrainEventTypeEnum.TRAIN_CUSTOM:
      return formValues;
    default: {
      const newFormValues: TrainFormSchema = {
        ...formValues,
        dropsLogText: { ...formValues.dropsLogText, enabled: false },
      };

      return newFormValues;
    }
  }
}

export const useTrainInfoPreview = ({ formValues, train }: PreviewProps) => {
  const parsedForm = trainFormSchema.safeParse(
    getPreviewFormValues(formValues),
  );

  const {
    status: previewStatus,
    data: previewData,
    refetch,
  } = useQuery({
    queryKey: [formValues, train],
    refetchOnWindowFocus: false,
    enabled: false,
    queryFn: ({ signal }) => {
      const { identifier, countryCode, nominalDate } = train;

      const body: TrainInfoRequest | null = parsedForm.success
        ? singleTrainFormToRequestBody(parsedForm.data, train)
        : null;

      return queryFnPOST<PreviewResponse>({
        url: `${getBackendUrl()}/trainInformation/preview/${countryCode ?? "NO"}/${identifier}/${nominalDate}`,
        body,
        signal,
      });
    },
  });

  useEffect(() => {
    if (parsedForm.success) {
      refetch();
    }
  }, [formValues]);

  return { previewData, previewStatus };
};
