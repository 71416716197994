import { UseQueryResult } from "@tanstack/react-query";
import {
  FilterFill24Icon,
  FilterOutline24Icon,
  InformationOutline18Icon,
  TimeOutline18Icon,
} from "@vygruppen/spor-icon-react";
import {
  ChoiceChip,
  Flex,
  HStack,
  Skeleton,
  SkeletonText,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import {
  TrainIdentifierWithIncidentIdString,
  TrainIdentifierWithIncidentIds_JSON,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import {
  AffectedTrain,
  AffectedTrainsResponse,
} from "features/CenterContent/RoleContent/Vaktleder/types";
import { CreateTrainInfoBatchModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/CreateTrainInfoBatchModal";
import HanddledTrainList from "features/VehicleSidebar/AffectedTrains/lists/HandledTrainList";
import { UnhandledTrainList } from "features/VehicleSidebar/AffectedTrains/lists/UnhandledTrainList";
import { AffectedTrainsContainer } from "features/VehicleSidebar/AffectedTrains/styles";
import { getSessionStorageFilter } from "features/VehicleSidebar/AffectedTrains/utils/filterAffectedTrains";
import { FC, useEffect, useRef, useState } from "react";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import styled, { useTheme } from "styled-components";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import TabWithPulse from "./TabWithPulse";
import { InfoBox } from "./styles";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
  width: 100%;
`;

const StyledTabPanels = styled(TabPanels)`
  overflow: hidden;
  height: 100vh;
  -webkit-scrollbar: none; /* WebKit */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const StyledTabPanel = styled(TabPanel)`
  height: 100%;
  border-radius: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
`;

const AffectedTrainsLoader = () => {
  const theme = useTheme();
  return (
    <ul>
      {[...new Array(5)].map((_, i) => (
        <HStack width="100%" key={i} p={2} justify="space-between">
          <SkeletonText width="150px" />
          <SkeletonText noOfLines={2} alignSelf="center" width="100px" />
          <Skeleton height={5} width="110px" borderRadius="100" />
        </HStack>
      ))}
    </ul>
  );
};

type AffectedTrainsProps = {
  affectedTrainsResult: UseQueryResult<AffectedTrainsResponse>;
};

const AffectedTrains: FC<AffectedTrainsProps> = ({ affectedTrainsResult }) => {
  /* Data */
  const {
    data: affectedTrainsData,
    isPending,
    isSuccess,
    isError,
    refetch,
  } = affectedTrainsResult;

  /* State */
  const [selectedTrains, setSelectedTrains] = useState<AffectedTrain[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [showFilter, setShowfilter] = useState<boolean>(() =>
    getSessionStorageFilter() === null ? false : true,
  );
  const [showPassedTrains, setShowPassedTrains] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [tabPulse, setTabPulse] = useState<number>(0);
  const [pendingTrains, setPendingTrains] = useState<
    TrainIdentifierWithIncidentIdString[]
  >([]);

  const notAffectedTrains =
    affectedTrainsData?.affectedTrains.filter(
      (train) => train.state === "NOT_AFFECTED",
    ) || [];

  // Data source for no. incidents
  const incidentsCount = new Set(
    affectedTrainsData?.affectedTrains.map((t) => t.incidentId) ?? [],
  ).size;

  const { message: trainInfoMessage } = useDropsWebsocket("AffectedTrains", [
    "TRAIN_INFORMATION",
  ]);

  /**
   * refetch affected trains whenever we receive a new event for one of the affected trains
   * If we receive a lot of messages at once, we only refetch after the last one
   */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        trainInfoMessage.status === "received" &&
        trainInfoMessage.data.topic === "TRAIN_INFORMATION" &&
        affectedTrainsResult.isSuccess
      ) {
        const messageContainsAffectedTrain =
          trainInfoMessage.data.message.versions.some((version) =>
            [
              ...affectedTrainsResult.data.affectedTrains,
              ...affectedTrainsResult.data.handledAffectedTrains,
            ].some(
              (affectedTrain) =>
                affectedTrain.trainId.identifier ===
                  version.trainIdentifier.operational_identifier &&
                affectedTrain.trainId.nominalDate ===
                  version.trainIdentifier.nominal_date &&
                affectedTrain.trainId.countryCode ===
                  version.trainIdentifier.country_code,
            ),
          );
        if (messageContainsAffectedTrain) {
          affectedTrainsResult.refetch();
          setTimeout(() => {
            setTabPulse(2);
          }, 200);
          setTimeout(() => {
            setTabPulse(0);
          }, 1400);
        }
      }
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [trainInfoMessage]);

  /* Handlers */
  const updateSelectedTrains = (affectedTrain: AffectedTrain) => {
    const idx = selectedTrains.findIndex(
      (selectedTrain) =>
        selectedTrain.trainId.identifier === affectedTrain.trainId.identifier &&
        selectedTrain.incidentId === affectedTrain.incidentId &&
        selectedTrain.trainId.nominalDate === affectedTrain.trainId.nominalDate,
    );
    if (idx !== -1) {
      setSelectedTrains(selectedTrains.toSpliced(idx, 1));
    } else {
      setSelectedTrains([...selectedTrains, affectedTrain]);
    }
  };

  const batchUpdateSelectedTrains = (affectedTrains: AffectedTrain[]) => {
    if (
      selectedTrains.length > 0 &&
      affectedTrains[0].incidentId === selectedTrains[0].incidentId
    ) {
      setSelectedTrains([...selectedTrains, ...affectedTrains]);
    } else {
      setSelectedTrains(affectedTrains);
    }
  };

  const clearSelectedTrains = () => {
    setSelectedTrains([]);
  };

  const handleCreateEvents = () => {
    // Will handle all trains in selectedTrains
    setModalOpen(true);
  };

  const affectedTrainsToShow = () => {
    if (showPassedTrains) {
      return affectedTrainsData?.affectedTrains.filter(
        (t) => t.state === "AFFECTED" || t.state === "INDIRECTLY_AFFECTED",
      );
    }
    return affectedTrainsData?.affectedTrains.filter((t) => {
      if (t.state === "NOT_AFFECTED") return false;
      const time = t.incidentExitTime ?? t.incidentArrivalTime;
      const gracePeriod = 900_000; // 900s grace period
      if (!time) return true;
      return (
        time.getTime() + gracePeriod >= new Date().getTime() ||
        selectedTrains.find(
          (it) => it.trainId === t.trainId && it.incidentId === t.incidentId,
        )
      );
    });
  };

  const addPendingTrains = (trains: TrainIdentifierWithIncidentIds_JSON[]) => {
    const newTrainIds = trains
      .map<TrainIdentifierWithIncidentIdString>(
        (train) =>
          `${train.incident_ids[0]}-${train.country_code}-${train.operational_identifier}-${train.nominal_date}`,
      )
      .filter((trainId) => !pendingTrains.includes(trainId));

    setPendingTrains([...pendingTrains, ...newTrainIds]);
  };

  const removePendingTrains = (
    trains: TrainIdentifierWithIncidentIds_JSON[],
  ) => {
    const idsToRemove = trains.map<TrainIdentifierWithIncidentIdString>(
      (train) =>
        `${train.incident_ids[0]}-${train.country_code}-${train.operational_identifier}-${train.nominal_date}`,
    );

    setPendingTrains(
      pendingTrains.filter((trainId) => !idsToRemove.includes(trainId)),
    );
  };

  /* Side effects */

  // On data update sync trains and selectedTrains
  useEffect(() => {
    if (affectedTrainsData?.affectedTrains && selectedTrains.length > 0) {
      setSelectedTrains(
        selectedTrains.filter((selectedTrain) =>
          affectedTrainsData.affectedTrains.some(
            ({ trainId, incidentId }) =>
              trainId.identifier === selectedTrain.trainId.identifier &&
              trainId.nominalDate === selectedTrain.trainId.nominalDate &&
              selectedTrain.incidentId === incidentId,
          ),
        ),
      );
      removePendingTrains(
        affectedTrainsData.affectedTrains.map(({ trainId, incidentId }) => ({
          country_code: trainId.countryCode,
          operational_identifier: trainId.identifier,
          nominal_date: trainId.nominalDate,
          incident_ids: [incidentId],
        })),
      );
    }
  }, [affectedTrainsData]);

  // Reset show passed trains when switching tabs
  useEffect(() => {
    setShowPassedTrains(false);
    setSelectedTrains([]); // Reset selectedTrains when tab is changed
  }, [tabIndex]);

  const theme = useTheme();

  // Train lists need a ref to container for pagination of results on scroll
  const trainListContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Flex flexDir="column" overflowY="auto" width="100%" height="100%">
      <AffectedTrainsContainer>
        {isPending && <AffectedTrainsLoader />}
        {isError && <FailureMessage m={1} />}
        {isSuccess && (
          <>
            {affectedTrainsData.errorMessages.length > 0 && (
              <ExpandableInfoMessage
                severity="error"
                title="Kunne ikke hente berørte tog for enkelte hendelser"
                style={{ margin: "12px" }}
              >
                {affectedTrainsData.errorMessages.map((stretch) => (
                  <Text key={stretch} m={1}>
                    {stretch}: Kunne ikke hente berørte tog for hendelsen.
                  </Text>
                ))}
              </ExpandableInfoMessage>
            )}
            <Tabs
              variant="accent"
              colorScheme="base"
              overflowY="auto"
              onChange={setTabIndex}
              height="100%"
            >
              <VStack
                px={2}
                py={2}
                mb="2px"
                alignItems="flex-start"
                backgroundColor={theme.colorBackgroundSecondary}
                borderRadius={6}
                border={`1px solid ${theme.colorBorder}`}
                gap={4}
              >
                <HStack
                  justify="space-between"
                  flexWrap="wrap"
                  alignItems="center"
                  width="100%"
                >
                  <TabList>
                    <TabWithPulse pulsing={tabPulse === 1}>
                      <Flex gap={1} alignItems="center">
                        Ubehandlet
                        <AlertsCounter
                          alerts={
                            affectedTrainsData.affectedTrains.filter(
                              (t) =>
                                t.state === "AFFECTED" ||
                                t.state === "INDIRECTLY_AFFECTED",
                            ).length
                          }
                          severity={tabIndex === 0 ? "success" : "neutral"}
                        />
                      </Flex>
                    </TabWithPulse>
                    <TabWithPulse pulsing={tabPulse === 2}>
                      <Flex gap={1} alignItems="center">
                        Behandlet
                        <AlertsCounter
                          alerts={
                            affectedTrainsData.handledAffectedTrains.length
                          }
                          severity={tabIndex === 1 ? "success" : "neutral"}
                        />
                      </Flex>
                    </TabWithPulse>
                    <TabWithPulse pulsing={tabPulse === 3}>
                      <Flex gap={1} alignItems="center">
                        Ikke berørt
                        <AlertsCounter
                          alerts={notAffectedTrains.length}
                          severity={tabIndex === 2 ? "success" : "neutral"}
                        />
                      </Flex>
                    </TabWithPulse>
                  </TabList>
                </HStack>
                <Flex
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex>
                    {tabIndex === 0 && (
                      <ChoiceChip
                        size="sm"
                        isChecked={showPassedTrains}
                        onChange={() => setShowPassedTrains(!showPassedTrains)}
                        icon={{
                          default: <TimeOutline18Icon />,
                          checked: <TimeOutline18Icon />,
                        }}
                      >
                        <Text variant="xs">Vis passerte tog</Text>
                      </ChoiceChip>
                    )}
                  </Flex>
                  <Flex gap={2} my="1px" alignItems="center">
                    {incidentsCount === 1 && (
                      <Text variant="xs" pl={2} whiteSpace="nowrap">
                        {incidentsCount} aktivt brudd
                      </Text>
                    )}
                    {incidentsCount > 1 && (
                      <Text variant="xs" pl={2} whiteSpace="nowrap">
                        {incidentsCount} aktive brudd
                      </Text>
                    )}
                    <ChoiceChip
                      size="xs"
                      icon={{
                        default: <FilterOutline24Icon />,
                        checked: <FilterFill24Icon />,
                      }}
                      isChecked={showFilter}
                      onChange={() => setShowfilter(!showFilter)}
                    >
                      Filter
                    </ChoiceChip>
                  </Flex>
                </Flex>
              </VStack>
              <StyledTabPanels ref={trainListContainerRef} height="100%">
                <StyledTabPanel>
                  {affectedTrainsData?.affectedTrains.length > 0 ? (
                    <UnhandledTrainList
                      containerRef={trainListContainerRef}
                      trains={affectedTrainsToShow() ?? []}
                      selectedTrains={selectedTrains}
                      setTabPulse={setTabPulse}
                      updateSelectedTrains={updateSelectedTrains}
                      clearSelectedTrains={clearSelectedTrains}
                      batchUpdatedSelectedTrains={batchUpdateSelectedTrains}
                      handleCreateEvents={handleCreateEvents}
                      showFilter={showFilter}
                      pendingTrains={pendingTrains}
                      refetch={refetch}
                    />
                  ) : (
                    <NoDataMessage
                      height="100%"
                      pt={2}
                      mt={1}
                      reason="Det er ingen ubehandlede tog for øyeblikket"
                    />
                  )}
                </StyledTabPanel>
                <StyledTabPanel>
                  {affectedTrainsData?.handledAffectedTrains.length > 0 ? (
                    <HanddledTrainList
                      trains={affectedTrainsData.handledAffectedTrains}
                      showFilter={showFilter}
                    />
                  ) : (
                    <NoDataMessage
                      height="100%"
                      pt={2}
                      mt={1}
                      reason="Det er ingen behandlede tog for øyeblikket"
                    />
                  )}
                </StyledTabPanel>
                <StyledTabPanel>
                  {notAffectedTrains.length > 0 ? (
                    <UnhandledTrainList
                      containerRef={trainListContainerRef}
                      trains={notAffectedTrains ?? []}
                      selectedTrains={selectedTrains}
                      updateSelectedTrains={updateSelectedTrains}
                      clearSelectedTrains={clearSelectedTrains}
                      batchUpdatedSelectedTrains={batchUpdateSelectedTrains}
                      handleCreateEvents={handleCreateEvents}
                      showFilter={showFilter}
                      pendingTrains={pendingTrains}
                      refetch={refetch}
                      setTabPulse={setTabPulse}
                      notAffected
                    />
                  ) : (
                    <NoDataMessage
                      height="100%"
                      pt={2}
                      mt={1}
                      reason="Det er ingen ikke berørte tog for øyeblikket"
                    />
                  )}
                </StyledTabPanel>
              </StyledTabPanels>
              <InfoBox>
                <InformationOutline18Icon w={3} h={3} />
                <Text variant="xs">
                  Tidene kan avvike litt avhengig av datagrunnlag
                </Text>
              </InfoBox>
            </Tabs>
          </>
        )}
      </AffectedTrainsContainer>
      {modalOpen && affectedTrainsData && (
        <CreateTrainInfoBatchModal
          selectedTrains={selectedTrains}
          setModalOpen={setModalOpen}
          updateSelectedTrains={updateSelectedTrains}
          addPendingTrains={addPendingTrains}
        />
      )}
    </Flex>
  );
};

export default withErrorBoundary(AffectedTrains);
