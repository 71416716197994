import { Stack } from "@vygruppen/spor-react";
import { AddTimeIntervalRule } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/AddTimeIntervalRule";
import { TimeIntervalSummary } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/TimeIntervalSummary";
import {
  InfrastructureForm,
  Rule,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { getInitialTimedDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/initialDurationValues";

export enum WeekRepeat {
  WEEKLY = "weekly",
  BIWEEKLY = "biweekly",
}

type PeriodicDurationProps = {
  addRule: boolean;
  setAddRule: Dispatch<SetStateAction<boolean>>;
};

export const PeriodicDuration: FC<PeriodicDurationProps> = ({
  addRule,
  setAddRule,
}) => {
  const [selectedRuleId, setSelectedRuleId] = useState<string | null>(null);

  const { control, setValue, getValues } = useFormContext<InfrastructureForm>();
  const formState = useWatch({
    control,
    name: "infrastructureForm.durations",
  });

  const getSavedRule = () => {
    if (formState?.type === "PERIODIC") {
      return formState.rules.find((rule) => rule.id === selectedRuleId);
    }
    return undefined;
  };

  const updateRules = (newRule: Rule) => {
    const prevRules: Rule[] = getValues("infrastructureForm.durations.rules");
    setValue(
      "infrastructureForm.durations.rules",
      [newRule, ...prevRules.filter((rule) => rule.id !== newRule.id)],
      { shouldValidate: true },
    );
  };

  const onSaveRule = (rule: Rule) => {
    updateRules(rule);
    setAddRule(false);
    setSelectedRuleId(null);
  };

  const onDeleteRule = (deletedRule: Rule) => {
    if (formState?.type === "PERIODIC") {
      if (formState.rules.length === 1) {
        setValue("infrastructureForm.durations", getInitialTimedDuration(), {
          shouldValidate: true,
        });
      } else {
        const prevRules = formState.rules;
        setValue(
          "infrastructureForm.durations.rules",
          [...prevRules.filter((rule) => rule.id !== deletedRule.id)],
          { shouldValidate: true },
        );
      }
    }
  };

  return addRule ? (
    <AddTimeIntervalRule
      savedRule={getSavedRule()}
      onSaveClick={onSaveRule}
      onClose={() => {
        if (formState?.type === "PERIODIC" && formState.rules.length === 0) {
          setValue("infrastructureForm.durations", getInitialTimedDuration(), {
            shouldValidate: true,
          });
        }
        setAddRule(false);
        setSelectedRuleId(null);
      }}
    />
  ) : (
    <Stack>
      {formState?.type === "PERIODIC" &&
        formState.rules
          .sort((a, b) => a.startDateTime.compare(b.startDateTime))
          .map((rule) => (
            <TimeIntervalSummary
              key={rule.id}
              rule={rule}
              onEditClick={() => {
                setSelectedRuleId(rule.id);
                setAddRule(true);
              }}
              onDeleteRule={() => onDeleteRule(rule)}
            />
          ))}
    </Stack>
  );
};
