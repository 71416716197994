import { useMutation } from "@tanstack/react-query";
import { FormControl, FormErrorMessage, Textarea } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { format } from "date-fns";
import { FC, FormEvent } from "react";
import { ActionModal } from "shared/components/ActionModal";
import { useTypedForm } from "shared/hooks/useTypedForm";
import { z } from "zod";

type ISirkLogMessageModalProps = {
  isirkId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const createIncidentLogMessageSchema = z.object({
  message: z.string().nonempty(),
  isirkId: z.string().nullable(),
  incidentId: z.string().nullable(),
  date: z.string().nullable(),
});

type CreateIncidentLogMessageSchema = z.infer<
  typeof createIncidentLogMessageSchema
>;

const NewLogMessageModal: FC<ISirkLogMessageModalProps> = ({
  isirkId,
  isOpen,
  setIsOpen,
}) => {
  const {
    mutate: postIncidentLog,
    status: mutationStatus,
    reset: resetMutation,
  } = useMutation({
    mutationFn: (data: CreateIncidentLogMessageSchema) =>
      mutationFnPOST<void, CreateIncidentLogMessageSchema>(
        `${getBackendUrl()}/alternative-transport/incident-logs`,
        data,
      ),
  });

  const {
    values,
    handleChange,
    reset: resetForm,
    startValidation,
    errors: formErrors,
  } = useTypedForm<{ message: string }>({ message: "" }, (formValues) => ({
    message:
      formValues.message.length > 0 ? undefined : "Loggmelding må fylles ut",
  }));

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const payload: CreateIncidentLogMessageSchema = {
      message: values.message,
      isirkId,
      incidentId: null,
      date: format(Date.now(), "yyyy-MM-dd"),
    };
    postIncidentLog(payload);
  };

  const onClose = () => {
    setIsOpen(false);
    resetForm();
    resetMutation();
  };

  return isOpen ? (
    <ActionModal
      title="Skriv ny tilbakemelding på I-Sirk"
      actionTitle="Send melding"
      onClose={onClose}
      onSubmit={onSubmit}
      isLoading={mutationStatus === "pending"}
      isSuccess={mutationStatus === "success"}
      isError={mutationStatus === "error"}
      isSubmitDisabled={values.message.length === 0}
      successMessage="Tilbakemelding sendt"
      failureMessage="Kunne ikke sende tilbakemelding. Prøv igjen, eller kontakt IT hvis feilen vedvarer."
    >
      <FormControl isInvalid={!!formErrors.message}>
        <Textarea
          label="Skriv ny tilbakemelding"
          name="logmessage"
          value={values.message}
          onChange={(e) => handleChange({ message: e.target.value })}
          onBlur={() => startValidation()}
          aria-invalid={formErrors.message !== undefined}
          aria-errormessage="logmessage-error-message"
          minHeight="10rem"
        />
        <FormErrorMessage>{formErrors.message}</FormErrorMessage>
      </FormControl>
    </ActionModal>
  ) : null;
};

export default NewLogMessageModal;
