import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { useActiveVehicleTabs } from "features/CenterContent/shared/Tabs/useTabsContext";
import { formatDateString } from "shared/utils/datetime";
import { TrainListElement } from "./TrainListElement";
import { DateHeader } from "./styles";
import { TrainData } from "./types";

type Props = {
  trainDatas: TrainData[];
  internalMessages: InternalMessageResponse[] | undefined;
};

// Section: trainDatas of the same date
export const TrainListSection = ({ trainDatas, internalMessages }: Props) => {
  const activeTrainTabs = useActiveVehicleTabs();

  const trainElementSelected = (train: TrainData) =>
    activeTrainTabs.filter(
      (it: any) =>
        it.trainNumber === train.trainNumber &&
        it.trainDate === train.trainDate,
    ).length !== 0;

  const date = trainDatas[0].trainDate;
  return (
    <>
      <DateHeader>{formatDateString(date, "dd.MM.yy")}</DateHeader>
      {trainDatas.map((train: TrainData) => (
        <TrainListElement
          key={`trainsList_item_${train.trainNumber}_${train.trainDate}`}
          train={train}
          trainElementSelected={trainElementSelected(train)}
          internalMessages={internalMessages}
        />
      ))}
    </>
  );
};
