import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

export const ruleEditLabel = "addRule";
export const customReasonLabel = { NOB: "årsak", ENG: "engelsk årsak" };

type State = {
  openEditFields: string[];
};

type Action = {
  addToOpenEditFields: (fieldName: string) => void;
  removeFromOpenEditFields: (fieldName: string) => void;
};

export const useOpenEditFields = createWithEqualityFn<State & Action>(
  (set) => ({
    openEditFields: [],
    addToOpenEditFields: (newFieldName) =>
      set((oldState) => ({
        openEditFields: [...oldState.openEditFields, newFieldName],
      })),
    removeFromOpenEditFields: (newFieldName) =>
      set((oldState) => ({
        openEditFields: oldState.openEditFields.filter(
          (oldFieldName) => oldFieldName !== newFieldName,
        ),
      })),
  }),
  shallow,
);
