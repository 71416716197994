import { zodResolver } from "@hookform/resolvers/zod";
import { fromDate, getLocalTimeZone } from "@internationalized/date";
import { useQuery } from "@tanstack/react-query";
import { Skeleton, Stack, Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { formToGlobalInformationRequest } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import { getDefaultValues as getGlobalDefaultValues } from "features/CenterContent/RoleContent/GlobalEvents/globalEventDefaultValues";
import {
  GlobalEventResponse,
  GlobalFormSchema,
  globalEventsResponseSchema,
  globalFormSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { usePostGlobalInfo } from "features/CenterContent/RoleContent/GlobalEvents/usePostGlobalInfo";
import { createInternalMessage } from "features/CenterContent/RoleContent/InternalMessage/utils/queries";
import { InfrastructureModalContent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureModalContent";
import { MajorEventWarning } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/MajorEventWarning";
import { ReferencedGlobalEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/ReferencedGlobalEvent";
import { usePostInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/usePostInfrastructureEvent";
import {
  InfrastructureForm as InfraFormSchema,
  formSchema as infraFormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import {
  formToRequest,
  responseToForm,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/transformers";
import { getStretchName } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { useDepublishInformation } from "features/OperationalInformation/useDepublishInformation";
import { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import {
  isInfrastructureStretchClosedEvent,
  isPlannedInfraStructureEvent,
} from "shared/utils/infrastructureEvent";
import { useOpenEditFields } from "stores/useOpenEditFields";
import { useStretchBuilder } from "stores/useStretchBuilder";

const FIELDS_AFFECTING_TEXT = [
  "stretchName",
  "reason",
  "action",
  "consequence",
  "prognosis",
  "prognosisTime",
  "event",
];

export const GLOBAL_INFO_RELATED_TO_INCIDENT_QUERY_KEY =
  "globalInfoRelatedToIncident";

export const InfrastructureEvent: FC = () => {
  const [
    clickedStops,
    selectedLegs,
    allStopsSelected,
    stretchBuilderModalState,
    closeAndResetStretchBuilder,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.stretchBuilderModalState,
    state.closeAndResetStretchBuilder,
  ]);

  const event =
    (stretchBuilderModalState.type === "infrastructure" &&
      stretchBuilderModalState.eventInfo) ||
    undefined;

  const isEditMode = !!event;

  const {
    data: globalInformationData,
    isSuccess: globalInfoIsSuccess,
    isPending: globaInfoIsPending,
    isFetching: globalInfoIsFetching,
    isError: globalInfoIsError,
  } = useQuery({
    queryKey: [GLOBAL_INFO_RELATED_TO_INCIDENT_QUERY_KEY, event?.incidentId],
    queryFn: ({ signal }) =>
      queryFnGET<GlobalEventResponse[]>({
        signal,
        url: `${getBackendUrl()}/global-information/by-incident-id/${event!.incidentId}`,
      }).then((res) => globalEventsResponseSchema.parse(res)),
    enabled: !!event,
    staleTime: 0,
  });

  const globalInformation = globalInformationData
    ? globalInformationData.filter(
        (globalEvent) => globalEvent.type === "GLOBAL_GENERAL_WARNING",
      )
    : [];
  const relatedGlobalInformation =
    globalInformation.length > 0 ? globalInformation[0] : undefined;

  const infrastructureDefaultValues = responseToForm(event);

  const infrastructureFormMethods = useForm<InfraFormSchema>({
    resolver: zodResolver(infraFormSchema),
    defaultValues: infrastructureDefaultValues,
  });

  const globalFormMethods = useForm<GlobalFormSchema>({
    resolver: zodResolver(globalFormSchema),
    values: getGlobalDefaultValues(
      event
        ? {
            uuid: event.uuid,
            incidentId: event.incidentId ?? "",
            texts: {
              NOB: { title: event.stretchName, description: event.message },
            },
            visibility: {
              type: "OPEN_ENDED",
              fromTime: fromDate(new Date(), getLocalTimeZone()),
            },
          }
        : undefined,
      relatedGlobalInformation,
    ),
  });

  const {
    mutateAsync: postInfraInfo,
    isPending: isPostInfrastructureRequestPending,
    isSuccess: isPostInfrastructureRequestSuccess,
    isError: isPostInfrastructureRequestError,
    data: postInfrastructureRequestData,
  } = usePostInfrastructureEvent(event?.uuid);

  const {
    mutate: postGlobalInfo,
    isPending: isPostGlobalRequestPending,
    isSuccess: isPostGlobalRequestSuccess,
    isError: isPostGlobalRequestError,
  } = usePostGlobalInfo(relatedGlobalInformation?.uuid);

  const { mutate: depublishGlobalInfo } = useDepublishInformation();

  const [shouldPostGlobalInfo, setShouldPostGlobalInfo] = useState(false);
  const [shouldDepublishGlobalInfo, setShouldDepublishGlobalInfo] =
    useState(false);
  const [isMajorEvent, setIsMajorEvent] = useState(false);

  const {
    handleSubmit: handleInfrastructureSubmit,
    trigger: triggerInfrastructureValidation,
  } = infrastructureFormMethods;

  const { handleSubmit: handleGlobalSubmit, trigger: triggerGlobalValidation } =
    globalFormMethods;

  const openEditFields = useOpenEditFields((state) => state.openEditFields);

  const legsIncludeStop = (
    stopId: string,
    legs: { fromStop: string; toStop: string }[],
  ) => legs.some((leg) => leg.fromStop === stopId || leg.toStop === stopId);

  const onSubmit = handleInfrastructureSubmit((data) => {
    const requestBody = formToRequest(data.infrastructureForm);
    postInfraInfo(requestBody, {
      onSuccess: () => {
        const form = data.infrastructureForm;
        const osloSIsClosed =
          legsIncludeStop("OSL", form.affectedLegs) &&
          isInfrastructureStretchClosedEvent(form.type);
        const minimumThirtyMinutes =
          form.durations.type === "OPEN_ENDED" &&
          Number(form.durations.extension_time_in_minutes) >= 30;

        if (osloSIsClosed && minimumThirtyMinutes && !isEditMode) {
          setIsMajorEvent(true);
        }
      },
    }).then(createInternalMessage(data.infrastructureForm.internalMessages));
  });

  const onGlobalEventSubmit = handleGlobalSubmit((globalFormData) =>
    postGlobalInfo(
      formToGlobalInformationRequest(
        globalFormData.globalForm,
        event?.incidentId ?? undefined,
      ),
    ),
  );

  if (stretchBuilderModalState.type !== "infrastructure") return null;

  if (isMajorEvent && isPostInfrastructureRequestSuccess) {
    return (
      <MajorEventWarning
        response={postInfrastructureRequestData}
        onClose={closeAndResetStretchBuilder}
      />
    );
  }

  const changedInfrastructureFields =
    infrastructureFormMethods.formState.dirtyFields.infrastructureForm;
  const changedFieldNames = changedInfrastructureFields
    ? Object.keys(changedInfrastructureFields)
    : [];

  const isChangedText = changedFieldNames.some((field) =>
    FIELDS_AFFECTING_TEXT.includes(field),
  );

  return (
    <FormProvider {...infrastructureFormMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <ActionModal
          title={`${isEditMode ? "Endre" : "Opprett"} infrastrukturhendelse`}
          actionTitle={`${isEditMode ? "Endre" : "Opprett"} hendelse`}
          onClose={() => {
            if (!isMajorEvent) closeAndResetStretchBuilder();
          }}
          onSubmit={async () => {
            const globalIsValid = await triggerGlobalValidation();
            const infraIsValid = await triggerInfrastructureValidation();
            if (infraIsValid) {
              if (shouldPostGlobalInfo && globalIsValid) {
                onGlobalEventSubmit();
              }
              if (shouldDepublishGlobalInfo && relatedGlobalInformation) {
                depublishGlobalInfo(relatedGlobalInformation.uuid);
              }
              onSubmit();
            }
          }}
          isLoading={
            isPostInfrastructureRequestPending || isPostGlobalRequestPending
          }
          isSuccess={
            isPostInfrastructureRequestSuccess &&
            (shouldPostGlobalInfo ? isPostGlobalRequestSuccess : true)
          }
          isError={isPostInfrastructureRequestError || isPostGlobalRequestError}
          isSubmitDisabled={openEditFields.length > 0}
          successMessage={`Hendelse ${isEditMode ? "endret" : "opprettet"}`}
          failureMessage={`Kunne ikke ${isEditMode ? "endre" : "opprette"} hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
          internalMessagesFormField="infrastructureForm.internalMessages"
          internalMessagesMode={isEditMode ? "edit" : "create"}
        >
          <Stack gap={4} width="100%">
            <InfrastructureModalContent
              stretchTitle={
                clickedStops.length < 1 && isEditMode
                  ? event.stretchName
                  : getStretchName(
                      selectedLegs,
                      clickedStops,
                      allStopsSelected,
                    ).join(", ")
              }
            />
            {relatedGlobalInformation && isChangedText && (
              <DropsStaticAlert variant="warning" title="OBS!">
                <Text>
                  Du har endret infrastrukturhendelsen på en måte som kanskje
                  påvirker den globale meldingen
                </Text>
              </DropsStaticAlert>
            )}
            {isEditMode &&
              !isPlannedInfraStructureEvent(
                infrastructureFormMethods.getValues("infrastructureForm.type"),
              ) && (
                <>
                  {globaInfoIsPending && globalInfoIsFetching && (
                    <Skeleton height={6} width="100%" />
                  )}
                  {globalInfoIsError && (
                    <DropsStaticAlert variant="error">
                      Kunne ikke hente global driftsmelding
                    </DropsStaticAlert>
                  )}
                  {globalInfoIsSuccess && (
                    <ReferencedGlobalEvent
                      globalEventFormMethods={globalFormMethods}
                      shouldPost={shouldPostGlobalInfo}
                      setShouldPost={setShouldPostGlobalInfo}
                      shouldDepublish={shouldDepublishGlobalInfo}
                      setShouldDepublish={setShouldDepublishGlobalInfo}
                      relatedGlobalInformation={relatedGlobalInformation}
                    />
                  )}
                </>
              )}
          </Stack>
        </ActionModal>
      ) : (
        <div />
      )}
    </FormProvider>
  );
};
