import { useQuery } from "@tanstack/react-query";
import { EditOutline18Icon } from "@vygruppen/spor-icon-react";
import { Button, HStack } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { FC, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import * as z from "zod";
import { secondsToMilliseconds } from "date-fns";
import NewLogMessageModal from "./NewLogMessageModal";
import ReadLogMessagesModal from "./ReadLogMessagesModal";
import { incidentLogResponseSchema } from "./types";

type ISirkLogButtonsProps = { isirk: string };

const ISirkLogButtons: FC<ISirkLogButtonsProps> = ({ isirk }) => {
  const [showNewLogMessageModal, setShowNewLogMessageModal] = useState(false);
  const [showReadLogMessagesModal, setShowReadLogMessagesModal] =
    useState(false);

  const { isError, isSuccess, data } = useQuery({
    queryKey: ["iSirkLogs", isirk],
    refetchInterval: secondsToMilliseconds(30),
    queryFn: async ({ signal }) =>
      axiosClient
        .get<
          z.infer<typeof incidentLogResponseSchema>
        >(`${getBackendUrl()}/alternative-transport/incident-logs?isirkIds=${isirk}&countryCode=NO`, { signal })
        .then((res) => incidentLogResponseSchema.parse(res.data)),
  });

  return (
    <HStack minWidth="100%" pt={1} pb={1} justifyContent="flex-end">
      <Button
        leftIcon={<EditOutline18Icon />}
        variant="secondary"
        size="sm"
        onClick={() => setShowNewLogMessageModal(true)}
      >
        Ny tilbakemelding
      </Button>
      {isSuccess && !!data.incidentLogs.length && (
        <>
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => setShowReadLogMessagesModal(true)}
          >
            Se tilbakemeldinger ({data.incidentLogs.length})
          </Button>
          <ReadLogMessagesModal
            isOpen={showReadLogMessagesModal}
            setIsOpen={setShowReadLogMessagesModal}
            isirkId={isirk}
            messages={data.incidentLogs || []}
          />
        </>
      )}
      {isError && (
        <FailureMessage customMessage="Feil med henting av tilbakemeldinger" />
      )}
      <NewLogMessageModal
        isirkId={isirk}
        isOpen={showNewLogMessageModal}
        setIsOpen={setShowNewLogMessageModal}
      />
    </HStack>
  );
};

export default ISirkLogButtons;
