import { WarningOutline18Icon } from "@vygruppen/spor-icon-react";
import { Button, Flex, HStack, Stack, Text } from "@vygruppen/spor-react";
import { format } from "date-fns";
import { GlobalEventModalContent } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModalContent";
import {
  GlobalEventResponse,
  GlobalFormSchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { EditGlobalEventCheckbox } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/EditGlobalEventCheckbox";
import { FC, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { ChevronDownButton } from "shared/components/buttons/ChevronButton";
import { PlusButton } from "shared/components/buttons/PlusButton";
import { useTheme } from "styled-components";

type ButtonProps = {
  active: boolean;
  title: string;
  undoTitle: string;
  action: () => void;
  undoAction: () => void;
};

const DepublishReopenButton: FC<ButtonProps> = ({
  active,
  title,
  undoTitle,
  action,
  undoAction,
}) => (
  <Flex justifyContent="flex-end">
    <Button
      onClick={() => (active ? undoAction() : action())}
      variant="tertiary"
      size="xs"
    >
      {active ? undoTitle : title}
    </Button>
  </Flex>
);

type ReferencedGlobalEventProps = {
  globalEventFormMethods: UseFormReturn<GlobalFormSchema>;
  shouldPost: boolean;
  setShouldPost: (value: boolean) => void;
  shouldDepublish: boolean;
  setShouldDepublish: (value: boolean) => void;
  relatedGlobalInformation?: GlobalEventResponse;
};

export const ReferencedGlobalEvent: FC<ReferencedGlobalEventProps> = ({
  globalEventFormMethods,
  shouldPost,
  setShouldPost,
  shouldDepublish,
  setShouldDepublish,
  relatedGlobalInformation,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [editChecked, setEditChecked] = useState(false);
  const theme = useTheme();
  const hasGlobalInfo = !!relatedGlobalInformation;

  useEffect(() => {
    if (!hasGlobalInfo) {
      setShouldPost(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (hasGlobalInfo) {
      setShouldPost(editChecked);
    }
  }, [editChecked]);

  return (
    <Stack
      width="100%"
      gap={4}
      paddingX={3}
      paddingY={2}
      boxShadow={`inset 0 0 0 1px ${theme.colorSeparationLine}`}
      borderRadius="sm"
    >
      <HStack justifyContent="space-between">
        <HStack>
          <WarningOutline18Icon />
          <Text variant="xs" fontWeight="bold">
            Global driftsmelding
          </Text>
        </HStack>
        {hasGlobalInfo ? (
          <ChevronDownButton active={isOpen} setActive={setIsOpen} />
        ) : (
          <PlusButton active={isOpen} setActive={setIsOpen} />
        )}
      </HStack>
      {isOpen && (
        <>
          {hasGlobalInfo && (
            <Stack
              borderBottom={`1px solid ${theme.colorSeparationLine}`}
              gap={4}
              paddingBottom={3}
            >
              <Text variant="xs" width="100%">
                <span style={{ fontWeight: "bold" }}>Sist oppdatert:</span>{" "}
                {format(
                  relatedGlobalInformation.updatedAt.toDate(),
                  "dd.MM.yyyy 'kl.' HH:mm",
                )}
              </Text>
              {((relatedGlobalInformation.isVisible && !shouldDepublish) ||
                (!relatedGlobalInformation.isVisible && shouldPost)) && (
                <EditGlobalEventCheckbox
                  editGlobalEvent={editChecked}
                  setEditGlobalEvent={setEditChecked}
                />
              )}
              {shouldDepublish && (
                <Text variant="xs">
                  Global driftsmelding vil bli depublisert.
                </Text>
              )}
              {relatedGlobalInformation.isVisible ? (
                <DepublishReopenButton
                  active={shouldDepublish}
                  title="Depubliser driftsmelding"
                  undoTitle="Angre"
                  action={() => {
                    setShouldDepublish(true);
                    setEditChecked(false);
                  }}
                  undoAction={() => setShouldDepublish(false)}
                />
              ) : (
                <DepublishReopenButton
                  active={shouldPost}
                  title="Gjenåpne driftsmelding"
                  undoTitle="Angre gjenåpning"
                  action={() => setEditChecked(true)}
                  undoAction={() => setEditChecked(false)}
                />
              )}
            </Stack>
          )}
          <GlobalEventModalContent
            formMethods={globalEventFormMethods}
            isDisabled={hasGlobalInfo && !editChecked}
          />
        </>
      )}
    </Stack>
  );
};
