import { InfrastructureResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import {
  Leg,
  Stop,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import { StationEventResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
import { useDropsRole } from "./useDropsRole";

type EventState = {
  eventMode: boolean;
};

type EventAction = {
  setEventMode: (newState: EventState["eventMode"]) => void;
};

export const useEventMode = createWithEqualityFn<EventState & EventAction>(
  (set) => ({
    eventMode: false,
    setEventMode: (newState) => set(() => ({ eventMode: newState })),
  }),
);

export const setEventMode = (newState: boolean) => {
  useEventMode.getState().setEventMode(newState);
};

export type StretchBuilderModalState =
  | {
      type: "infrastructure";
      stretchBuilderMode: boolean;
      eventInfo?: InfrastructureResponse;
    }
  | {
      type: "station";
      stretchBuilderMode: boolean;
      eventInfo?: StationEventResponse;
    }
  | {
      type: "closeOrEdit";
      uuids: { infrastructure: string[]; station: string[] };
    }
  | { type: "none" };

type State = {
  clickedStops: Stop[];
  selectedLegs: Leg[];
  selectedStops: Stop[];
  allStopsSelected: boolean;
  stretchBuilderModalState: StretchBuilderModalState;
};

type Action = {
  setClickedStops: (newState: State["clickedStops"]) => void;
  setSelectedLegs: (newState: State["selectedLegs"]) => void;
  setSelectedStops: (newState: State["selectedStops"]) => void;
  setAllStopsSelected: (newState: State["allStopsSelected"]) => void;
  setStretchBuilderModalState: (
    newState: State["stretchBuilderModalState"],
  ) => void;
  closeAndResetStretchBuilder: () => void;
  isStretchBuilderMode: () => boolean;
};

export const useStretchBuilder = createWithEqualityFn<State & Action>(
  (set, get) => ({
    clickedStops: [],
    selectedLegs: [],
    selectedStops: [],
    allStopsSelected: false,
    stretchBuilderModalState: { type: "none" },
    setClickedStops: (newState) => set(() => ({ clickedStops: newState })),
    setSelectedLegs: (newState) => set(() => ({ selectedLegs: newState })),
    setSelectedStops: (newState) => set(() => ({ selectedStops: newState })),
    setAllStopsSelected: (newState) =>
      set(() => ({ allStopsSelected: newState })),
    setStretchBuilderModalState: (newState) =>
      set(() => ({
        stretchBuilderModalState: useDropsRole.getState().isVaktleder()
          ? newState
          : { type: "none" },
      })),
    closeAndResetStretchBuilder: () => {
      useEventMode.getState().setEventMode(false);
      set(() => ({
        stretchBuilderModalState: { type: "none" },
        clickedStops: [],
        selectedLegs: [],
        selectedStops: [],
        allStopsSelected: false,
      }));
    },
    isStretchBuilderMode: () => {
      const state = get().stretchBuilderModalState;
      return (
        (state.type === "infrastructure" || state.type === "station") &&
        state.stretchBuilderMode
      );
    },
  }),
  shallow,
);
