import { z } from "zod";

export enum UserRole {
  personellstyrerkond = "personellstyrerkond",
  personellstyrerlok = "personellstyrerlok",
  personellstyrervest = "personellstyrervest",
  vaktleder = "vaktleder",
  materiellstyrer = "materiellstyrer", // TODO Fjernes når alle er over på nye roller
  materiellstyrerlokal = "materiellstyrerlokal",
  materiellstyrerregion = "materiellstyrerregion",
  trafikkplanlegger = "trafikkplanlegger",
  lokleder = "lokleder", // TODO Fjernes når alle er over på nye roller
  lokleder1 = "lokleder1",
  lokleder2 = "lokleder2",
  transportleder = "transportleder",
}

export const UserRoleSchema = z.enum([
  UserRole.personellstyrerlok,
  UserRole.personellstyrervest,
  UserRole.vaktleder,
  UserRole.materiellstyrerlokal,
  UserRole.materiellstyrerregion,
  UserRole.trafikkplanlegger,
  UserRole.lokleder1,
  UserRole.lokleder2,
  UserRole.transportleder,
]);

export const isValidUserRole = (value: string | undefined): value is UserRole =>
  !!value && Object.keys(UserRole).includes(value);

const baseRoles = [
  {
    text: "Vaktleder",
    value: UserRole.vaktleder,
    abbreviation: "vl",
  },
  {
    text: "Transportleder",
    value: UserRole.transportleder,
    abbreviation: "tl",
  },
  {
    text: "Personellstyrer (konduktør)",
    value: UserRole.personellstyrerkond,
    abbreviation: "psk",
  },
  {
    text: "Personellstyrer (lokomotivfører)",
    value: UserRole.personellstyrerlok,
    abbreviation: "psl",
  },
  {
    text: "Personellstyrer (vest)",
    value: UserRole.personellstyrervest,
    abbreviation: "psv",
  },
  {
    text: "Materiellstyrer (lokaltog)",
    value: UserRole.materiellstyrerlokal,
    abbreviation: "msl",
  },
  {
    text: "Materiellstyrer (regiontog)",
    value: UserRole.materiellstyrerregion,
    abbreviation: "msr",
  },
  {
    text: "Trafikkplanlegger",
    value: UserRole.trafikkplanlegger,
    abbreviation: "tp",
  },
];

export const ROLE_TEXTS = [
  ...baseRoles,
  {
    text: "Lokleder 1",
    value: UserRole.lokleder1,
    abbreviation: "ll1",
  },
  {
    text: "Lokleder 2",
    value: UserRole.lokleder2,
    abbreviation: "ll2",
  },
];

export const TODO_ROLES = [
  ...baseRoles,
  {
    text: "Lokleder",
    value: UserRole.lokleder1,
    abbreviation: "ll",
  },
];

export const getRoleText = (roleValue: string) => {
  if (roleValue === UserRole.lokleder1) {
    return "Lokleder";
  }

  return ROLE_TEXTS.find((role) => role.value === roleValue)?.text;
};

export const getTodoRole = (currentRole: UserRole) => {
  switch (currentRole) {
    case UserRole.lokleder1:
    case UserRole.lokleder2:
      return [UserRole.lokleder1];
    default:
      return [currentRole];
  }
};
