import { Box } from "@vygruppen/spor-react";
import { setLocalStorageTodoFilterSettings } from "api/dropsLocalStorage";
import { log, LogLevel } from "logging/datadogBrowserLogs";
import { useEffect } from "react";
import { Text } from "shared/components/typography/TitleStyles";
import { getTodoRole, UserRole } from "shared/types/roles";
import { useDropsRole } from "stores/useDropsRole";
import SkeletonLoader from "../../../shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ListContainer, TodoMessageWrapper } from "./TodoStyles";
import { defaultTodoFilterState } from "./todoFilter/useTodoFilterState";
import TodoList from "./todoList/TodoList";
import { useData, useStatus } from "./useTodoContext";

export type TodoStatus = "active" | "inActive";
export type TodoFilterStatus = "incomplete" | "completed" | "futureAlarms";

export type TodoFilterState = {
  roles: UserRole[];
  vehicleSetTypes: string[];
  trainNumber: string;
  todoStatus: TodoFilterStatus[];
};

const TodoComponent = ({
  todoFilterState,
  setTodoFilterState,
}: {
  todoFilterState: TodoFilterState;
  setTodoFilterState: (state: TodoFilterState) => void;
}) => {
  useEffect(() => {
    setLocalStorageTodoFilterSettings(todoFilterState);
  }, [todoFilterState]);

  const data = useData();
  const status = useStatus();

  const { role: currentRole } = useDropsRole();

  useEffect(() => {
    if (!todoFilterState.roles.includes(currentRole)) {
      setTodoFilterState({
        ...defaultTodoFilterState,
        roles: getTodoRole(currentRole),
      });
    }
  }, [currentRole]);

  switch (status) {
    case "pending":
      return (
        <TodoMessageWrapper>
          <SkeletonLoader skeletonType="todo" />
        </TodoMessageWrapper>
      );
    case "error":
      log(LogLevel.error, "TodoComponent", "Feil ved henting av todo-data");
      return (
        <TodoMessageWrapper>
          <Box m="8px">
            <Text>Feil ved henting av todo-data</Text>
          </Box>
        </TodoMessageWrapper>
      );
    case "success":
      return (
        <TodoMessageWrapper>
          <ListContainer>
            {data && (
              <TodoList todoList={data} todoFilterState={todoFilterState} />
            )}
          </ListContainer>
        </TodoMessageWrapper>
      );
    default:
      return null;
  }
};

export default TodoComponent;
