import { getLocalTimeZone, toZoned } from "@internationalized/date";
import { companyId, RollingSockType } from "../utils";
import { ChecklistFormData } from "./formSchema";
import { FilloutRequest } from "./requestSchema";

export const FilloutFormToRequest = (
  data: ChecklistFormData,
): FilloutRequest => {
  const zonedDateTime = toZoned(data.dateTime, getLocalTimeZone());
  const executedAt = zonedDateTime.toString();

  const checklistData: FilloutRequest = {
    companyId: data.trainOwner as companyId,
    rollingStockId: data.rollingStockId,
    rollingStockType: RollingSockType.VEHICLE_SET,
    executedAt,
    checklistType: data.fillOutType,
    vehicleType: data.vehicleType,
    comment: data.comment,
  };

  return checklistData;
};
