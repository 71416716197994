import { toCalendarDate, toTime } from "@internationalized/date";
import {
  Box,
  DatePicker,
  FormControl,
  FormErrorMessage,
  Text,
  TimePicker,
} from "@vygruppen/spor-react";
import { VisibilitySchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { FieldValues, UseFormReturn } from "react-hook-form";
import { EndsWith } from "shared/utils/objectUtils";

export type HasVisibilityFormSchema = FieldValues & {
  [key: string]: {
    visibility: VisibilitySchema;
  };
};

type VisibilityInputProps<T extends HasVisibilityFormSchema> = {
  isRelatedToIncident: boolean;
  formMethods: UseFormReturn<T>;
  formField: EndsWith<".visibility">;
};

// T: A form that has a visibility field
export const VisibilityInput = <T extends HasVisibilityFormSchema>({
  isRelatedToIncident,
  formMethods,
  formField,
}: VisibilityInputProps<T>) => {
  const { watch, setValue, formState } =
    formMethods as UseFormReturn<HasVisibilityFormSchema>;

  const visibility = watch(formField);
  if (visibility === undefined) {
    console.log(
      `FormField (${formField}) does not have a defaultValue. This component is most likely set up wrong.\n
       Use internalMessagesFormDefault() or internalMessagesResponseToForm() as starting value.`,
    );
    return null;
  }

  const fieldError = formState.errors.root;
  const dateError =
    !!fieldError &&
    visibility.type === "TIMED" &&
    visibility.fromTime.day > visibility.toTime.day;

  if (!isRelatedToIncident && visibility.type !== "TIMED") {
    return null;
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns="1fr 1fr"
      rowGap={1}
      columnGap={2}
      width="100%"
      paddingY={2}
    >
      <Text variant="xs" gridColumn="span 2">
        Synlig inntil:
      </Text>
      {isRelatedToIncident && (
        <Text variant="xs" fontWeight="bold" gridColumn="span 2">
          Følger synlighet til tilhørende infrastrukturmelding
        </Text>
      )}
      {visibility.type === "TIMED" && (
        <>
          <FormControl isInvalid={dateError}>
            <DatePicker
              withPortal={false}
              width="100%"
              variant="base"
              label="Dato"
              value={toCalendarDate(visibility.toTime)}
              onChange={(date) => {
                if (date !== null) {
                  setValue(`${formField}.toTime.day`, date.day ?? 0);
                  setValue(`${formField}.toTime.month`, date.month ?? 0);
                  setValue(`${formField}.toTime.year`, date.year ?? 0);
                }
              }}
            />
            <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!fieldError && !dateError}>
            <TimePicker
              width="100%"
              label="Tidspunkt"
              value={toTime(visibility.toTime)}
              minuteInterval={5}
              onChange={(time) => {
                if (time !== null) {
                  setValue(`${formField}.toTime.hour`, time.hour ?? 0);
                  setValue(`${formField}.toTime.minute`, time.minute ?? 0);
                }
              }}
            />
            <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
          </FormControl>
        </>
      )}
    </Box>
  );
};
