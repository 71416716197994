import { UseQueryResult } from "@tanstack/react-query";
import {
  CalendarFill18Icon,
  CalendarOutline18Icon,
  WarningFill18Icon,
  WarningOutline18Icon,
} from "@vygruppen/spor-icon-react";
import {
  HStack,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonCircle,
  SkeletonText,
  ModalBody as SporModalBody,
  VStack,
} from "@vygruppen/spor-react";
import { GlobalEventModal } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModal";
import { GlobalEventResponse } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import EditInternalMessageModal from "features/CenterContent/RoleContent/InternalMessage/EditInternalMessageModal";
import { InfrastructureResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { GlobalEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/GlobalEvents";
import { InternalMessageEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/InternalMessageEvents";
import { UpcomingEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEvents";
import { Tab, TabsSides } from "features/CenterContent/shared/Tabs/TabsSides";
import { FC, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { useDropsRole } from "stores/useDropsRole";
import styled from "styled-components";
import { InternalMessageResponse } from "../../stationEvents/stationEventResponseSchema";

const ModalBody = styled(SporModalBody)`
  ${ObtrusiveScrollCss};
  overflow-y: auto;
  display: grid;

  /* Hide scrollbar */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

type UpcomingEventsModalProps = {
  onClose: () => void;
  header: string;
  selectedTab: string;
  upcomingInfrastructureEvents?: UseQueryResult<InfrastructureResponse[]>;
  globalEvents?: UseQueryResult<GlobalEventResponse[]>;
  internalMessageEvents?: UseQueryResult<InternalMessageResponse[]>;
};

export const EventsOverviewModal: FC<UpcomingEventsModalProps> = ({
  onClose,
  header,
  selectedTab,
  upcomingInfrastructureEvents,
  globalEvents,
  internalMessageEvents,
}) => {
  const { isVaktleder } = useDropsRole();

  const numGlobalEvents = globalEvents?.data?.length ?? 0;

  const tabs: Tab[] = [
    globalEvents && {
      id: "globalEvents",
      iconInactive: <WarningOutline18Icon />,
      iconActive: <WarningFill18Icon />,
      text: `Globale ${globalEvents.isSuccess ? `(${numGlobalEvents})` : ""}`,
    },
    upcomingInfrastructureEvents && {
      id: "upcomingEvents",
      iconInactive: <CalendarOutline18Icon />,
      iconActive: <CalendarFill18Icon />,
      text: `Fremtidige ${upcomingInfrastructureEvents.isSuccess ? `(${upcomingInfrastructureEvents.data.length})` : ""}`,
    },
    // No need for a tab for internalMessages, since showTabs = false during its modal
  ].filter(Boolean) as Tab[];

  const [selectedTabIds, setSelectedTabIds] = useState([selectedTab]);

  const [internalEventToEdit, setInternalEventToEdit] =
    useState<InternalMessageResponse | null>(null);
  const [globalEventToEdit, setGlobalEventToEdit] =
    useState<GlobalEventResponse | null>(null);

  const isPending =
    upcomingInfrastructureEvents?.isPending ||
    globalEvents?.isPending ||
    internalMessageEvents?.isPending;

  const isFailure =
    (selectedTabIds.includes("globalEvents") && globalEvents?.isError) ||
    (selectedTabIds.includes("upcomingEvents") &&
      upcomingInfrastructureEvents?.isError) ||
    (selectedTabIds.includes("internalEvents") &&
      internalMessageEvents?.isError);

  const showTabs = tabs.length > 1 && isVaktleder();

  if (globalEventToEdit) {
    // We send the uuid instead of the event since we need to retrieve
    // the event using /by-uuid in order to retrieve internal messages
    return <GlobalEventModal onClose={onClose} uuid={globalEventToEdit.uuid} />;
  }
  if (internalEventToEdit) {
    return (
      <EditInternalMessageModal
        setModalOpen={onClose}
        uuid={internalEventToEdit.uuid}
      />
    );
  }
  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="80vh" minH="80vh" minW="700px">
        <ModalHeader>{header}</ModalHeader>
        <ModalCloseButton />
        {showTabs && (
          <TabsSides
            tabs={tabs}
            selectedTabIds={selectedTabIds}
            setSelectedTabIds={setSelectedTabIds}
            justifyContent="start"
            ml="18px"
          />
        )}
        <ModalBody gap={2}>
          {isPending && (
            <VStack gap={1} w="100%">
              {Array.from(Array(3)).map((_, i) => (
                <HStack
                  key={i}
                  w="100%"
                  justify="space-between"
                  alignItems="flex-top"
                  h="100px"
                >
                  <SkeletonText noOfLines={4} w="70%" />
                  <SkeletonCircle />
                </HStack>
              ))}
            </VStack>
          )}
          {isFailure && <FailureMessage />}

          {globalEvents?.isSuccess &&
            selectedTabIds.includes("globalEvents") && (
              <GlobalEvents
                data={globalEvents.data}
                onEditClick={(event) => {
                  setGlobalEventToEdit(event);
                }}
              />
            )}

          {upcomingInfrastructureEvents?.isSuccess &&
            selectedTabIds.includes("upcomingEvents") && (
              <UpcomingEvents data={upcomingInfrastructureEvents.data} />
            )}

          {internalMessageEvents?.isSuccess &&
            selectedTabIds.includes("internalEvents") && (
              <InternalMessageEvents
                data={internalMessageEvents.data}
                onEditClick={(event) => {
                  setInternalEventToEdit(event);
                }}
              />
            )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
