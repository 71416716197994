import { Box, HStack, TabPanels } from "@vygruppen/spor-react";
import styled from "styled-components";
import TabWithPulse from "./TabWithPulse";

export const AffectedTrainGrid = styled.li<{ $borderColor: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 12px;
  background: ${({ theme }) => theme.colorBackgroundSecondary};
  border-radius: 6px;
  border-right: 1px solid ${({ $borderColor }) => $borderColor};
  border-left: 1px solid ${({ $borderColor }) => $borderColor};

  &:first-child {
    border-top: 1px solid ${({ $borderColor }) => $borderColor};
  }
`;

export const VStackContainer = styled.div`
  container-type: inline-size;
  container-name: VStackContainer;
`;

export const VStackWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 6px;

  grid-template-areas:
    "trainId"
    "stations"
    "affected"
    "badge";
  align-items: center;

  @container VStackContainer (width > 300px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "trainId affected"
      "stations affected"
      "badge affected";
  }

  @container VStackContainer (width > 500px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
      "trainId badge affected"
      "stations badge affected";
  }
`;

export const AffectedTrainsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow-y: auto;
  width: 100%;
  height: 100%;
`;

export const TabPanelsStyled = styled(TabPanels)`
  border-top: 1px solid ${({ theme }) => theme.colorSeparationLine};
  overflow-y: auto;
`;

export const TabPanelContent = styled.div`
  height: 100%;
`;

export const InfoBox = styled(Box)`
  padding: 12px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-top: 1px solid ${({ theme }) => theme.colorSeparationLine};
  color: ${({ theme }) => theme.colorTextSecondary};
  container-type: inline-size;

  p {
    @container (width < 350px) {
      font-size: 14px !important;
    }
  }
`;

export const ButtonGroupFitter = styled(HStack)`
  button {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
`;

export const StyledTabWithPulse = styled(TabWithPulse)`
  max-width: 10px;
  * * {
    max-width: 10px;
  }
`;
