import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import styled from "styled-components";
import { OpLoggInput } from "./OploggInput";
import OploggMessages from "./OploggMessages";

const OploggWrapper = styled.div`
  display: grid;
  grid-template-areas: "input" "messages";
  grid-template-rows: auto 1fr;

  min-height: 0;
  height: 100%;

  width: 100%;
  overflow-y: auto;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-bottom: 1px solid ${({ theme }) => theme.colorBorder};
`;

const Oplogg = () => (
  <OploggWrapper>
    <OpLoggInput />
    <OploggMessages />
  </OploggWrapper>
);

export default withErrorBoundary(Oplogg);
