import { TimeOutline18Icon } from "@vygruppen/spor-icon-react";
import { ChoiceChip } from "@vygruppen/spor-react";
import { Dispatch, SetStateAction } from "react";
import styled, { css } from "styled-components";

const Wrapper = styled.div<{ disable: boolean }>`
  display: flex;
  margin-top: 8px;
  ${({ disable }) =>
    disable &&
    css`
      [data-disabled],
      [data-disabled] > *[data-disabled] {
        cursor: not-allowed;
      }
    `}
`;

const FilterButtons = ({
  showOldVehicles,
  setShowOldVehicles,
  disable,
  overrideCheck,
}: {
  showOldVehicles: boolean;
  setShowOldVehicles: Dispatch<SetStateAction<boolean>>;
  disable: boolean;
  overrideCheck: boolean;
}) => (
  <Wrapper disable={disable}>
    <ChoiceChip
      size="md"
      isDisabled={disable}
      isChecked={overrideCheck ? true : showOldVehicles}
      onChange={() => setShowOldVehicles((prev) => !prev)}
      icon={{
        default: <TimeOutline18Icon />,
        checked: <TimeOutline18Icon />,
      }}
    >
      Vis gamle turer
    </ChoiceChip>
  </Wrapper>
);

export default FilterButtons;
