import { OperationalInfrastructureInfo, Train } from "@vygruppen/vy-train-map";
import { Environment, environment } from "app/environment/environment";
import { WebsocketClientProps } from "websocket/useWebsocketClient";
import { FIVE_SECONDS, ONE_MINUTE } from "websocket/utils";

type MessageType = "UPDATE" | "DELETE";

export interface OTRMessage {
  type: MessageType;
  train: Train;
  timestamp: number;
}

export type OperationalInfraInfoMessage = {
  type: MessageType;
  message: OperationalInfrastructureInfo;
  topic: "INFRASTRUCTURE_EVENT";
};

export const isOTRMessage = (message: any): message is OTRMessage =>
  "type" in message && "train" in message && "timestamp" in message;

export type TrainMapWebsocketMessage = OTRMessage | OperationalInfraInfoMessage;

export const getTrainMapWebsocketUrl = (): string => {
  const env = environment();

  switch (env) {
    case Environment.Development:
      return "wss://websocket.test.trafficgui.vydev.io/trainmap-backend";
    case Environment.Test:
      return "wss://websocket.test.trafficgui.vydev.io/trainmap-backend";
    case Environment.Stage:
      return "wss://websocket.stage.trafficgui.vydev.io/trainmap-backend";
    case Environment.Production:
      return "wss://websocket.trafficgui.vydev.io/trainmap-backend";
    default:
      throw new Error(`getTrainMapWebsocketUrl: Unknown environment ${env}`);
  }
};

export const TRAINMAP_WS_PROPERTIES: WebsocketClientProps = {
  url: getTrainMapWebsocketUrl(),
  pingInterval: ONE_MINUTE,
  pongTimeout: FIVE_SECONDS,
};
