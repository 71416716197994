import debounce from "debounce";
import { useEffect, useState } from "react";
import { usePuzzelCurrentlyCalling } from "stores/usePuzzleCurrentlyCalling";
import { useApiAction } from "../../../../../../api/drops/useApiAction";
import {
  initiatePhoneCall,
  performPuzzelCatalogSearch,
} from "../../../../../../api/puzzel/puzzel";
import { useOnOutsideMouseclick } from "../../../../../../shared/hooks/useOnOutsideMouseclick";
import { CallOutStyled } from "../../../PuzzelStyle";
import { outboundPhoneNumber } from "../../../outboundPhoneNumber";
import { usePuzzelExtendedStateInformation } from "../../../provider/contexts/ListenForCallStatusContext";
import { usePuzzelAuthenticationContext } from "../../../provider/contexts/PuzzelAuthenticationContext";
import { useUserDetailsState } from "../../../provider/contexts/UserDetailsAndLogOnOffContext";
import { useUserValidationState } from "../../../provider/contexts/UserValidationContext";
import { validPhoneNumber } from "../../../utils";
import { CatalogContactType, UserStatus } from "../../types";
import CatalogSearchInput from "../CatalogSearchInput";
import { CatalogSearchResults, ContactInfo } from "../CatalogSearchResults";

const NONE_SELECTED = -1;

const compareNames = (contactA: ContactInfo, contactB: ContactInfo) => {
  if (contactA.name.toUpperCase() < contactB.name.toUpperCase()) {
    return -1;
  }
  if (contactA.name.toUpperCase() > contactB.name.toUpperCase()) {
    return 1;
  }
  return 0;
};

const assembleSearchResults = (
  catalogSearchResult: CatalogContactType[],
): ContactInfo[] =>
  catalogSearchResult
    .flatMap((contact: CatalogContactType): ContactInfo[] => {
      const fullName = `${contact.firstName} ${contact.lastName}`.trim();
      const telephoneNumber = contact.communicationPoints.find(
        (communicationPoint: any) =>
          communicationPoint.communicationType === "Phone",
      )?.remoteAddress;
      const cellphoneNumber = contact.communicationPoints.find(
        (communicationPoint: any) =>
          communicationPoint.communicationType === "Cell",
      )?.remoteAddress;
      const contactPoints = [
        { name: fullName, phoneNumber: telephoneNumber },
        { name: fullName, phoneNumber: cellphoneNumber },
      ];
      if (
        contactPoints.some((contactPoint) =>
          validPhoneNumber(contactPoint.phoneNumber),
        )
      ) {
        return contactPoints.filter((contactPoint) =>
          validPhoneNumber(contactPoint.phoneNumber),
        );
      }
      return [{ name: fullName, phoneNumber: undefined }];
    })
    .sort(compareNames);

const CallOut = () => {
  const puzzelUserId = useUserValidationState().userId;
  const currentPuzzelProfileName = useUserDetailsState().currentProfile?.name;
  const outboundVisualPhoneNumber = outboundPhoneNumber(
    currentPuzzelProfileName,
  );
  const puzzelContext = usePuzzelAuthenticationContext();

  const { userStateInformation } = usePuzzelExtendedStateInformation();

  const [searchString, setSearchString] = useState("");
  const { currentlyCalling, setCurrentlyCalling } = usePuzzelCurrentlyCalling();

  const [catalogRawResponse, performStatefulCatalogSearch] = useApiAction(
    performPuzzelCatalogSearch,
    false,
  );
  const [performCatalogSearchWithDebounce] = useState(() =>
    debounce(performStatefulCatalogSearch, 200),
  );
  const [searchResults, setSearchResults] = useState<ContactInfo[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(NONE_SELECTED);

  const resetCatalogSearch = () => {
    setSearchString("");
    setSelectedIndex(NONE_SELECTED);
    setSearchResults([]);
    performCatalogSearchWithDebounce.clear();
  };

  const initiatePhoneCallWithTimeout = (numberToCall: string) => {
    initiatePhoneCall(
      numberToCall,
      // @ts-ignore
      puzzelUserId,
      puzzelContext,
      outboundVisualPhoneNumber,
    );
    setTimeout(() => {
      setCurrentlyCalling(null);
    }, 3000);
  };

  const { mouseclickRef } = useOnOutsideMouseclick(resetCatalogSearch);

  useEffect(() => {
    if (currentlyCalling) {
      initiatePhoneCallWithTimeout(currentlyCalling);
    }
  }, [currentlyCalling]);

  useEffect(() => {
    if (catalogRawResponse.data?.result) {
      setSearchResults(assembleSearchResults(catalogRawResponse.data?.result));
      setSelectedIndex(NONE_SELECTED);
    }
  }, [catalogRawResponse.data]);

  useEffect(() => {
    if (searchString !== "") {
      performCatalogSearchWithDebounce({
        searchString,
        userId: puzzelUserId,
      });
    } else {
      resetCatalogSearch();
    }
  }, [searchString]);

  let suggestionsToDisplay: ContactInfo[];
  if (validPhoneNumber(searchString)) {
    const inputPhoneNumber: ContactInfo = {
      phoneNumber: searchString,
      name: "",
    };
    suggestionsToDisplay = [inputPhoneNumber, ...searchResults];
  } else {
    suggestionsToDisplay = [...searchResults];
  }

  const keyDownHandler = (event: any) => {
    if (
      event.key === "ArrowDown" &&
      selectedIndex < suggestionsToDisplay.length - 1
    ) {
      setSelectedIndex(selectedIndex + 1);
    } else if (event.key === "ArrowUp" && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
    } else if (event.key === "Escape") {
      resetCatalogSearch();
    } else if (event.key === "Enter") {
      event.stopPropagation();
      const selectedPhoneNumber =
        suggestionsToDisplay[selectedIndex === -1 ? 0 : selectedIndex]
          ?.phoneNumber;
      if (selectedPhoneNumber) {
        setCurrentlyCalling(selectedPhoneNumber);
      }
    }
  };

  let CallOutElement = null;
  if (userStateInformation?.userStatus === UserStatus.Available) {
    const hasSuggestionsToDisplay = suggestionsToDisplay.length > 0;
    CallOutElement = (
      <CallOutStyled ref={mouseclickRef}>
        <CatalogSearchInput
          hasDropdown={hasSuggestionsToDisplay}
          onKeyDown={keyDownHandler}
          onChange={(event) => {
            const inputValue = event.target.value;
            setSearchString(inputValue);
          }}
          value={searchString}
          placeholder="Hvem vil du ringe?"
        />
        {hasSuggestionsToDisplay && (
          <CatalogSearchResults
            onPhoneNumberClick={(phoneNumber) =>
              setCurrentlyCalling(phoneNumber)
            }
            searchResults={suggestionsToDisplay}
            selectedIndex={selectedIndex}
            isCalling={currentlyCalling !== null}
          />
        )}
      </CallOutStyled>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{CallOutElement}</>;
};

export default CallOut;
