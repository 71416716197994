import {
  Button,
  ChoiceChip,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { trainIdentifierIsEqual } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import { AffectedTrain } from "features/CenterContent/RoleContent/Vaktleder/types";
import {
  TrainFormGroup,
  TrickFormHookGroupedTrainFormSchema,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { TrainSeriesGrouping } from "features/History/groupTrainSeries";
import { LogLevel, log } from "logging/datadogBrowserLogs";
import { FC } from "react";
import {
  UseFormGetValues,
  UseFormSetValue,
  useFormContext,
} from "react-hook-form";

type TrainInfoBatchModalHeaderProps = {
  activeTrainSeriesKey: `\${groupKey}`;
  activeTrainSeriesIndex: number;
  setActiveTrainSeriesIndex: (index: number) => void;
  activeTrainSeries: AffectedTrain[];
  activeTrainGrouping: TrainSeriesGrouping;
};

type SelectedTrainProps = {
  selectedTrains: AffectedTrain[];
  updateSelectedTrains: (selectedTrain: AffectedTrain) => void;
};

function removeTrainFromGroup(
  getValues: UseFormGetValues<TrickFormHookGroupedTrainFormSchema>,
  setValue: UseFormSetValue<TrickFormHookGroupedTrainFormSchema>,
  // reset: UseFormReset<TrickFormHookGroupedTrainFormSchema>,
  trainId: TrainIdentifier,
  trainSeriesKey: `\${groupKey}`,
): void {
  const trainGroups: TrainFormGroup[] = getValues(trainSeriesKey);

  // Get the index of the group that contains the current train
  const relevantTrainGroupIndex = trainGroups.findIndex(
    (trainGroup) =>
      trainGroup.trainIdsInGroup.find((id) =>
        trainIdentifierIsEqual(trainId, id),
      ) !== undefined,
  );

  if (relevantTrainGroupIndex === -1) {
    // Train isn't in any of these groups
    // Should not happen
    log(
      LogLevel.error,
      "TrainInfoBatchModalHeader/removeTrainFromGroup",
      `Train ${trainId.identifier} is not in any group in series ${trainSeriesKey}: invalid batch state`,
    );
    return;
  }

  const relevantTrainGroup = trainGroups[relevantTrainGroupIndex];

  if (
    relevantTrainGroup.trainIdsInGroup.length === 1 &&
    trainGroups.length === 1
  ) {
    // Train series will also be empty after removal, delete the entire series
    // This is not allowed for now, do nothing
    // const trainForm = getValues(); // The whole form;
    // Filter out the current train series
    /* const nextForm = Object.entries(trainForm).filter(
        ([key]) => key !== trainSeriesKey,
      );

      reset(Object.fromEntries(nextForm));
      */
  } else {
    // Series includes other trains, just remove the trainId from the state
    // Remove the train from the group, but keep the group in state
    const nextGroups = relevantTrainGroup.trainIdsInGroup.filter(
      (id) => !trainIdentifierIsEqual(id, trainId),
    );

    setValue(
      `${trainSeriesKey}.${relevantTrainGroupIndex}.trainIdsInGroup`,
      nextGroups,
    );
  }
}

const TrainInfoBatchModalHeader: FC<
  TrainInfoBatchModalHeaderProps & SelectedTrainProps
> = ({
  activeTrainSeriesKey,
  activeTrainSeriesIndex,
  setActiveTrainSeriesIndex,
  activeTrainSeries,
  activeTrainGrouping,
  selectedTrains,
  updateSelectedTrains,
}) => {
  const {
    formState: { errors },
    setValue,
    getValues,
    // reset,
  } = useFormContext<TrickFormHookGroupedTrainFormSchema>();

  const trainSeriesInActiveGroup = Object.keys(activeTrainGrouping.trainSeries);

  return (
    <div>
      {selectedTrains.length === 1 && (
        <HStack wrap="wrap">
          {selectedTrains[0].eventStretchName && (
            <Text fontWeight="bold">
              Avvik på {selectedTrains[0].eventStretchName}
            </Text>
          )}
          <Text>
            Opprett hendelse for {selectedTrains[0].trainId.identifier} (
            {selectedTrains[0].trainId.nominalDate})
          </Text>
        </HStack>
      )}

      <Text>{activeTrainGrouping.stretchName}</Text>

      {trainSeriesInActiveGroup.length > 1 ? (
        <HStack flexWrap="wrap">
          {trainSeriesInActiveGroup.map((series, i) => (
            <VStack key={series}>
              <Button
                size="sm"
                variant={activeTrainSeriesIndex === i ? "primary" : "secondary"}
                type="button"
                onClick={() => setActiveTrainSeriesIndex(i)}
              >
                {series}
              </Button>
              <FormControl isInvalid={!!errors?.[series as `\${groupKey}`]}>
                <FormErrorMessage>Mangler utfylling</FormErrorMessage>
              </FormControl>
            </VStack>
          ))}
        </HStack>
      ) : (
        <Text>{trainSeriesInActiveGroup.at(0)}</Text>
      )}

      <HStack wrap="wrap">
        {activeTrainSeries.length > 1 ? (
          activeTrainSeries.map((train, index) => (
            <ChoiceChip
              size="sm"
              chipType="filter"
              isChecked
              key={`${train.trainId.identifier}_${train.trainId.nominalDate}_${train.incidentId ?? index}`}
              onChange={() => {
                // Remove the trains from the form state
                removeTrainFromGroup(
                  getValues,
                  setValue,
                  // reset,
                  train.trainId,
                  activeTrainSeriesKey,
                );
                // Also remove the trains from the selected trains list in affectedTrains
                updateSelectedTrains(train);
              }}
            >
              {train.trainId.identifier}
            </ChoiceChip>
          ))
        ) : (
          <Text>{activeTrainSeries[0].trainId.identifier}</Text>
        )}
      </HStack>
    </div>
  );
};

export { TrainInfoBatchModalHeader };
export type { SelectedTrainProps };
