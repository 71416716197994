import {
  ListFill18Icon,
  ListOutline18Icon,
  NewspaperFill18Icon,
  NewspaperOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { Flex } from "@vygruppen/spor-react";
import { getEnvBoolSafe } from "api/common";
import {
  getLocalStorageOploggTodoTabsSettings,
  setLocalStorageOploggTodoTabsSettings,
} from "api/dropsLocalStorage";
import { Environment, environment } from "app/environment/environment";
import CollapseButton from "features/CenterContent/shared/Tabs/CollapseButton";
import { Tab, TabsSides } from "features/CenterContent/shared/Tabs/TabsSides";
import { useEffect, useState } from "react";
import { CollapsibleWrapper } from "shared/components/CollapseableWrapper";
import { useSidePanelsState } from "stores/useSidePanelsState";
import Oplogg from "./Oplogg";
import {
  OploggProvider,
  useNotifications as useOploggNotifications,
  useSetNotifications as useSetOploggNotifications,
} from "./Oplogg/useOploggContext";
import Todo from "./Todo";
import {
  TodoProvider,
  useSetNotifications as useSetTodoNotifications,
  useNotifications as useTodoNotifications,
} from "./Todo/useTodoContext";

const TODO_OPLOGG_TABS: Tab[] = [
  {
    id: "oplogg",
    iconActive: <NewspaperFill18Icon />,
    iconInactive: <NewspaperOutline18Icon />,
    text: "Oplogg",
  },
  {
    id: "todo",
    iconActive: <ListFill18Icon />,
    iconInactive: <ListOutline18Icon />,
    text: "Todo",
  },
];

const env: Environment = environment();

const Content = () => {
  const [isOpen, setIsOpen] = useSidePanelsState((state) => [
    state.oploggTodoListOpen,
    state.setOploggTodoListOpen,
  ]);

  const [selectedTabIds, setSelectedTabIds] = useState(
    getLocalStorageOploggTodoTabsSettings(),
  );

  useEffect(() => {
    setLocalStorageOploggTodoTabsSettings(selectedTabIds);
  }, [selectedTabIds]);

  const oploggNotifications = useOploggNotifications();
  const todoNotifications = useTodoNotifications();
  const setOploggNotifications = useSetOploggNotifications();
  const setTodoNotifications = useSetTodoNotifications();

  return (
    <CollapsibleWrapper
      $gridArea="todoOplogg"
      $side="right"
      $isOpen={isOpen}
      $multipleTabs={selectedTabIds.length >= 2}
    >
      <Flex
        flexDirection="column"
        overflowY="auto"
        position="relative"
        borderRadius="6px"
        width="100%"
      >
        <Flex justifyContent="space-between" alignItems="center">
          {env === Environment.Development &&
            getEnvBoolSafe("VITE_CLOSEABLE_OPLOGG") && (
              <CollapseButton
                isOpen={!isOpen}
                setOpen={() => setIsOpen(!isOpen)}
                title="Skjul oplogg"
                inverted
              />
            )}
          <TabsSides
            tabs={TODO_OPLOGG_TABS}
            selectedTabIds={selectedTabIds}
            setSelectedTabIds={setSelectedTabIds}
            justifyContent="start"
            allowSplit
            notifications={[oploggNotifications, todoNotifications]}
            setNotifications={(value: number[]) => {
              setOploggNotifications(value[0]);
              setTodoNotifications(value[1]);
            }}
          />
        </Flex>
        <Flex overflowY="auto" flex="1" gap="6px">
          {selectedTabIds.includes("oplogg") && <Oplogg />}
          {selectedTabIds.includes("todo") && <Todo />}
        </Flex>
      </Flex>
    </CollapsibleWrapper>
  );
};

export const TodoOplogg = () => (
  <OploggProvider>
    <TodoProvider>
      <Content />
    </TodoProvider>
  </OploggProvider>
);
