import { zodResolver } from "@hookform/resolvers/zod";
import {
  fromDate,
  getLocalTimeZone,
  now,
  today,
} from "@internationalized/date";
import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST, mutationFnPUT } from "api/tanStackQuery/helpers";
import { useFetchVehicleSetVariants } from "features/CenterContent/RoleContent/VehicleChecklist/useVehicleSetVariants";
import { FC, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { ActionModal } from "shared/components/ActionModal";
import { ChecklistFormContent } from "./ChecklistFormContent";
import { ChecklistFormData, checklistFormSchema } from "./schema/formSchema";
import { FilloutRequest, FilloutRequestSchema } from "./schema/requestSchema";
import { FillOut } from "./schema/responseSchema";
import { FilloutFormToRequest } from "./schema/transformers";

interface NewChecklistModalProps {
  currentFillOut: FillOut | null;
  onClose: () => void;
  refetch: () => void;
}

const ChecklistModal: FC<NewChecklistModalProps> = ({
  currentFillOut,
  onClose,
  refetch,
}) => {
  const formMethods = useForm<ChecklistFormData>({
    resolver: zodResolver(checklistFormSchema),
    defaultValues: {
      dateTime: now(getLocalTimeZone()),
      comment: "",
      fillOutType: undefined,
      rollingStockId: undefined,
      vehicleType: undefined,
      trainOwner: undefined,
    },
  });

  const [initialValues, setInitialValues] = useState<ChecklistFormData | null>(
    null,
  );

  const { mutate: putChecklist, status: putStatus } = useMutation({
    mutationFn: (data: FilloutRequest) => {
      if (!currentFillOut) {
        throw new Error("currentFillOut is null");
      }
      return mutationFnPUT<undefined, any>(
        `${getBackendUrl()}/checklist/fill-out/${currentFillOut.fillOutId}`,
        data,
      );
    },
  });

  const { mutate: postChecklist, status: postStatus } = useMutation({
    mutationFn: (data: FilloutRequest) =>
      mutationFnPOST<undefined, any>(
        `${getBackendUrl()}/checklist/fill-out`,
        data,
      ),
  });

  useEffect(() => {
    if (currentFillOut) {
      const date = new Date(currentFillOut.executedAt.toString());
      const dateTime = fromDate(date, getLocalTimeZone());

      const initialFormValues: ChecklistFormData = {
        dateTime,
        comment: currentFillOut.comment || "",
        fillOutType: currentFillOut.checklistType,
        rollingStockId: currentFillOut.rollingStock,
        vehicleType: currentFillOut.vehicleType,
        trainOwner: currentFillOut.companyId,
      };

      formMethods.reset(initialFormValues);
      setInitialValues(initialFormValues);
    }
  }, [currentFillOut, formMethods]);

  const fetchVehicleSetVariants = useFetchVehicleSetVariants(
    today(getLocalTimeZone()).toString(),
  );

  const onSubmit = (data: ChecklistFormData) => {
    const checklistData: FilloutRequest = FilloutFormToRequest(data);

    const validationResult = FilloutRequestSchema.safeParse(checklistData);

    if (!validationResult.success) {
      console.error(validationResult.error);
      return;
    }

    if (currentFillOut) {
      putChecklist(checklistData, {
        onSuccess: () => {
          refetch();
          onClose();
        },
      });
    } else {
      postChecklist(checklistData, {
        onSuccess: () => {
          refetch();
          onClose();
        },
      });
    }
  };

  const watchedValues = useWatch({ control: formMethods.control });
  const isModified =
    initialValues &&
    JSON.stringify(initialValues) !== JSON.stringify(watchedValues);

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        onClose={onClose}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        title={currentFillOut ? "Endre Kvittering" : "Opprett kvittering"}
        actionTitle={currentFillOut ? "Lagre endringer" : "Opprett kvittering"}
        isLoading={postStatus === "pending" || putStatus === "pending"}
        isSuccess={postStatus === "success" || putStatus === "success"}
        isError={postStatus === "error" || putStatus === "error"}
        successMessage="Kvittering lagret"
        failureMessage="Kunne ikke lagre kvittering"
        isSubmitDisabled={
          !formMethods.formState.isValid ||
          (currentFillOut ? !isModified : false)
        }
      >
        <ChecklistFormContent
          currentFillOut={currentFillOut}
          vehicleSetVariants={
            fetchVehicleSetVariants.data?.vehicleSetVariants || []
          }
          onVehicleSelect={(value, setType) => {
            formMethods.setValue("rollingStockId", value);
            formMethods.setValue("vehicleType", setType);
          }}
          formMethods={formMethods}
        />
      </ActionModal>
    </FormProvider>
  );
};

export default ChecklistModal;
