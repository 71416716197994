import { Tooltip } from "@chakra-ui/react";
import {
  CalendarDateTime,
  getLocalTimeZone,
  toCalendarDateTime,
  toZoned,
} from "@internationalized/date";
import { format } from "date-fns";
import {
  GlobalEventResponse,
  Visibility,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { EventCategory } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/Badges";
import {
  Duration,
  InfrastructureResponse,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useExistingTrainCancelledEvents";
import { partition } from "shared/utils/arrayUtils";
import { InternalMessageResponse } from "../../stationEvents/stationEventResponseSchema";

export const getDisplayDurationFromVisibility = (
  visibility: Visibility,
): DisplayDuration | null => {
  switch (visibility.type) {
    case "TIMED":
      return {
        fromTime: toCalendarDateTime(visibility.fromTime),
        toTime: toCalendarDateTime(visibility.toTime),
      };
    case "OPEN_ENDED":
      return {
        fromTime: toCalendarDateTime(visibility.fromTime),
        toTime: null,
      };
    case "CONSTANT":
    default:
      return null;
  }
};

export type DisplayDuration = {
  fromTime: CalendarDateTime;
  toTime: CalendarDateTime | null;
};

const toDate = (date: CalendarDateTime) =>
  toZoned(date, getLocalTimeZone()).toDate();

export const getDisplayDuration = (
  duration: Duration,
): DisplayDuration | null => {
  switch (duration.type) {
    case "TIMED":
      return {
        fromTime: duration.from_time,
        toTime: duration.to_time,
      };
    case "OPEN_ENDED":
      return {
        fromTime: duration.from_time,
        toTime: null,
      };
    case "PERIODIC": {
      if (duration.periods.length === 0) return null;

      const from = duration.periods.sort(
        (a, b) => toDate(a.fromTime).valueOf() - toDate(b.fromTime).valueOf(),
      )[0];
      const to = duration.periods.sort(
        (a, b) => toDate(b.toTime).valueOf() - toDate(a.toTime).valueOf(),
      )[0];

      return {
        fromTime: from.fromTime,
        toTime: to.toTime,
      };
    }
    default:
      return null;
  }
};

export const sortByDisplayDuration = (
  a: InfrastructureResponse,
  b: InfrastructureResponse,
) => {
  const durationA = getDisplayDuration(a.durations);
  const durationB = getDisplayDuration(b.durations);

  if (!durationA || !durationB) return 0;

  const fromTimeA = toDate(durationA.fromTime).valueOf();
  const fromTimeB = toDate(durationB.fromTime).valueOf();

  return fromTimeA - fromTimeB;
};

export const getDisplayDurationString = (
  displayDuration: DisplayDuration | null,
) => {
  if (displayDuration === null) return "Ukjent tidsrom.";

  if (!displayDuration.toTime)
    return `Fra kl. ${format(toDate(displayDuration.fromTime), "HH:mm, dd.MM.yyyy")}`;

  return `Fra ${format(toDate(displayDuration.fromTime), "dd.MM.yyyy")} - ${format(
    toDate(displayDuration.toTime),
    "dd.MM.yyyy",
  )}`;
};

export const sortByVisibility = (
  a: GlobalEventResponse,
  b: GlobalEventResponse,
): number => {
  if (a.visibility.type === "CONSTANT" || b.visibility.type === "CONSTANT") {
    return 0;
  }
  const fromTimeA = a.visibility.fromTime.toDate().valueOf();
  const fromTimeB = b.visibility.fromTime.toDate().valueOf();

  return fromTimeB - fromTimeA;
};

export const getCategory = (event: InternalMessageResponse): EventCategory => {
  if (event.relevantTrain) return "train";
  if (event.relevantStops.length > 0) return "station";
  switch (event.relatedEventUuidCategory) {
    case "TRAIN":
      return "trainEvent";
    case "STATION":
      return "stationEvent";
    case "INFRASTRUCTURE":
      return "infrastructure";
    case "GLOBAL":
      return "global";
    default:
      return "internal";
  }
};

const getStopName = (stop: Stop) => stop?.ids[0]?.name ?? stop?.ids[0]?.id;

export const getBadgeItem = (
  event: InternalMessageResponse,
): string | undefined => {
  const identifier = event.relevantTrain?.operational_identifier;
  return identifier ?? getStopName(event.relevantStops[0]);
};

export const getBadgeExtra = (event: InternalMessageResponse) => {
  const numStops = event.relevantStops.length;
  if (numStops <= 1) return null;
  return (
    <Tooltip
      label={event.relevantStops
        .slice(1)
        .map((it) => getStopName(it))
        .join(", ")}
      hasArrow
      shouldWrapChildren
    >
      +{numStops - 1}
    </Tooltip>
  );
};

export const partitionInternalMessagesRelatedToEvent = (
  data: InternalMessageResponse[],
) => partition(data, (internalMessage) => !internalMessage.relatedEventUuid);
