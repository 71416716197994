import { SkeletonText } from "@chakra-ui/react";
import {
  ArrowLeftFill24Icon,
  TownOutline30Icon,
  TracksOutline30Icon,
  TrainOutline30Icon,
  WarningFill30Icon,
} from "@vygruppen/spor-icon-react";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Table,
  Text,
  VStack,
} from "@vygruppen/spor-react";
import { ACTIONABLE_TRAIN_TYPES } from "features/History/Filter/FilterData";
import { DetailsHeader } from "features/History/HistoryDetail/components/DetailsHeader";
import { TableDetailAccordionButton } from "features/History/HistoryDetail/components/TableDetailAccordionButton";
import {
  TableDetailAccordionPanel,
  TextOutline,
} from "features/History/HistoryDetail/components/TableDetailAccordionPanel";
import {
  Group,
  HISTORY_DETAILS_QUERY_KEY,
  OriginSystem,
  State,
  useHistoryDetail,
} from "features/History/HistoryDetail/fetchHistoryDetail";
import { EventState, stateMap } from "features/History/OperationalEvents";
import { DepublishInformation } from "features/OperationalInformation/DepublishInformation";
import { FC, ReactNode } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { TableSkeleton } from "shared/components/feedback/SkeletonLoader/TableSkeleton";
import { useDropsRole } from "stores/useDropsRole";
import styled, { useTheme } from "styled-components";

const HistoryWrapper = styled.div`
  grid-area: 2 / 1 / 3 / 4;
  box-sizing: border-box;
  width: 100%;
`;

type HistoryDetailProps = {
  eventUuid: string;
  setTrainDetailUUID: (uuid: string | null) => void;
};

const userFromOriginSystem = (originSystem: OriginSystem) => {
  const { name } = originSystem;
  const user = originSystem?.user?.split("@")[0];
  return [name, user].filter(Boolean).join(" - ");
};

export const getEventState = (state: State, type: string): EventState => {
  const isUnhandled = state === "OPEN" && ACTIONABLE_TRAIN_TYPES.includes(type);
  if (isUnhandled) {
    return stateMap.UNHANDLED;
  }
  return stateMap[state];
};

const tagFromGroup: { [key in Group]: ReactNode } = {
  TRAIN: (
    <>
      <TrainOutline30Icon height={3} />
      Toghendelse
    </>
  ),
  INFRASTRUCTURE: (
    <>
      <TracksOutline30Icon height={3} />
      Infrastruktur
    </>
  ),
  STATION: (
    <>
      <TownOutline30Icon height={3} />
      Stasjonshendelse
    </>
  ),
  GLOBAL: (
    <>
      <WarningFill30Icon height={3} />
      Global hendelse
    </>
  ),
  ALTERNATIVE_TRANSPORT: <>Unexpected type: ALTERNATIVE_TRANSPORT</>,
};

const SkeletonTable = styled(Table)`
  * {
    border-width: 0 !important;
  }
`;

const SkeletonHistoryDetail = () => (
  <>
    <SkeletonText width="500px" />

    <Accordion marginY="1rem" allowToggle paddingY={1} index={0}>
      <AccordionItem>
        <AccordionButton height="75px">
          <SkeletonText width="100%" />
        </AccordionButton>
        <AccordionPanel height="300px">
          <VStack flex="1" w={{ base: "100%" }}>
            <Divider
              borderWidth="1px"
              width="999%" // overflow is hidden therefore its okay
              marginTop={-2}
            />
            <SkeletonTable width="100%">
              <tbody>
                <TableSkeleton numOfRows={1} numOfColumns={1} />
                <TableSkeleton numOfRows={1} numOfColumns={1} />
                <TableSkeleton numOfRows={1} numOfColumns={1} />
                <TableSkeleton numOfRows={1} numOfColumns={1} />
                <TableSkeleton numOfRows={1} numOfColumns={1} />
              </tbody>
            </SkeletonTable>
          </VStack>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  </>
);

export const HistoryDetail: FC<HistoryDetailProps> = ({
  eventUuid,
  setTrainDetailUUID,
}) => {
  const details = useHistoryDetail(eventUuid);

  const theme = useTheme();

  const { isVaktleder } = useDropsRole();

  if (details.status === "pending") {
    return (
      <HistoryWrapper>
        <Box padding={2} marginX="auto">
          <Button
            marginY="1rem"
            variant="ghost"
            leftIcon={<ArrowLeftFill24Icon />}
            onClick={() => {
              setTrainDetailUUID(null);
            }}
          >
            <Text fontWeight="bold">Tilbake</Text>
          </Button>
          <SkeletonHistoryDetail />
        </Box>
      </HistoryWrapper>
    );
  }

  if (details.status === "error" || !details.data) {
    return (
      <HistoryWrapper>
        <Box marginTop="32px">
          <FailureMessage />
        </Box>
      </HistoryWrapper>
    );
  }

  const finalVersion = details.data.versions[0];
  const enableDepublishButton = finalVersion.isVisible;

  return (
    <HistoryWrapper>
      <Box padding={2} marginX="auto">
        <Button
          marginY="1rem"
          variant="ghost"
          leftIcon={<ArrowLeftFill24Icon />}
          onClick={() => {
            setTrainDetailUUID(null);
          }}
        >
          <Text fontWeight="bold">Tilbake</Text>
        </Button>
        <TextOutline
          padding="4px 10px 2px 0px"
          fontSize="2xs"
          fontWeight="bold"
          display="flex"
          marginBottom="15px"
        >
          {tagFromGroup[finalVersion.type.group]}
        </TextOutline>
        <Flex alignItems="center" justifyContent="space-between">
          <DetailsHeader information={finalVersion} />
          {isVaktleder() && (
            <DepublishInformation
              uuid={finalVersion.uuid}
              isDisabled={!enableDepublishButton}
              queryKey={[HISTORY_DETAILS_QUERY_KEY, eventUuid]}
            />
          )}
        </Flex>
        <Accordion marginY="1rem" allowToggle paddingY={1} defaultIndex={0}>
          {details.data?.versions?.map(
            (
              {
                actionCard,
                distributions,
                visibility,
                originSystem,
                reason: _reason,
                state,
                stretchName,
                affectedStops: _affectedStops,
                stops,
                type,
                updatedAt,
                version,
              },
              index,
            ) => {
              const affectedStops = _affectedStops ?? stops;
              const reason = _reason?.description?.NOB ?? "Ingen årsak";
              return (
                <AccordionItem
                  key={index}
                  backgroundColor={theme.colorBackgroundTertiary}
                  outlineColor={theme.colorBorder}
                >
                  <TableDetailAccordionButton
                    version={version}
                    date={updatedAt}
                    state={getEventState(state, type.type)}
                    title={type.label ?? "-"}
                    subtitle={stretchName}
                    description={
                      distributions.find((it) =>
                        it.contextName.includes("DROPS"),
                      )?.text.summary ?? "-"
                    }
                    user={userFromOriginSystem(originSystem)}
                  />
                  <TableDetailAccordionPanel
                    group={type.group}
                    reason={reason}
                    actionCard={actionCard?.type ?? "-"}
                    visibility={visibility}
                    originSystem={originSystem}
                    distributions={distributions}
                    affectedStops={affectedStops}
                  />
                </AccordionItem>
              );
            },
          )}
        </Accordion>
      </Box>
    </HistoryWrapper>
  );
};
