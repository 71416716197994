import { WagonFill18Icon } from "@vygruppen/spor-icon-react";
import {
  Box,
  Divider,
  Flex,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@vygruppen/spor-react";
import { FC, JSX, useState } from "react";
import { useTheme } from "styled-components";
import {
  CancelButton,
  DeleteButton,
  DeleteModal,
  DeleteModalContent,
  DetailsContainer,
} from "./ChecklistCustomStyle";
import { formatDateTime } from "./ChecklistTable";
import { FillOut } from "./schema/responseSchema";
import { getChecklistText } from "./utils";

interface DeleteChecklistModelProps {
  isOpen: boolean;
  onClose: () => void;
  currentFillOut: FillOut | null;
  handleDelete: (checklist: FillOut) => void;
  refetch: () => void;
}

const wait = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

const ChecklistDetail: FC<{
  theme: any;
  label: string;
  value: string | JSX.Element;
}> = ({ theme, label, value }) => (
  <Flex pl="18px" pt="10px" gap="10px">
    <Text fontWeight="900" color={theme.colorSecondaryAlarmTextMain}>
      {label}:{" "}
    </Text>
    <Text color={theme.colorSecondaryAlarmTextSecondary}>{value}</Text>
  </Flex>
);

const DeleteChecklistModal: FC<DeleteChecklistModelProps> = ({
  isOpen,
  onClose,
  currentFillOut,
  handleDelete,
  refetch,
}) => {
  const theme = useTheme();
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDeleteClick = async () => {
    if (currentFillOut) {
      try {
        await handleDelete(currentFillOut);
        setIsDeleted(true);
        await wait(2000);
        setIsDeleted(false);
        refetch();
        onClose();
      } catch (error) {
        console.error("Error deleting checklist:", error);
      }
    }
  };

  const renderContent = () => {
    if (isDeleted) {
      return (
        <Text
          fontWeight={500}
          pl={30}
          pt={30}
          pb={30}
          borderBottomRadius="16px"
        >
          Kvitteringen er slettet.
        </Text>
      );
    }

    if (!currentFillOut) {
      return (
        <Text fontWeight={500} pl={30} pt={30} pb={30}>
          Ingen kvittering er valgt.
        </Text>
      );
    }
    const formattedDateTime = `${formatDateTime(currentFillOut.executedAt.toString())}`;
    return (
      <>
        <Box backgroundColor={theme.colorSecondaryAlarmFillPulse}>
          <Text
            fontWeight={500}
            pl={30}
            pt={30}
            pb={30}
            color={theme.colorSecondaryAlarmTextMain}
          >
            Du er i ferd med å slette denne kvitteringen:
          </Text>
          <DetailsContainer>
            <ChecklistDetail
              theme={theme}
              label="Opprettet av"
              value={currentFillOut.executor || "N/A"}
            />
            <ChecklistDetail
              theme={theme}
              label="Tidspunkt"
              value={formattedDateTime}
            />
            <ChecklistDetail
              theme={theme}
              label="Kvitteringsliste"
              value={getChecklistText(currentFillOut.checklistType)}
            />
            <ChecklistDetail
              theme={theme}
              label="Kjøretøy-id"
              value={
                <Flex key={currentFillOut.rollingStockId} alignItems="center">
                  <WagonFill18Icon />
                  <Text
                    pl={1}
                    color={theme.colorSecondaryAlarmTextSecondary}
                    fontWeight="600"
                  >
                    {currentFillOut.rollingStockId}
                  </Text>
                </Flex>
              }
            />
          </DetailsContainer>
        </Box>
        <Box>
          <Text
            fontWeight={500}
            pl={30}
            pt={30}
            pb={30}
            color={theme.colorSecondaryAlarmTextMain}
          >
            Er du sikker på at du vil slette?
          </Text>
          <Divider />
          <ModalFooter pt={2}>
            <Flex justifyContent="space-between" width="100%">
              <CancelButton
                pl={0}
                pr={100}
                fontWeight={500}
                onClick={onClose}
                variant="tertiary"
              >
                Avbryt
              </CancelButton>
              <DeleteButton
                variant="secondary"
                onClick={handleDeleteClick}
                sx={{
                  color: theme.colorSecondaryAlarmTextTertiary,
                  backgroundColor: theme.colorSecondaryAlarm,
                }}
              >
                Ja, slett kvittering
              </DeleteButton>
            </Flex>
          </ModalFooter>
        </Box>
      </>
    );
  };

  return (
    <DeleteModal
      isOpen={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
    >
      <ModalOverlay />
      <DeleteModalContent>
        <ModalHeader id="modal-title" color={theme.colorSecondaryAlarmTextMain}>
          Slett kvittering
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        {renderContent()}
      </DeleteModalContent>
    </DeleteModal>
  );
};

export default DeleteChecklistModal;
