import { useQuery } from "@tanstack/react-query";
import { CountryCode, TrainIdentifier } from "@vygruppen/vy-train-map";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { keysSnakeCase } from "shared/utils/objectUtils";
import { TrainRoute } from "./useTrainRoute";

export type TrainRoutesResponse = (TrainRoute | null)[];

type TrainIdentifier_JSON = {
  identifier: string;
  nominal_date: string;
  country_code: CountryCode;
};

export type TrainRoutesBody = TrainIdentifier_JSON[];

export const useTrainRoutes = (trainIds: TrainIdentifier[]) => {
  const body: TrainRoutesBody = trainIds.map(keysSnakeCase);

  const { status, data, isSuccess, refetch } = useQuery({
    queryKey: ["trainRoutes", JSON.stringify(trainIds)],
    queryFn: ({ signal }) =>
      axiosClient
        .post<TrainRoutesResponse>(
          `${getBackendUrl()}/trainRoute/trains`,
          body,
          { signal },
        )
        .then((res) => (trainId: TrainIdentifier) => {
          const trainRoute = res.data.find(
            (route) => route?.trainId === trainId.identifier,
          );
          if (!trainRoute) return undefined;
          return trainRoute;
        }),
    enabled: trainIds.length > 0,
  });

  return { status, data, isSuccess, refetch };
};
