import EditInternalMessageModal from "features/CenterContent/RoleContent/InternalMessage/EditInternalMessageModal";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { internalMessagesResponseToFormHistory } from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { closeInternalMessage } from "features/CenterContent/RoleContent/InternalMessage/utils/queries";
import { EventInfoMessage } from "features/CenterContent/VehicleDetails/shared/EventInfoMessage";
import { FC, useState } from "react";
import { formatDayDateString } from "shared/utils/datetime";

type InternalInfoMessageProps = {
  info: InternalMessageResponse[];
};

export const InternalInfoMessage: FC<InternalInfoMessageProps> = ({ info }) => {
  const newestVersion = info[0];
  const [askConfirmation, setAskConfirmation] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const versions = internalMessagesResponseToFormHistory(info);
  return (
    <>
      {newestVersion && (
        <EventInfoMessage
          title="Intern melding"
          subTitle={formatDayDateString(newestVersion.updatedAt)}
          severity="neutral"
          versions={versions}
          showActionButtons
          askConfirmation={askConfirmation}
          setAskConfirmation={setAskConfirmation}
          setModalOpen={setModalOpen}
          close={() => closeInternalMessage(info)}
        />
      )}
      {modalOpen && (
        <EditInternalMessageModal
          uuid={newestVersion.uuid}
          setModalOpen={setModalOpen}
        />
      )}
    </>
  );
};
