import { Box, tokens } from "@vygruppen/spor-react";
import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { useEventMode, useStretchBuilder } from "stores/useStretchBuilder";
import { useTrainMapOverlayProvider } from "stores/useTrainMapOverlayProvider";
import { shouldForwardProp } from "@chakra-ui/react";

const Overlay = styled(Box).withConfig({
  shouldForwardProp: (prop) => shouldForwardProp(prop) && prop !== "isVisible",
})<{ isVisible: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${tokens.default.depth["z-index"].overlay};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition:
    opacity 0.3s ease-in-out,
    visibility 0.3s ease-in-out;
`;

export const TrainMapOverlayProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [trainMapOverlay, setTrainMapOverlay] = useTrainMapOverlayProvider(
    (state) => [state.trainMapOverlay, state.setTrainMapOverlay],
    Object.is,
  );

  const [isStretchBuilderMode, setStretchBuilderModalState] = useStretchBuilder(
    (state) => [state.isStretchBuilderMode, state.setStretchBuilderModalState],
    Object.is,
  );

  const eventMode = useEventMode((state) => state.eventMode);
  const setEventMode = useEventMode((state) => state.setEventMode);

  useEffect(() => {
    if (eventMode) {
      setTrainMapOverlay(false);
    }
  }, [eventMode, setTrainMapOverlay]);

  const handleOverlayClick = () => {
    setStretchBuilderModalState({
      type: "station",
      stretchBuilderMode: false,
    });
    setEventMode(false);
  };

  const isVisible = isStretchBuilderMode() && !trainMapOverlay;

  return (
    <>
      {isStretchBuilderMode() && (
        <Overlay isVisible={isVisible} onClick={handleOverlayClick} />
      )}
      {children}
    </>
  );
};
