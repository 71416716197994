import { Text } from "@vygruppen/spor-react";
import {
  Version,
  VersionProps,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/Version";
import { useActionButtons } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/useActionButtons";
import { Dispatch, FC, ReactNode, SetStateAction, useState } from "react";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { ExpandableInfoMessage } from "shared/components/feedback/InfoMessage/ExpandableInfoMessage/ExpandableInfoMessage";
import {
  Severity,
  severityBorderColor,
} from "shared/components/feedback/InfoMessage/severity";
import styled, { useTheme } from "styled-components";

const Versions = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding: 0;
  margin: 12px 30px 12px 0;
`;

type EventInfoMessageProps = {
  title: string;
  subTitle: string;
  severity: Severity;
  versions: Omit<VersionProps, "severity" | "isLastElement">[];
  showActionButtons: boolean;
  askConfirmation: boolean;
  setAskConfirmation: Dispatch<SetStateAction<boolean>>;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  close: () => Promise<any>;
  dropsLogTextInput?: ReactNode;
  initialOpenState?: boolean;
};

export const EventInfoMessage: FC<EventInfoMessageProps> = ({
  title,
  subTitle,
  severity,
  versions,
  showActionButtons,
  askConfirmation,
  setAskConfirmation,
  setModalOpen,
  close,
  dropsLogTextInput,
  initialOpenState = false,
}) => {
  const theme = useTheme();
  const [isError, setIsError] = useState<boolean>(false);
  const actionButtons = useActionButtons(
    versions,
    setModalOpen,
    () => {
      setIsError(false);
      close().catch(() => setIsError(true));
    },
    askConfirmation,
    setAskConfirmation,
    isError,
  );
  if (versions.length === 0) return null;
  return (
    <ExpandableInfoMessage
      style={{ width: "100%" }}
      severity={severity}
      title={title}
      subTitle={subTitle}
      actionButtons={showActionButtons ? actionButtons : []}
      initialOpenState={initialOpenState}
    >
      <Versions>
        {versions.map((version, index) => (
          <Version
            key={index}
            {...version}
            severity={severity}
            isLastElement={index === versions.length - 1}
          />
        ))}
        {askConfirmation && (
          <Text
            variant="xs"
            fontWeight="bold"
            borderTop={`1px dashed ${severityBorderColor(severity, theme)}`}
            pt={3}
          >
            Er du sikker på at du vil friskmelde hendelsen?
          </Text>
        )}
        {askConfirmation && dropsLogTextInput}
        {isError && (
          <DropsStaticAlert variant="error" mb={4}>
            Noe gikk galt, vennligst prøv igjen
          </DropsStaticAlert>
        )}
      </Versions>
    </ExpandableInfoMessage>
  );
};
