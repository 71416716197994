import {
  ZonedDateTime,
  fromDate,
  getLocalTimeZone,
} from "@internationalized/date";
import {
  addHours,
  endOfDay,
  formatISO,
  isAfter,
  isBefore,
  startOfDay,
} from "date-fns";

export const getISODrivingFromToTimes = (inputDate: Date | null) => {
  const date = inputDate ?? new Date();
  const from = formatISO(addHours(startOfDay(date), 3));
  const to = formatISO(addHours(endOfDay(date), 3));

  return { from, to };
};
export const getDate = (inputDate: Date | null) => {
  const date = inputDate ?? new Date();
  return formatISO(date, { representation: "date" });
};

export const isBeforeNow = (time: ZonedDateTime) => {
  const now = fromDate(new Date(), getLocalTimeZone());
  return time.compare(now) < 0;
};
export const parseZonedTime = (time: string) =>
  fromDate(new Date(time), getLocalTimeZone());

// Compare days, ignoring hh:mm:ss:xxxx
export const isBeforeDay = (date: Date, dateToCompare: Date) => {
  const dateDay = startOfDay(date);
  const dateToCompareDay = startOfDay(dateToCompare);
  return isBefore(dateDay, dateToCompareDay);
};

// Compare days, ignoring hh:mm:ss:xxxx
export const isAfterDay = (date: Date, dateToCompare: Date) => {
  const dateDay = startOfDay(date);
  const dateToCompareDay = startOfDay(dateToCompare);
  return isAfter(dateDay, dateToCompareDay);
};
