import { puzzelLogin } from "api/puzzel/puzzel";
import axios from "axios";
import { log, LogLevel } from "logging/datadogBrowserLogs";

export const PUZZEL_BASE_URL = "https://api.puzzel.com/ContactCentre5/";
export const PUZZEL_ACCESS_TOKEN = "PUZZEL_ACCESS_TOKEN";
export const PUZZEL_CUSTOMER_KEY = "PUZZEL_CUSTOMER_KEY";

const puzzelHttpClient = axios.create();
const logSource = "puzzelHttpClient";

puzzelHttpClient.interceptors.request.use((config) => {
  config.url = `${
    PUZZEL_BASE_URL + localStorage.getItem(PUZZEL_CUSTOMER_KEY)
  }/${config.url}`;
  config.headers.Authorization = `Bearer ${localStorage.getItem(
    PUZZEL_ACCESS_TOKEN,
  )}`;
  return config;
});

puzzelHttpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      (error.response.status === 401 || error.response.status === 403) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const loginResponse = await puzzelLogin();
      localStorage.setItem(
        PUZZEL_ACCESS_TOKEN,
        loginResponse.data?.accessToken,
      );
      return puzzelHttpClient(originalRequest);
    }
    await log(
      LogLevel.error,
      logSource,
      `Call to ${error.response?.request?.responseURL} failed with message: ${error.message}`,
    );
    return error;
  },
);

export { puzzelHttpClient };
