import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";

export const useDepublishInformation = () =>
  useMutation({
    mutationFn: (uuid: string) =>
      mutationFnPOST<string, undefined>(
        `${getBackendUrl()}/operational-information/${uuid}/depublish`,
      ),
  });
