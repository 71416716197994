import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import styled, { css, keyframes } from "styled-components";

const oploggPulse = (fromAlarmColor: string, toAlarmColor: string) => keyframes`
    0% {
        background-color: ${fromAlarmColor};
    }
    50% {
        background-color: ${toAlarmColor};    
    }
    
    100% {
        background-color: ${fromAlarmColor};
    }
`;

export const LogItem = styled.li<{ $readonly: boolean }>`
  display: grid;
  grid-template-columns: 15px max-content 1fr;
  grid-template-rows: 1fr "24px" ${() =>
      useFeatureFlag("readReceipts").enabled ? "24px" : ""};
  gap: 6px;
  padding: 12px 12px 12px 6px;
  border-radius: 6px;
  max-width: 100%;
  box-sizing: border-box;

  outline: 1px solid transparent;

  transition: background-color 0.2s ease-in-out;

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colorBorder};
  }

  ${({ $readonly, theme }) =>
    !$readonly
      ? css`
          cursor: pointer;
          background-color: ${theme.colorBackgroundSecondary};
          border-right: 1px solid ${theme.colorBorder};
          border-left: 1px solid ${theme.colorBorder};

          &:hover {
            background-color: ${theme.colorHoverItem};
          }

          &[data-bookmarked="true"] {
            color: ${theme.colorTextMain};
            background-color: ${theme.colorSelected};
            border-right: 1px solid ${theme.colorSelected};
            border-left: 1px solid ${theme.colorSelected};
            transition: background-color 0.2s ease-in-out;
            margin-bottom: 0px;
            z-index: 9;
            outline: 3px solid ${theme.colorBackgroundMain};
          }

          &[data-new="true"] {
            background-color: ${theme.colorAlarmFill};
            border: 1px solid ${theme.colorAlarmOutline};
            transition: background-color 0.2s ease-in-out;
            margin-bottom: 3px;

            animation: ${oploggPulse(
                theme.colorAlarmFill,
                theme.colorAlarmFillPulse,
              )}
              2s ease-in-out 10 forwards;
          }

          &[data-new="true"]:hover {
            background-color: ${theme.colorAlarmFillPulse};
            animation: none;
          }

          &[data-new="true"][data-bookmarked="true"] {
            background-color: ${theme.colorAlarmOutline};
            animation: none;
            outline: 3px solid ${theme.colorBackgroundMain};
            z-index: 9;
          }

          &[data-almost-new="true"] {
            background-color: ${theme.colorSecondaryAlarmFill};
            border: 1px solid ${theme.colorSecondaryAlarmOutline};
            transition: background-color 0.2s ease-in-out;
            margin-bottom: 3px;
          }

          &[data-almost-new="true"]:hover {
            background-color: ${theme.colorSecondaryAlarmFillPulse};
            transition: background-color 0.2s ease-in-out;
          }

          &[data-almost-new="true"][data-bookmarked="true"] {
            background-color: ${theme.colorSecondaryAlarmOutline};
            transition: background-color 0.2s ease-in-out;
            outline: 3px solid ${theme.colorBackgroundMain};
            z-index: 9;
          }
        `
      : css`
          &[data-new="true"] {
            outline-color: ${theme.colorAlarm};
          }
        `}
`;

export const IndicatorWrapper = styled.div`
  grid-row: 1 / ${() => (useFeatureFlag("readReceipts").enabled ? "4" : "3")};
  margin-bottom: -13px;
  margin-top: -12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const VerticalLineForHighlightedBox = styled.div`
  width: 1px;
  height: calc(100% - 3px);
  background-color: ${({ theme }) => theme.colorAlarm};
  margin-top: -2px;
  margin-left: 13px;
`;

export const VerticalLine = styled.div<{
  $color: string;
}>`
  width: 1px;
  height: calc(100% - 27px);
  background-color: ${({ $color }) => $color};
  margin: 0px auto;

  &:first-child {
    height: 17px;
  }
`;

export const HorizontalLineContainer = styled.div`
  width: 100%;
  height: 1px;
  margin: 3px 6px 3px 0;
  align-self: flex-end;
`;
