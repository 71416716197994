import { useMutation, useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST, queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import constate from "constate";
import { useEffect, useState } from "react";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { TodoMessageData } from "websocket/drops/dropsWebsocketMessages";
import { useDropsRole } from "stores/useDropsRole";
import { SubmitTodoForm } from "../formSchema";
import { Todo } from "./todoList/todoElement/TodoElement";

const TODO_QUERY_KEY = "todo";

function useTodo() {
  const [showFilter, setShowFilter] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  const [showVehicleSets, setShowVehicleSets] = useState(false);
  const [notifications, setNotifications] = useState(0);

  const { mutate: post, status: postStatus } = useMutation({
    mutationFn: (data: SubmitTodoForm) =>
      mutationFnPOST<undefined, SubmitTodoForm>(
        `${getBackendUrl()}/todo`,
        data,
      ),
  });

  const { status, data } = useQuery({
    queryKey: [TODO_QUERY_KEY],
    refetchOnWindowFocus: true,
    queryFn: ({ signal }) =>
      queryFnGET<Todo[]>({
        signal,
        url: `${getBackendUrl()}/todo`,
      }),
  });

  const { message } = useDropsWebsocket("TodoComponent", ["TODO"]);

  const { role: currentRole } = useDropsRole();

  const handleTodoMessage = (incomingMessage: TodoMessageData) => {
    if (incomingMessage.action === "ADD") {
      if (incomingMessage.todo.role === currentRole) {
        setNotifications(notifications + 1);
      }
      queryClient.setQueryData<Todo[]>([TODO_QUERY_KEY], (prevState) =>
        prevState
          ? [...prevState, incomingMessage.todo]
          : [incomingMessage.todo],
      );
    } else if (incomingMessage.action === "UPDATE") {
      queryClient.setQueryData<Todo[]>([TODO_QUERY_KEY], (prevState) =>
        prevState
          ? [
              ...prevState.filter(
                (todo) => todo.id !== incomingMessage.todo.id,
              ),
              incomingMessage.todo,
            ]
          : [incomingMessage.todo],
      );
    }
  };

  useEffect(() => {
    if (message.status === "received" && message.data.topic === "TODO") {
      handleTodoMessage(message.data.message);
    }
  }, [message]);

  return {
    post,
    postStatus,
    data,
    status,
    showFilter,
    setShowFilter,
    showRoles,
    setShowRoles,
    showVehicleSets,
    setShowVehicleSets,
    notifications,
    setNotifications,
  };
}

const [
  TodoProvider,
  usePost,
  usePostStatus,
  useData,
  useStatus,
  useShowFilter,
  useSetShowFilter,
  useShowRoles,
  useSetShowRoles,
  useShowVehicleSets,
  useSetShowVehicleSets,
  useNotifications,
  useSetNotifications,
] = constate(
  useTodo,
  (value) => value.post,
  (value) => value.postStatus,
  (value) => value.data,
  (value) => value.status,
  (value) => value.showFilter,
  (value) => value.setShowFilter,
  (value) => value.showRoles,
  (value) => value.setShowRoles,
  (value) => value.showVehicleSets,
  (value) => value.setShowVehicleSets,
  (value) => value.notifications,
  (value) => value.setNotifications,
);

export {
  TodoProvider,
  useData,
  useNotifications,
  usePost,
  usePostStatus,
  useSetNotifications,
  useSetShowFilter,
  useSetShowRoles,
  useSetShowVehicleSets,
  useShowFilter,
  useShowRoles,
  useShowVehicleSets,
  useStatus,
};
