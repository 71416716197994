import {
  EditOutline24Icon,
  StopwatchOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { Flex, IconButton, Text, Tooltip } from "@vygruppen/spor-react";
import { format } from "date-fns/format";
import { isEqual } from "date-fns/isEqual";
import { nb } from "date-fns/locale/nb";
import { setMilliseconds } from "date-fns/setMilliseconds";
import { StopIcon } from "features/CenterContent/shared/StopIcon";
import {
  Line,
  RouteFraction,
  TrainTime,
  VehicleStopWrapper,
} from "features/CenterContent/shared/styles";
import { FC, useState } from "react";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import {
  AlternativeTransportItinerary,
  AlternativeTransportItineraryStop,
  stopActivitySchema,
  StopReference,
  VehicleStatus,
} from "shared/types/alternativeTransport";
import styled, { useTheme } from "styled-components";
import { z } from "zod";
import { delayThreshold } from "shared/utils/delayUtils";
import { ChangeActualTime } from "./ChangeActualTime";
import {
  getColor,
  getIsBetweenStops,
  isStopCancelled,
  timeFromKeys,
} from "./utils";

type Props = {
  id: string;
  travelDate: Date | null;
  itinerary: AlternativeTransportItinerary | null;
  vehicleStatus: VehicleStatus;
  distributionStopReferences: Array<StopReference>;
};

const ItineraryWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-x: scroll;
  scrollbar-color: ${({ theme }) => theme.colorSelected} rgba(0, 0, 0, 0);
`;

const TimeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 6px;
  height: 24px;
`;

const InnerTimingBundle = styled.div`
  display: flex;
  flex-direction: column;
`;

const TimeRow = styled.div`
  display: flex;
  flex-direction: row;

  p:not(:last-child) {
    margin-right: 9px;
  }
`;

const EditButtonWrapper = styled.div`
  display: none;
  margin-left: 9px;
  justify-content: center;
  align-items: center;

  ${TimeWrapper}:hover & {
    display: inline-flex;
  }
`;

const stops = (itinerary: AlternativeTransportItinerary | null) => {
  if (itinerary === null) {
    return [];
  }
  if (itinerary.returnStops !== null && itinerary.returnStops.length > 0) {
    return itinerary.stops.concat(itinerary.returnStops.slice(1));
  }
  return itinerary.stops;
};

type TimesProps = {
  actualOrEstimatedTime: Date | null;
  scheduledTime: Date | null;
  isCancelled: boolean;
};

const Times: FC<TimesProps> = ({
  actualOrEstimatedTime,
  scheduledTime,
  isCancelled,
}) => {
  const isEqualToTheMinute =
    actualOrEstimatedTime &&
    scheduledTime &&
    isEqual(
      setMilliseconds(actualOrEstimatedTime, 0),
      setMilliseconds(scheduledTime, 0),
    );

  const hasTimeDeviation = !!actualOrEstimatedTime && !isEqualToTheMinute;

  return (
    <TimeRow>
      {hasTimeDeviation && (
        <TrainTime $hasActualTime $isCancelled={isCancelled}>
          {format(actualOrEstimatedTime, "HH:mm", {
            locale: nb,
          })}
        </TrainTime>
      )}
      {scheduledTime && (
        <TrainTime
          $hasActualTime
          $isCancelled={hasTimeDeviation || isCancelled}
        >
          {format(scheduledTime, "HH:mm", {
            locale: nb,
          })}
        </TrainTime>
      )}
    </TimeRow>
  );
};

export const ItineraryDetails: FC<Props> = ({
  id,
  travelDate,
  itinerary,
  vehicleStatus,
  distributionStopReferences,
}) => {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  const [selectedStop, setSelectedStop] =
    useState<AlternativeTransportItineraryStop | null>(null);
  const allStops = stops(itinerary);
  if (allStops.length === 0) {
    return <NoDataMessage reason="Fant ingen rute for dette kjøretøyet" />;
  }

  const closeModal = () => {
    setShowModal(false);
    setSelectedStop(null);
  };

  const openModalForStop = (stop: AlternativeTransportItineraryStop) => {
    setSelectedStop(stop);
    setShowModal(true);
  };

  const getActivity = (activity: z.infer<typeof stopActivitySchema>) => {
    switch (activity) {
      case "BOARDING":
        return "Kun påstigning";
      case "DISEMBARKING":
        return "Kun avstigning";
      default:
        return null;
    }
  };

  return (
    <>
      <ItineraryWrapper>
        {allStops.map((stop, index, array) => {
          const arrivalTimes = {
            actualOrEstimatedTime: timeFromKeys(stop, [
              "actualArrivalTime",
              "estimatedArrivalTime",
            ]),
            scheduledTime: timeFromKeys(stop, ["scheduledArrivalTime"]),
          };

          const departureTimes = {
            actualOrEstimatedTime: timeFromKeys(stop, [
              "actualDepartureTime",
              "estimatedDepartureTime",
            ]),
            scheduledTime: timeFromKeys(stop, ["scheduledDepartureTime"]),
          };

          const { lineColor, stopColor, textColor } = getColor(
            stop,
            theme,
            index,
          );
          const stopIsCancelled = isStopCancelled(stop, vehicleStatus);
          const activity = getActivity(stop.activity);

          const isBetween = getIsBetweenStops(allStops, stop, index);
          const isMissingData = !arrivalTimes.actualOrEstimatedTime;
          return (
            <VehicleStopWrapper key={`${stop.name}-${index}`}>
              <RouteFraction className="route_fraction">
                <StopIcon
                  justPassingThrough={activity === "Kun påstigning"}
                  isCancelled={stopIsCancelled}
                  color={stopColor}
                />
                {!(index === array.length - 1) && (
                  <Line
                    $isInBetweenStops={isBetween}
                    $isMissingData={false}
                    $color={lineColor}
                  />
                )}
              </RouteFraction>
              <Flex flexDirection="row" gap={1}>
                <Text fontWeight="600" color={textColor}>
                  {stop.name}
                </Text>
                {isMissingData && (
                  <Tooltip content="Det finnes ikke passeringsmeldinger for dette stoppet">
                    <span style={{ cursor: "pointer" }}>
                      <StopwatchOutline18Icon
                        color={theme.colorTextSecondary}
                      />
                    </span>
                  </Tooltip>
                )}
              </Flex>
              {activity && <Text variant="secondary">{activity}</Text>}

              <TimeWrapper>
                <InnerTimingBundle>
                  <Times
                    {...arrivalTimes}
                    isCancelled={
                      stop.isArrivalCancelled
                        ? theme.colorTextSecondary
                        : theme.colorTextPrimary
                    }
                  />
                  <Times {...departureTimes} isCancelled={stopIsCancelled} />
                </InnerTimingBundle>
                <EditButtonWrapper>
                  <IconButton
                    variant="ghost"
                    size="sm"
                    icon={<EditOutline24Icon />}
                    onClick={() => openModalForStop(stop)}
                    aria-label="Endre tidspunkt for stoppested"
                  />
                </EditButtonWrapper>
              </TimeWrapper>
            </VehicleStopWrapper>
          );
        })}
      </ItineraryWrapper>
      {travelDate && (
        <ChangeActualTime
          id={id}
          travelDate={travelDate}
          distributionStopReferences={distributionStopReferences}
          showModal={showModal}
          stop={selectedStop}
          closeModal={closeModal}
        />
      )}
    </>
  );
};
