import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button, ButtonGroup, Spacer } from "@vygruppen/spor-react";
import { EventsOverviewButtonAndModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import { InternalMessageOverviewButtonAndModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/InternalMessageOverviewButtonAndModal";
import { FC } from "react";
import { useEventMode, useStretchBuilder } from "stores/useStretchBuilder";
import { useTheme } from "styled-components";

export const CreateEvent: FC = () => {
  const [eventMode, setEventMode] = useEventMode((state) => [
    state.eventMode,
    state.setEventMode,
  ]);
  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );
  const theme = useTheme();

  return !eventMode ? (
    <ButtonGroup
      position="absolute"
      top={2}
      left={2}
      right={2}
      pointerEvents="none"
    >
      <Button
        _active={{ backgroundColor: theme.colorBackgroundTertiary }}
        leftIcon={<AddOutline24Icon />}
        pointerEvents="auto"
        onClick={() => {
          setEventMode(true);
          setStretchBuilderModalState({
            type: "infrastructure",
            stretchBuilderMode: true,
          });
        }}
      >
        Ny hendelse
      </Button>
      <EventsOverviewButtonAndModal />
      <Spacer />
      <InternalMessageOverviewButtonAndModal />
    </ButtonGroup>
  ) : null;
};
