import {
  ButtonColors,
  ButtonSeverity,
} from "shared/components/buttons/SemanticButton";
import { DefaultTheme } from "styled-components";

const SeverityColors = (
  severity: ButtonSeverity,
  theme: DefaultTheme,
): ButtonColors => {
  switch (severity) {
    case "alarm":
      return {
        severityTextMain: theme.colorAlarmTextMain,
        severity: theme.colorAlarm,
        severityFill: theme.colorAlarmFill,
        severityFillPulse: theme.colorAlarmFillPulse,
      };
    case "secondaryAlarm":
      return {
        severityTextMain: theme.colorSecondaryAlarmTextMain,
        severity: theme.colorAlarmSecondary,
        severityFill: theme.colorSecondaryAlarmFill,
        severityFillPulse: theme.colorSecondaryAlarmFillPulse,
      };
    case "warning":
      return {
        severityTextMain: theme.colorWarningTextMain,
        severity: theme.colorWarning,
        severityFill: theme.colorWarningFill,
        severityFillPulse: theme.colorWarningFillPulse,
      };
    case "info":
      return {
        severityTextMain: theme.colorInfoTextMain,
        severity: theme.colorInfo,
        severityFill: theme.colorInfoFill,
        severityFillPulse: theme.colorInfoFillPulse,
      };
    case "success":
      return {
        severityTextMain: theme.colorSuccessTextMain,
        severity: theme.colorSuccess,
        severityFill: theme.colorSuccessFillPulse,
        severityFillPulse: theme.colorSuccessFill,
      };
    case "neutral":
    default:
      return {
        severityTextMain: theme.colorNeutralTextMain,
        severity: theme.colorNeutral,
        severityFill: theme.colorNeutralFill,
        severityFillPulse: theme.colorNeutralFillPulse,
      };
  }
};

export const getButtonStyleTertiary = (
  severity: ButtonSeverity,
  theme: DefaultTheme,
) => {
  const colors = SeverityColors(severity, theme);

  return {
    color: colors.severityTextMain,
    backgroundColor: colors.severityFill,
    outlineColor: colors.severity,
    "&:hover": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFill,
      outlineColor: colors.severity,
    },
    "&:active": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFillPulse,
      outlineColor: colors.severity,
      outlineWidth: "2px",
    },
    "&:disabled": {
      color: colors.severityTextMain,
      backgroundColor: colors.severityFillPulse,
      outline: "none",
      opacity: 0.5,
    },
  };
};
