import { Checkbox } from "@vygruppen/spor-react";
import { InfrastructureResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { SingleTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { GroupedInformationResponse } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/useInfrastructureInformationForTrain";
import {
  InfrastructureConsequence,
  mapInfrastructureConsequence,
} from "features/VehicleSidebar/AffectedTrains/utils/utils";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { UseFormSetValue, useFormContext } from "react-hook-form";
import { Select } from "shared/components/forms/Select";
import { customInputInfo } from "shared/types/form";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";

const mapKeysToLabel: Record<keyof GroupedInformationResponse, string> = {
  directlyAffecting: "Påvirker direkte",
  rest: "Øvrige",
};

type NarrowedGroup = InfrastructureResponse & { incidentId: string };

function mapToOptions(
  groupedInformation: GroupedInformationResponse,
): Option[] {
  return Object.keys(groupedInformation)
    .map((key) => {
      const typedKey = key as keyof GroupedInformationResponse;
      if (groupedInformation[typedKey].length === 0) {
        return null;
      }
      return {
        label: mapKeysToLabel[typedKey],
        selection: groupedInformation[typedKey]
          .filter((group): group is NarrowedGroup => group.incidentId !== null)
          .map((group) => {
            let displayValue = `${group.stretchName}`;

            if (isPlannedInfraStructureEvent(group.type)) {
              displayValue = `${displayValue} (planlagt)`;
            }

            return {
              id: group.uuid,
              value: group.incidentId,
              displayValue,
            };
          }),
      };
    })
    .filter((opt: Option | null): opt is Option => opt !== null);
}

type Option = {
  label: string;
  selection: {
    id: string;
    value: string;
    displayValue: string;
  }[];
};

function buildOptGroups(...options: Option[]) {
  return (
    <>
      {options.map((group) => (
        <optgroup key={group.label} label={group.label}>
          {group.selection.map((opt) => (
            <option key={opt.id} value={opt.value}>
              {opt.displayValue}
            </option>
          ))}
        </optgroup>
      ))}
    </>
  );
}

type IncidentsForTrainProps = {
  items: GroupedInformationResponse;
};

const handleIncidentSelectChange = ({
  selectedId,
  infrastructureData,
  setValue,
}: {
  selectedId: string;
  infrastructureData: GroupedInformationResponse;
  setValue: UseFormSetValue<SingleTrainFormSchema>;
}) => {
  const flattenedInfrastructureData = [
    ...infrastructureData.directlyAffecting,
    ...infrastructureData.rest,
  ];

  const selectedInfrastructureData = flattenedInfrastructureData.find(
    (val) => val.incidentId === selectedId,
  );

  if (!selectedInfrastructureData) {
    return;
  }

  const { consequence, reason, action } = selectedInfrastructureData;

  const updates = [
    {
      name: customInputInfo.event.formField,
      value: mapInfrastructureConsequence(
        consequence?.type as InfrastructureConsequence,
      ),
    },
    {
      name: customInputInfo.reason.formField,
      value: reason?.type ?? "",
    },
    {
      name: customInputInfo.action.formField,
      value: action?.type ?? "",
    },
  ];

  updates.forEach(({ name, value }) => {
    setValue(name, value);
  });
};

export const SelectIncidentsForTrain: FC<IncidentsForTrainProps> = ({
  items,
}) => {
  const formField = "trainForm.incidentId";

  const { register, setValue, getValues } =
    useFormContext<SingleTrainFormSchema>();

  const displayIncidents = buildOptGroups(...mapToOptions(items));

  const incidentId = getValues(formField);

  const [showIncidentsCombobox, setShowIncidentsCombobox] = useState(
    () => !!incidentId, // Possibly true when opening an edit modal
  );

  useEffect(() => {
    if (incidentId && !showIncidentsCombobox) {
      setShowIncidentsCombobox(true);
    }
  });

  return (
    <>
      <Checkbox
        isChecked={showIncidentsCombobox}
        onChange={() => {
          if (showIncidentsCombobox) {
            // Toggling off the incidents box, empty the field too
            setValue("trainForm.incidentId", "", { shouldValidate: true });
          }
          setShowIncidentsCombobox((prev) => !prev);
        }}
      >
        Knytt hendelse til infrastrukturhendelse
      </Checkbox>
      {showIncidentsCombobox && (
        <Select
          label="Infrastrukturhendelse"
          placeholder="Velg en infrastrukturhendelse"
          {...register(formField, {
            onChange: (e: ChangeEvent<HTMLSelectElement>) => {
              setValue(formField, e.target.value);
              handleIncidentSelectChange({
                selectedId: e.target.value,
                infrastructureData: items,
                setValue,
              });
            },
          })}
          value={incidentId ?? ""}
        >
          {displayIncidents}
        </Select>
      )}
    </>
  );
};
