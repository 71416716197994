import { FC } from "react";
import { Button } from "@vygruppen/spor-react";
import { getLocalStorageUsername } from "api/dropsLocalStorage";
import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { format } from "date-fns";
import { DayPlanTrackingStatus } from "../types";

type AcknowledgeManualRequest = {
  username: string;
  employeeId: string;
  date: string;
};

type Props = {
  status: DayPlanTrackingStatus;
  employeeId: string;
};

const DayPlanTrackingActionButton: FC<Props> = ({ status, employeeId }) => {
  const { mutate: setAcknowledgeManual, isPending: acknowledgeIsLoading } =
    useMutation({
      mutationFn: (body: AcknowledgeManualRequest) =>
        mutationFnPOST<any, AcknowledgeManualRequest>(
          `${getBackendUrl()}/day-plan/trackings/${body.date}/${
            body.employeeId
          }/add-manual-acknowledgement?username=${body.username}`,
          body,
        ),
    });

  const { mutate: removeAcknowledgeManual, isPending: removeAckIsLoading } =
    useMutation({
      mutationFn: (body: AcknowledgeManualRequest) =>
        mutationFnPOST<any, AcknowledgeManualRequest>(
          `${getBackendUrl()}/day-plan/trackings/${body.date}/${
            body.employeeId
          }/remove-manual-acknowledgement?username=${body.username}`,
          body,
        ),
    });

  const username = getLocalStorageUsername() ?? "Drops";

  if (
    status === DayPlanTrackingStatus.SENT ||
    status === DayPlanTrackingStatus.RECEIVED
  ) {
    return (
      <Button
        variant="tertiary"
        size="xs"
        isLoading={acknowledgeIsLoading}
        onClick={(e) => {
          e.stopPropagation();
          setAcknowledgeManual({
            username,
            date: format(new Date(), "yyyy-MM-dd"),
            employeeId,
          });
        }}
      >
        Bekreft
      </Button>
    );
  }
  if (status === DayPlanTrackingStatus.MANUAL) {
    return (
      <Button
        variant="tertiary"
        size="xs"
        isLoading={removeAckIsLoading}
        onClick={(e) => {
          e.stopPropagation();
          removeAcknowledgeManual({
            username,
            date: format(new Date(), "yyyy-MM-dd"),
            employeeId,
          });
        }}
      >
        Gjenåpne
      </Button>
    );
  }

  return null;
};

export default DayPlanTrackingActionButton;
