import { Button } from "@vygruppen/spor-react";
import styled from "styled-components";

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 6px;
  flex: 2;
`;

// Invisible button
export const ButtonStyled = styled(Button)`
  &&& {
    padding: 0px;
    min-height: 0px;
    min-width: 0px;
  }
  > * > * > * {
    /* Why is it so nested Spor? */
    display: flex;
  }
`;

export const DateHeader = styled.div`
  padding: 5px 35px;
  background-color: transparent;
  width: 100%;
`;
