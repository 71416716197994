import { HeadphonesOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { FormEvent, useEffect } from "react";
import { InputField } from "shared/components/forms/Inputfield";
import { Text } from "shared/components/typography/TitleStyles";
import SelectList from "../../../../../shared/components/SelectList/SelectList";
import {
  usePhoneNumber,
  useProfileId,
  useSetPhoneNumber,
  useSetProfileId,
} from "../../provider/contexts/CallStatusContext";
import { usePuzzelExtendedStateInformation } from "../../provider/contexts/ListenForCallStatusContext";
import { usePuzzelAuthenticationContext } from "../../provider/contexts/PuzzelAuthenticationContext";
import {
  usePerformUserLogOnOff,
  useUserDetailsState,
} from "../../provider/contexts/UserDetailsAndLogOnOffContext";
import { useUserValidationState } from "../../provider/contexts/UserValidationContext";
import { removePhoneNumberPrefix, validPhoneNumber } from "../../utils";
import { HorizontalContainer } from "./StyledLogonComponents";

const Logon = () => {
  const setPhoneNumber = useSetPhoneNumber();
  const userDetails = useUserDetailsState();
  const { userStateInformation } = usePuzzelExtendedStateInformation();
  const { currentProfile, profiles, isLoggedOn } = userDetails;
  const { phoneNumberPrefix } = usePuzzelAuthenticationContext();
  const setProfileId = useSetProfileId();
  const profileId = useProfileId();
  const { userId } = useUserValidationState();
  const nonPrefixedPhoneNumber = usePhoneNumber();
  const performUserLogOnOff = usePerformUserLogOnOff();
  const enableBtn = profileId && validPhoneNumber(nonPrefixedPhoneNumber);

  useEffect(() => {
    if (currentProfile?.id) {
      setProfileId(currentProfile?.id);
    }
  }, [currentProfile?.id]);

  const options = profiles?.map((it: any) => ({
    text: it.name,
    value: it.id,
  }));

  const onLogonSubmit = (event: FormEvent) => {
    event.preventDefault();
    performUserLogOnOff({
      param: {
        userId,
        logOnOrOff: "logon",
      },
      payload: {
        phoneNumber: phoneNumberPrefix + nonPrefixedPhoneNumber,
        profileId,
      },
    });
  };

  const onLogOffSubmit = (event: FormEvent) => {
    event.preventDefault();
    performUserLogOnOff({
      param: {
        userId,
        logOnOrOff: "logoff",
      },
      payload: {},
    });
  };

  if (isLoggedOn === null) {
    return null;
  }

  if (
    !isLoggedOn ||
    userStateInformation?.contactCentreStatus === "LoggedOff"
  ) {
    return (
      <HorizontalContainer onSubmit={onLogonSubmit}>
        <SelectList
          id="profileId"
          name="profileId"
          options={options || []}
          // @ts-ignore
          selected={profileId}
          onChange={(value: string) => {
            // @ts-ignore
            setProfileId(value);
          }}
        />
        <InputField
          type="text"
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
          value={nonPrefixedPhoneNumber}
          placeholder="Telefonnummer"
        />
        <Button
          ml={2}
          size="sm"
          variant="secondary"
          type="submit"
          isDisabled={!enableBtn}
          leftIcon={<HeadphonesOutline24Icon />}
        >
          Logg på
        </Button>
      </HorizontalContainer>
    );
  }

  return (
    <HorizontalContainer onSubmit={onLogOffSubmit}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            marginRight: "1rem",
          }}
        >
          <Text>{currentProfile?.name}</Text>
          <Text>
            (
            {removePhoneNumberPrefix(
              currentProfile.phoneNumber,
              phoneNumberPrefix,
            )}
            )
          </Text>
        </div>
        <Button
          size="sm"
          variant="secondary"
          type="submit"
          leftIcon={<HeadphonesOutline24Icon />}
        >
          Logg av
        </Button>
      </div>
    </HorizontalContainer>
  );
};

export default Logon;
