import {
  FormControl,
  FormErrorMessage,
  HStack,
  Skeleton,
  Stack,
} from "@vygruppen/spor-react";
import { InfrastructureProvider } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import { SubTypeInputs } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/subTypeInputs";
import { InfrastructureForm } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { FieldErrorOrUndefined } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import { InfrastructureFormFieldWithCustomInput } from "features/CenterContent/shared/operationalInformation/components/InfrastructureFormFieldWithCustomInput";
import {
  EventGroup,
  useOperationalInformationTypes,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import {
  filterActions,
  sortAndFilterReasons,
} from "features/CenterContent/shared/operationalInformation/utils";
import { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { Select } from "shared/components/forms/Select";
import { isPlannedInfraStructureEvent } from "shared/utils/infrastructureEvent";

export const FormInputs: FC = () => {
  const { data: opInfoTypes, status: opInfoTypesStatus } =
    useOperationalInformationTypes();
  const {
    register,
    setValue,
    watch,
    formState: { errors },
    clearErrors,
  } = useFormContext<InfrastructureForm>();

  const formErrors = errors.infrastructureForm;

  const eventTypeFieldError = formErrors?.type as FieldErrorOrUndefined;

  const reasonFieldError = formErrors?.reason?.type as FieldErrorOrUndefined;

  const eventType = watch("infrastructureForm.type");

  const isPlanned = isPlannedInfraStructureEvent(eventType);

  useEffect(() => {
    if (isPlanned) {
      setValue("infrastructureForm.reason.type", "WORK_ON_TRACK", {
        shouldValidate: true,
      });
    }
  }, [eventType]);

  useEffect(() => {
    if (eventType) {
      clearErrors("infrastructureForm");
    }
  }, [eventType]);

  switch (opInfoTypesStatus) {
    case "success":
      return (
        <Stack gap={3}>
          <HStack>
            <FormControl isInvalid={!!eventTypeFieldError}>
              <Select
                {...register("infrastructureForm.type")}
                label="Hendelse*"
                placeholder="Velg en hendelse"
                isInvalid={!!eventTypeFieldError}
              >
                {opInfoTypes.events
                  .filter((it) => it.group === EventGroup.INFRASTRUCTURE)
                  .map((item) => (
                    <option key={item.type} value={item.type}>
                      {item.label}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage>
                {eventTypeFieldError?.message}
              </FormErrorMessage>
            </FormControl>
            {!isPlanned && (
              <Select
                label="Aksjonskort"
                placeholder="-"
                {...register("infrastructureForm.actionCard.type")}
              >
                {opInfoTypes.actionCards.map((item) => (
                  <option key={item.type} value={item.type}>
                    {item.label}
                  </option>
                ))}
              </Select>
            )}
          </HStack>
          <InfrastructureProvider isPlanned={isPlanned} />
          <FormControl isInvalid={!!reasonFieldError}>
            <InfrastructureFormFieldWithCustomInput
              items={sortAndFilterReasons(
                opInfoTypes.reasons,
                "INFRASTRUCTURE",
              )}
              formFieldType="reason"
              isDisabled={isPlanned}
            />
            <FormErrorMessage>{reasonFieldError?.message}</FormErrorMessage>
          </FormControl>
          <InfrastructureFormFieldWithCustomInput
            items={opInfoTypes.consequences}
            formFieldType="consequence"
          />
          <InfrastructureFormFieldWithCustomInput
            items={filterActions(opInfoTypes.actions, isPlanned)}
            formFieldType="action"
          />
          <SubTypeInputs type={eventType} />
        </Stack>
      );
    case "pending":
      return (
        <Stack gap={2}>
          <Skeleton height={6} />
          <Skeleton height={6} />
          <Skeleton height={6} />
        </Stack>
      );
    case "error":
    default:
      return (
        <DropsStaticAlert variant="error">
          Noe gikk galt ved henting av hendelser, aksjonskort, årsaker og
          konsevenser. Last siden på nytt for å prøve igjen. Ta kontakt med IT
          hvis feilen vedvarer.
        </DropsStaticAlert>
      );
  }
};
