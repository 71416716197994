import { BoxProps, LinkBox } from "@chakra-ui/react";
import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { FCWC } from "shared/utils/FCWC";
import { useTheme } from "styled-components";

type ElementProps = {
  selected: boolean;
  onClick?: () => void;
} & BoxProps;
const ElementWrapper: FCWC<ElementProps> = ({
  onClick,
  children,
  selected,
  ...props
}) => {
  const theme = useTheme();

  return (
    <>
      <LinkBox
        as="li"
        display="flex"
        gap={1}
        justifyContent="space-between"
        alignItems="center"
        alignContent="center"
        p={2}
        borderRight={`1px solid ${theme.colorBorder}`}
        borderLeft={`1px solid ${theme.colorBorder}`}
        borderRadius="6px"
        backgroundColor={
          selected ? theme.colorSelected : theme.colorBackgroundSecondary
        }
        _hover={{
          backgroundColor: theme.colorHoverItem,
          cursor: "pointer",
        }}
        onClick={onClick}
        sx={{
          "&:first-of-type": { borderTop: `1px solid ${theme.colorBorder}` },
        }}
        {...props}
      >
        {children}
      </LinkBox>
      <DashedLine color={theme.colorBackgroundSecondary} />
    </>
  );
};

export default ElementWrapper;
