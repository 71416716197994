import { datadogRum } from "@datadog/browser-rum";
import { getTrafficguiBaseUrl } from "api/common";
import { getLocalStorageUsername } from "api/dropsLocalStorage";
import { Environment, environment } from "app/environment/environment";
import { useEffect, useState } from "react";
import { useDropsRole } from "stores/useDropsRole";

const version: string | undefined = import.meta.env.APP_VERSION;

// Enable RUM for these environments
const enabledEnvironments = [Environment.Production, Environment.Test];

export const useDatadogRum = () => {
  const { role } = useDropsRole();
  const userName = getLocalStorageUsername(); // TODO: With new auth flow, get username from auth context

  const env = environment();
  const apiBaseUrl = getTrafficguiBaseUrl();

  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isInitialized && version && enabledEnvironments.includes(env)) {
      datadogRum.init({
        applicationId: "3600d048-b425-4982-bd42-128cdc394624",
        clientToken: "pub8ee527512847fa15aade4e1f59fb577e",
        site: "datadoghq.eu",
        service: "drops-frontend",
        env: env.toLowerCase(),
        version: version.toString(),
        traceSampleRate: 100,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        defaultPrivacyLevel: "mask", // Masks all HTML text, user input, images, links and data-* attributes.
        allowedTracingUrls: [(url) => url.startsWith(apiBaseUrl)],
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        trackAnonymousUser: true,
      });
      setIsInitialized(true);
    } else if (!isInitialized) {
      console.warn(`Datadog RUM not initialized for environment: ${env}`);
    }
  }, [env, isInitialized]);

  useEffect(() => {
    if (isInitialized && userName && role) {
      datadogRum.setUser({
        id: userName, // TODO: Use email later with new auth
        role: role.toString(),
      });
    }
  }, [userName, role, isInitialized]);
};
