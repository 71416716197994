import { BusFill24Icon, TrainOutline24Icon } from "@vygruppen/spor-icon-react";
import { Flex, HStack, Tab, TabList, Tabs, Text } from "@vygruppen/spor-react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { useState } from "react";
import styled, { useTheme } from "styled-components";
import { useDropsRole } from "stores/useDropsRole";
import { SharedGridStyle } from "../../shared/MainContentGrid";
import { VehicleMapBus } from "./VehicleMapBus";
import VehicleMapTrain from "./VehicleMapTrain";

export const Wrapper = styled(Flex)`
  ${SharedGridStyle}
  padding: 0;
  overflow: hidden;

  border: 1px solid ${({ theme }) => theme.colorBorder};
`;

enum VehicleMapType {
  Train,
  Bus,
}

const VehicleMap = () => {
  const theme = useTheme();
  const { isTrafikkplanlegger } = useDropsRole();

  const [vehicleMapType, setVehicleMapType] = useState(() => {
    if (isTrafikkplanlegger()) {
      return VehicleMapType.Bus;
    }
    return VehicleMapType.Train;
  });
  return (
    <Wrapper
      position="relative"
      height="100%"
      width="100%"
      align="center"
      justify="center"
    >
      <Tabs
        variant="accent"
        position="absolute"
        zIndex="1000"
        colorScheme="base"
        left="10px"
        top="10px"
        size="lg"
        onChange={(index: number) =>
          setVehicleMapType([VehicleMapType.Train, VehicleMapType.Bus][index])
        }
        defaultIndex={isTrafikkplanlegger() ? 1 : 0}
      >
        <TabList background={theme.colorBackgroundSecondary}>
          <Tab>
            <HStack mx={6}>
              <TrainOutline24Icon />
              <Text>Tog</Text>
            </HStack>
          </Tab>
          <Tab>
            <HStack mx={6}>
              <BusFill24Icon />
              <Text>Buss</Text>
            </HStack>
          </Tab>
        </TabList>
      </Tabs>
      {vehicleMapType === VehicleMapType.Train && <VehicleMapTrain />}
      {vehicleMapType === VehicleMapType.Bus && <VehicleMapBus />}
    </Wrapper>
  );
};

export default withErrorBoundary(VehicleMap);
