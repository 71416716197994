import { toCalendarDate, toTime } from "@internationalized/date";
import {
  AddOutline30Icon,
  LinkOutFill18Icon,
} from "@vygruppen/spor-icon-react";
import {
  Combobox,
  DatePicker,
  Flex,
  FormControl,
  HStack,
  Item,
  ItemLabel,
  Text,
  Textarea,
  TextLink,
  TimePicker,
} from "@vygruppen/spor-react";
import { FC, useEffect, useState } from "react";
import { useFormContext, UseFormReturn } from "react-hook-form";
import { Select } from "shared/components/forms/Select";
import { VehicleSetVariant } from "./VehicleSetVariant";
import { ChecklistFormData } from "./schema/formSchema";
import { FillOut } from "./schema/responseSchema";
import {
  CheckListType,
  companyId,
  getChecklistText,
  getCompanyName,
} from "./utils";

interface ChecklistFormContentProps {
  currentFillOut?: FillOut | null;
  vehicleSetVariants: VehicleSetVariant[];
  onVehicleSelect: (value: string, setType: string) => void;
  formMethods: UseFormReturn<ChecklistFormData>;
}

export const ChecklistFormContent: FC<ChecklistFormContentProps> = ({
  currentFillOut,
  vehicleSetVariants,
  onVehicleSelect,
  formMethods,
}) => {
  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<ChecklistFormData>();
  const [rollingStockId, setRollingStockId] = useState<string>(
    currentFillOut?.rollingStockId || "",
  );

  useEffect(() => {
    const setType = vehicleSetVariants.find(
      (variant) => variant.setId === rollingStockId,
    )?.setType;

    if (rollingStockId && setType) {
      setValue("rollingStockId", rollingStockId.toString());
      setValue("vehicleType", setType);
      onVehicleSelect(rollingStockId.toString(), setType);
    }
  }, [rollingStockId]);

  const { watch } = formMethods;

  const dateTime = watch("dateTime");

  return (
    <FormControl>
      <Flex gap="5px" pt="20px">
        <Text fontWeight="bold" mb={4}>
          {currentFillOut ? "Opprettet av:" : ""}
        </Text>
        <Text>{currentFillOut?.executor}</Text>
      </Flex>
      <HStack mb={4}>
        <DatePicker
          width="100%"
          minHeight="0"
          variant="base"
          label="Dato"
          value={toCalendarDate(dateTime)}
          onChange={(date) => {
            if (date !== null) {
              setValue("dateTime", dateTime.set({ ...date }));
            }
          }}
        />
        <TimePicker
          label="Fra kl"
          minuteInterval={5}
          value={toTime(dateTime)}
          onChange={(time) => {
            if (time !== null) {
              setValue("dateTime", dateTime.set({ ...time }));
            }
          }}
        />
      </HStack>
      <HStack mb={4} justifyContent="flex-end">
        <TextLink
          variant="primary"
          size="md"
          onClick={() => {
            window.open(
              "https://nsbas.sharepoint.com/sites/Sjekklisterforkjrety/Lists/Sjekklister/AllItems.aspx",
              "blank",
            );
          }}
        >
          Se alle sjekklister
        </TextLink>
        <LinkOutFill18Icon />
      </HStack>
      <FormControl isInvalid={!!errors.fillOutType} pb={2}>
        <Select
          label="Kvitteringstype"
          placeholder="Velg type"
          {...register("fillOutType")}
        >
          {Object.values(CheckListType).map((item) => (
            <option key={item} value={item}>
              {getChecklistText(item)}
            </option>
          ))}
        </Select>
      </FormControl>
      <FormControl isInvalid={!!errors.rollingStockId} pb={2}>
        <Combobox
          leftIcon={<AddOutline30Icon />}
          label="Legg til kjøretøy-id"
          inputValue={rollingStockId}
          onInputChange={setRollingStockId}
        >
          {vehicleSetVariants &&
          Array.isArray(vehicleSetVariants) &&
          vehicleSetVariants.length > 0 ? (
            vehicleSetVariants.map((variant) => (
              <Item key={variant.setId} textValue={variant.setId}>
                <ItemLabel>{variant.setId}</ItemLabel>
              </Item>
            ))
          ) : (
            <Item>
              <ItemLabel>Ingen kjøretøy tilgjengelig</ItemLabel>
            </Item>
          )}
        </Combobox>
      </FormControl>

      <FormControl isInvalid={!!errors.trainOwner}>
        <Select
          label="Selskap"
          placeholder="Velg selskap"
          {...register("trainOwner")}
        >
          {Object.values(companyId).map((item) => (
            <option key={item} value={item as companyId}>
              {getCompanyName(item as companyId)}
            </option>
          ))}
        </Select>
      </FormControl>
      <Text pt={3}>Kommentar</Text>
      <Textarea
        label="Skriv kommentar her"
        {...register("comment")}
        height="130px"
      />
    </FormControl>
  );
};
