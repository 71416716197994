import { visibilitySchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InternalMessageCommon } from "features/CenterContent/RoleContent/InternalMessage/schema/baseSchema";
import { InternalMessagesResponseSchema } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import {
  stationCommunicationSystemsEnum,
  stationCustomEventSeverityEnum,
  stationMeansOfPaymentEnum,
  stationThreatLevelEnum,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/formSchema";
import { Stop } from "features/CenterContent/VehicleDetails/TrainDetails/useExistingTrainCancelledEvents";
import { distributionInput } from "features/CenterContent/shared/operationalInformation/utils";
import { OriginSystem } from "features/History/HistoryDetail/fetchHistoryDetail";
import { z } from "zod";

const stationThreatLevelSchema = z.object({
  stationInformationType: z.literal("STATION_THREAT_LEVEL"),
  stops: z.string().array().min(1),
  threatLevel: stationThreatLevelEnum,
});

const stationSalesChannelReducedSchema = z.object({
  stationInformationType: z.literal("STATION_SALES_CHANNEL_REDUCED"),
  stops: z.string().array().min(1),
  meansOfPayment: stationMeansOfPaymentEnum,
});

const stationPersonSchema = z.object({
  stationInformationType: z.enum([
    "STATION_DIFFICULT_CUSTOMER",
    "STATION_WANTED_PERSON",
  ]),
  stops: z.string().array().min(1),
  personDescription: z
    .object({
      NOB: z.string(),
      ENG: z.string(),
    })
    .optional(),
});

const stationSingleStopSchema = z.object({
  stationInformationType: z.enum([
    "STATION_ARRIVAL_AND_DEPARTURE_MONITOR_FAULT",
    "STATION_ELEVATOR_FAULT",
    "STATION_SPEAKER_FAULT",
    "STATION_TRACK_MONITOR_FAULT",
    "STATION_WHEELCHAIR_RAMP_FAULT",
  ]),
  stop: z.string().min(1),
  affectedTracks: z.number().int().nonnegative().array(),
});

const StationCommunicationSystemsDownSchema = z.object({
  stationInformationType: z.literal("STATION_COMMUNICATION_SYSTEMS_DOWN"),
  stops: z.string().array().min(1),
  affectedSystems: stationCommunicationSystemsEnum.array().min(1),
});

const stationCustomSchema = z.object({
  stationInformationType: z.literal("STATION_GENERAL"),
  stops: z.string().array().min(1),
  severity: stationCustomEventSeverityEnum,
  distributions: distributionInput,
});

const stationGeneralSchema = z.object({
  stationInformationType: z.enum([
    "STATION_PLATFORM_FAULT",
    "STATION_ALL_SALES_CHANNELS_DOWN",
    "STATION_APP_SALES_CHANNEL_DOWN",
    "STATION_MOBILE_TERMINALS_SALES_CHANNEL_DOWN",
    "STATION_TICKET_MACHINE_DOWN",
    "STATION_TICKET_VALIDATOR_DOWN",
    "STATION_DEPOSIT_MACHINE_DOWN",
    "STATION_MAIN_MONITOR_FAULT",
    "STATION_PLATFORM_SLIPPERY",
    "STATION_PARKING_ISSUE",
    "STATION_ENTRANCE_CHANGED",
    "STATION_WAITING_ROOM_CLOSED",
    "STATION_CLOSED_FOR_PASSENGERS",
    "STATION_DROPS_EVACUATION",
  ]),
  stops: z.string().array().min(1),
});

export const stationFormSchema = z.discriminatedUnion(
  "stationInformationType",
  [
    stationThreatLevelSchema,
    stationSalesChannelReducedSchema,
    stationPersonSchema,
    stationSingleStopSchema,
    StationCommunicationSystemsDownSchema,
    stationCustomSchema,
    stationGeneralSchema,
  ],
);

export const stationEventResponse = stationFormSchema.and(
  z.object({
    message: z.string(),
    uuid: z.string(),
    internalMessages: InternalMessagesResponseSchema,
    visibility: visibilitySchema,
  }),
);
export type StationEventResponse = z.infer<typeof stationEventResponse>;

export const getAffectedStops = (stationEvent: StationEventResponse) => {
  switch (stationEvent.stationInformationType) {
    case "STATION_ARRIVAL_AND_DEPARTURE_MONITOR_FAULT":
    case "STATION_ELEVATOR_FAULT":
    case "STATION_SPEAKER_FAULT":
    case "STATION_TRACK_MONITOR_FAULT":
    case "STATION_WHEELCHAIR_RAMP_FAULT":
      return stationEvent.stop;
    default:
      return stationEvent.stops.length > 400
        ? "Hele landet"
        : stationEvent.stops.join(", ");
  }
};
export type InternalMessageResponse = {
  type: "GLOBAL_INTERNAL_INFO";
  uuid: string;
  updatedAt: string;
  referencedIncidentIds: string[];
  originSystem: OriginSystem;

  relevantStops: Stop[];
} & InternalMessageCommon;
