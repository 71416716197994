import {
  CloseFill24Icon,
  SearchOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Combobox, IconButton, Item } from "@vygruppen/spor-react";
import { FC, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import { Location } from "./locations";

interface LocationSelectProps {
  locations: Location[];
  label?: string;
}

const LocationSelect: FC<LocationSelectProps> = ({
  locations,
  label = "Velg sted",
}) => {
  const [query, setQuery] = useState<string | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null,
  );
  const map = useMap();

  useEffect(() => {
    const selected = locations.find((item) => item.name === query);
    setSelectedLocation(selected || null);
  }, [query]);

  useEffect(() => {
    if (selectedLocation) {
      map.fitBounds(selectedLocation.bounds);
    }
  }, [selectedLocation]);

  const resetCombobox = () => {
    setSelectedLocation(null);
    setQuery(null);
  };

  useEffect(() => {
    map.on("dragend", resetCombobox);

    return () => {
      map.off("dragend", resetCombobox);
    };
  }, []);

  return (
    <>
      <Combobox
        label={label}
        aria-label={label}
        onInputChange={setQuery}
        inputValue={query || ""}
        leftIcon={<SearchOutline24Icon />}
        paddingLeft={7}
      >
        {locations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((item) => (
            <Item key={item.name} textValue={item.name}>
              {item.name}
            </Item>
          ))}
      </Combobox>
      {query && (
        <IconButton
          icon={<CloseFill24Icon />}
          aria-label="Fjern søk"
          variant="ghost"
          marginLeft="-50px"
          height="25px"
          marginY="auto"
          onClick={() => {
            if (query) {
              setQuery(null);
            }
          }}
        />
      )}
    </>
  );
};

export default LocationSelect;
