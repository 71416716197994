import { useMutation } from "@tanstack/react-query";
import {
  CheckmarkOutline24Icon,
  CloseOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { Box, Button, Flex } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { TrainIdentifierWithIncidentIdString } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import { AffectedTrain as AffectedTrainType } from "features/CenterContent/RoleContent/Vaktleder/types";
import { findSeriesDiscrepancies } from "features/History/groupTrainSeries";
import AffectedTrain from "features/VehicleSidebar/AffectedTrains/lists/UnhandledListElement";
import { TrainFilter } from "features/VehicleSidebar/AffectedTrains/TrainFilter";
import { TrainRouteDiscrepancyWarning } from "features/VehicleSidebar/AffectedTrains/TrainRouteDiscrepancyWarning";
import { sortByIncidentArrivalTime } from "features/VehicleSidebar/AffectedTrains/utils/AffectedTrainSorter";
import {
  getFilteredTrains,
  getSessionStorageFilter,
  setSessionStorageFilter,
} from "features/VehicleSidebar/AffectedTrains/utils/filterAffectedTrains";
import { useMinute } from "features/VehicleSidebar/AffectedTrains/utils/timeFormatting";
import { FC, RefObject, useEffect, useMemo, useState } from "react";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import { DropsStaticAlert } from "shared/components/feedback/DropsStaticAlert";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import { useInfiniteScroll } from "shared/hooks/useInfiniteScroll";
import { useTheme } from "styled-components";
import { ButtonGroupFitter } from "../styles";
import { AffectedTrainFilter, TrainRouteDiscrepancies } from "../types";

type UnhandledTrainListProps = {
  containerRef: RefObject<HTMLElement>;
  trains: AffectedTrainType[];
  selectedTrains: AffectedTrainType[];
  updateSelectedTrains: (train: AffectedTrainType) => void;
  clearSelectedTrains: () => void;
  batchUpdatedSelectedTrains: (affectedTrains: AffectedTrainType[]) => void;
  handleCreateEvents: () => void;
  showFilter: boolean;
  pendingTrains: TrainIdentifierWithIncidentIdString[];
  refetch: () => void;
  setTabPulse: (value: number) => void;
  notAffected?: boolean;
};

export const UnhandledTrainList: FC<UnhandledTrainListProps> = ({
  containerRef,
  trains,
  selectedTrains,
  updateSelectedTrains,
  clearSelectedTrains,
  batchUpdatedSelectedTrains,
  handleCreateEvents,
  showFilter,
  pendingTrains,
  refetch,
  setTabPulse,
  notAffected,
}) => {
  const theme = useTheme();

  const currentTime = useMinute();

  const [filter, setFilter] = useState<AffectedTrainFilter>(
    getSessionStorageFilter,
  );

  useEffect(() => {
    setSessionStorageFilter(filter);
  }, [filter]);

  const [routeDiscrepancies, setRouteDiscrepancies] =
    useState<TrainRouteDiscrepancies>(null);

  useEffect(() => {
    const discrepancies = findSeriesDiscrepancies(selectedTrains);
    setRouteDiscrepancies(
      Object.keys(discrepancies).length > 0 ? discrepancies : null,
    );
  }, [selectedTrains]);

  // Ensure that only trains from the selected incident is included in batch
  useEffect(() => {
    clearSelectedTrains();
  }, [filter?.incidentId]);

  const hasFilteredTrains =
    filter?.trainSeriesIds && filter.trainSeriesIds.length > 0;
  const hasSelectedTrains = selectedTrains.length > 0;
  const shouldShowFilterButtons = showFilter && hasFilteredTrains;

  const shouldShowNotAffectedButton = hasSelectedTrains;
  const shouldShowRemoveAllButton = hasSelectedTrains;
  const shouldShowChooseAllButton =
    shouldShowFilterButtons && !hasSelectedTrains;

  const filteredTrains = useMemo(
    () => getFilteredTrains(filter, trains),
    [filter, trains],
  );

  const { numberOfItemsToRender } = useInfiniteScroll({
    containerRef,
    initialItemsToRender: 30,
    maxItemsToRender: filteredTrains.length,
  });

  const [error, setError] = useState(false);
  const markSelectedNotAffectedMutation = useMutation({
    mutationFn: () =>
      mutationFnPOST(
        `${getBackendUrl()}/affected-trains`,
        selectedTrains.map((train) => ({
          ...train,
          state: notAffected ? "AFFECTED" : "NOT_AFFECTED",
        })),
      ),
    onSuccess: () => {
      clearSelectedTrains();
      setError(false);
      refetch();
      (() => (notAffected ? setTabPulse(1) : setTabPulse(3)))();
      setTimeout(() => {
        setTabPulse(0);
      }, 1000);
    },
    onError: () => {
      setError(true);
    },
  });

  const markSelectedNotAffected = () => {
    markSelectedNotAffectedMutation.mutate();
  };

  return (
    <>
      <TrainRouteDiscrepancyWarning
        trainRouteDiscrepancies={routeDiscrepancies}
      />
      {shouldShowRemoveAllButton && (
        <ButtonGroupFitter
          id="buttonGroup"
          px={1}
          py={2}
          mt={0.5}
          justifyContent="space-between"
          backgroundColor={theme.colorBackgroundSecondary}
          borderWidth="1px"
          borderColor={theme.colorBorder}
          borderRadius={6}
        >
          {shouldShowRemoveAllButton && (
            <Button
              size="sm"
              fontWeight="500"
              leftIcon={<CloseOutline24Icon />}
              variant="tertiary"
              onClick={clearSelectedTrains}
            >
              Avmarker
            </Button>
          )}
          <Flex gap={1}>
            {shouldShowNotAffectedButton && (
              <Button
                size="sm"
                fontWeight={notAffected ? "600" : "500"}
                variant={notAffected ? "primary" : "secondary"}
                onClick={markSelectedNotAffected}
              >
                {notAffected ? (
                  <Flex gap={1} alignItems="center">
                    Likevel berørt
                    <AlertsCounter
                      severity="success"
                      alerts={selectedTrains.length}
                    />
                  </Flex>
                ) : (
                  "Ikke berørt"
                )}
              </Button>
            )}

            {shouldShowChooseAllButton && (
              <Button
                size="sm"
                leftIcon={<CheckmarkOutline24Icon />}
                variant="primary"
                fontWeight="600"
                isDisabled={filteredTrains.length <= 0}
                onClick={() =>
                  batchUpdatedSelectedTrains(
                    filteredTrains.filter((train) => {
                      // Don't include trains that are currently pending
                      const identifier: TrainIdentifierWithIncidentIdString = `${train.incidentId}-${train.trainId.countryCode}-${train.trainId.identifier}-${train.trainId.nominalDate}`;
                      return !pendingTrains.includes(identifier);
                    }),
                  )
                }
              >
                Velg alle tog
              </Button>
            )}
            {hasSelectedTrains && !notAffected ? (
              <Button
                size="xs"
                fontWeight="600"
                variant="primary"
                onClick={handleCreateEvents}
                isDisabled={selectedTrains.length <= 0}
              >
                Behandle {selectedTrains.length} tog
              </Button>
            ) : null}
          </Flex>
        </ButtonGroupFitter>
      )}
      {error && (
        <DropsStaticAlert gridRow="2" gridColumn="span 2" variant="error">
          Kunne ikke markere som{notAffected ? "" : " ikke"} berørt
        </DropsStaticAlert>
      )}
      <Flex flexDir="column" maxH="100%">
        <TrainFilter
          trains={trains}
          filter={filter}
          showFilter={showFilter}
          setFilter={setFilter}
        />
        <Box overflowY="scroll" pt={1}>
          {filteredTrains.length > 0 ? (
            filteredTrains
              .toSorted(sortByIncidentArrivalTime)
              .slice(0, numberOfItemsToRender)
              .map((train) => {
                const identifier: TrainIdentifierWithIncidentIdString = `${train.incidentId}-${train.trainId.countryCode}-${train.trainId.identifier}-${train.trainId.nominalDate}`;

                return (
                  <AffectedTrain
                    train={train}
                    selectedTrains={selectedTrains}
                    updateSelectedTrains={updateSelectedTrains}
                    key={identifier}
                    isPending={pendingTrains.includes(identifier)}
                    currentTime={currentTime}
                  />
                );
              })
          ) : (
            <NoDataMessage
              pt={2}
              height="100%"
              reason="Filteret gir ingen treff"
            />
          )}
        </Box>
      </Flex>
    </>
  );
};
