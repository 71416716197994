import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import {
  InfrastructureEventResponse,
  infrastructureEventResponseSchema,
  InfrastructureEventType,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { log, LogLevel } from "logging/datadogBrowserLogs";

export const eventByUuidQueryKey = "infrastructureEvent";

type Response = (InfrastructureEventType | null)[];

export const useInfrastructureEvent = (uuids: string[]) =>
  useQuery<InfrastructureEventResponse>({
    queryKey: [eventByUuidQueryKey, ...uuids],
    queryFn: async ({ signal }) => {
      const { data } = await axiosClient.get<Response>(
        `${getBackendUrl()}/infrastructure-event?uuids=${uuids.join("&uuids=")}`,
        {
          signal,
        },
      );

      return data.reduce((acc, infrastructureEvent) => {
        if (infrastructureEvent === null) {
          return acc;
        }
        const { error } =
          infrastructureEventResponseSchema.safeParse(infrastructureEvent);
        if (error) {
          log(
            LogLevel.error,
            "Infrastructure event response schema error",
            error.message,
          );
        }
        return {
          [infrastructureEvent.infrastructureInformation.uuid]: {
            // cannot use safeParse.data since the unpacking would crash
            ...infrastructureEventResponseSchema.parse(infrastructureEvent),
          },
          ...acc,
        };
      }, {}) as InfrastructureEventResponse;
    },
    staleTime: 0,
  });
