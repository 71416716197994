import { TrainIdentifier } from "@vygruppen/vy-train-map";
import { getRequestVisibility } from "features/CenterContent/RoleContent/GlobalEvents/formToGlobalInformationRequest";
import {
  Visibility,
  VisibilityRequest,
  VisibilitySchema,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { InternalMessagesForm } from "features/CenterContent/RoleContent/InternalMessage/schema/formSchema";
import { InternalMessageRequest } from "features/CenterContent/RoleContent/InternalMessage/schema/requestSchema";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import {
  Stop,
  formatTime,
  trainIdentifierToOperationalIdentifierJson,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/utils";
import { parseZonedTime } from "shared/utils/dateTimeUtils";
import { Nullish } from "shared/utils/objectUtils";
import { getCurrentCalendarDateTime } from "../../TrainMap/StretchBuilder/infrastructureEvents/types/transformers";

type formDefaultOptions = {
  uuid?: string;
  message?: string;
};

export const internalMessagesFormDefault = (
  options?: formDefaultOptions,
): InternalMessagesForm => {
  const { uuid = undefined, message = "" } = options ?? {};
  return [
    {
      uuid,
      message,
    },
  ];
};

export const contextsForInternalMessage = ["DROPS", "DROPS_LOG"];

const toCustomText = (description: string) => ({
  contexts: contextsForInternalMessage,
  texts: {
    NOB: { title: "", description },
  },
});

export const internalMessagesFormToRequest = (
  form?: InternalMessagesForm,
  visibility?: VisibilitySchema,
  relevantTrain?: TrainIdentifier,
  relevantStops: Stop[] = [],
): InternalMessageRequest | undefined => {
  if (!form) return undefined;
  const lastInternalMessage = form[form.length - 1];
  if (!lastInternalMessage?.message) return undefined;
  const { uuid, message } = lastInternalMessage;
  return {
    uuid, // Not sent to backend. Used as pathVariable

    globalInformationType: "GLOBAL_INTERNAL_INFO",
    visibility: visibility
      ? getRequestVisibility(visibility)
      : {
          type: "TIMED",
          fromTime: formatTime(getCurrentCalendarDateTime()),
          toTime: formatTime(getCurrentCalendarDateTime(24)),
        },
    incidentIds: [],
    countryCode: "NO",
    customTexts: [toCustomText(message)],
    relevantStops: relevantStops.map((it) => it.id),
    relevantTrain: relevantTrain
      ? trainIdentifierToOperationalIdentifierJson(relevantTrain)
      : undefined,
    relatedEventUuid: undefined, // Added from response from backend
  };
};

// Converts each response to form
export const internalMessagesResponseToFormHistory = (
  form: Nullish<InternalMessageResponse[]>,
) =>
  (form ?? []).map(({ originSystem, customTexts, updatedAt }) => ({
    uuid: undefined, // unused for prevMsgs
    message: customTexts[0].texts.NOB.description,
    user: originSystem.user ?? originSystem.name,
    updatedAt,
  }));

// Converts each response to form and adds an empty form for editing
export const internalMessagesResponseToForm = (
  form: Nullish<InternalMessageResponse[]>,
): InternalMessagesForm => {
  if (!form || form.length === 0) return internalMessagesFormDefault();
  const prevMsgs = internalMessagesResponseToFormHistory(form);
  const latestUuid = form[form.length - 1]?.uuid;
  const newMsg = internalMessagesFormDefault({ uuid: latestUuid })!;
  return [...prevMsgs, ...newMsg];
};

export const getVisibility = (visibility: VisibilityRequest): Visibility => {
  switch (visibility.type) {
    case "CONSTANT":
      return {
        type: "CONSTANT",
        visible: true,
      };
    case "TIMED":
      return {
        type: "TIMED",
        fromTime: parseZonedTime(visibility.fromTime),
        toTime: parseZonedTime(visibility.toTime),
      };
    case "OPEN_ENDED":
    default:
      return {
        type: "OPEN_ENDED",
        fromTime: parseZonedTime(visibility.fromTime),
      };
  }
};

export const getVisibilityRequest = (
  visibility: Visibility,
): VisibilityRequest => {
  switch (visibility.type) {
    case "CONSTANT":
      return {
        type: "CONSTANT",
        visible: true,
      };
    case "TIMED":
      return {
        type: "TIMED",
        fromTime: visibility.fromTime.toString(),
        toTime: visibility.toTime.toString(),
      };
    case "OPEN_ENDED":
    default:
      return {
        type: "OPEN_ENDED",
        fromTime: visibility.fromTime.toString(),
      };
  }
};
