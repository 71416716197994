import { FC } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent as SporModalContent,
  ModalHeader,
  ModalOverlay,
} from "@vygruppen/spor-react";
import styled from "styled-components";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { compareDateStringAsc } from "shared/utils/datetime";
import { IncidentLogMessage } from "./types";
import { LogEntry } from "../../VehicleDetails/shared/LogEntry";

const LogList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  row-gap: 24px;
`;

const ModalContent = styled(SporModalContent)`
  ${ObtrusiveScrollCss};
`;

type ReadLogMessagesModalProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isirkId: string;
  messages: IncidentLogMessage[];
};

const ReadLogMessagesModal: FC<ReadLogMessagesModalProps> = ({
  isOpen,
  setIsOpen,
  messages,
  isirkId,
}) => (
  <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
    <ModalOverlay />
    <ModalContent overflowY="scroll" maxH="80vh" minH="50vh" minW="800px">
      <ModalHeader textAlign="center">
        Loggmeldinger på I-Sirk {isirkId}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <LogList>
          {messages
            .sort((a, b) => compareDateStringAsc(a.timestamp, b.timestamp))
            .map(({ id, timestamp, message, sourceSystem }) => (
              <LogEntry
                key={id}
                timestamp={timestamp}
                message={message}
                source={sourceSystem.author?.split("@")[0]}
                fallbackSource={sourceSystem.name}
              />
            ))}
        </LogList>
      </ModalBody>
    </ModalContent>
  </Modal>
);
export default ReadLogMessagesModal;
