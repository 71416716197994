import { getBackendUrl } from "api/common";
import { queryFnPOST } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { Visibility } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import {
  getVisibilityRequest,
  internalMessagesFormToRequest,
} from "features/CenterContent/RoleContent/InternalMessage/utils/form";
import { TrainInternalMessagesQueryKey } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/useTrainInternalMessages";
import { Nullish } from "shared/utils/objectUtils";
import { InternalMessageResponse } from "../../TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";
import { InternalMessagesForm } from "../schema/formSchema";

type ResponseWithVisibility = { uuid: string; visibility: Visibility };

// If you're debugging with this function, have a look at internalMessages.test.ts for help
export const createInternalMessage =
  (internalMessagesForm: InternalMessagesForm) =>
  async (data: ResponseWithVisibility) => {
    if (!data || !data.visibility || !data.uuid || !internalMessagesForm)
      return;
    const internalMessage = internalMessagesFormToRequest(internalMessagesForm);
    if (!internalMessage) return;
    internalMessage.visibility = getVisibilityRequest(data.visibility);
    internalMessage.relatedEventUuid = data.uuid;
    const path = internalMessage.uuid
      ? `${internalMessage.uuid}/change`
      : `create`;
    await queryFnPOST({
      url: `${getBackendUrl()}/global-information/${path}`,
      body: internalMessage,
      signal: undefined,
    });
  };

// If you're debugging with this function, have a look at internalMessages.test.ts for help
export const closeInternalMessage = async (
  internalMessages: Nullish<InternalMessageResponse[]>,
) => {
  if (!internalMessages || internalMessages.length === 0) return;
  const lastInternalMessage = internalMessages[internalMessages.length - 1];
  await queryFnPOST({
    url: `${getBackendUrl()}/operational-information/${lastInternalMessage.uuid}/depublish`,
    signal: undefined,
  }).then(() => {
    const train = lastInternalMessage.relevantTrain;
    if (train) {
      queryClient.invalidateQueries({
        queryKey: TrainInternalMessagesQueryKey(
          train?.nominal_date,
          train?.operational_identifier,
        ),
      });
    }
  });
};
