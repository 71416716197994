import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { useState } from "react";
import { UserRole } from "shared/types/roles";
import { useTheme } from "styled-components";
import { Text } from "../../../../../shared/components/typography/TitleStyles";
import { TodoStatus } from "../../TodoComponent";
import { TodoElementWrapper } from "../../TodoStyles";
import TodoBanner from "./TodoBanner";
import TodoEdit from "./TodoEdit";
import TodoFooter from "./TodoFooter";

export interface Todo {
  id: string;
  userId: string;
  title: string | null;
  description: string;
  dateCreated: string;
  role: UserRole;
  status: TodoStatus | null;
  priority: boolean | null;
  markedAsRead: boolean | null;
  trainNumber: string;
  vehicleSetTypes: string | null;
  transferOnShiftEnd: null;
  alarmTime: string | null;
}

type Props = {
  todo: Todo;
};
const TodoElement = ({ todo }: Props) => {
  const [selectedTodoId, setSelectedTodoId] = useState<string>("");

  const isShowingEdit = selectedTodoId === todo.id;

  const theme = useTheme();

  return (
    <>
      <TodoElementWrapper>
        <TodoBanner todo={todo} />
        {isShowingEdit ? (
          <TodoEdit
            todo={todo}
            selectedTodoId={selectedTodoId}
            setSelectedTodoId={setSelectedTodoId}
          />
        ) : (
          <div>
            <Text>{todo.description}</Text>
            <TodoFooter
              todo={todo}
              onClick={() => {
                setSelectedTodoId(todo.id);
              }}
            />
          </div>
        )}
      </TodoElementWrapper>
      <DashedLine color={theme.colorBackgroundSecondary} />
    </>
  );
};

export default TodoElement;
