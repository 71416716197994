import { Flex } from "@vygruppen/spor-react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import FitToBounds from "features/CenterContent/shared/Map/FitToBounds";
import { BaseGpsData } from "features/CenterContent/shared/types";
import { LatLngLiteral } from "leaflet";
import { FC } from "react";
import { MapContainer, TileLayer, ZoomControl } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import styled from "styled-components";
import LocationSelect from "./LocationSelect";
import { VehicleMapMarkers } from "./VehicleMapMarkers";
import { MAP_MAX_ZOOM } from "./constants";
import { locations } from "./locations";
import { createClusterCustomIcon, getBounds, makeBound } from "./utils";

const GrayscaleTileLayer = styled(TileLayer)`
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
`;

type VehicleMapLayersProps = {
  gpsData: BaseGpsData[];
  query: string;
  searchPhoneNumber?: boolean;
  searchMarginTop?: number;
};

const VehicleMapLayers: FC<VehicleMapLayersProps> = ({
  gpsData,
  query,
  searchPhoneNumber = false,
  searchMarginTop = 0,
}) => {
  const coords: LatLngLiteral[] = gpsData.map((data) => ({
    lat: data.gps.latitude,
    lng: data.gps.longitude,
  }));
  const bounds = getBounds(coords);
  const getPhoneLocations = () =>
    gpsData.map((it: BaseGpsData) => ({
      name: it.phoneNumber ?? "N/A",
      bounds: makeBound(it.gps),
    }));

  return (
    <MapContainer
      attributionControl={false}
      bounds={bounds}
      zoomControl={false}
      boundsOptions={{ padding: [64, 64], maxZoom: MAP_MAX_ZOOM }}
      maxZoom={MAP_MAX_ZOOM}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {/* Background layer */}
      <GrayscaleTileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        maxZoom={MAP_MAX_ZOOM}
      />

      {/* Railway layer */}
      <GrayscaleTileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tiles.openrailwaymap.org/standard/{z}/{x}/{y}.png"
        maxZoom={MAP_MAX_ZOOM}
      />

      <MarkerClusterGroup
        chunkedLoading
        iconCreateFunction={createClusterCustomIcon}
        showCoverageOnHover={false}
        zoomToBoundsOnClick
        spiderfyOnEveryZoom={false}
        disableClusteringAtZoom={MAP_MAX_ZOOM - 1}
      >
        <VehicleMapMarkers gpsData={gpsData} />
      </MarkerClusterGroup>

      <ZoomControl position="bottomright" />

      {query.length && <FitToBounds bounds={bounds} />}

      <Flex position="absolute" top={0} right={0} p={2} zIndex={1000}>
        <LocationSelect
          locations={searchPhoneNumber ? getPhoneLocations() : locations}
          label={searchPhoneNumber ? "Søk på telefonnummer" : "Velg sted"}
        />
      </Flex>
    </MapContainer>
  );
};

export default withErrorBoundary(VehicleMapLayers);
