import { Box, BoxProps, Text } from "@vygruppen/spor-react";
import { ButtonSeverity } from "shared/components/buttons/SemanticButton";
import { SeverityColors } from "shared/components/buttons/SemanticButtonPrimary";
import styled, { useTheme } from "styled-components";

const StyledBox = styled(Box)`
  position: relative;
  border-radius: 5px;
  height: 18px;
  min-width: 18px;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AlertsCounter = ({
  alerts,
  severity,
  ...rest
}: {
  alerts: number;
  severity: ButtonSeverity;
} & BoxProps) => {
  const theme = useTheme();
  if (alerts === 0) return null;
  const { severityFill, severityTextMain } = SeverityColors(severity, theme);
  return (
    <StyledBox backgroundColor={severityFill} {...rest}>
      <Text
        color={severityTextMain}
        variant="xs"
        textAlign="center"
        lineHeight="0"
        fontWeight="600"
        fontSize="12px"
        paddingX="3px"
      >
        {alerts}
      </Text>
    </StyledBox>
  );
};
