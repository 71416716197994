import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";

export type ExistingCancelledTrainRouteResponse = {
  uuid: string;
  stops: Stop[];
};

export type Stop = {
  ids: Array<StopId>;
};

type StopId = {
  id: string;
  name?: string;
  context: string;
};

type Props = {
  trainId: string | undefined;
  nominalDate: string | undefined;
  uuid: string | undefined;
};

const routeChangedEventsQueryKey = "existingCancelledTrainEvents";
export const useExistingTrainCancelledEvents = ({
  trainId,
  nominalDate,
  uuid,
}: Props) => {
  const { status, data, isSuccess } = useQuery({
    queryKey: [routeChangedEventsQueryKey, trainId, nominalDate, uuid],
    queryFn: ({ signal }) =>
      queryFnGET<ExistingCancelledTrainRouteResponse[]>({
        signal,
        url: `${getBackendUrl()}/trainInformation/train/existing/cancelled-stops/NO/${trainId}/${nominalDate}`,
      }),
    gcTime: 0,
  });
  return { status, data, isSuccess };
};
