import { TodoFilterState } from "features/TodoOploggSidebar/Todo/todoFilter/useTodoFilterState";
import { UserRole } from "shared/types/roles";
import { v4 as uuidv4 } from "uuid";

export const getDropsToken = () => localStorage.getItem("ACCESS_TOKEN");
export const getRefreshToken = () => localStorage.getItem("REFRESH_TOKEN");
export const setDropsToken = (token: string) => {
  localStorage.setItem("ACCESS_TOKEN", token);
};
export const setRefreshToken = (token: string) => {
  localStorage.setItem("REFRESH_TOKEN", token);
};
export const createCognitoState = () => {
  const state = uuidv4();
  localStorage.setItem("COGNITO_STATE_PARAM", state);
  return state;
};

export const getCognitoState = () =>
  localStorage.getItem("COGNITO_STATE_PARAM");

export const storeLocalUserData = (username: string, email: string) => {
  localStorage.setItem("username", username);
  localStorage.setItem("user_email", email);
};

export const getLocalStorageUsername = () => localStorage.getItem("username");

export const getLocalStorageUserEmail = () =>
  localStorage.getItem("user_email");

export const getLocalStorageRole = () =>
  localStorage.getItem("user_role") as UserRole;

export const setLocalStorageRole = (userRole: UserRole) =>
  localStorage.setItem("user_role", userRole);

export const getLocalStorageChangelogUuid = () =>
  localStorage.getItem("changelogUuid");

export const setLocalStorageChangelogUuid = (uuid: string) =>
  localStorage.setItem("changelogUuid", uuid);

export const setLocalStorageTodoFilterSettings = (
  todoFilterSettings: TodoFilterState,
) =>
  localStorage.setItem(
    "todoFilterSettings",
    JSON.stringify(todoFilterSettings),
  );

export const getLocalStorageTodoFilterSettings = (): TodoFilterState =>
  JSON.parse(localStorage.getItem("todoFilterSettings") ?? "null");

export const setLocalStorageOploggTodoTabsSettings = (
  oploggTodoTabSettings: string[],
) =>
  localStorage.setItem(
    "oploggTodoTabsSettings",
    JSON.stringify(oploggTodoTabSettings),
  );

export const getLocalStorageOploggTodoTabsSettings = (): string[] =>
  JSON.parse(
    localStorage.getItem("oploggTodoTabsSettings") ??
      JSON.stringify(["oplogg"]),
  );

export const setLocalStorageVehicleAffectedSidePanelSettings = (
  VehicleAffectedSidePanelSettings: boolean,
) =>
  localStorage.setItem(
    "VehicleAffectedSidePanelSettings",
    JSON.stringify(VehicleAffectedSidePanelSettings),
  );

export const getLocalStorageVehicleAffectedSidePanelSettings = (): boolean =>
  JSON.parse(
    localStorage.getItem("VehicleAffectedSidePanelSettings") ??
      JSON.stringify(true),
  );
