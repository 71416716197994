import { isToday } from "date-fns";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { delayThreshold } from "shared/utils/delayUtils";
import { DefaultTheme } from "styled-components";
import { TrainData } from "./types";
/* Returns true if the current time(now) is between the train's scheduled departure time minus 5 min,
 * and scheduled arrival time + delay + 2 min.
 * */
export const trainIsActive = (train: TrainData) => {
  if (
    !train.destinationScheduledArrivalTime ||
    !train.originScheduledDepartureTime
  ) {
    return false;
  }

  const departureTime = new Date(train.originScheduledDepartureTime);
  const arrivalTime = new Date(train.destinationScheduledArrivalTime);
  const delayMinutes = train.delay ? Math.max(train.delay, 0) : 0;

  // five minutes before scheduled departure time from origin stop
  departureTime.setMinutes(departureTime.getMinutes() - 5);
  // two minutes after scheduled arrival time + possible delays for end stop
  arrivalTime.setMinutes(arrivalTime.getMinutes() + delayMinutes + 2);

  const now = Date.now();

  return now >= departureTime.valueOf() && now <= arrivalTime.valueOf();
};

/* Return the color to indicate trains delay status. If there is no major, moderate or minor delays, it return a transparent color */
export const calcDelayColor = (delay: number | null, theme: DefaultTheme) => {
  if (delay) {
    if (delay < -1) return theme.colorInfo;
    if (delay >= delayThreshold.major) return theme.colorAlarm;
    if (delay >= delayThreshold.moderate) return theme.colorSecondaryAlarm;
    if (delay >= delayThreshold.minor) return theme.colorWarning;
  }

  return "transparent";
};

/* If the user has entered a search query > 2 characters; it filters based on matches for those characters.
 * Else; it filters based on which trains are active.
 * */
export const filterTrainList = (
  train: TrainData,
  searchQuery: string,
  date: Date,
  showTrainsMissingData: boolean,
) => {
  const lowerCaseQuery = searchQuery.toLowerCase();
  const hasTrainCompany = train.trainCompany !== null;
  if (lowerCaseQuery.length >= 2) {
    const trainMatchingQuery =
      train.trainNumber?.toLowerCase().startsWith(lowerCaseQuery) ||
      train.originStopName?.toLowerCase()?.startsWith(lowerCaseQuery) ||
      train.destinationStopName?.toLowerCase()?.startsWith(lowerCaseQuery) ||
      train.lineIds.filter((id) =>
        id.lineId.toLowerCase().startsWith(lowerCaseQuery),
      ).length > 0 ||
      train.vehicleSetIds.filter((set) =>
        set.toLowerCase().startsWith(lowerCaseQuery),
      ).length > 0;

    return showTrainsMissingData
      ? trainMatchingQuery
      : trainMatchingQuery && hasTrainCompany;
  }
  // Show all trains instead if you're checking a spesific day
  if (hasTrainCompany) {
    return isToday(date) ? trainIsActive(train) : true;
  }
  return false;
};

/* Sort trains with a delay > 0 at the top in an ascending order. Ones with the same delay are secondary sorted based on descending departure times.
 * Sort the rest ( delay !> 0) based on descending departure times
 * */
export const sortByDepartureTimeAsc = (a: TrainData, b: TrainData) => {
  const aOriginTime = a.originScheduledDepartureTime;
  const bOriginTime = b.originScheduledDepartureTime;

  const sortByDepartureTime =
    aOriginTime && bOriginTime
      ? aOriginTime.valueOf() - bOriginTime.valueOf()
      : 1;

  if (a.delay !== null && a.delay > 0) {
    if (b.delay !== null && b.delay > 0) {
      return b.delay - a.delay || sortByDepartureTime;
    }
    return -1;
  }

  if (b.delay !== null && b.delay > 0) {
    return 1;
  }

  return sortByDepartureTime;
};

export const sortByTrainNumberAsc = (a: TrainData, b: TrainData) => {
  const aTrainNumberInt = parseInt(a.trainNumber, 10);
  const bTrainNumberInt = parseInt(b.trainNumber, 10);
  const aIsToday = isToday(new Date(a.trainDate));
  const bIsToday = isToday(new Date(b.trainDate));

  if (a.trainNumber === b.trainNumber) {
    if (aIsToday) return -1;
    if (bIsToday) return 1;
  }

  if (aTrainNumberInt !== bTrainNumberInt)
    return aTrainNumberInt - bTrainNumberInt;
  return (
    a.trainNumber.charCodeAt(a.trainNumber.length - 1) -
    b.trainNumber.charCodeAt(b.trainNumber.length - 1)
  );
};

export const sortTrainData = (
  a: TrainData,
  b: TrainData,
  searchQuery: string,
) => {
  if (searchQuery.length >= 2) {
    return sortByTrainNumberAsc(a, b);
  }

  return sortByDepartureTimeAsc(a, b);
};

export const internalMessagesHasTrain = (
  internalMessages: InternalMessageResponse[] | undefined,
  train: TrainData,
) => {
  const internalMsg = internalMessages
    ?.map((it) => it.relevantTrain)
    .find(
      (it) =>
        it?.operational_identifier === train.trainNumber &&
        it?.nominal_date === train.trainDate &&
        it?.country_code === train.countryCode,
    );
  return !!internalMsg;
};
