import { useMutation } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { GLOBAL_EVENT_QUERY_KEY } from "features/CenterContent/RoleContent/GlobalEvents/GlobalEventModal";
import {
  GlobalEventRequest,
  GlobalEventResponse,
} from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { GLOBAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/useGlobalInformation";

export const usePostGlobalInfo = (uuid?: string) =>
  useMutation({
    mutationFn: (data: GlobalEventRequest) =>
      mutationFnPOST<GlobalEventResponse, GlobalEventRequest>(
        `${getBackendUrl()}/global-information${uuid ? `/${uuid}/change` : "/create"}`,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [GLOBAL_EVENTS_QUERY_KEY],
      });
      queryClient.invalidateQueries({
        queryKey: [GLOBAL_EVENT_QUERY_KEY, uuid],
      });
    },
  });
