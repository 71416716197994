import { getCognitoBaseUrl } from "app/Auth/authConfig";
import axios from "axios";
import { log, LogLevel } from "logging/datadogBrowserLogs";

const cognitoHttpClient = axios.create();
const logSource = "cognitoHttpClient";

cognitoHttpClient.interceptors.request.use((config) => {
  config.url = `${getCognitoBaseUrl()}/${config.url}`;
  return config;
});

cognitoHttpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    log(
      LogLevel.info,
      logSource,
      `Call to ${error.response?.request?.responseURL} failed with message: ${error.message}`,
    );
    return error;
  },
);

export { cognitoHttpClient };
