import { Tooltip } from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { Button, ButtonGroup, Flex, Text } from "@vygruppen/spor-react";
import { useDepublishInformation } from "features/OperationalInformation/useDepublishInformation";
import { FC, useState } from "react";
import { FailureMessageWithRetryButton } from "shared/components/feedback/FailureMessageWithRetryButton";

type DepublishInfoProps = {
  uuid: string;
  isDisabled: boolean;
  queryKey: string[];
  closeInternalMessage?: () => Promise<void>;
};

export const DepublishInformation: FC<DepublishInfoProps> = ({
  uuid,
  isDisabled,
  queryKey,
  closeInternalMessage = () => {},
}) => {
  const queryClient = useQueryClient();
  const [askConfirmation, setAskConfirmation] = useState<boolean>(false);
  const { mutateAsync: depublish, status: depublishStatus } =
    useDepublishInformation();

  const onSuccess = () => {
    setAskConfirmation(false);
    queryClient.invalidateQueries({ queryKey });
  };

  if (depublishStatus === "error") {
    return (
      <FailureMessageWithRetryButton
        customMessage="Kunne ikke depublisere hendelsen"
        retryFunction={() => {
          depublish(uuid, { onSuccess }).then(closeInternalMessage);
        }}
      />
    );
  }

  return askConfirmation ? (
    <Flex alignItems="center" gap="8px" minWidth="fit-content">
      <Text>Er du sikker?</Text>
      <ButtonGroup>
        <Button
          variant="primary"
          size="xs"
          onClick={() =>
            depublish(uuid, { onSuccess }).then(closeInternalMessage)
          }
          isLoading={depublishStatus === "pending"}
        >
          Ja, depubliser
        </Button>
        <Button
          variant="tertiary"
          size="xs"
          onClick={() => setAskConfirmation(false)}
        >
          Avbryt
        </Button>
      </ButtonGroup>
    </Flex>
  ) : (
    <Tooltip
      label="Kan ikke depublisere allerede usynlig hendelse"
      hasArrow
      placement="left"
      isDisabled={!isDisabled}
      shouldWrapChildren
    >
      <Button
        variant="tertiary"
        size="xs"
        onClick={() => setAskConfirmation(true)}
        isDisabled={isDisabled}
      >
        Depubliser
      </Button>
    </Tooltip>
  );
};
