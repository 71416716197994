import {
  FormControl,
  FormErrorMessage,
  Text,
  Textarea,
} from "@vygruppen/spor-react";
import { GlobalFormSchema } from "features/CenterContent/RoleContent/GlobalEvents/globalEventTypes";
import { FC, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTheme } from "styled-components";

type TextAreaWithCharacterCountProps = {
  label: string;
  formMethods: UseFormReturn<GlobalFormSchema>;
  formField: "NOB.title" | "NOB.description" | "ENG.title" | "ENG.description";
  isInvalid: boolean;
  errorMessage: string | undefined;
  maxLength: number;
  minHeight?: string;
  isDisabled?: boolean;
};
export const TextAreaWithCharacterCount: FC<
  TextAreaWithCharacterCountProps
> = ({
  label,
  formMethods,
  formField,
  isInvalid,
  errorMessage,
  maxLength,
  minHeight,
  isDisabled = false,
}) => {
  const theme = useTheme();
  const { getValues, setValue } = formMethods;
  const formPath = `globalForm.customTexts.texts.${formField}` as const;
  const [numChars, setNumChars] = useState(getValues(formPath)?.length ?? 0);

  return (
    <FormControl isInvalid={isInvalid}>
      <Textarea
        label={label}
        value={getValues(formPath)}
        maxLength={maxLength}
        onChange={(e) => {
          setNumChars(e.target.value.length);
          setValue(formPath, e.target.value, { shouldValidate: true });
        }}
        minHeight={minHeight}
        isDisabled={isDisabled}
      />

      <Text
        variant="xs"
        color={theme.colorTextSecondary}
        position="absolute"
        bottom="5px"
        right="15px"
      >
        {numChars}/{maxLength}
      </Text>
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
