import { Tbody, Th } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { DropdownDownFill18Icon } from "@vygruppen/spor-icon-react";
import { Button, Flex, HStack, Table, Thead, Tr } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { EditReasonCodeModal } from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/EditReasonCodeModal/EditReasonCodeModal";
import TableContent from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/TableContent/TableContent";
import TableFilter from "features/CenterContent/RoleContent/Lokleder/ReasonCodes/TableFilter/TableFilter";
import { useRef, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { TableSkeleton } from "shared/components/feedback/SkeletonLoader/TableSkeleton";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import useDebouncedValue from "shared/hooks/useDebouncedValue";
import { useReasonCodesTableFilter } from "stores/useReasonCodesTableFilter";
import styled, { useTheme } from "styled-components";
import {
  ReasonCodeItem,
  ReasonCodes as ReasonCodesResponse,
  ReasonCodesSchema,
} from "./schemas";

export type SortDirection = "DESC" | "ASC";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
  gap: 9px;
  overflow: visible;
`;

const TableContainer = styled.div`
  ${ObtrusiveScrollCss};
  overflow: auto;
  width: 100%;
  div {
    overflow: visible;
  }
`;

type TableColumn = {
  label: string;
  accessor: keyof ReasonCodeItem;
};

export const tableColumns: TableColumn[] = [
  { label: "Togdato", accessor: "trainDate" },
  { label: "Tognr", accessor: "trainNumber" },
  { label: "Innmeldt", accessor: "registeredAt" },
  { label: "Sted", accessor: "stationName" },
  { label: "Lok-kode", accessor: "employeeReasonCode" },
  { label: "Kond-kode", accessor: "employeeReasonCode" },
  { label: "DROPS-kode", accessor: "dropsReasonCode" },
  { label: "BN-kode", accessor: "tiosReasonCode" },
];

const ReasonCodes = () => {
  const theme = useTheme();
  const [selectedRC, setSelectedRC] = useState<ReasonCodeItem | null>();
  const [modalOpen, setModalOpen] = useState(false);

  // State and Actions for Table Filtering
  const { trainFilter, codeFilter, dateRange } = useReasonCodesTableFilter();

  const [debouncedTrainId] = useDebouncedValue(trainFilter, 800);
  const [debouncedCode] = useDebouncedValue(codeFilter, 400);

  const { data, isPending, isError, isSuccess, refetch } = useQuery({
    queryKey: ["getReasonCodesByDateRange", dateRange.start, dateRange.end],
    refetchInterval: 3 * 60 * 1000, // Every three minutes
    queryFn: ({ signal }) =>
      axiosClient
        .get<ReasonCodesResponse>(
          `${getBackendUrl()}/reason-codes/by-date-range?from=${
            dateRange.start
          }&to=${dateRange.end}`,
          {
            signal,
          },
        )
        .then((res) => ReasonCodesSchema.parse(res.data)),
  });

  const onRowClick = (reasonCode: ReasonCodeItem) => {
    setModalOpen(true);
    setSelectedRC(reasonCode);
  };

  // Sorting of table based on its columns
  const [sortDirection, setSortDirection] = useState<SortDirection>("DESC");
  const [sortColumn, setSortColumn] =
    useState<keyof ReasonCodeItem>("registeredAt");

  const tableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <TableFilter />

      {isError && <FailureMessage />}
      {!isError && (
        <TableContainer ref={tableContainerRef}>
          <Flex
            maxW="0px" // This is to make the table not overflow the container
          >
            <Table size="sm" mx={3} pr={3}>
              <Thead position="sticky" top={0} zIndex="1">
                <Tr>
                  {tableColumns.map((column, index) => (
                    <Th
                      key={column.label}
                      padding={1}
                      cursor="pointer"
                      onClick={() => {
                        setSortColumn(column.accessor);
                        setSortDirection((dir) =>
                          dir === "DESC" ? "ASC" : "DESC",
                        );
                      }}
                      style={{
                        borderRadius: `${index === 0 ? "12px" : "0px"} ${
                          index === tableColumns.length - 1 ? "12px" : "0px"
                        } 0px 0px`,
                        backgroundColor: theme.colorBackgroundTertiary,
                        border: "0px",
                      }}
                    >
                      <Button variant="ghost" size="sm">
                        <HStack>
                          <p style={{ whiteSpace: "nowrap" }}>{column.label}</p>
                          {sortColumn === column.accessor && (
                            <DropdownDownFill18Icon
                              style={{
                                transition: "rotate 0.3s ease",
                                rotate:
                                  sortDirection === "DESC" ? "none" : "-180deg",
                              }}
                            />
                          )}
                        </HStack>
                      </Button>
                    </Th>
                  ))}
                </Tr>
              </Thead>

              <Tbody>
                {isPending && (
                  <TableSkeleton
                    numOfRows={6}
                    numOfColumns={tableColumns.length}
                  />
                )}
                {isSuccess && (
                  <TableContent
                    data={data}
                    onRowClick={onRowClick}
                    trainFilter={debouncedTrainId}
                    codeFilter={debouncedCode}
                    sortOptions={{ sortColumn, sortDirection }}
                    tableContainerRef={tableContainerRef}
                  />
                )}
              </Tbody>
            </Table>
          </Flex>
        </TableContainer>
      )}

      {isSuccess && selectedRC && modalOpen && (
        <EditReasonCodeModal
          closeModal={() => setModalOpen(false)}
          reasonCodeItem={selectedRC}
          refetchReasonCodes={refetch}
        />
      )}
    </Container>
  );
};

export default withErrorBoundary(ReasonCodes);
