import { BoxProps, FormControl, FormErrorMessage } from "@vygruppen/spor-react";
import { FieldErrorOrUndefined } from "./formSchema";

export const FormControlErrorMessage = ({
  field,
  ...rest
}: {
  field: FieldErrorOrUndefined;
} & BoxProps) => (
  <FormControl {...rest} isInvalid={!!field}>
    <FormErrorMessage data-testid="form-control-error-msg">
      {field?.message}
    </FormErrorMessage>
  </FormControl>
);
