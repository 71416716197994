import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import { InternalMessageResponse } from "features/CenterContent/RoleContent/InternalMessage/schema/responseSchema";
import { INTERNAL_EVENTS_QUERY_KEY } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewButtonAndModal";
import { GlobalEventType } from "shared/types/operationalInformation";

export const useInternalMessages = () => {
  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" at badges
  const internalMessagesType: GlobalEventType = "GLOBAL_INTERNAL_INFO";
  const internalMessageEvents = useQuery({
    queryKey: [INTERNAL_EVENTS_QUERY_KEY],
    staleTime: 0,
    enabled: internalMessageEnabled,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InternalMessageResponse[]>(
          `${getBackendUrl()}/global-information/visible/NO?type=${internalMessagesType}`,
          {
            signal,
          },
        )
        .then((res) => res.data.reverse()),
  });
  return internalMessageEvents;
};
