import { toCalendarDate, toTime } from "@internationalized/date";
import {
  DateRangePicker,
  FormControl,
  HStack,
  Stack,
  TimePicker,
} from "@vygruppen/spor-react";
import { InfrastructureForm } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";

export const TimedDuration: FC = () => {
  const { setValue, getValues } = useFormContext<InfrastructureForm>();

  return (
    <Stack spacing={2}>
      <FormControl>
        <DateRangePicker
          variant="base"
          startLabel="Startdato"
          endLabel="Sluttdato"
          aria-label="Dato"
          value={{
            start: toCalendarDate(
              getValues("infrastructureForm.durations.from_time"),
            ),
            end: toCalendarDate(
              getValues("infrastructureForm.durations.to_time"),
            ),
          }}
          onChange={(date) => {
            if (date !== null) {
              if (date.start != null) {
                setValue(
                  "infrastructureForm.durations.from_time.day",
                  date.start.day ?? 0,
                );
                setValue(
                  "infrastructureForm.durations.from_time.month",
                  date.start.month ?? 0,
                );
                setValue(
                  "infrastructureForm.durations.from_time.year",
                  date.start.year ?? 0,
                );
              }
              if (date.end !== null) {
                setValue(
                  "infrastructureForm.durations.to_time.day",
                  date.end.day ?? 0,
                );
                setValue(
                  "infrastructureForm.durations.to_time.month",
                  date.end.month ?? 0,
                );
                setValue(
                  "infrastructureForm.durations.to_time.year",
                  date.end.year ?? 0,
                );
              }
            }
          }}
        />
      </FormControl>
      <HStack spacing={1} width="min-content">
        <TimePicker
          label="Fra kl"
          minuteInterval={5}
          value={toTime(getValues("infrastructureForm.durations.from_time"))}
          onChange={(time) => {
            if (time !== null) {
              setValue(
                "infrastructureForm.durations.from_time.hour",
                time.hour ?? 0,
              );
              setValue(
                "infrastructureForm.durations.from_time.minute",
                time.minute ?? 0,
              );
            }
          }}
        />
        <TimePicker
          label="Til kl"
          minuteInterval={5}
          value={toTime(getValues("infrastructureForm.durations.to_time"))}
          onChange={(time) => {
            if (time !== null) {
              setValue(
                "infrastructureForm.durations.to_time.hour",
                time.hour ?? 0,
              );
              setValue(
                "infrastructureForm.durations.to_time.minute",
                time.minute ?? 0,
              );
            }
          }}
        />
      </HStack>
    </Stack>
  );
};
