import { Box, tokens } from "@vygruppen/spor-react";
import { TodoOplogg } from "features/TodoOploggSidebar/TodoOploggSidebar";
import VehicleSidebar from "features/VehicleSidebar/VehicleSidebar";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import { useStretchBuilder } from "stores/useStretchBuilder";
import styled from "styled-components";

const CenterContentWrapper = styled(Box)<{ $modal: boolean }>`
  grid-area: main;
  box-sizing: border-box;
  justify-self: stretch;
  min-height: 0; // https://css-tricks.com/preventing-a-grid-blowout/
  height: 100%;
  display: flex;
  flex-direction: column;
  filter: ${({ theme }) => theme.dropShadow};
  z-index: ${({ $modal }) =>
    $modal
      ? tokens.default.depth["z-index"].overlay
      : tokens.default.depth["z-index"].base};
`;

export const DashboardLayout: FC = () => {
  const [isStretchBuilderMode] = useStretchBuilder(
    (state) => [state.isStretchBuilderMode],
    Object.is,
  );

  return (
    <>
      <VehicleSidebar />
      <CenterContentWrapper $modal={isStretchBuilderMode()}>
        <Outlet />
      </CenterContentWrapper>
      <TodoOplogg />
    </>
  );
};
