import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button, Flex } from "@vygruppen/spor-react";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import CreateInternalMessageModal from "features/CenterContent/RoleContent/InternalMessage/CreateInternalMessageModal";
import { CreateTrainInfoModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/CreateTrainInfoModal";
import { getInfoMessages } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/getInfoMessages";
import { useTrainInformation } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/useTrainInformation";
import { useTrainInternalMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/useTrainInternalMessages";
import { getOpenStates } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/utils";
import { DetailsParams } from "features/CenterContent/VehicleDetails/TrainDetails/TrainDetails";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoteOutline24Icon } from "shared/icons/NoteIcon";
import { useDropsRole } from "stores/useDropsRole";
import styled from "styled-components";

const TrainInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 6px;
  width: 100%;
`;

export const OperationalTrainInfo = () => {
  const { trainNumber, trainDate } = useParams<DetailsParams>();
  const internalMessagesInformation = useTrainInternalMessage();
  const trainInformation = useTrainInformation();
  const trainInfoWithOpenState = getOpenStates(trainInformation.data);

  const { isVaktleder, isLokleder } = useDropsRole();

  const [internalMessageModalOpen, setInternalMessageModalOpen] =
    useState<boolean>(false);
  const [trainInfoModalOpen, setTrainInfoModalOpen] = useState<boolean>(false);
  const internalMessageEnabled = useFeatureFlag("internalMessage").enabled; // Enabled: Show "Intern melding" at train page

  if (trainNumber === undefined || trainDate === undefined) {
    return (
      <FailureMessage customMessage="Kunne ikke hente tognummer eller dato for dette toget." />
    );
  }

  return (
    <TrainInfoWrapper>
      <Flex
        gap={2}
        mb={2}
        display={
          internalMessageEnabled || isVaktleder() || isLokleder()
            ? "flex"
            : "none"
        }
        justifyContent="flex-end"
        flexWrap="wrap"
      >
        {internalMessageEnabled && ( // TODO: Find out which roles should have access to this button
          <Button
            variant="tertiary"
            onClick={() => setInternalMessageModalOpen(true)}
            leftIcon={<NoteOutline24Icon />}
          >
            Intern melding
          </Button>
        )}
        {(isVaktleder() || isLokleder()) && (
          <Button
            onClick={() => setTrainInfoModalOpen(true)}
            rightIcon={<AddOutline24Icon />}
          >
            Ny hendelse
          </Button>
        )}
      </Flex>
      {getInfoMessages(trainInformation, internalMessagesInformation)}
      {internalMessageModalOpen && (
        <CreateInternalMessageModal
          train={{
            identifier: trainNumber,
            nominalDate: trainDate,
            countryCode: "NO", // TODO: CountryCode should not be hardcoded
          }}
          setModalOpen={setInternalMessageModalOpen}
        />
      )}
      {trainInfoModalOpen && (
        <CreateTrainInfoModal
          train={{
            identifier: trainNumber,
            nominalDate: trainDate,
            countryCode: "NO", // TODO: CountryCode should not be hardcoded
          }}
          setModalOpen={setTrainInfoModalOpen}
          trainInfoWithOpenState={trainInfoWithOpenState}
        />
      )}
    </TrainInfoWrapper>
  );
};
