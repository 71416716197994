// Store custom colors with representative names here.
// Use in local variables within each theme-file

export const appPalette = {
  // Grey
  darkerGrey: "#1C1C1D", // Based on grey 900
  carbon: "#363638", // remove when spor tokens is updated
  iron: "#4F5054", // remove when spor tokens is updated
  // Green
  interstellar: "#010B0A", // remove when spor tokens is updated
  new_night: "#001A18", // remove when spor tokens is updated
  jungle: "#012622", // remove when spor tokens is updated
  nightLighter: "#012925",
  blueGreen40: "#35625C",
  seaMist40: "#526D6A",
  nightTeal20: "#CBD4D1",
  green25darker: "#F0F5F2",
  green25: "#F2F7F4",
  green10: "#FDFFFD",
  // Blue
  navy: "#032647", // remove when spor tokens is updated
  royal: "#041A2F", // remove when spor tokens is updated
  blueberry: "#4B78B0",
  // Yellow
  coffee: "#413300", // remove when spor tokens is updated
  olive: "#2A2203", // remove when spor tokens is updated
  bronce: "#8A6404", // remove when spor tokens is updated
  // Orange
  new_chocolate: "#3A1E0E", // remove when spor tokens is updated
  toast: "#28160A", // remove when spor tokens is updated
  russet: "#84441F", // remove when spor tokens is updated
  autumn: "#BB4D0F",
  // Red
  burgundy: "#581F1F", // remove when spor tokens is updated
  maroon: "#371818", // remove when spor tokens is updated
  crimson: "#932B2B", // remove when spor tokens is updated
  // Expansions
  wine: "#99002E",
  raspberry: "#CC003D",
  rose: "#FF3370",
};
