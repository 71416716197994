import { CalendarDateTime, toCalendarDateTime } from "@internationalized/date";
import { createRulesFromPeriodicDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/periodicDurationUtils";
import {
  FormSchemaDuration,
  OpenEndedFormDuration,
  PeriodicFormDuration,
  TimedFormDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { Duration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";

const DEFAULT_TIME_HORIZON_IN_MINUTES = "150";

export const dateToCalendarDateTime = (date: Date) =>
  new CalendarDateTime(
    date.getFullYear(),
    date.getMonth() + 1,
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
  );

export const getCurrentCalendarDateTime = (plusHours: number = 0) => {
  const now = new Date();
  now.setHours(now.getHours() + plusHours);
  return dateToCalendarDateTime(now);
};

/**
 * Gives the CalendarDateTime for the next day at 03:00,
 * which is during low traffic hours, towards the end of yesterday's plans,
 * and before most new plans take effect.
 * Example:
 * Input: Monday 0:00 - Output: Tuesday 3:00 (27:00 hours gap)
 * Input: Monday 12:00 - Output: Tuesday 3:00 (15:00 hours gap)
 * Input: Monday 23:59 - Output: Tuesday 3:00 (3:01 hours gap)
 */
export const getNextDayStartOfPlanCalendarDateTime = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  now.setHours(3);
  now.setMinutes(0);
  return dateToCalendarDateTime(now);
};

export const getInitialOpenEndedDuration = (): OpenEndedFormDuration => ({
  type: "OPEN_ENDED",
  from_time: getCurrentCalendarDateTime(),
  extension_time_in_minutes: DEFAULT_TIME_HORIZON_IN_MINUTES,
});

export const getInitialTimedDuration = (): TimedFormDuration => ({
  type: "TIMED",
  from_time: getCurrentCalendarDateTime().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  }),
  to_time: getCurrentCalendarDateTime().set({
    hour: 23,
    minute: 59,
    second: 0,
    millisecond: 0,
  }),
});

export const initialPeriodicDuration: PeriodicFormDuration = {
  type: "PERIODIC",
  rules: [],
};

export const getInitialDuration = (duration: Duration): FormSchemaDuration => {
  switch (duration.type) {
    case "PERIODIC": {
      const rules = createRulesFromPeriodicDuration(duration);
      return { type: "PERIODIC", rules };
    }
    case "TIMED": {
      return {
        type: "TIMED",
        from_time: toCalendarDateTime(duration.from_time),
        to_time: toCalendarDateTime(duration.to_time),
      };
    }

    case "OPEN_ENDED":
    default: {
      return { ...duration, from_time: toCalendarDateTime(duration.from_time) };
    }
  }
};
