import {
  getCognitoAccessToken,
  ICognitoTokenRequest,
} from "api/cognito/cognito";
import {
  getCognitoState,
  setDropsToken,
  setRefreshToken,
} from "api/dropsLocalStorage";
import { TrainLoader } from "app/Auth/Login/TrainLoader";
import { log, LogLevel } from "logging/datadogBrowserLogs";
import { useEffect } from "react";

/**
 * This is the page that cognito will redirect back to after authentication
 * @constructor
 */
const LoginResult = () => {
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const authenticationCode = queryParams.get("code");
    const state = queryParams.get("state");
    const pkceCodeVerifier = localStorage.getItem("PKCE_CODE_VERIFIER");

    if (state !== getCognitoState()) {
      log(
        LogLevel.error,
        "LoginResult",
        "State param mismatch on login redirect",
      );
      window.location.href = "/";
    } else if (authenticationCode && pkceCodeVerifier) {
      const cognitoRequestBody: ICognitoTokenRequest = {
        authenticationCode,
        pkceVerifier: pkceCodeVerifier,
      };
      getCognitoAccessToken(cognitoRequestBody).then((response) => {
        setDropsToken(response.data.access_token);
        setRefreshToken(response.data.refresh_token);
        // wait 2 seconds to allow the loader to finish its animation
        setTimeout(() => {
          // by redirecting to / the AuthBoundary will perform authorization checks
          window.location.href = "/";
        }, 2000);
      });
    } else {
      log(
        LogLevel.error,
        "LoginResult",
        "Missing authenticationCode or pkceCodeVerifier in LoginResult ",
      );
      window.location.href = "/";
    }
  }, []);

  return <TrainLoader />;
};

export default LoginResult;
