import { z } from "zod";

const internalMessageFormSchema = z.object({
  uuid: z.string().optional(),
  message: z.string(),
  user: z.string().optional(),
  updatedAt: z.string().optional(),
});

export const internalMessagesFormSchema = internalMessageFormSchema
  .array()
  .nullish();

export type InternalMessagesForm = z.infer<typeof internalMessagesFormSchema>;
