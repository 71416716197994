import { trainInformationAggregatedSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import z from "zod";

const TrainIdSchema = z.object({
  identifier: z.string(),
  nominalDate: z.string(),
  countryCode: z.enum(["NO", "SE"]),
});

const LegSchema = z.object({
  fromJbvId: z.string(),
  toJbvId: z.string(),
});

const AffectedTrainSchema = z.object({
  trainId: TrainIdSchema,
  eventUuid: z.string(),
  origin: z.string().nullable(),
  destination: z.string().nullable(),
  eventStretchName: z.string(),
  incidentArrivalTime: z.coerce.date().nullable(),
  incidentExitTime: z.coerce.date().nullable(),
  incidentId: z.string(),
  referencedOperationalTrainInformation:
    trainInformationAggregatedSchema.array(),
  otherOperationalTrainInformation: trainInformationAggregatedSchema.array(),
  lineId: z.string().nullable(),
  legs: z.array(LegSchema),
  state: z.enum(["AFFECTED", "NOT_AFFECTED", "INDIRECTLY_AFFECTED"]),
});

export const AffectedTrainsResponseSchema = z.object({
  affectedTrains: z.array(AffectedTrainSchema),
  handledAffectedTrains: z.array(AffectedTrainSchema),

  errorMessages: z.array(z.string()),
});

export type AffectedTrainsResponse = z.infer<
  typeof AffectedTrainsResponseSchema
>;
export type AffectedTrain = z.infer<typeof AffectedTrainSchema>;
