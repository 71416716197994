import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { ResultList } from "features/VehicleSidebar/VehicleList/common/ResultList";
import { ResultListWrapper } from "features/VehicleSidebar/VehicleList/common/ResultListWrapper";
import { useFilter } from "features/VehicleSidebar/VehicleList/Filtering/useFilter";
import { VehicleSearchFilter } from "features/VehicleSidebar/VehicleList/Filtering/VehicleSearchFilter";
import { FC, RefObject, useMemo } from "react";
import { generatePath, useLocation } from "react-router-dom";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { useInfiniteScroll } from "shared/hooks/useInfiniteScroll";
import { getDate } from "shared/utils/dateTimeUtils";
import { BUS } from "shared/utils/routes";
import RenderNoDataMessage, {
  RenderMessage,
} from "../common/RenderNoDataMessage";
import { filterAlternativeTransport } from "../Filtering/vehicleFiltering";
import { processTransportList } from "../utils";
import { AlternativeTransportElement } from "./AlternativeTransportElement";
import { useAlternativeTransportData } from "./useAlternativeTransportData";
import { useVyatData } from "./useVyAtData";

type AlternativeTransportListProps = {
  searchQuery: string;
  selectedDate: Date;
  showOldVehicles: boolean;
  scrollBoxRef: RefObject<HTMLDivElement>;
};

type Location = {
  latitude: string;
  longitude: string;
  timestamp: string;
};

type OperationalVehicle = {
  vehicleId: string;
  latestLocation: Location;
};

export type OperationalVehiclesCollection = {
  operationalVehicles: OperationalVehicle[];
};

const AlternativeTransportList: FC<AlternativeTransportListProps> = ({
  searchQuery,
  selectedDate,
  showOldVehicles,
  scrollBoxRef,
}) => {
  const day = getDate(selectedDate);
  const { pathname } = useLocation();

  const { status, data } = useAlternativeTransportData(day);

  const { vyAtData } = useVyatData();

  const filterOptions = ["Skiftnummer", "Telefonnummer", "Tognummer"];
  const {
    selectedFilter,
    handleFilterChange,
    filterOptions: options,
  } = useFilter(filterOptions);

  const alternativeTransportVehicleList =
    status === "success" && data ? data.vehicles : [];

  const processedList = processTransportList({
    transportList: alternativeTransportVehicleList,
    showOldVehicles,
    timeKey: "scheduledArrivalTimeDestination",
  });

  const filteredAlternativeTransportList = useMemo(
    () =>
      processedList.filter((alternativeTransport) =>
        filterAlternativeTransport(
          alternativeTransport,
          searchQuery,
          selectedFilter,
        ),
      ),
    [
      processedList,
      searchQuery,
      selectedFilter,
      filterAlternativeTransport,
      showOldVehicles,
    ],
  );

  const { numberOfItemsToRender } = useInfiniteScroll({
    containerRef: scrollBoxRef,
    initialItemsToRender: 20,
    maxItemsToRender: filteredAlternativeTransportList.length,
  });

  const listToShow =
    searchQuery.length > 0 ? filteredAlternativeTransportList : processedList;

  switch (status) {
    case "pending":
      return <SkeletonLoader skeletonType="trainList" />;
    case "success":
      return (
        <>
          <VehicleSearchFilter
            searchQuery={searchQuery}
            resultLength={filteredAlternativeTransportList.length}
            filterOptions={options}
            selectedFilter={selectedFilter}
            handleFilterChange={handleFilterChange}
          />

          <ResultListWrapper role="tabpanel">
            <ResultList>
              {listToShow.length > 0 ? (
                listToShow
                  .slice(0, numberOfItemsToRender)
                  .map((alternativeTransport) => (
                    <AlternativeTransportElement
                      key={alternativeTransport.id}
                      alternativeTransport={alternativeTransport}
                      elementSelected={
                        pathname ===
                        generatePath(BUS, {
                          vehicleUuid: alternativeTransport.id,
                        })
                      }
                      hasTracking={
                        vyAtData?.operationalVehicles.some(
                          (it) => it.vehicleId === alternativeTransport.id,
                        ) ?? false
                      }
                    />
                  ))
              ) : (
                <RenderNoDataMessage
                  processedList={processedList}
                  renderMessage={RenderMessage.Alternative}
                />
              )}
            </ResultList>
          </ResultListWrapper>
        </>
      );
    case "error":
    default:
      return <FailureMessage style={{ margin: "12px" }} />;
  }
};

export default withErrorBoundary(AlternativeTransportList);
[1, 3].some((el) => el === 2);
