import { useQuery } from "@tanstack/react-query";
import {
  CalendarOutline18Icon,
  WarningFill18Icon,
  WarningOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { Flex } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { EventsOverviewModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/EventsOverviewModal";
import { useGlobalInformation } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/useGlobalInformation";
import {
  InfrastructureResponse,
  upcomingEventsResponseSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/responseSchema";
import { useState } from "react";
import { SemanticButton } from "shared/components/buttons/SemanticButton";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import { useDropsRole } from "stores/useDropsRole";
import { useTheme } from "styled-components";

export const UPCOMING_EVENTS_QUERY_KEY = "upcomingInfrastructureEvents";
export const INTERNAL_EVENTS_QUERY_KEY = "internalEvents";

export const EventsOverviewButtonAndModal = () => {
  const [showModal, setShowModal] = useState(false);
  const { isVaktleder } = useDropsRole();

  const upcomingInfrastructureEvents = useQuery({
    queryKey: [UPCOMING_EVENTS_QUERY_KEY],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InfrastructureResponse[]>(
          `${getBackendUrl()}/infrastructure-event/upcoming?countryCode=NO`,
          {
            signal,
          },
        )
        .then((res) => upcomingEventsResponseSchema.parse(res.data)),
  });

  const { globalEvents } = useGlobalInformation();

  const numOfGlobalEvents = globalEvents.isSuccess
    ? globalEvents.data.length
    : 0;
  const numberOfEvents = upcomingInfrastructureEvents.isSuccess
    ? upcomingInfrastructureEvents.data.length + numOfGlobalEvents
    : 0;

  const isActiveGlobalEvents =
    globalEvents.isSuccess && globalEvents.data.length > 0;

  const theme = useTheme();

  const severity =
    isActiveGlobalEvents && isVaktleder() ? "warning" : undefined; // No severity

  const Warning18Icon =
    severity === "warning" ? WarningFill18Icon : WarningOutline18Icon;

  const selectedTab =
    isVaktleder() && (globalEvents.data?.length ?? 0) > 0
      ? "globalEvents"
      : "upcomingEvents";

  const header = isVaktleder() ? "Oversikt hendelser" : "Fremtidige hendelser";

  return (
    <>
      <SemanticButton
        severity={severity}
        variant="tertiary"
        size="md"
        leftIcon={isVaktleder() ? <Warning18Icon /> : <CalendarOutline18Icon />}
        aria-label="Se fremtidige hendelser"
        onClick={() => setShowModal(true)}
        style={{ width: "fit-content" }}
        pointerEvents="auto"
      >
        {isVaktleder() ? (
          <Flex
            gap="6px"
            alignItems="center"
            color={
              severity === "warning"
                ? theme.colorWarningTextSecondary
                : theme.colorNeutralTextPrimary
            }
          >
            Oversikt hendelser
            <AlertsCounter
              alerts={numberOfEvents}
              severity={severity ?? "neutral"}
            />
          </Flex>
        ) : (
          "Fremtidige hendelser"
        )}
      </SemanticButton>

      {showModal && (
        <EventsOverviewModal
          onClose={() => setShowModal(false)}
          header={header}
          selectedTab={selectedTab}
          upcomingInfrastructureEvents={upcomingInfrastructureEvents}
          globalEvents={globalEvents}
        />
      )}
    </>
  );
};
