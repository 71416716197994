import { HStack, Table, Text, tokens, Tr } from "@vygruppen/spor-react";
import styled from "styled-components";

export const HistoryCustomTableStyle = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0px;
  }

  tr {
    margin-bottom: 12px;
    border: 1px solid ${({ theme }) => theme.colorHoverItem};
    border-radius: 8px;
  }

  tr:hover > td {
    background-color: ${({ theme }) => theme.colorSelected};
  }

  td,
  th {
    border-bottom: none;
    padding-left: 12px;
    padding-right: 6px;
    padding-top: 18px;
    padding-bottom: 18px;
    background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  }

  th {
    background-color: ${({ theme }) => theme.colorBackgroundTertiary};
    cursor: default;
  }

  tr > td:first-of-type,
  tr > th:first-of-type {
    border-top-left-radius: ${tokens.default.size["border-radius"].xs};
    border-bottom-left-radius: ${tokens.default.size["border-radius"].xs};
    cursor: default;
    :hover {
      background-color: transparent;
    }
  }

  tr > td:last-of-type,
  tr > th:last-of-type {
    border-top-right-radius: ${tokens.default.size["border-radius"].xs};
    border-bottom-right-radius: ${tokens.default.size["border-radius"].xs};
  }

  tfoot > tr > td {
    cursor: default;
  }

  tfoot:hover > tr > td {
    background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  }
`;

export const CustomText = styled(Text)`
  @container (width < 1200px) {
    &&& {
      display: none;
    }
  }
`;

export const HistoryWrapper = styled.div`
  container-type: inline-size;
  grid-area: 2 / 1 / 3 / 4;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  overflow-y: auto;
  height: 100%; // <1200px
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colorBorder};
  padding-left: 24px;
  padding-right: 24px;
  -webkit-scrollbar: none; /* WebKit */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @container (width < 1200px) {
    .DontShowIfSmallWidth {
      display: none;
    }
  }
`;

export const ResponsiveHStack = styled(HStack)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const CustomResponsiveHStack = styled(HStack)`
    gap: 1.5rem;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @container (width < 1200px) {
     {
    gap: 0.2rem;
    }
}
`;

export const CustomResponsiveTr = styled(Tr)`
  display: grid;
  grid-template-columns: 130px 150px 140fr 100px 190px 300fr;
  @container (width < 1200px) {
    grid-template-columns: 130px 150px 140px 190px 300fr;
  }
  margin-bottom: 5px;
  cursor: pointer;
`;
