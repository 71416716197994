import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";

export type VehicleSetVariants = {
  vehicleSetVariants: VehicleSetVariant[];
};

export type VehicleSetVariant = {
  setId: string;
  setType: string;
};
export function useFetchVehicleSetVariants(date: string) {
  return useQuery<VehicleSetVariants>({
    queryKey: ["vehicle-set-variants", date], // Unique query key
    queryFn: () =>
      queryFnGET<VehicleSetVariants>({
        signal: undefined,
        url: `${getBackendUrl()}/rolling-stock/vehicle-set-variants?validAt=${date}`,
      }).then((response) => response ?? { vehicleSetVariants: [] }),
    enabled: !!date,
    staleTime: 1000 * 60 * 5,
    retry: 2,
  });
}
