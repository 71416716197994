import { EditOutline24Icon } from "@vygruppen/spor-icon-react";
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  HStack,
  Skeleton,
  Spacer,
  Stack,
} from "@vygruppen/spor-react";
import { isToday } from "date-fns";
import {
  AffectedTrain,
  AffectedTrain as AffectedTrainType,
} from "features/CenterContent/RoleContent/Vaktleder/types";
import { EditTrainInfoModal } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/EditTrainInfoModal";
import DashedLine from "features/CenterContent/VehicleDetails/shared/DashedLine";
import { VStackContainer } from "features/VehicleSidebar/AffectedTrains/styles";
import {
  getEventSemantics,
  getInfoTitle,
} from "features/VehicleSidebar/AffectedTrains/utils/utils";
import { FC, useState } from "react";
import { DropsBadge } from "shared/components/badge/DropsBadge";
import { Text } from "shared/components/typography/TitleStyles";
import { InfrastructureIncidentIcon } from "shared/icons/InfrastructureIncidentIcon";
import { formatDateString } from "shared/utils/datetime";
import styled, { useTheme } from "styled-components";

type HandledTrainListElementProps = {
  train: AffectedTrainType;
  selectedTrainIds: AffectedTrain[];
  selectTrain: (train: AffectedTrain) => void;
  isLoading: boolean;
};

const LocalVStackWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  gap: 6px;
  grid-template-areas:
    "trainId"
    "stations"
    "affected"
    "badge";
  align-items: center;

  @container VStackContainer (width > 250px) {
    grid-template-columns: 1fr 2fr;
    grid-template-areas:
      "trainId affected"
      "stations badge";
  }
`;

const HandledTrainListElement: FC<HandledTrainListElementProps> = ({
  train,
  selectedTrainIds,
  selectTrain,
  isLoading,
}) => {
  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const trainInfo = train.referencedOperationalTrainInformation.at(0);
  const newestVersion = trainInfo?.versions.at(0);
  const newestVersionIsOpen = newestVersion?.state === "OPEN";

  const trainDateIsToday = isToday(new Date(train.trainId.nominalDate));

  return (
    <>
      <Stack
        as="li"
        w="100%"
        gap={0}
        sx={{
          "&:nth-child(1)": {
            borderTop: `1px solid ${theme.colorBorder}`,
            borderRadius: "6px",
          },
        }}
      >
        <Grid
          autoFlow="column"
          autoColumns="min-content 1.5fr 1fr"
          px={2}
          py={2}
          columnGap={0}
          backgroundColor={theme.colorBackgroundSecondary}
          borderRadius={6}
          borderRightWidth="1px"
          borderLeftWidth="1px"
          borderColor={theme.colorBorder}
        >
          {newestVersionIsOpen && !isLoading ? (
            <Checkbox
              pr={3}
              isChecked={selectedTrainIds.includes(train)}
              name="Select Train"
              onChange={() => selectTrain(train)}
            />
          ) : (
            <Spacer width={7} />
          )}

          <VStackContainer>
            <LocalVStackWrap>
              <Text style={{ gridArea: "trainId" }} bold>
                {train.trainId.identifier}
              </Text>

              <Text style={{ gridArea: "stations" }}>
                {train.origin} - {train.destination}
              </Text>

              <HStack gridArea="affected">
                <InfrastructureIncidentIcon />
                <Text>{train.eventStretchName}</Text>
              </HStack>

              <HStack style={{ gridArea: "badge" }} alignItems="flex-start">
                {train.referencedOperationalTrainInformation
                  .filter((info) => info.versions.length > 0)
                  .map((info) => {
                    const version = info.versions[0];
                    const isClosed = version.state !== "OPEN";
                    return isLoading ? (
                      <Skeleton
                        key={info.latestInfoUuid}
                        height={4}
                        width="100px"
                        borderRadius="16px"
                      />
                    ) : (
                      <Flex flexDir="row" gap="1">
                        <DropsBadge
                          semantics={
                            isClosed ? "info" : getEventSemantics(version.type)
                          }
                          key={info.latestInfoUuid}
                        >
                          {getInfoTitle(version)}
                        </DropsBadge>
                        {isClosed && (
                          <DropsBadge
                            semantics="success"
                            key={info.latestInfoUuid}
                          >
                            Friskmeldt
                          </DropsBadge>
                        )}
                      </Flex>
                    );
                  })}
              </HStack>
              {!trainDateIsToday && (
                <Text secondary>
                  {formatDateString(train.trainId.nominalDate, "dd.MM.yy")}
                </Text>
              )}
            </LocalVStackWrap>
          </VStackContainer>

          <Flex
            gap={1}
            alignContent="center"
            flexWrap="wrap"
            justifySelf="flex-end"
            paddingBottom={4}
          >
            {isLoading && (
              <Skeleton height={6} width="120px" m="auto" borderRadius={5} />
            )}

            {!isLoading && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => setModalOpen(true)}
                leftIcon={<EditOutline24Icon />}
              >
                {newestVersionIsOpen ? "Endre" : "Gjenåpne"}
              </Button>
            )}
          </Flex>
        </Grid>
        <DashedLine color={theme.colorBackgroundSecondary} />
      </Stack>
      {modalOpen && trainInfo && (
        <EditTrainInfoModal
          uuid={trainInfo.latestInfoUuid}
          trainId={train.trainId.identifier}
          nominalDate={train.trainId.nominalDate}
          countryCode={train.trainId.countryCode}
          setModalOpen={setModalOpen}
          trainInfoWithOpenState={[]}
          title={newestVersionIsOpen ? undefined : "Gjenåpne hendelse"}
          submitButtonLabel={newestVersionIsOpen ? undefined : "Gjenåpne"}
        />
      )}
    </>
  );
};

export default HandledTrainListElement;
