import {
  Divider,
  Flex,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@vygruppen/spor-react";
import { InternalMessageEventsList } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/InternalMessageEventsList";
import { partitionInternalMessagesRelatedToEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { AlertsCounter } from "shared/components/dataDisplay/AlertsCounter";
import { InternalMessageResponse } from "../../stationEvents/stationEventResponseSchema";

type InternalMessageEventsProps = {
  data: InternalMessageResponse[];
  onEditClick: (event: InternalMessageResponse) => void;
};

export const InternalMessageEvents = ({
  data,
  onEditClick,
}: InternalMessageEventsProps) => {
  const [internalMessagesNoEvent, internalMessagesEvent] =
    partitionInternalMessagesRelatedToEvent(data);
  return (
    <Tabs
      variant="accent"
      colorScheme="base"
      isFitted
      gap={3}
      size="sm"
      marginTop={3}
    >
      <TabPanels>
        <TabPanel display="flex" gap="12px" flexDir="column">
          <Flex gap="6px" alignItems="center">
            <Text fontWeight={600}>Frittstående meldinger</Text>
            <AlertsCounter
              alerts={internalMessagesNoEvent.length}
              severity="neutral"
            />
          </Flex>
          <InternalMessageEventsList
            data={internalMessagesNoEvent}
            onEditClick={onEditClick}
          />
          <Divider my="12px" />
          <Text fontWeight={600}>Tilknyttet hendelse</Text>
          <InternalMessageEventsList
            data={internalMessagesEvent}
            onEditClick={onEditClick}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
