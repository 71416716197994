import {
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
} from "@vygruppen/spor-react";
import { FormControlErrorMessage } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/FormControlErrorMessage";
import { TrickFormHookGroupedTrainFormSchema } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/TrainInfoModal/formSchema";
import {
  EventType,
  GenericType,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { isCustomInputType } from "features/CenterContent/shared/operationalInformation/utils";
import { FC, useEffect, useState } from "react";
import {
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { Select } from "shared/components/forms/Select";
import { customInputInfo } from "shared/types/form";
import { getFieldFromNestedObject } from "shared/utils/objectUtils";
import { useTheme } from "styled-components";

export type BatchCommonInputProps = {
  pathPrefix: `\${groupKey}`;
  numberOfGroups: number;
};

type CustomInputProps = {
  items: GenericType[] | EventType[];
  formFieldType: "event" | "reason" | "action";
  isDisabled?: boolean;
  optionDisabledPredicate?: (event: EventType | GenericType) => boolean;
};

const RenderError = ({
  errors,
  errorPath,
}: {
  errors?: FieldErrors<FieldValues>;
  errorPath: string;
}) => {
  if (!errors) {
    return null;
  }

  return (
    <FormControlErrorMessage
      field={getFieldFromNestedObject<FieldError>(errors, errorPath)}
    />
  );
};

export const BatchTrainFormFieldWithCustomInput: FC<
  CustomInputProps & BatchCommonInputProps
> = ({
  pathPrefix,
  numberOfGroups,
  items,
  formFieldType,
  isDisabled = false,
  optionDisabledPredicate = () => false,
}) => {
  const theme = useTheme();

  const {
    formField: _formField,
    label,
    customFormField: _customFormField,
  } = customInputInfo[formFieldType];

  // All groups in a train series have the same value for these fields,
  // Use the values from the first group to display in modal
  const displayTypeFormField = `${pathPrefix}.0.${_formField}` as const;
  const displayCustomFormField = `${pathPrefix}.0.${_customFormField}` as const;

  const [previousDisplayType, setPreviousDisplayType] =
    useState(displayTypeFormField);
  const [previousDisplayCustom, setPreviousDisplayCustom] = useState(
    displayCustomFormField,
  );

  const {
    control,
    register,
    unregister,
    getValues,
    setValue: _setValue,
    formState: { errors },
  } = useFormContext<TrickFormHookGroupedTrainFormSchema>();

  const type = getValues(displayTypeFormField);
  const isCustomInput = isCustomInputType(type);

  // Wrapper function around react-hook-form setValue to keep all groups in
  // a single series synced, and for even narrower field typing.
  const setValue = (
    formField:
      | typeof _formField
      | `${typeof _customFormField}.${"ENG" | "NOB"}`
      | "trainForm.severity",
    value: string,
  ) => {
    for (let i = 0; i < numberOfGroups; i += 1) {
      _setValue(`${pathPrefix}.${i}.${formField}`, value, {
        shouldValidate: true,
      });
    }
  };

  // Unregister old fields when field path changes (e.g. when the user navigates to another series
  // in the batch modal)
  useEffect(() => {
    if (
      displayCustomFormField !== previousDisplayCustom &&
      getValues(previousDisplayCustom)
    ) {
      unregister(
        [`${previousDisplayCustom}.NOB`, `${previousDisplayCustom}.ENG`],
        { keepValue: true },
      );
      setPreviousDisplayCustom(displayCustomFormField);
    }

    if (displayTypeFormField !== previousDisplayType) {
      unregister(previousDisplayType, { keepValue: true });
      setPreviousDisplayType(displayTypeFormField);
    }
  }, [displayTypeFormField, displayCustomFormField]);

  const select = (
    <Select
      label={label}
      placeholder={`Velg en ${label.toLowerCase().replace("*", "")}`}
      {...register(displayTypeFormField)}
      value={type ?? ""}
      onChange={(e) => {
        e.preventDefault();
        setValue(_formField, e.target.value);
      }}
      disabled={isDisabled}
    >
      {items.map((item) => (
        <option
          key={item.type}
          value={item.type}
          disabled={optionDisabledPredicate(item)}
        >
          {item.label}
        </option>
      ))}
    </Select>
  );

  if (!isCustomInput) {
    return select;
  }

  return (
    <Stack
      gap={2}
      gridColumn="span 2"
      padding={4}
      boxShadow={`inset 0 0 0 1px ${theme.colorSeparationLine}`}
      borderRadius="sm"
    >
      {select}
      <Stack gap={2}>
        {formFieldType === "event" && (
          <>
            <FormControl as="fieldset">
              <FormLabel as="legend">Alvorlighetsgrad</FormLabel>
              <Controller
                control={control}
                name={`${pathPrefix}.0.trainForm.severity`}
                render={({ field }) => (
                  <RadioGroup
                    name="severity"
                    value={field.value ?? ""}
                    onChange={(value) => setValue("trainForm.severity", value)}
                  >
                    <Radio value="INFO" name="INFO">
                      Informasjon
                    </Radio>
                    <Radio value="WARNING" name="WARNING">
                      Advarsel
                    </Radio>
                  </RadioGroup>
                )}
              />
            </FormControl>
            <RenderError
              errors={errors}
              errorPath={`${pathPrefix}.0.trainForm.severity`}
            />
          </>
        )}
        <FormControl>
          <Textarea
            label="Norsk tekst"
            {...register(`${displayCustomFormField}.NOB`, {
              value: "",
            })}
            value={getValues(`${displayCustomFormField}.NOB`) ?? ""}
            onChange={(e) => {
              e.preventDefault();
              setValue(`${_customFormField}.NOB`, e.target.value);
            }}
          />
        </FormControl>
        <RenderError
          errors={errors}
          errorPath={`${displayCustomFormField}.NOB`}
        />
        <FormControl>
          <Textarea
            label="Engelsk tekst"
            {...register(`${displayCustomFormField}.ENG`, {
              value: "",
            })}
            value={getValues(`${displayCustomFormField}.ENG`) ?? ""}
            onChange={(e) => {
              e.preventDefault();
              setValue(`${_customFormField}.ENG`, e.target.value);
            }}
          />
        </FormControl>
        <RenderError
          errors={errors}
          errorPath={`${displayCustomFormField}.ENG`}
        />
      </Stack>
    </Stack>
  );
};
