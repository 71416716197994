import { z } from "zod";
import { CheckListType, RollingSockType } from "../utils";

export const FilloutRequestSchema = z.object({
  executedAt: z.string(),
  companyId: z.string(),
  checklistType: z.custom<CheckListType>(),
  vehicleType: z.string(),
  rollingStockId: z.string(),
  rollingStockType: z.custom<RollingSockType>(),
  comment: z.string().optional(),
});

export type FilloutRequest = z.infer<typeof FilloutRequestSchema>;
