import { getBackendUrl } from "api/common";
import { usePatchRequest } from "api/http/hooks";
import { FC, FormEvent, useState } from "react";
import { Button } from "@vygruppen/spor-react";
import {
  AddOutline24Icon,
  SuccessOutline24Icon,
} from "@vygruppen/spor-icon-react";
import { ActionModal } from "shared/components/ActionModal";
import { Text } from "shared/components/typography/TitleStyles";
import { ReserveAlternativeTransport } from "shared/types/alternativeTransport";

type BookingRequestBody = {
  available: boolean;
};

type VehicleBookingProps = {
  vehicle: ReserveAlternativeTransport;
};

export const VehicleBooking: FC<VehicleBookingProps> = ({ vehicle }) => {
  const { id, isAvailable } = vehicle;
  const [showModal, setShowModal] = useState(false);
  const {
    patchRequest,
    patchRequestStatus: openState,
    resetStatus,
  } = usePatchRequest<string, BookingRequestBody>(
    `${getBackendUrl()}/alternativeTransports/reserve/${id}/book`,
  );

  const onCloseModal = () => {
    setShowModal(false);
    resetStatus();
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const body: BookingRequestBody = {
      available: !isAvailable,
    };
    patchRequest(body);
  };

  return (
    <>
      <Button
        variant="tertiary"
        size="sm"
        onClick={() => setShowModal(true)}
        leftIcon={isAvailable ? <AddOutline24Icon /> : <SuccessOutline24Icon />}
      >
        {isAvailable ? "Ta i bruk" : "Fristill"}
      </Button>
      {showModal && (
        <ActionModal
          title={
            isAvailable
              ? "Ta i bruk reservekjøretøy"
              : "Fristill reservekjøretøy"
          }
          actionTitle={isAvailable ? "Ta i bruk" : "Fristill"}
          onClose={onCloseModal}
          onSubmit={onSubmit}
          isLoading={openState.status === "loading"}
          isSuccess={openState.status === "success"}
          isError={openState.status === "failure"}
          successMessage={
            isAvailable
              ? "Kjøretøyet ble tatt i bruk"
              : "Kjøretøyet ble Fristilt"
          }
          failureMessage={`Kunne ikke ${
            isAvailable ? "ta i bruk" : "fristill"
          } reservekjøretøyet. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
        >
          <Text>
            Bussen står som &quot;I bruk&quot; til den fristilles av
            kundeveileder på plattform.
          </Text>
          <Text>
            Husk å loggføre trasè og evt. tog og skiftnummer bussen settes inn
            for.
          </Text>
        </ActionModal>
      )}
    </>
  );
};
