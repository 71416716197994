import { OpenEndedDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/OpenEndedDuration";
import { Prognosis } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/Prognosis";
import { SelectRedirectStretches } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/SelectRedirectStretches";
import { InfrastructureForm } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types/formSchema";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import {
  isInfrastructureStretchClosedEvent,
  isInfrastructureStretchPartiallyClosedEvent,
} from "shared/utils/infrastructureEvent";

export const CommonInfrastructureEvent: FC = () => {
  const { getValues } = useFormContext<InfrastructureForm>();
  const type = getValues("infrastructureForm.type");
  const showRedirectStretches =
    isInfrastructureStretchClosedEvent(type) ||
    isInfrastructureStretchPartiallyClosedEvent(type);
  return (
    <>
      {showRedirectStretches && <SelectRedirectStretches />}
      <OpenEndedDuration />
      <Prognosis />
    </>
  );
};
